import { Observable, pipe, of } from 'rxjs';

import { map, mergeMap } from 'rxjs/operators';

import { Epic, ofType } from 'redux-observable';

import { message } from 'antd';
import { helpers } from '../utils';
import { userAPI } from '../services';

import _ from 'lodash';

// dispatch传进来的action类型
interface PayloadIn {
  type: string;
  payload: {
    [random: string]: any;
  };
}

// epic传到reducer里的action类型
interface PayloadOut {
  type: string;
  payload: {
    [random: string]: any;
  };
}

export const updatePersonalInfo: Epic<PayloadIn, PayloadOut> = (
  action$,
  store
) => {
  return action$.pipe(
    ofType('updatePersonalInfoEpic'),
    mergeMap(action => {
      return userAPI.handleUpdateUser(action.payload).pipe(
        map(res => {
          if (res !== null) {
            message.success('更新成功');
          }
          return helpers.createAction<typeof action.payload>('no');
        })
      );
    })
  );
};
