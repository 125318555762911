import * as React from 'react';
import * as _ from 'lodash';
import { helpers } from '@/utils';
import moment from 'moment';
import {
  Select,
  DatePicker,
  Table,
  Button,
  Input,
  Modal,
  Divider,
  Switch,
  Pagination,
  message,
} from 'antd';
const { RangePicker } = DatePicker;
const confirm = Modal.confirm;
const Option = Select.Option;

import {
  ContainerPropsInterface,
  ContainerStateInterface,
} from '@/routes/containerInterface';

import UserAdd from './userAddPage';
import UserEdit from './userEditPage';
import { multiPlatform } from '@/multiPlatform';
export default class User extends React.PureComponent<
  ContainerPropsInterface<any>,
  ContainerStateInterface
> {
  // 表格列
  public columns: any = [
    {
      align: 'center',
      title: '序号',
      width: 80,
      dataIndex: 'id',
      render: (text, record, index) => (
        <span>
          {index +
            1 +
            this.state.page.num * this.state.page.size -
            this.state.page.size}
        </span>
      ),
    },
    {
      title: '用户名',
      dataIndex: 'name',
      width: 150,
    },
    {
      title: '邮箱账号',
      dataIndex: 'email',
      width: 220,
    },
    // {
    //   title: '可见标签',
    //   dataIndex: 'tags',
    //   render: tags => (
    //     <div style={{ whiteSpace: 'pre-line' }}>
    //       {tags.map(item => (
    //         <span key={item.id}>{item.name}</span>
    //       ))}
    //     </div>
    //   ),
    //   width: 150
    // },
    {
      title: '所在角色组',
      dataIndex: 'role',
      render: role => <span>{_.get(role, 'role_name')}</span>,
      width: 150,
    },
    {
      title: '创建时间',
      dataIndex: 'created_at',
      width: 120,
      render: created_at => (
        <time>
          {created_at.slice(0, 10)}
          <br />
          {created_at.slice(10)}
        </time>
      ),
    },
    {
      title: '最后登录时间',
      dataIndex: 'updated_at',
      width: 150,
      render: updated_at => (
        <time>
          {updated_at.slice(0, 10)}
          <br />
          {updated_at.slice(10)}
        </time>
      ),
    },
    {
      title: '状态',
      dataIndex: 'is_locked',
      width: 150,
      render: is_locked => (
        <p>
          {helpers.isJudge(is_locked === 0)(
            <span style={{ color: '#16a085' }}>启动</span>,
            <span style={{ color: '#ff0000' }}>禁用</span>,
          )}
        </p>
      ),
    },
    {
      title: '启用/停用',
      width: 150,
      dataIndex: 'is_locked',
      render: (text, record) => (
        <p style={{ marginLeft: '10px' }}>
          {helpers.isJudge(this.state.startFlag && this.userId !== record.id)(
            <Switch
              checked={record.is_locked === 0}
              onChange={() => this.setLocked(record.id, record.is_locked)}
            />,
            null,
          )}
        </p>
      ),
    },
    {
      title: '操作',
      dataIndex: 'action',
      width: 80,
      render: (text, record) => (
        <span>
          {helpers.isJudge(this.state.editFlag)(
            <a
              href={'javascript:;'}
              onClick={() =>
                this.openEditWindow({
                  ...record,
                  editTimes: this.editTimes++,
                })
              }
            >
              编辑
            </a>,
            null,
          )}
           <Divider type={'vertical'} />
          {helpers.isJudge(this.state.delFlag && this.userId !== record.id)(
            <a
              href={'javascript:;'}
              onClick={() => this.confirmDelete(record.id)}
            >
              删除
            </a>,
            null
          )}
        </span>
      ),
    },
  ];

  // 启动/停用账户
  public setLocked(id, lockedNum) {
    const isLock = helpers.isJudge(lockedNum === 0)('lock', 'unlock');
    const text = helpers.isJudge(lockedNum === 0)('禁用', '启用');
    const self = this;
    confirm({
      title: `您确定要${text}该账号吗？`,
      onOk() {
        self.props.dispatch({
          type: 'system/setLockedEpic',
          payload: {
            users_id: id,
            isLock,
          },
        });
      },
    });
  }

  // 批量启动/停用账户
  public setArrLocked(lockedNum) {
    const isLock = helpers.isJudge(lockedNum === 0)('lock', 'unlock');
    if (this.selectedRowKeys.length === 0) {
      message.warning('请选择用户再操作！');
      return;
    }
    const self = this;
    self.props.dispatch({
      type: 'system/setLockedEpic',
      payload: {
        users_id: this.selectedRowKeys.join(','),
        isLock,
      },
    });
  }

  // 删除用户
  public confirmDelete(deleteId: number) {
    const self = this;
    confirm({
      title: '您确定要删除该用户吗？',
      onOk() {
        self.props.dispatch({
          type: 'system/deleteUserEpic',
          payload: {
            id: deleteId,
          },
        });
      },
    });
  }

  public editTimes = 0; // 编辑次数

  constructor(props) {
    super(props);
    this.state = {
      list: [], // 用户列表
      roles: [], // 角色列表
      tags: [], // 标签列表
      // 过滤对象组
      filterObj: {
        name: '', // 过滤的名称
        rolesId: undefined, // 过滤的权限
        tagsId: undefined, // 过滤的标签
        isLocked: undefined, // 过滤是否锁定
        startDate: '', // 最近登录时间 开始时间
        endDate: '', // 最近登录时间 结束时间
      },
      editData: {}, // 编辑的系统用户信息
      page: {
        size: 10, // 用户列表的每页大小
        num: 1, // 用户列表的当前页数
      },
      editFlag: false,
      delFlag: false,
      startFlag: false,
      addFlag: false,
    };
  }

  public UserAddComponent = multiPlatform.userAddMulti(UserAdd); // 用户添加
  public UserEditComponent = multiPlatform.userAddMulti(UserEdit); // 用户编辑

  public data: any = []; // 获取的用户列表信息

  public openEditWindow(item: any) {
    this.setState({ editData: item });
  }

  public userId = 0; // 当前用户id

  public componentWillReceiveProps(nextProps) {
    if (nextProps.all.main.userInfo.id !== undefined) {
      this.userId = nextProps.all.main.userInfo.id;
    }
    // 用户列表绑定
    if (nextProps.state.userList !== undefined) {
      if (
        nextProps.state.userList.length !== 0 &&
        !_.isEqual(nextProps.state.userList, this.data)
      ) {
        this.setState({ list: nextProps.state.userList });
        this.data = nextProps.state.userList;
      }

      // 页号
      let pageNum = this.state.page.num;
      if (
        pageNum >
          Math.ceil(nextProps.state.userList.length / this.state.page.size) &&
        Math.ceil(nextProps.state.userList.length / this.state.page.size) !== 0
      ) {
        pageNum = nextProps.state.userList.length / this.state.page.size;
      }

      const buttonPermission = nextProps.all.main.buttonPermission;
      this.setState({
        editFlag: _.includes(buttonPermission, 39),
        delFlag: _.includes(buttonPermission, 40),
        startFlag:
          _.includes(buttonPermission, 43) || _.includes(buttonPermission, 44),
        addFlag: _.includes(buttonPermission, 38),
        page: {
          size: this.state.page.size,
          num: pageNum,
        },
      });
    }

    // 角色列表绑定
    if (nextProps.all.roles.roles !== undefined) {
      if (
        nextProps.all.roles.roles.length !== 0 &&
        !_.isEqual(nextProps.all.roles.roles, this.state.roles)
      ) {
        this.setState({ roles: nextProps.all.roles.roles });
      }
    }

    // 标签列表绑定
    if (nextProps.all.tags.tags !== undefined) {
      if (
        nextProps.all.tags.tags.length !== 0 &&
        !_.isEqual(nextProps.all.tags.tags, this.state.tags)
      ) {
        this.setState({ tags: nextProps.all.tags.tags });
      }
    }
  }

  // 获取用户列表
  public getUserList = () => {
    this.props.dispatch({
      type: 'system/getUserListEpic',
      payload: {
        page_size: 1000,
      },
    });
  };

  public componentWillMount() {
    this.getUserList(); // 获取用户列表

    // 获取角色列表
    if (this.props.all.roles.roles.length === 0) {
      this.getRoles();
    }

    // 获取标签列表
    if (this.props.all.tags.tags.length === 0) {
      this.getTags();
    }
  }

  // 获取角色
  public getRoles = () => {
    this.props.dispatch({
      type: 'system/roles/getRolesEpic',
      payload: {
        page_size: 1000,
      },
    });
  };

  // 获取标签
  public getTags = () => {
    this.props.dispatch({
      type: 'system/tags/getTagsEpic',
      payload: {
        page_size: 1000,
      },
    });
  };

  // 过滤筛选
  public checkFilter(item) {
    const itemDate = new Date(item.updated_at.slice(0, 10)); // 用户日期
    const startDate = new Date(this.state.filterObj.startDate); // 筛选开始日期
    const endDate = new Date(this.state.filterObj.endDate); // 筛选结束日期
    let isShow = true; // 是否显示该条信息
    // 用户名过滤
    if (item.name.indexOf(this.state.filterObj.name) === -1) {
      isShow = false;
    } else if (
      // 角色过滤
      this.state.filterObj.rolesId !== undefined &&
      _.get(item.role, 'id') !== this.state.filterObj.rolesId
    ) {
      isShow = false;
    } else if (
      // 标签过滤
      this.state.filterObj.tagsId !== undefined &&
      item.tags.filter(n => n.id === this.state.filterObj.tagsId).length === 0
    ) {
      isShow = false;
    } else if (
      // 是否锁定过滤
      this.state.filterObj.isLocked !== undefined &&
      item.is_locked !== this.state.filterObj.isLocked
    ) {
      isShow = false;
    } else if (isNaN(this.state.filterObj.startDate)) {
      // 最近登录时间过滤
      if (
        startDate.getTime() > itemDate.getTime() ||
        endDate.getTime() < itemDate.getTime()
      ) {
        isShow = false;
      }
    }

    return isShow;
  }

  // 触发筛选
  public handleFilter() {
    if (this.data.length !== 0) {
      this.setState({
        list: this.data.filter(item => this.checkFilter(item)),
        page: { num: 1, size: this.state.page.size },
      });
    }
  }

  // 筛选重置
  public handleFilterReset() {
    this.setState({
      filterObj: {
        name: '',
        rolesId: undefined,
        tagsId: undefined,
        isLocked: undefined,
        startDate: '',
        endDate: '',
      },
    });
  }

  // 选择筛选的最近登录时间
  public selectLoginDate(dateString) {
    this.setState({
      filterObj: {
        ...this.state.filterObj,
        startDate: dateString[0],
        endDate: dateString[1],
      },
    });
  }

  // 筛选模块渲染
  public renderFilter = () => {
    const dateFormat = 'YYYY/MM/DD';
    const UserAddComponent = this.UserAddComponent;

    // 按钮权限控制
    return (
      <div>
        <p
          style={{
            width: '100%',
            overflow: 'hidden',
          }}
        >
          <span className={'notWrap'}>
            用户名：
            <Input
              type={'text'}
              placeholder={'请输入用户名'}
              value={this.state.filterObj.name}
              onChange={e =>
                this.setState({
                  filterObj: { ...this.state.filterObj, name: e.target.value },
                })
              }
            />
          </span>
          <span className={'notWrap'}>
            所在角色组：
            <Select
              allowClear
              placeholder={'所在角色组'}
              value={this.state.filterObj.rolesId}
              onChange={value =>
                this.setState({
                  filterObj: { ...this.state.filterObj, rolesId: value },
                })
              }
            >
              {this.state.roles.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.role_name}
                </Option>
              ))}
            </Select>
          </span>
          {/* <span className={'notWrap'}>
            可见标签：
            <Select
              allowClear
              placeholder={'请选择标签组'}
              value={this.state.filterObj.tagsId}
              onChange={value =>
                this.setState({
                  filterObj: { ...this.state.filterObj, tagsId: value }
                })
              }
            >
              {this.state.tags.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </span> */}
          <span className={'notWrap'}>
            账户状态：
            <Select
              allowClear
              placeholder={'请选择账户状态'}
              value={this.state.filterObj.isLocked}
              onChange={value =>
                this.setState({
                  filterObj: { ...this.state.filterObj, isLocked: value },
                })
              }
            >
              <Option value={0}>启动</Option>
              <Option value={1}>禁用</Option>
            </Select>
          </span>
          <span className={'notWrap'}>
            最近登录时间：
            <RangePicker
              onChange={(date, dateString) => this.selectLoginDate(dateString)}
              value={helpers.isJudge(this.state.filterObj.startDate === '')(
                null,
                [
                  moment(this.state.filterObj.startDate, dateFormat),
                  moment(this.state.filterObj.endDate, dateFormat),
                ],
              )}
            />
            <Button type={'primary'} onClick={() => this.handleFilter()}>
              筛选
            </Button>
            <Button onClick={() => this.handleFilterReset()}>重置</Button>
          </span>
          {helpers.isJudge(this.state.addFlag)(<UserAddComponent />, null)}
        </p>
      </div>
    );
  };

  public selectedRowKeys = []; // 选中的id数组

  // 列表模块渲染
  public renderList = () => {
    const self = this;
    // 选择框
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.selectedRowKeys = selectedRowKeys;
      },
      getCheckboxProps: record => ({
        disabled: record.id === self.props.all.main.userInfo.id, // Column configuration not to be checked
        name: record.id,
      }),
    };
    return (
      <React.Fragment>
        <Table
          columns={this.columns}
          dataSource={this.state.list}
          rowSelection={rowSelection}
          rowKey={'id'}
          scroll={{ x: 1502, y: 500 }}
        />
        {this.state.list.length !== 0 && this.state.startFlag ? (
          <div className={'paginationNumLeft'} style={{ bottom: '60px' }}>
            <Button
              className={'user_redBtn'}
              onClick={() => this.setArrLocked(0)}
              style={{ marginLeft: 10 }}
            >
              {' '}
              批量停用
            </Button>
            <Button
              className={'user_greenBtn'}
              onClick={() => this.setArrLocked(1)}
              style={{ marginLeft: 10 }}
            >
              {' '}
              批量启用
            </Button>
            <span>
              共有 {this.state.list.length} 条数据{' '}
              {Math.ceil(this.state.list.length / this.state.page.size)} 页
            </span>
          </div>
        ) : null}
      </React.Fragment>
    );
  };

  public render() {
    const UserEditComponent = this.UserEditComponent;
    return (
      <React.Fragment>
        {this.renderFilter()}
        {this.renderList()}
        <UserEditComponent editData={this.state.editData} />
      </React.Fragment>
    );
  }
}
