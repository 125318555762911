import { request, servicPath } from '../utils';
import {
  createGetRequestByQuery,
  createGetRequestByQueryForDownload,
} from './requestTransfer';
import { Observable } from 'rxjs';

/**
 * 获取话务统计坐席号。
 */
export function handleGetSits() {
  return request(createGetRequestByQuery(`${servicPath}/mixcall/monitoring`));
}

/**
 * 获取话务统计列表。
 */
export function handleGetTelList(params) {
  return request(
    createGetRequestByQuery(`${servicPath}/products/callrecords/stats`, params),
  );
}

/**
 * 获取话务统计详情列表。
 */
export function handleGetTelDetailList(params) {
  return request(
    createGetRequestByQuery(`${servicPath}/products/callrecords`, params),
  );
}

/**
 * 下载录音文件
 */
export function handleDownloadVoiceFile(params: {
  path: string;
}): Observable<any> {
  return request(createGetRequestByQueryForDownload(params.path));
}
