import * as React from 'react';
import * as _ from 'lodash';
import { helpers } from '@/utils';
import { Modal, Input, Form, message, AutoComplete } from 'antd';

interface MarketingAccountsEditProps {
  editData: any;
  [random: string]: any;
}
interface MarketingAccountsEditState {
  [random: string]: any;
}

export default class MarketingAccountsEdit extends React.PureComponent<
  MarketingAccountsEditProps,
  MarketingAccountsEditState
> {
  // 用户列表
  public userList: any = [];

  // 营销账户类型列表
  public accountType: any = [];

  // 营销账户列表
  public marketingAccounts: any = [];

  constructor(props) {
    super(props);
    this.state = {
      visible: false, // 是否显示营销账户编辑窗口
      userList: [], // 用列表
      accountType: [] // 营销账户类型列表
    };
  }

  // 传进来需要修改的原始数据
  public editData: any = {};

  // 修改营销账户
  public handleUpdate = () => {
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }

      if (!this.userList.map(item => item.name).includes(values.belong_user)) {
        message.error('账号所属人不存在');
        return;
      }

      const belong_user = this.userList.filter(
        item => item.name === values.belong_user
      )[0].id;

      this.props.dispatch({
        type: 'system/marketingAccounts/updateMarketingAccountsEpic',
        payload: {
          id: this.editData.id,
          account_type: helpers.filter.trimStr(values.account_type),
          account_no: helpers.filter.trimStr(values.account_no),
          belong_user,
          belong_user_name: values.belong_user
        }
      });

      this.setState({ visible: false });
    });
  };

  public componentWillReceiveProps(nextProps) {
    // 绑定营销账户信息
    if (!_.isEqual(nextProps.editData, this.editData)) {
      this.editData = nextProps.editData;
      this.setState({ visible: true });
      this.props.form.setFieldsValue({
        account_type: nextProps.editData.account_type,
        account_no: nextProps.editData.account_no,
        belong_user: nextProps.editData.user.name
      });
    }

    // 绑定营销账户列表
    if (
      nextProps.state.marketingAccounts.length !== 0 &&
      !_.isEqual(nextProps.state.marketingAccounts, this.marketingAccounts)
    ) {
      this.marketingAccounts = nextProps.state.marketingAccounts;
      // 过滤出营销账户类型列表
      this.accountType = nextProps.state.marketingAccounts
        .map(item => item.account_type)
        .filter((el, i, arr) => {
          return arr.indexOf(el) === i;
        });
      this.setState({
        accountType: this.accountType
      });
    }

    // 绑定用户列表
    if (
      nextProps.all.user.userList.length !== 0 &&
      this.userList.length === 0
    ) {
      this.userList = nextProps.all.user.userList;
      this.setState({ userList: nextProps.all.user.userList.map(n => n.name) });
    }
  }

  // 选择成员输入筛选
  public handleSearch = value => {
    this.setState({
      userList: helpers.isJudge(!value)(
        [],
        this.userList
          .filter(item => item.name.indexOf(value) !== -1)
          .map(n => n.name)
      )
    });
  };

  // 应用类型补全提示
  public handleSearchType = value => {
    this.setState({
      accountType: helpers.isJudge(!value)(
        [],
        this.accountType.filter(item => item.indexOf(value) !== -1)
      )
    });
  };

  public render() {
    return (
      <React.Fragment>
        <Modal
          title={'编辑'}
          visible={this.state.visible}
          onOk={this.handleUpdate}
          maskClosable={false}
          onCancel={() => this.setState({ visible: false })}
          width={600}
          className={'modalFrom'}
        >
          <Form.Item label={'应用类型：'}>
            {this.props.form.getFieldDecorator('account_type', {
              rules: [
                {
                  required: true,
                  message: '请输入应用类型名称'
                },
                {
                  validator: helpers.antdFormValidators.lengthLessThan32
                }
              ]
            })(
              <AutoComplete
                dataSource={this.state.accountType}
                onSearch={this.handleSearchType}
                placeholder={'请输入应用类型名称'}
              />
            )}
          </Form.Item>
          <Form.Item label={'账号名称：'}>
            {this.props.form.getFieldDecorator('account_no', {
              rules: [
                {
                  required: true,
                  message: '请输入账号名称'
                }
              ]
            })(<Input placeholder={'请输入账号名称'} maxLength={32} />)}
          </Form.Item>
          <Form.Item label={'账号所属人：'}>
            {this.props.form.getFieldDecorator('belong_user', {
              rules: [
                {
                  required: true,
                  message: '请输入账号所属人'
                }
              ]
            })(
              <AutoComplete
                dataSource={this.state.userList}
                onSearch={this.handleSearch}
                placeholder={'请选择账号所属人'}
              />
            )}
          </Form.Item>
        </Modal>
      </React.Fragment>
    );
  }
}
