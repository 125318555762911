import * as _ from 'lodash';
import { Action } from '../reducers/reducerInterface';

const initialStore = {
  marketStatus: []
};

export type AppStoreType = typeof initialStore;

export default function marketStatusReducer(
  state: any = initialStore,
  action: Action
) {
  try {
    switch (action.type) {
      case 'reports/getMarketStatus': {
        const marketStatus = action.payload;
        return _.assign({}, state, { marketStatus });
      }
      default:
        return state;
    }
  } catch (e) {
    return state;
  }
}
