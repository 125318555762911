import { request, servicPath } from '../utils';
import {
  createFormDataPostRequest,
  createFormDataPutRequest,
  createGetRequestByQuery,
} from './requestTransfer';

/**
 *  呼叫中心 任务管理 编辑任务
 */
export function handleEditTask(params) {
  return request(
    createFormDataPutRequest(`${servicPath}/mixcall/editTask`, params),
  );
}

/**
 *  呼叫中心 任务管理 模板下拉数据
 */
export function handleGetTempData(params) {
  return request(
    createGetRequestByQuery(`${servicPath}/mixcall/getTemplate`, params),
  );
}

/**
 *  呼叫中心 群呼任务 列表
 */
export function handleGetGroupCallTaskList(params) {
  return request(
    createFormDataPostRequest(`${servicPath}/mixcall/getTaskList`, params),
  );
}

/**
 * 呼叫中心 群呼任务 新增群呼任务
 */
export function handleAddGroupCallTask(params) {
  return request(
    createFormDataPostRequest(
      `${servicPath}/mixcall/${params.file_name ? 'importAddData' : 'addData'}`,
      params,
    ),
  );
}
/**
 * 查看群呼任务详情
 */
export function handleGetGroupCallTaskDetail(params) {
  return request(
    createFormDataPostRequest(`${servicPath}/mixcall/taskDetail`, params),
  );
}
/**
 * 导出群呼任务列表
 */
export function handleGetGroupCallTaskDownload() {
  return request(
    createFormDataPostRequest(`${servicPath}/mixcall/exportTaskList`, {}, true),
  );
}
/**
 * 下载明细数据导入模板
 */
export function handleGetGroupCallTaskTemplateDownload(params) {
  return request(
    createFormDataPostRequest(
      `${servicPath}/mixcall/downloadTaskDetailTemplate`,
      params,
      true,
    ),
  );
}
/**
 * 导出群呼任务详情
 */
export function handleGetGroupCallTaskDetailDownload(params) {
  return request(
    createFormDataPostRequest(
      `${servicPath}/mixcall/exportTaskDetail`,
      params,
      true,
    ),
  );
}
/**
 * 呼叫中心 任务管理 新增群呼任务
 */
export function handleInsertCallTask(params) {
  return request(
    createFormDataPostRequest(`${servicPath}/mixcall/addTask`, params),
  );
}

/**
 * 获取被分配的任务
 */
export function handleGetCallCenterList(params) {
  return request(
    createGetRequestByQuery(
      `${servicPath}/products/tasks/${params.type}`,
      params,
    ),
  );
}

/**
 * 退回被分配的任务
 */
export function handleTasksRollback(params) {
  // 判断数据类型，如果是是群组下的
  // 则使用另外一个接口，Web设计接口时并没有做统一设计
  // 所以这里做了hack，通过一个type来判断类型
  // 使用完后再删除
  const baseURL =
    params._type === 'group'
      ? '/products/tasks/groupMaintenance/'
      : '/products/rollback/';
  delete params._type;
  return request(
    createFormDataPutRequest(`${servicPath}${baseURL}${params.id}`, params),
  );
}

/**
 * 获取受阻
 */
export function handleGetBlocked(params) {
  return request(
    createGetRequestByQuery(
      `${servicPath}/products/tasks/blockedSearch`,
      params,
    ),
  );
}

/**
 * 更新受阻
 */
export function handleUpdateBlocked(params) {
  return request(
    createFormDataPutRequest(
      `${servicPath}/products/tasks/blockedUpdate`,
      params,
    ),
  );
}

/**
 * 已处理
 */
export function handleDealWith(params) {
  return request(
    createFormDataPutRequest(`${servicPath}/products/deal`, params),
  );
}

/**
 * 电话呼叫
 */
export function handleCall(params) {
  return request(
    createFormDataPostRequest(`${servicPath}/users/call/${params.id}`, params),
  );
}

/**
 * 发送短信
 */
export function handleSendSms(params) {
  let url = `${servicPath}/products/systems/sendsms`;
  if (params.record_id) {
    url = `${servicPath}/products/systems/resend`;
  }
  return request(createFormDataPostRequest(url, params));
}

/**
 * 当前客服未完成任务数量
 */
export function handleGetTasksBadgeamount(params) {
  return request(
    createGetRequestByQuery(`${servicPath}/products/tasks/badgeamount`, params),
  );
}

/**
 * 心跳
 */
export function handleCallServer() {
  return request(createGetRequestByQuery(`${servicPath}/polylink/heartbeat`));
}

/**
 * 获取登录状态
 */
export function handleAuthByToken() {
  return request(createGetRequestByQuery(`${servicPath}/polylink/authbytoken`));
}

/**
 * 语音坐席上线
 */
export function handleLoginCti(params) {
  return request(
    createFormDataPostRequest(`${servicPath}/polylink/ctisignin`, params),
  );
}

/**
 * 语音坐席下线
 */
export function handleCtiSignOut(params) {
  return request(
    createGetRequestByQuery(`${servicPath}/polylink/ctisignout`, params),
  );
}

/**
 * 保存用户信息
 */
export function handleSaveUserInfo(params) {
  return request(
    createFormDataPostRequest(`${servicPath}/polylink/userinfo`, params),
  );
}

/**
 * 状态切换
 */
export function handleChangeStatus(params) {
  return request(
    createFormDataPutRequest(`${servicPath}/users/changeStatus`, params),
  );
}

/**
 * 挂线
 */
export function handleHungUp(params) {
  return request(
    createFormDataPostRequest(`${servicPath}/users/hangUp`, params),
  );
}

/**
 * 静音
 */
export function handleMuteVoice(params) {
  return request(
    createGetRequestByQuery(`${servicPath}/polylink/mutevoice`, params),
  );
}

/**
 * 取消静音
 */
export function handleUnmuteVoice(params) {
  return request(
    createGetRequestByQuery(`${servicPath}/polylink/unmutevoice`, params),
  );
}

/**
 * 保持和取消保持
 */
export function handleCallHold() {
  return request(createFormDataPutRequest(`${servicPath}/mixcall/hold`, {}));
}

/**
 * 获取坐席状态
 */
export function handleGetAgentState() {
  return request(createGetRequestByQuery(`${servicPath}/mixcall/getStatus`));
}
