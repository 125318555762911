import * as React from 'react';
import { Select, Modal, Button, Input, Form, message } from 'antd';
import * as _ from 'lodash';
const Option = Select.Option;
import { InputPWD } from '@/components';
import { helpers } from '@/utils';
import {
  ContainerPropsInterface,
  ContainerStateInterface
} from '@/routes/containerInterface';

export default class UserAdd extends React.PureComponent<
  ContainerPropsInterface<any>,
  ContainerStateInterface
  > {
  constructor(props) {
    super(props);
    this.state = {
      // visible: false, // 是否显示添加窗口
      roles: [], // 角色列表
      tags: [], // 标签列表
      selectedRolesId: undefined, // 选择的角色
      selectedTagsId: [] // 选择的标签
    };
  }

  public userlist: any = []; // 用户列表

  // 添加标签
  public handleAdd = () => {
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }

      const userEmailList = this.userlist.map(n => n.email); // 用户邮件列表
      if (userEmailList.includes(values.email)) {
        message.error('邮箱已存在');
        return;
      }

      const userNameList = this.userlist.map(n => n.name); // 用户名列表
      if (userNameList.includes(values.name)) {
        message.error('用户名已存在');
        return;
      }

      // 添加用户信息
      this.props.dispatch({
        type: 'system/addUserEpic',
        payload: {
          name: values.name,
          email: values.email,
          password: values.password,
          role_id: helpers.isJudge(this.state.selectedRolesId === undefined)(
            null,
            this.state.selectedRolesId
          ),
          tag_ids: this.selectedTagsId.toString()
        }
      });

      // 重置输入的信息
      this.reSet();
    });
  };

  public selectedTagsId = []; // 选择的标签

  // 重置输入的信息
  public reSet() {
    this.props.form.setFieldsValue({
      name: '',
      email: '',
      password: ''
    });
    this.setState({
      selectedRolesId: this.state.roles[0].id,
      selectedTagsId: []
    });

    this.selectedTagsId = [];
  }

  public componentWillReceiveProps(nextProps) {
    // 绑定角色列表
    if (nextProps.all.roles.roles !== undefined) {
      if (
        nextProps.all.roles.roles.length !== 0 &&
        !_.isEqual(nextProps.all.roles.roles, this.state.roles)
      ) {
        this.setState({
          roles: nextProps.all.roles.roles,
          selectedRolesId: nextProps.all.roles.roles[0].id
        });
      }
    }

    // 绑定标签列表
    if (nextProps.all.tags.tags !== undefined) {
      if (
        nextProps.all.tags.tags.length !== 0 &&
        !_.isEqual(nextProps.all.tags.tags, this.state.tags)
      ) {
        this.setState({ tags: nextProps.all.tags.tags });
      }
    }

    // 用户列表绑定
    if (nextProps.state.userList !== undefined) {
      if (
        nextProps.state.userList.length !== 0 &&
        !_.isEqual(nextProps.state.userList, this.userlist)
      ) {
        this.userlist = nextProps.state.userList;
      }
    }
  }

  // 渲染表单块
  public renderFrom = () => {
    return (
      <Modal
        title={'新增系统用户'}
        maskClosable={false}
        visible={this.props.all.user.visibleAdd}
        onOk={this.handleAdd}
        onCancel={() => {
          this.props.dispatch({
            type: 'system/addUserSuccess',
            payload: {
              visibleAdd: false
            }
          });
          this.reSet();
        }}
        width={600}
        className={'modalFrom'}
      >
        <Form.Item label={'用户名：'}>
          {this.props.form.getFieldDecorator('name', {
            rules: [
              {
                message: '请输入邮箱地址自动生成'
              }
            ]
          })(<Input placeholder={'根据邮箱地址自动生成'} disabled={true} />)}
        </Form.Item>
        <Form.Item label={'邮箱地址：'}>
          {this.props.form.getFieldDecorator('email', {
            rules: [
              {
                required: true,
                message: '请输入邮箱地址'
              },
              {
                validator: helpers.antdFormValidators.email
              }
            ]
          })(
            <Input
              placeholder={'请输入邮箱地址'}
              onChange={e =>
                this.props.form.setFieldsValue({
                  name: e.target.value.slice(0, e.target.value.indexOf('@'))
                })
              }
            />
          )}
        </Form.Item>
        <div>
          <div className={'ant-form-item-label'}>所在角色组：</div>
          <Select
            placeholder={'所在角色组'}
            value={this.state.selectedRolesId}
            onChange={value => this.setState({ selectedRolesId: value })}
          >
            {this.state.roles.map(item => (
              <Option key={item.id} value={item.id}>
                {item.role_name}
              </Option>
            ))}
          </Select>
        </div>
        {/* <div>
          <div className={'ant-form-item-label'}>可见标签：</div>
          <Select
            mode={'multiple'}
            placeholder={'选择可见标签'}
            value={this.state.selectedTagsId}
            onChange={(value, key: any) => {
              this.selectedTagsId = key.map(n => parseInt(n.key, 10));
              this.setState({
                selectedTagsId: value
              });
            }}
          >
            {this.state.tags.map(item => (
              <Option key={item.id} value={item.name}>
                {item.name}
              </Option>
            ))}
          </Select>
        </div> */}
        <Form.Item label={'密码：'}>
          {this.props.form.getFieldDecorator('password', {
            rules: [
              {
                required: true,
                message: '请输入密码'
              },
              {
                validator: helpers.antdFormValidators.password
              }
            ]
          })(<InputPWD placeholder={'请设置密码'} />)}
        </Form.Item>
      </Modal>
    );
  };

  public render() {
    return (
      <React.Fragment>
        <Button
          type={'primary'}
          icon={'plus'}
          className={'pBtn fRight'}
          onClick={() =>
            this.props.dispatch({
              type: 'system/addUserSuccess',
              payload: {
                visibleAdd: true
              }
            })
          }
        >
          新增系统用户
        </Button>
        {this.renderFrom()}
      </React.Fragment>
    );
  }
}
