import * as React from 'react';
import * as _ from 'lodash';
import moment from 'moment';
import { Table, Button, Input, DatePicker, Pagination } from 'antd';
const { RangePicker } = DatePicker;
import { helpers } from '@/utils';
import {
  ContainerPropsInterface,
  ContainerStateInterface,
} from '@/routes/containerInterface';
export default class AssignmentsDetailed extends React.PureComponent<
  ContainerPropsInterface<any>,
  ContainerStateInterface
> {
  // 表格列
  public columns: any = [
    {
      align: 'center',
      title: '序号',
      width: 100,
      dataIndex: 'id',
      render: (text, record, index) => (
        <span>
          {index +
            1 +
            this.state.page.num * parseInt(this.state.page.size, 10) -
            parseInt(this.state.page.size, 10)}
        </span>
      ),
    },
    {
      title: '分配人',
      dataIndex: '分配人',
      width: 200,
      render: (text, record) => <span>{_.get(record.operator, 'name')}</span>,
    },
    {
      title: '被分配人',
      dataIndex: '被分配人',
      width: 200,
      render: (text, record) => <span>{_.get(record.user, 'name')}</span>,
    },
    {
      title: '客户来源',
      dataIndex: '客户来源',
      width: 150,
      render: (text, record) => (
        <span style={{ color: '#75cdc5' }}>
          {helpers.isJudge(_.get(record.customer, 'source_id') === 0)(
            '会员',
            '外部',
          )}
        </span>
      ),
    },
    {
      title: '用户名',
      dataIndex: '用户名',
      width: 200,
      render: (text, record) => (
        <span>{_.get(record.customer, 'username')}</span>
      ),
    },
    {
      title: '真实姓名',
      dataIndex: '真实姓名',
      width: 150,
      render: (text, record) => (
        <span>{_.get(record.customer, 'x_real_name')}</span>
      ),
    },
    {
      title: '手机号码',
      dataIndex: '手机号码',
      width: 150,
      render: (text, record) => <span>{_.get(record.customer, 'x_phone')}</span>,
    },
  ];

  // 页面大小存储使用的key
  public STORE_PAGE_SIZE_KEY = 'smallPageSize';

  constructor(props) {
    super(props);
    this.state = {
      list: [], // 列表
      total: 0, // 总数量
      page: {
        size: helpers.isJudge(
          localStorage.getItem(this.STORE_PAGE_SIZE_KEY) === null,
        )(100, localStorage.getItem(this.STORE_PAGE_SIZE_KEY)), // 用户列表的每页大小
        num: 1, // 用户列表的当前页数
      },
      // 过滤对象组
      filterObj: {
        ...this.filterObj,
      },
    };
  }
  // 用于分页时可以使用上次的筛选操作
  public filterObj = {
    id: this.props.match.params.id, // 任务id
    operator_name: '', // 分配人用户名
    assigned_user_name: '', // 客户用户名
    customer_name: '', // 客户名
    phone: '', // 手机号
    source_id: -1, // 客户来源
  };

  public data: any = []; // 标签列表

  public componentWillMount() {
    // 获取任务列表
    this.getList();
  }

  // 获取任务
  public getList = () => {
    const obj = Object.assign({}, this.filterObj);

    if (obj.source_id === -1) {
      delete obj.source_id;
    }

    this.props.dispatch({
      type: 'customers/getTasksAssignmentsDetailsEpic',
      payload: {
        ...obj,
        page: this.state.page.num,
        page_size: this.state.page.size,
      },
    });
  };

  // 过滤查询
  public filterSelect = () => {
    this.filterObj = this.state.filterObj; // 保存被筛选的条件

    this.setState(
      { page: { num: 1, size: this.state.page.size } },
      this.getList,
    );
  };

  // 通过之前的筛选条件列表
  public getListBeforeFilter = () => {
    const obj = Object.assign({}, this.filterObj);
    localStorage.setItem(this.STORE_PAGE_SIZE_KEY, this.state.page.size);

    if (obj.source_id === -1) {
      delete obj.source_id;
    }

    this.props.dispatch({
      type: 'customers/getTasksAssignmentsDetailsEpic',
      payload: {
        ...obj,
        page: this.state.page.num,
        page_size: this.state.page.size,
      },
    });
  };

  // 筛选重置
  public handleFilterReset = () => {
    this.filterObj = {
      id: this.filterObj.id,
      operator_name: '', // 分配人用户名
      assigned_user_name: '', // 客户用户名
      customer_name: '', // 客户名
      phone: '', // 手机号
      source_id: -1, // 客户来源
    };
    this.setState({
      filterObj: this.filterObj,
    });
  };

  public componentWillReceiveProps(nextProps) {
    // // 绑定列表
    this.data = nextProps.state.assignmentsDetailed;
    this.setState({
      list: nextProps.state.assignmentsDetailed,
      total: nextProps.state.total,
    });
  }

  // 筛选标签列表
  public handleFilter(filterText) {
    if (this.data.length !== 0) {
      this.setState({
        filterText,
      });
    }
  }

  // 列表模块渲染
  public renderList = () => {
    return (
      <React.Fragment>
        <Table
          columns={this.columns}
          dataSource={this.state.list}
          pagination={false}
          rowKey={'id'}
          scroll={{
            x: 1250,
            y: 500,
          }}
        />
        {helpers.isJudge(this.state.total !== 0)(
          <div className={'paginationNumLeft'}>
            <span>
              共有 {this.state.total} 条数据{' '}
              {Math.ceil(this.state.total / this.state.page.size)} 页
            </span>
          </div>,
          null,
        )}
        <Pagination
          pageSize={parseInt(this.state.page.size, 10)}
          showQuickJumper
          showSizeChanger
          onShowSizeChange={(page, size) => {
            this.setState({ page: { num: 1, size } }, this.getListBeforeFilter);
          }}
          total={this.state.total}
          onChange={(page, size) => {
            this.setState(
              { page: { num: page, size } },
              this.getListBeforeFilter,
            );
          }}
          pageSizeOptions={['10', '20', '30', '40']}
          current={this.state.page.num}
          hideOnSinglePage={this.state.total === 0}
        />
      </React.Fragment>
    );
  };

  // 筛选模块渲染
  public renderFilter = () => {
    const dateFormat = 'YYYY/MM/DD';
    return (
      <div>
        <p>
          <span className={'notWrap'}>
            分配人：
            <Input
              type={'text'}
              placeholder={'请输入分配人名'}
              value={this.state.filterObj.operator_name}
              maxLength={15}
              onChange={e =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    operator_name: e.target.value,
                  },
                })
              }
            />
          </span>
          <span className={'notWrap'}>
            被分配人：
            <Input
              type={'text'}
              placeholder={'请输入被分配人名'}
              value={this.state.filterObj.assigned_user_name}
              maxLength={15}
              onChange={e =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    assigned_user_name: e.target.value,
                  },
                })
              }
            />
          </span>
          <span className={'notWrap'}>
            用户名：
            <Input
              type={'text'}
              placeholder={'请输入用户名'}
              value={this.state.filterObj.customer_name}
              maxLength={15}
              onChange={e =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    customer_name: e.target.value,
                  },
                })
              }
            />
          </span>
          <span className={'notWrap'}>
            客户来源：
            <Button
              type={helpers.isJudge(this.state.filterObj.source_id === -1)(
                'primary',
                null,
              )}
              onClick={() =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    source_id: -1,
                  },
                })
              }
            >
              不限
            </Button>
            <Button
              type={helpers.isJudge(this.state.filterObj.source_id === 0)(
                'primary',
                null,
              )}
              onClick={() =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    source_id: 0,
                  },
                })
              }
            >
              系统会员
            </Button>
            <Button
              type={helpers.isJudge(this.state.filterObj.source_id === 1)(
                'primary',
                null,
              )}
              onClick={() =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    source_id: 1,
                  },
                })
              }
              className={'mr40'}
            >
              外部导入
            </Button>
          </span>
          <span className={'notWrap'}>
            手机号：
            <Input
              type={'text'}
              placeholder={'请输入手机号'}
              value={this.state.filterObj.phone}
              maxLength={13}
              onChange={e =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    phone: e.target.value,
                  },
                })
              }
            />
            <Button type={'primary'} onClick={this.filterSelect}>
              筛选
            </Button>
            <Button onClick={this.handleFilterReset}>重置</Button>
          </span>
        </p>
      </div>
    );
  };

  public render() {
    return (
      <React.Fragment>
        {this.renderFilter()}
        {this.renderList()}
      </React.Fragment>
    );
  }
}
