import { Observable, pipe, of } from 'rxjs';

import { map, mergeMap } from 'rxjs/operators';
import { Epic, ofType } from 'redux-observable';
import { helpers } from '@/utils';
import { tagsAPI } from '@/services';

interface Payload {
  [random: string]: any;
}
// dispatch传进来的action类型
interface PayloadIn {
  type: string;
  payload: {
    [random: string]: any;
  };
}

// epic传到reducer里的action类型
interface PayloadOut {
  type: string;
  payload: {
    [random: string]: any;
  };
}

// 获取标签信息
export const getTags: Epic<PayloadIn, PayloadOut> = (action$, store) => {
  return action$.pipe(
    ofType('system/tags/getTagsEpic'),
    mergeMap(action => {
      return tagsAPI.handleGetTags(action.payload).pipe(
        map(res => {
          if (res !== null) {
            return helpers.createAction<Payload>(
              'system/tags/getTags',
              res.data.data
            );
          }
          return helpers.createAction<typeof action.payload>('no');
        })
      );
    })
  );
};

// 获取标签详细信息
export const getTagsSingle: Epic<PayloadIn, PayloadOut> = (action$, store) => {
  return action$.pipe(
    ofType('system/tags/getTagsSingleEpic'),
    mergeMap(action => {
      return tagsAPI.handleGetTagsSingle(action.payload).pipe(
        map(res => {
          if (res !== null) {
            return helpers.createAction<Payload>(
              'system/tags/getTagsSingle',
              res.data
            );
          }
          return helpers.createAction<typeof action.payload>('no');
        })
      );
    })
  );
};

// 删除标签信息
export const deleteTags: Epic<PayloadIn, PayloadOut> = (action$, store) => {
  return action$.pipe(
    ofType('system/tags/deleteTagsEpic'),
    mergeMap(action => {
      return tagsAPI.handleDeleteTags(action.payload).pipe(
        map(res => {
          if (res !== null) {
            return helpers.createAction<Payload>(
              'system/tags/deleteTags',
              action.payload.id
            );
          }
          return helpers.createAction<typeof action.payload>('no');
        })
      );
    })
  );
};

// 更新标签信息
export const updateTags: Epic<PayloadIn, PayloadOut> = (action$, store) => {
  return action$.pipe(
    ofType('system/tags/updateTagsEpic'),
    mergeMap(action => {
      return tagsAPI.handleUpdateTags(action.payload).pipe(
        map(res => {
          if (res !== null) {
            return helpers.createAction<Payload>('system/tags/updateTags', res);
          }
          return helpers.createAction<typeof action.payload>('no');
        })
      );
    })
  );
};

// 添加标签信息
export const addTag: Epic<PayloadIn, PayloadOut> = (action$, store) => {
  return action$.pipe(
    ofType('system/tags/addTagsEpic'),
    mergeMap(action => {
      return tagsAPI.handleAddTags(action.payload).pipe(
        map(res => {
          if (res !== null) {
            return helpers.createAction<Payload>(
              'system/tags/addTags',
              res.data
            );
          }
          return helpers.createAction<typeof action.payload>('no');
        })
      );
    })
  );
};
