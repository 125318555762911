import * as React from 'react';
import * as _ from 'lodash';
import moment from 'moment';
import { Table, Button, Input, DatePicker, Select, Modal } from 'antd';
import { helpers } from '@/utils';
import {
  ContainerPropsInterface,
  ContainerStateInterface
} from '@/routes/containerInterface';

const { MonthPicker } = DatePicker;
const { confirm } = Modal;
const { Option } = Select;

export default class PerformReference extends React.PureComponent<
  ContainerPropsInterface<any>,
  ContainerStateInterface
> {
  // 表格列
  public columns: any = [
    {
      align: 'center',
      title: '序号',
      width: 130,
      dataIndex: 'id',
      render: (text, record, index) => <span>{index + 1}</span>
    },
    {
      title: '用户名',
      dataIndex: 'name',
      width: 300
    },
    {
      title: '所在标签',
      dataIndex: 'name',
      width: 350
    },
    {
      title: '接通量',
      dataIndex: 'name',
      width: 150
    },
    {
      title: '有效通话量',
      dataIndex: 'name',
      width: 150
    },
    {
      title: '上月总存款',
      dataIndex: 'name',
      width: 300
    },
    {
      title: '本月总存款',
      dataIndex: 'name',
      width: 300
    },
    {
      title: '上月总投注额',
      dataIndex: 'users_count',
      width: 300
    },
    {
      title: '本月总投注额',
      dataIndex: 'users_count',
      width: 300
    }
  ];

  constructor(props) {
    super(props);
    this.state = {
      list: [], // 标签列表
      filterObj: this.filterObj // 筛选条件
    };
  }

  public data: any = [];

  public allSelectData: any = []; // 选中数据

  public filterObj: any = {
    user_name: '', // 用户名
    label_group: '', // 所在标签
    give_perform: '', // 绩效发放
    statistic_perid: '' // 统计周期
  };

  // 统计周期
  public selectStatisticPerid = timeStr => {
    this.setState({
      filterObj: {
        ...this.state.filterObj,
        statistic_perid: timeStr
      }
    });
  };

  // 重置
  public handleReset = () => {
    this.setState({
      filterObj: {
        user_name: '', // 用户名
        label_group: '', // 所在标签
        give_perform: '', // 绩效发放
        statistic_perid: '' // 统计周期
      }
    });
  };

  // 筛选
  public renderFilter = () => {
    const dateFormat = 'YYYY/MM';
    return (
      <div>
        <p>
          <span>
            用户名 :&nbsp;&nbsp;
            <Input
              value={this.state.filterObj.user_name}
              type={'text'}
              placeholder={'请输入名称'}
              onChange={e =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    user_name: e.target.value
                  }
                })
              }
            />
          </span>
          <span>
            所在标签 :&nbsp;&nbsp;
            <Select
              allowClear
              placeholder={'不限'}
              onChange={value =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    label_group: value
                  }
                })
              }
              value={this.state.filterObj.label_group}
            >
              <Option key={'1'} value={1}>
                {'已接'}
              </Option>
              <Option key={'0'} value={0}>
                {'未接'}
              </Option>
            </Select>
          </span>
          <span>
            绩效是否发放 :&nbsp;&nbsp;
            <Select
              allowClear
              placeholder={'不限'}
              onChange={value =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    give_perform: value
                  }
                })
              }
              value={this.state.filterObj.give_perform}
            >
              <Option key={'1'} value={1}>
                {'是'}
              </Option>
              <Option key={'0'} value={0}>
                {'否'}
              </Option>
            </Select>
          </span>
          <span className={'icon-justify'}>
            统计周期 :&nbsp;&nbsp;
            <MonthPicker
              placeholder={'选择周期'}
              onChange={(date, dateString) =>
                this.selectStatisticPerid(dateString)
              }
              value={helpers.isJudge(
                this.state.filterObj.statistic_perid === ''
              )(null, moment(this.state.filterObj.statistic_perid, dateFormat))}
            />
          </span>
          <Button type={'primary'}>筛选</Button>
          <Button onClick={this.handleReset}>重置</Button>
        </p>
      </div>
    );
  };

  // 导出
  public renderExport = () => {
    return (
      <div style={{ textAlign: 'right', marginTop: '15px' }}>
        <Button icon={'download'}>导出报表</Button>
      </div>
    );
  };

  // 获取数据
  public getData = () => {
    this.props.dispatch({
      type: 'system/tags/getTagsEpic',
      payload: {
        page_size: 1000
      }
    });
  };

  public componentWillMount() {
    // 获取标签列表
    if (this.state.list.length === 0) {
      this.getData();
    }
  }

  public componentWillReceiveProps(nextProps) {
    if (
      nextProps.state.tags.length !== 0 &&
      !_.isEqual(nextProps.state.tags, this.data)
    ) {
      this.data = nextProps.state.tags;
      this.setState({ list: nextProps.state.tags });
    }
  }

  public render() {
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.allSelectData = selectedRowKeys;
      }
    };
    return (
      <React.Fragment>
        {this.renderFilter()}
        {this.renderExport()}
        <Table
          columns={this.columns}
          dataSource={this.state.list}
          rowKey={'id'}
          rowSelection={rowSelection}
          pagination={helpers.isJudge(this.state.list.length === 0)(
            false,
            true
          )}
        />
      </React.Fragment>
    );
  }
}
