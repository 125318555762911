import * as React from 'react';
import * as _ from 'lodash';
import { Modal, Table } from 'antd';
import { helpers } from '@/utils';

interface TasksDetailedProps {
  [random: string]: any;
}
interface TasksDetailedState {
  [random: string]: any;
}

export default class TasksDetailed extends React.PureComponent<
  TasksDetailedProps,
  TasksDetailedState
> {
  constructor(props) {
    super(props);
    this.state = {
      visible: false, // 是否显示窗口
      list: [] // 用列表
    };
  }

  // 表格列
  public columns: any = [
    {
      title: '行销结果',
      dataIndex: 'market_status',
      render: (text, record, index) => (
        <span style={{ color: '#758ca4' }}>{record.market_status}</span>
      ),
      width: 200
    },
    {
      title: '客户数量',
      dataIndex: 'value',
      width: 200
    },
    {
      title: '结果占比',
      dataIndex: 'percent',
      render: (text, record, index) => (
        <span style={{ paddingLeft: 0 }}>{record.percent.toFixed(2)}%</span>
      ),
      width: 200
    }
  ];

  public showTimes = 0; // 显示的次数

  public componentDidMount() {
    this.showTimes = this.props.state.tasksDetailed.showTimes;
  }

  public componentWillReceiveProps(nextProps) {
    if (nextProps.state.tasksDetailed.showTimes !== this.showTimes) {
      this.showTimes = nextProps.state.tasksDetailed.showTimes;
      const list: any = [];
      for (const item of nextProps.state.tasksDetailed.handled) {
        if (nextProps.state.tasksDetailed.total === 0) {
          item.percent = 0;
        } else {
          item.percent =
            (item.value * 100) / nextProps.state.tasksDetailed.total;
        }

        list.push(item);
      }
      this.setState({
        list,
        visible: true
      });
    }
  }

  public render() {
    return (
      <React.Fragment>
        <Modal
          title={'任务情况'}
          visible={this.state.visible}
          maskClosable={false}
          onOk={() =>
            this.setState({
              visible: false
            })
          }
          onCancel={() =>
            this.setState({
              visible: false
            })
          }
          width={600}
          className={'modalList2'}
          footer={null}
        >
          <Table
            columns={this.columns}
            dataSource={this.state.list}
            rowKey={'market_status'}
            pagination={false}
          />
        </Modal>
      </React.Fragment>
    );
  }
}
