import * as React from 'react';
import * as _ from 'lodash';
import { Modal, Input, Form, Select, message } from 'antd';
const Option = Select.Option;
import { InputPWD } from '@/components';
import { helpers } from '@/utils';
import md5 from 'md5';

interface UserEditProps {
  editData: any;
  [random: string]: any;
}
interface UserEditState {
  [random: string]: any;
}
export default class UserEdit extends React.PureComponent<
  UserEditProps,
  UserEditState
  > {
  constructor(props) {
    super(props);
    this.state = {
      visible: false, // 是否显示编辑
      roles: [], // 角色列表
      tags: [], // 标签列表
      selectedRolesId: undefined, // 当前选择的角色
      selectedTagsId: [], // 当前选择的标签
      employeeId: '' // 坐席号
    };
  }

  public beforeEmail = ''; // 原始邮箱
  public userlist: any = []; // 用户列表

  // 传进来需要修改的id
  public editId = -1;
  // 传进来需要修改的原始数据
  public editData = {};

  public selectedTagsId: any = []; // 选择的标签

  public phone_line = -1; // 电话类型
  public employeeId = ''; // ploylink id
  public mixcall_employee_id = ''; // 米糠云id

  // 修改
  public handleUpdate = () => {
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }

      const userEmailList = this.userlist.map(n => n.email); // 用户邮件列表
      if (
        userEmailList.includes(values.email) &&
        values.email !== this.beforeEmail
      ) {
        message.error('邮箱已存在');
        return;
      }

      const userNameList = this.userlist.map(n => n.name); // 用户名列表
      if (
        userNameList.includes(values.name) &&
        values.name !== this.beforeEmail.slice(0, this.beforeEmail.indexOf('@'))
      ) {
        message.error('用户名已存在');
        return;
      }

      // 第三方密码
      let polylink_pwd = '';
      if (values.polylink_pwd !== '' && values.polylink_pwd !== undefined) {
        polylink_pwd = md5(values.polylink_pwd);
      }

      this.props.dispatch({
        type: 'system/updateUserEpic',
        payload: {
          id: this.editId,
          email: values.email,
          password: values.password,
          name: values.name,
          role_id: helpers.isJudge(this.state.selectedRolesId === undefined)(
            0,
            this.state.selectedRolesId
          ),
          tag_ids: this.selectedTagsId.toString(),
          employee_id: helpers.isJudge(this.phone_line === 1)(
            this.state.employeeId,
            this.employeeId
          ),
          mixcall_employee_id: helpers.isJudge(this.phone_line === 2)(
            this.state.employeeId,
            this.mixcall_employee_id
          ),
          polylink_pwd
        }
      });

      // 清掉密码
      this.props.form.setFieldsValue({
        password: '',
        polylink_pwd: ''
      });
      // 隐藏编辑窗口
      this.setState({ visible: false });
    });
  };

  public beforeEmployeeId = ''; // 原始坐席号

  public componentWillReceiveProps(nextProps) {
    // 绑定要编辑的用户信息
    if (!_.isEqual(nextProps.editData, this.editData)) {
      this.editId = nextProps.editData.id;
      this.editData = nextProps.editData;

      // 该用户的标签数组
      const tagsID: any = [];
      for (const item of nextProps.editData.tags) {
        tagsID.push(item.id);
      }

      let curruntAgentId = '';

      this.phone_line = nextProps.all.main.userInfo.phone_line; // 电话类型

      this.employeeId = _.get(
        nextProps.editData.polylink_account,
        'employee_id'
      );
      this.mixcall_employee_id = _.get(
        nextProps.editData.mixcall_account,
        'employee_id'
      );

      if (this.phone_line === 1) {
        curruntAgentId = this.employeeId;
      } else {
        curruntAgentId = this.mixcall_employee_id;
      }

      // 显示编辑窗口并绑定值
      this.setState({
        visible: true,
        selectedRolesId: _.get(nextProps.editData.role, 'id'),
        employeeId: curruntAgentId
      });

      if (this.phone_line === 1) {
        // 绑定原始坐席
        this.beforeEmployeeId = _.get(
          nextProps.editData.polylink_account,
          'employee_id'
        );
      } else {
        // 绑定原始坐席
        this.beforeEmployeeId = _.get(
          nextProps.editData.mixcall_account,
          'employee_id'
        );
      }

      this.selectedTagsId = tagsID;

      this.props.form.setFieldsValue({
        name: nextProps.editData.name,
        email: nextProps.editData.email,
        polylink_pwd: ''
      });

      this.beforeEmail = nextProps.editData.email; // 保存原始邮箱用来判断
    }

    // 绑定角色列表
    if (nextProps.all.roles.roles !== undefined) {
      if (
        nextProps.all.roles.roles.length !== 0 &&
        !_.isEqual(nextProps.all.roles.roles, this.state.roles)
      ) {
        this.setState({ roles: nextProps.all.roles.roles });
      }
    }

    // 绑定标签列表
    if (nextProps.all.tags.tags !== undefined) {
      if (
        nextProps.all.tags.tags.length !== 0 &&
        !_.isEqual(nextProps.all.tags.tags, this.state.tags)
      ) {
        this.setState({
          tags: nextProps.all.tags.tags
        });
      }

      if (
        this.selectedTagsId.length > 0 &&
        nextProps.all.tags.tags.length !== 0
      ) {
        const selectedTagsName: any = [];
        for (const n of nextProps.all.tags.tags) {
          if (this.selectedTagsId.includes(n.id)) {
            selectedTagsName.push(n.name);
          }
        }

        this.setState({
          selectedTagsId: selectedTagsName
        });
      }

      // 空的时候清空
      if (
        this.selectedTagsId.length === 0 &&
        nextProps.all.tags.tags.length !== 0
      ) {
        this.setState({
          selectedTagsId: []
        });
      }
    }

    // 用户列表绑定
    if (nextProps.state.userList !== undefined) {
      if (
        nextProps.state.userList.length !== 0 &&
        !_.isEqual(nextProps.state.userList, this.userlist)
      ) {
        this.userlist = nextProps.state.userList;
      }
    }
  }

  public render() {
    return (
      <React.Fragment>
        <Modal
          title={'编辑'}
          maskClosable={false}
          visible={this.state.visible}
          onOk={this.handleUpdate}
          onCancel={() => {
            // 清掉密码
            this.props.form.setFieldsValue({
              password: '',
              polylink_pwd: ''
            });
            // 隐藏编辑窗口
            this.setState({ visible: false });
          }}
          width={600}
          className={'modalFrom'}
        >
          <Form.Item label={'用户名：'}>
            {this.props.form.getFieldDecorator('name', {
              rules: [
                {
                  message: '请输入用户名'
                }
              ]
            })(<Input placeholder={'根据邮箱地址自动生成'} disabled={true} />)}
          </Form.Item>
          <Form.Item label={'邮箱地址：'}>
            {this.props.form.getFieldDecorator('email', {
              rules: [{}]
            })(
              <Input
                placeholder={'请输入邮箱地址'}
                onChange={e =>
                  this.props.form.setFieldsValue({
                    name: e.target.value.slice(0, e.target.value.indexOf('@'))
                  })
                }
                disabled={true}
              />
            )}
          </Form.Item>
          {/* <div>
            <div className={'ant-form-item-label'}>可见标签：</div>
            <Select
              mode={'multiple'}
              placeholder={'选择可见标签'}
              value={this.state.selectedTagsId}
              onChange={(value, key: any) => {
                this.selectedTagsId = key.map(n => parseInt(n.key, 10));
                this.setState({
                  selectedTagsId: value
                });
              }}
            >
              {this.state.tags.map(item => (
                <Option key={item.id} value={item.name}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </div> */}
          <div>
            <div className={'ant-form-item-label'}>所在角色组：</div>
            <Select
              placeholder={'所在角色组'}
              value={this.state.selectedRolesId}
              onChange={value => this.setState({ selectedRolesId: value })}
            >
              {this.state.roles.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.role_name}
                </Option>
              ))}
            </Select>
          </div>
          <Form.Item label={'密码：'}>
            {this.props.form.getFieldDecorator('password', {
              rules: [
                {
                  validator: helpers.antdFormValidators.password
                }
              ]
            })(<InputPWD placeholder={'为空则不修改密码'} />)}
          </Form.Item>
          <div>
            <div className={'ant-form-item-label'}>
              {helpers.isJudge(this.phone_line === 1)(
                'ploylink坐席号：',
                '米糠云坐席号：'
              )}
            </div>
            <Input
              placeholder={helpers.isJudge(this.phone_line === 1)(
                '请输入ploylink坐席号',
                '请输入米糠云坐席号'
              )}
              onChange={e => {
                this.setState({ employeeId: e.target.value });
                if (this.phone_line === 2) {
                  this.beforeEmployeeId = e.target.value;
                }

                this.props.form.setFieldsValue({
                  polylink_pwd: ''
                });
              }}
              value={this.state.employeeId}
            />
          </div>
          {helpers.isJudge(this.phone_line === 1)(
            <Form.Item label={'坐席密码：'}>
              {this.props.form.getFieldDecorator('polylink_pwd', {
                rules: [
                  helpers.isJudge(
                    this.state.employeeId === this.beforeEmployeeId ||
                    this.state.employeeId === '' ||
                    this.state.employeeId === undefined
                  )(
                    {},
                    {
                      required: true,
                      message: '新的坐席号，坐席密码为必填'
                    }
                  )
                ]
              })(
                <InputPWD
                  placeholder={helpers.isJudge(
                    this.state.employeeId === this.beforeEmployeeId ||
                    this.state.employeeId === '' ||
                    this.state.employeeId === undefined
                  )('为空则不修改坐席密码', '请输入坐席密码')}
                />
              )}
            </Form.Item>,
            null
          )}
        </Modal>
      </React.Fragment>
    );
  }
}
