import * as React from 'react';
import * as _ from 'lodash';
import { Modal, Input, Form } from 'antd';
const { TextArea } = Input;

interface TextProps {
  [random: string]: any;
}
interface TextState {
  [random: string]: any;
}
export default class SendSms extends React.PureComponent<TextProps, TextState> {
  public showTimes = 0; // 显示次数
  public customer_id = ''; // 客户id
  public record_id = ''; // 短信id
  public relationshipId = undefined; // 关系id
  public obstruct_id = undefined; // 受阻id
  public tag_id = undefined; // 所属标签ID

  constructor(props) {
    super(props);
    this.state = {
      visible: false, // 是否显示
    };
  }

  // 传进来需要修改的原始数据
  public editData: any = {};

  // 发送短信
  public handleSend = () => {
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }

      this.props.dispatch({
        type: 'callCenter/sendSmsEpic',
        payload: {
          tag_id: this.tag_id,
          customer_id: this.customer_id,
          record_id: this.record_id,
          sms_content: values.sms_content,
          relationshipId: this.relationshipId,
          obstruct_id: this.obstruct_id,
          type: this.props.type, // 1为发短信，2为重发短信
        },
      });

      // if (this.relationshipId !== undefined || this.obstruct_id !== undefined) {
      //   // 已处理
      //   this.props.dispatch({
      //     type: 'callCenter/dealWithEpic',
      //     payload: {
      //       relationshipId: this.relationshipId,
      //       obstruct_id: this.obstruct_id
      //     }
      //   });
      // }

      this.props.form.setFieldsValue({
        sms_content: '',
      });

      this.setState({ visible: false });
    });
  };

  public componentWillReceiveProps(nextProps) {
    // 显示弹出
    if (this.showTimes !== nextProps.showTimes) {
      if (nextProps.content !== '' || nextProps.content === undefined) {
        this.props.form.setFieldsValue({
          sms_content: nextProps.content, // 绑定短信内容
        });
      }
      this.showTimes = nextProps.showTimes;
      this.customer_id = nextProps.customer_id;
      this.record_id = nextProps.record_id;
      this.relationshipId = nextProps.relationshipId;
      this.obstruct_id = nextProps.obstruct_id;
      this.tag_id = nextProps.tag_id;
      this.setState({ visible: true });
    }
  }

  public render() {
    return (
      <React.Fragment>
        <Modal
          title={'发送短信'}
          visible={this.state.visible}
          onOk={this.handleSend}
          onCancel={() => this.setState({ visible: false })}
          maskClosable={false}
          width={600}
          className={'modalFrom'}
          okText={'发送短信'}
        >
          <Form.Item label={'短信内容：'}>
            {this.props.form.getFieldDecorator('sms_content', {
              rules: [
                {
                  required: true,
                  message: '请输入要发送的信息',
                },
              ],
            })(<TextArea placeholder={'请输入要发送的信息'} rows={3} />)}
          </Form.Item>
        </Modal>
      </React.Fragment>
    );
  }
}
