// ie9 support start
// import 'core-js/es6/map';

// import 'core-js/es6/set';

// import 'raf/polyfill';

// ie9 support end

import * as React from 'react';

import * as ReactDOM from 'react-dom';

import * as redux from 'redux';

import { createEpicMiddleware } from 'redux-observable';

import { BrowserRouter } from 'react-router-dom';

// 只允许开发环境使用redux调试工具,正式环境无法使用
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

import * as reactRedux from 'react-redux';

import rootReducer from '@/reducers';

import rootEpic from '@/epics';

import AppRouter from '@/router';

import '../node_modules/minireset.css/minireset.min.css';

import '@/scss/style.css';

import { LocaleProvider } from 'antd';

import zh_CN from 'antd/lib/locale-provider/zh_CN';

import 'moment/locale/zh-cn';

// 给部分浏览器不支持的功能打补丁
import '@/patch';

interface AppIsRequireProps {
  store?: any;
}

const epicMiddleware = createEpicMiddleware();

const { createStore, applyMiddleware, combineReducers, compose } = redux;

const { Provider } = reactRedux;

const applyCreateStore = applyMiddleware(epicMiddleware)(createStore as any);

// 使用combineReducers合并后,每个reducer会对应一个独立的store数据
const store = applyCreateStore(rootReducer, composeWithDevTools() as any);

epicMiddleware.run(rootEpic);

class App extends React.Component<AppIsRequireProps, {}> {
  public render(): any {
    return (
      <Provider store={this.props.store}>
        <LocaleProvider locale={zh_CN}>
          <AppRouter />
        </LocaleProvider>
      </Provider>
    );
  }
}

const app: HTMLElement = document.getElementById('app') as HTMLElement;

declare var module;

ReactDOM.render(<App store={store} />, app);

// 热替换代码
if (module.hot) {
  module.hot.accept();
} else {
  window.onload = () => {
    ReactDOM.render(<App store={store} />, app);
  };
}
