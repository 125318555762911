import * as React from 'react';
import * as _ from 'lodash';
import moment from 'moment';
import { history } from '@/router';
import {
  Table,
  Button,
  Input,
  Divider,
  Modal,
  Select,
  DatePicker,
  Icon,
  Pagination,
} from 'antd';
const { RangePicker } = DatePicker;
const confirm = Modal.confirm;
const Option = Select.Option;
import { helpers } from '@/utils';
import {
  ContainerPropsInterface,
  ContainerStateInterface,
} from '@/routes/containerInterface';

import Assignments from './assignmentsPage';
import TasksDetailed from './tasksDetailedPage';

import { XList } from '@/components';
import { multiPlatform } from '@/multiPlatform';

export default class Tasks extends React.PureComponent<
  ContainerPropsInterface<any>,
  ContainerStateInterface
> {
  // 任务状态
  public taskState = {
    '0': '暂停',
    '1': '运行中',
  };

  // 表格列
  public columns: any = [
    {
      title: '任务名称',
      dataIndex: 'task_name',
      align: 'center',
      width: 250,
      widthAuto: true,
      render: text => (
        <span
          style={{
            width: 200,
            whiteSpace: 'normal',
            display: 'inline-block',
            wordBreak: 'break-all',
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: '分配标签',
      dataIndex: 'name',
      align: 'center',
      width: 150,
      render: (text, record) =>
        _.get(this.state.tags.filter(n => n.id === record.tag_id)[0], 'name'),
    },
    {
      title: '创建人',
      dataIndex: 'creator',
      align: 'center',
      width: 250,
      render: text => <span>{_.get(text, 'name')}</span>,
    },
    {
      title: '任务状态',
      dataIndex: 'task_status',
      align: 'center',
      width: 150,
      render: text => <span>{this.taskState[text]}</span>,
    },
    {
      title: '创建日期',
      dataIndex: 'created_at',
      align: 'center',
      width: 150,
      render: created_at => (
        <time>
          {created_at.slice(0, 10)}
          <br />
          {created_at.slice(10)}
        </time>
      ),
    },
    {
      title: '分配详情',
      dataIndex: '分配详情',
      align: 'center',
      width: 100,
      render: (text, record) =>
        helpers.isJudge(this.state.detailFlag)(
          <a
            href={'javascript:;'}
            onClick={() => {
              history.push('/main/customers/tasks/' + record.id);
            }}
          >
            查看
          </a>,
          null,
        ),
    },
    {
      title: '行销情况',
      dataIndex: '行销情况',
      align: 'center',
      width: 100,
      render: (text, record) =>
        helpers.isJudge(this.state.detailFlag)(
          <a
            href={'javascript:;'}
            onClick={() => this.getTasksDetails(record.id)}
          >
            查看
          </a>,
          null,
        ),
    },
    {
      title: '分配任务',
      dataIndex: '分配任务',
      align: 'center',
      width: 100,
      render: (text, record) =>
        helpers.isJudge(record.task_status === 0 || record.task_status === 2)(
          null,
          helpers.isJudge(this.state.setFlag)(
            <a
              href={'javascript:;'}
              onClick={() => {
                this.selectedTasks = [record.id];
                this.unprocessedAmount = record.unassigned_amount;
                this.setState({
                  showAssignmentsTimes: this.state.showAssignmentsTimes + 1,
                });
              }}
            >
              分配
            </a>,
            null,
          ),
        ),
    },
    {
      title: '操作',
      dataIndex: 'action',
      align: 'center',
      width: 150,
      render: (text, record) => (
        <span style={{ fontSize: 18 }}>
          {helpers.isJudge(this.state.downFlag)(
            <a
              href={'javascript:;'}
              title={'下载'}
              onClick={() => this.getTasksDownLoad(record.id, record.task_name)}
            >
              <Icon type={'cloud-download'} />
            </a>,
            null,
          )}
          <Divider type={'vertical'} />
          {helpers.isJudge(this.state.startFlag)(
            helpers.isJudge(record.task_status === 0)(
              <a href={'javascript:;'} onClick={() => this.runTask(record.id)}>
                <Icon type={'caret-right'} />
              </a>,
              <a
                href={'javascript:;'}
                onClick={() => this.pauseTask(record.id)}
              >
                <Icon type={'pause'} />
              </a>,
            ),
            null,
          )}
          {helpers.isJudge(this.state.delFlag)(
            helpers.isJudge(record.task_status === 0)(
              <React.Fragment>
                <Divider type={'vertical'} />
                <a
                  href={'javascript:;'}
                  style={{ color: '#ff0000' }}
                  onClick={() => this.confirmDelete(record.id)}
                >
                  <Icon type={'close'} />
                </a>
              </React.Fragment>,
              null,
            ),
            null,
          )}
        </span>
      ),
    },
  ];

  // 页面大小存储使用的key
  public STORE_PAGE_SIZE_KEY = 'smallPageSize';

  constructor(props) {
    super(props);
    this.state = {
      list: [], // 任务列表
      total: 0, // 全部任务的数量
      tags: [], // 标签列表
      page: {
        size: helpers.isJudge(
          localStorage.getItem(this.STORE_PAGE_SIZE_KEY) === null,
        )(10, localStorage.getItem(this.STORE_PAGE_SIZE_KEY)), // 用户列表的每页大小
        num: 1, // 用户列表的当前页数
      },
      selectedTasks: [], // 选中的任务
      showAssignmentsTimes: 0, // 显示分配窗口任务次数
      // 过滤对象组
      filterObj: {
        ...this.filterObj,
      },
      detailFlag: false,
      delFlag: false,
      downFlag: false,
      setFlag: false,
      startFlag: false,
    };
  }

  // 分配任务组件
  public AssignmentsComponent = multiPlatform.assignmentsMulti(Assignments);

  // 任务详情组件
  public TasksDetailedComponent = multiPlatform.tasksDetailedMulti(
    TasksDetailed,
  );

  // 用于分页时可以使用上次的筛选操作
  public filterObj = {
    task_name: '', // 任务名称
    tag_id: -1, // 标签id
    created_at_start: '', // 创建开始日期
    created_at_end: '', // 创建结束日期
    task_status: undefined, // 任务状态
  };

  public selectedTasks: any = []; // 用户提交的选中任务
  public updateTimes = 0; // 被动更新次数
  public unprocessedAmount = 0; // 未处理总数
  public selectedUnprocessedAmount = 0; // 选中未处理总数

  public data: any = []; // 标签列表

  public componentWillMount() {
    // 获取标签列表
    if (this.props.all.tags.tags.length === 0) {
      this.getTags();
    }

    // 获取任务列表
    this.getTasks();
  }

  // 获取标签
  public getTags = () => {
    this.props.dispatch({
      type: 'system/tags/getTagsEpic',
      payload: {
        page_size: 1000,
      },
    });
  };

  // 获取任务
  public getTasks = () => {
    const obj = Object.assign({}, this.state.filterObj);
    if (obj.tag_id === -1) {
      obj.tag_id = null;
    }

    this.props.dispatch({
      type: 'customers/getTasksEpic',
      payload: {
        ...obj,
        page: this.state.page.num,
        page_size: this.state.page.size,
      },
    });
  };

  // 过滤查询
  public filterSelect = () => {
    this.filterObj = this.state.filterObj; // 保存被筛选的条件

    this.setState(
      { page: { num: 1, size: this.state.page.size } },
      this.getTasks,
    );
  };

  // 通过之前的筛选条件获取任务
  public getTasksBeforeFilter = () => {
    localStorage.setItem(this.STORE_PAGE_SIZE_KEY, this.state.page.size);
    const obj = Object.assign({}, this.state.filterObj);
    if (obj.tag_id === -1) {
      delete obj.tag_id;
    }

    this.props.dispatch({
      type: 'customers/getTasksEpic',
      payload: {
        ...obj,
        page: this.state.page.num,
        page_size: this.state.page.size,
      },
    });
  };

  // 获取任务详情
  public getTasksDetails = id => {
    this.props.dispatch({
      type: 'customers/getTasksDetailsEpic',
      payload: {
        id,
      },
    });
  };

  // 任务下载
  public getTasksDownLoad = (id, name) => {
    this.props.dispatch({
      type: 'customers/downloadTaskEpic',
      payload: {
        id,
        name,
      },
    });
  };

  // 启动任务
  public pauseTask = id => {
    const self = this;
    confirm({
      title: '您确定要暂停任务吗？',
      content: '（暂停后，将从被分配的系统用户的操作台上消失）',
      onOk() {
        self.props.dispatch({
          type: 'customers/pauseTaskEpic',
          payload: {
            id,
          },
        });
      },
    });
  };

  // 启动任务
  public runTask = id => {
    const self = this;
    confirm({
      title: '您确定要运行该任务吗？',
      content: (
        <div>
          <p>（名单将按照原分配，展示到操作台）</p>
        </div>
      ),
      onOk() {
        self.props.dispatch({
          type: 'customers/runTaskEpic',
          payload: {
            id,
          },
        });
      },
    });
  };

  // 筛选重置
  public handleFilterReset = () => {
    this.setState({
      filterObj: {
        task_name: '', // 任务名称
        tag_id: -1, // 标签id
        created_at_start: '', // 创建开始日期
        created_at_end: '', // 创建结束日期
        task_status: undefined, // 任务状态
      },
    });
  };

  // 选择创建日期
  public selectCreateDate = dateString => {
    this.setState({
      filterObj: {
        ...this.state.filterObj,
        created_at_start: dateString[0],
        created_at_end: dateString[1],
      },
    });
  };

  public componentWillReceiveProps(nextProps) {
    // 按钮数据权限控制
    const buttonPermission = nextProps.all.main.buttonPermission;
    this.setState({
      detailFlag: _.includes(buttonPermission, 15),
      delFlag: _.includes(buttonPermission, 14),
      downFlag: _.includes(buttonPermission, 17),
      setFlag: _.includes(buttonPermission, 16),
      startFlag: _.includes(buttonPermission, 12),
    });

    // 标签列表绑定
    if (nextProps.all.tags.tags !== undefined) {
      if (
        nextProps.all.tags.tags.length !== 0 &&
        !_.isEqual(nextProps.all.tags.tags, this.state.tags)
      ) {
        const arr = nextProps.all.tags.tags.filter(
          obj => obj.name !== '存提受阻',
        );
        this.setState({ tags: arr });
      }
    }

    // 绑定任务列表
    if (!_.isEqual(nextProps.state.tasks, this.state.list)) {
      this.data = nextProps.state.tasks;
      this.setState({
        list: nextProps.state.tasks,
      });
    }

    if (nextProps.state.total !== this.state.total) {
      this.data = nextProps.state.tasks;
      this.setState({
        total: nextProps.state.total,
      });
    }

    if (nextProps.state.updateTimes !== this.updateTimes) {
      this.updateTimes = nextProps.state.updateTimes;
      this.getTasks();
    }
  }

  // 删除标签
  public confirmDelete(deleteId: number) {
    const self = this;
    confirm({
      title: '您确定要删除该任务吗？',
      content: '（删除后，将从被分配的系统用户的操作台上消失）',
      onOk() {
        self.props.dispatch({
          type: 'customers/removeTasksEpic',
          payload: {
            id: deleteId,
          },
        });
      },
    });
  }

  // 筛选标签列表
  public handleFilter(filterText) {
    if (this.data.length !== 0) {
      this.setState({
        filterText,
        tags: this.data.filter(obj => obj.name.indexOf(filterText) !== -1),
      });
    }
  }

  // 改变页号
  public changePage = (page, size) => {
    this.setState({ page: { num: page, size } }, this.getTasksBeforeFilter);
  };

  // 改变每页大小
  public changePageSize = (page, size) => {
    this.setState({ page: { num: 1, size } }, this.getTasksBeforeFilter);
  };

  // 列表模块渲染
  public renderList = () => {
    return (
      <React.Fragment>
        <XList
          columns={this.columns}
          dataSource={this.state.list}
          rowKey={'id'}
          autoCalculation={true}
          pagination={{
            current: this.state.page.num,
            pageSize: this.state.page.size,
            total: this.state.total,
            showSizeChanger: true,
            showQuickJumper: true,
            onChange: this.changePage,
            onShowSizeChange: this.changePageSize,
            pageSizeOptions: ['10', '20', '30', '40'],
          }}
        />
      </React.Fragment>
    );
  };

  // 筛选模块渲染
  public renderFilter = () => {
    const dateFormat = 'YYYY/MM/DD';
    return (
      <div>
        <p>
          分配标签：
          <Button
            type={helpers.isJudge(this.state.filterObj.tag_id === -1)(
              'primary',
              null,
            )}
            onClick={() =>
              this.setState(
                {
                  filterObj: {
                    ...this.state.filterObj,
                    tag_id: -1,
                  },
                  page: {
                    num: 1,
                    size: this.state.page.size,
                  },
                },
                this.getTasks,
              )
            }
          >
            不限
          </Button>
          {this.state.tags.map(item => (
            <Button
              type={helpers.isJudge(this.state.filterObj.tag_id === item.id)(
                'primary',
                null,
              )}
              onClick={() =>
                this.setState(
                  {
                    filterObj: {
                      ...this.state.filterObj,
                      tag_id: item.id,
                    },
                    page: {
                      num: 1,
                      size: this.state.page.size,
                    },
                  },
                  this.getTasks,
                )
              }
            >
              {item.name}
            </Button>
          ))}
        </p>
        <p>
          任务名称：
          <Input
            type={'text'}
            placeholder={'请输入名称'}
            value={this.state.filterObj.task_name}
            maxLength={15}
            onChange={e =>
              this.setState({
                filterObj: {
                  ...this.state.filterObj,
                  task_name: e.target.value,
                },
              })
            }
          />
          <span className={'notWrap'}>
            创建日期：
            <RangePicker
              onChange={(date, dateString) => this.selectCreateDate(dateString)}
              value={helpers.isJudge(
                this.state.filterObj.created_at_start === '',
              )(null, [
                moment(this.state.filterObj.created_at_start, dateFormat),
                moment(this.state.filterObj.created_at_end, dateFormat),
              ])}
            />
          </span>
          <span className={'notWrap'}>
            任务状态：
            <Select
              allowClear
              value={this.state.filterObj.task_status}
              placeholder={'不限'}
              onChange={value =>
                this.setState({
                  filterObj: { ...this.state.filterObj, task_status: value },
                })
              }
            >
              <Option value={''}>不限</Option>
              {Object.keys(this.taskState).map(n => (
                <Option value={n}>{this.taskState[n]}</Option>
              ))}
            </Select>
            <Button type={'primary'} onClick={this.filterSelect}>
              筛选
            </Button>
            <Button onClick={this.handleFilterReset}>重置</Button>
          </span>
        </p>
      </div>
    );
  };

  public render() {
    const AssignmentsComponent = this.AssignmentsComponent;
    const TasksDetailedComponent = this.TasksDetailedComponent;

    return (
      <React.Fragment>
        {this.renderFilter()}
        {this.renderList()}
        <AssignmentsComponent
          showTimes={this.state.showAssignmentsTimes}
          selectedTasks={this.selectedTasks}
          unprocessedAmount={this.unprocessedAmount}
        />
        <TasksDetailedComponent />
      </React.Fragment>
    );
  }
}
