import * as React from 'react';
import * as _ from 'lodash';
import { helpers } from '@/utils';
import moment from 'moment';
import CustomersUpload from './customersUploadPage';
import CreateTask from './createTaskPage';
import CreateGroupCallTask from './createGroupCallTaskPage';
import { Alert1, XList } from '@/components';
import {
  Select,
  DatePicker,
  Button,
  Input,
  Modal,
  Upload,
  Icon,
  message,
} from 'antd';

const { RangePicker } = DatePicker;
const Option = Select.Option;
import {
  ContainerPropsInterface,
  ContainerStateInterface,
} from '@/routes/containerInterface';
import { multiPlatform } from '@/multiPlatform';
import '../../scss/common.scss';
import { customersAPI } from '@/services';
import { servicPath } from '@/utils';

export default class Customers extends React.PureComponent<
  ContainerPropsInterface<any>,
  ContainerStateInterface
> {
  // 会员来源
  public selectTypeList = {
    '1': '非官方代理线下会员',
    '2': '官方代理线下会员',
  };
  // 名单库表格列
  public listColumns: any = [
    {
      align: 'center',
      title: '客户来源',
      dataIndex: 'source_id',
      width: 80,
      render: source_id => (
        <p>
          {source_id === 0 ? (
            <span>会员</span>
          ) : (
            <span style={{ color: '#75cdc5' }}>外部</span>
          )}
        </p>
      ),
    },
    {
      title: '用户名',
      dataIndex: 'username',
      width: 150,
      widthAuto: true,
    },
    {
      title: '代理/上级',
      dataIndex: 'is_daili',
      width: 100,
      align: 'center',
      render: (text, record) => {
        const { is_daili, self } = record;
        let dailiusername = '';
        if (self) {
          dailiusername = self.username;
        }

        return (
          <span className={'greenPointCell'}>
            {helpers.isJudge(is_daili === 0)(
              '否/-',
              helpers.isJudge(is_daili === 1)(`是/${dailiusername}`, ''),
            )}
            {text}
          </span>
        );
      },
    },
    {
      title: '会员来源',
      dataIndex: 'daili_type',
      width: 150,
      // render: (text) => <span>{this.typeList[text]}</span>,
    },
    {
      title: '真实姓名',
      dataIndex: 'x_real_name',
      width: 80,
    },
    {
      title: '手机号码',
      dataIndex: 'x_phone',
      width: 100,
      align: 'center',
      render: text => (
        <span
          style={{
            lineHeight: '14px',
            width: 100,
            overflow: 'hidden',
            padding: 0,
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: '客户标签',
      dataIndex: 'yabotags',
      width: 150,
      render: text => {
        if (text.length === 0) {
          return '';
        } else {
          return text.map(n => n.tag_name).join(' ');
        }
      },
    },
    {
      title: 'VIP等级',
      dataIndex: 'viplevel',
      align: 'center',
      width: 60,
    },
    {
      title: '存款次数',
      dataIndex: 'deposit_count',
      align: 'center',
      width: 80,
    },
    {
      title: '存款金额',
      dataIndex: 'total_deposit_amount',
      align: 'center',
      width: 100,
    },
    {
      title: '存款时间',
      dataIndex: 'last_deposit_time',
      width: 85,
      render: text =>
        !text || text.slice(0, 10) === '0000-00-00' ? null : (
          <time>
            {text.slice(0, 10)}
            <br />
            {text.slice(10)}
          </time>
        ),
    },
    {
      title: '提款金额',
      dataIndex: 'last_withdraw_amount',
      align: 'center',
      width: 100,
    },
    {
      title: '提款时间',
      dataIndex: 'last_withdraw_time',
      width: 85,
      render: text =>
        !text || text.slice(0, 10) === '0000-00-00' ? null : (
          <time>
            {text.slice(0, 10)}
            <br />
            {text.slice(10)}
          </time>
        ),
    },
    {
      title: '标签',
      dataIndex: 'tag_id',
      width: 100,
      align: 'center',
      render: (text, record) =>
        _.get(this.state.tags.filter(n => n.id === record.tag_id)[0], 'name'),
    },
    {
      title: '总流水',
      dataIndex: 'total_amount',
      align: 'center',
      width: 100,
    },
    {
      title: '注册时间',
      dataIndex: 'register_time',
      width: 85,
      render: text =>
        !text || text.slice(0, 10) === '0000-00-00' ? null : (
          <time>
            {text.slice(0, 10)}
            <br />
            {text.slice(10)}
          </time>
        ),
    },
    {
      title: '最后登录时间',
      dataIndex: 'last_login_time',
      width: 100,
      render: text =>
        !text || text.slice(0, 10) === '0000-00-00' ? null : (
          <time>
            {text.slice(0, 10)}
            <br />
            {text.slice(10)}
          </time>
        ),
    },
    {
      title: '导入时间',
      dataIndex: 'created_at',
      width: 100,
      render: text => {
        return text ? (
          <time>
            {text.slice(0, 10)}
            <br />
            {text.slice(10)}
          </time>
        ) : (
          ''
        );
      },
    },
  ];

  constructor(props) {
    super(props);
    this.state = {
      pageType: this.props.match.params.id, // 页面类型
      list: [], // 客户列表
      total: 0, // 总条数
      tags: [], // 标签列表
      lastImportMount: 0, // 最后导入数量
      newImportMount: 0, // 新导入数量
      newImportMountDate: '', // 新导入日期
      page: {
        size: helpers.isJudge(
          localStorage.getItem('customersPageSize') === null,
        )(100, localStorage.getItem('customersPageSize')), // 用户列表的每页大小
        num: 1, // 用户列表的当前页数
      },
      // 过滤对象组
      filterObj: {
        ...this.filterObj,
      },
      allSelectedCustomers: [], // 选中得id列表
    };
  }

  // 用于分页时可以使用上次的筛选操作
  public filterObj = {
    username: '', // 过滤的名称
    source_id: -1, // 过滤的客户来源
    deposit_count: -1, // 过滤的存款次数
    created_at_start: '', // 导入开始日期
    created_at_end: '', // 导入结束日期
    register_time_start: '', // 注册时间范围开始
    register_time_end: '', // 注册时间范围截至
    phone: '', // 过滤的手机号
    last_login_day: -1, // 过滤的最后登录天
    tag_id: -1, // 标签id
    user_level_list: [' '], // 过滤的用户等级
    deposit_amount: '', // 最少存款金额
    deposit_amount_upper_limit: '', // 最大存款金额
    yabo_tag: undefined, // 客户标签id
    daili_type: undefined, // 会员来源id
    is_daili: '',
  };

  // 用户上传组件
  public CustomersUploadComponent = multiPlatform.customersUploadMulti(
    CustomersUpload,
  );
  // 创建任务组件
  public CreateTaskComponent = multiPlatform.createTaskMulti(CreateTask);
  // 创建群呼任务组件
  public CreateGroupCallTaskComponent = multiPlatform.createTaskMulti(
    CreateGroupCallTask,
  );

  public componentWillReceiveProps(nextProps) {
    // 绑定客户列表
    this.setState({
      list: nextProps.state.customers.data,
      total: nextProps.state.customers.total,
    });

    // 页面类型绑定
    if (nextProps.match.params.id !== this.state.pageType) {
      this.setState({ pageType: nextProps.match.params.id });
    }

    // 标签列表绑定
    if (nextProps.all.tags.tags !== undefined) {
      if (
        nextProps.all.tags.tags.length !== 0 &&
        !_.isEqual(nextProps.all.tags.tags, this.state.tags)
      ) {
        this.setState({ tags: nextProps.all.tags.tags });
      }
    }
  }

  // 获取客户信息
  public getCustomers = () => {
    const obj = Object.assign({}, this.state.filterObj);
    if (obj.last_login_day === -1) {
      delete obj.last_login_day;
    }
    if (obj.source_id === -1) {
      delete obj.source_id;
    }
    if (obj.deposit_count === -1) {
      delete obj.deposit_count;
    }
    // 如果出现空格值，转为空
    obj.user_level_list = obj.user_level_list.join(',').trim();
    this.props.dispatch({
      type: 'customers/getCustomersEpic',
      payload: {
        page_size: parseInt(this.state.page.size, 10),
        page: this.state.page.num,
        ...obj,
      },
    });
  };

  // 过滤查询
  public filterSelect = () => {
    this.filterObj = this.state.filterObj; // 保存被筛选的条件

    this.setState(
      { page: { num: 1, size: this.state.page.size } },
      this.getCustomers,
    );
  };

  // 通过之前的筛选条件获取列表
  public getListBeforeFilter = () => {
    localStorage.setItem('customersPageSize', this.state.page.size);
    const obj = Object.assign({}, this.filterObj);
    if (obj.last_login_day === -1) {
      delete obj.last_login_day;
    }
    if (obj.source_id === -1) {
      delete obj.source_id;
    }
    if (obj.deposit_count === -1) {
      delete obj.deposit_count;
    }
    this.props.dispatch({
      type: 'customers/getCustomersEpic',
      payload: {
        page_size: parseInt(this.state.page.size, 10),
        page: this.state.page.num,
        ...obj,
        user_level_list: obj.user_level_list.join(','),
      },
    });
  };

  // 选择注册时间
  public selectRegisterDate = dateString => {
    this.setState({
      filterObj: {
        ...this.state.filterObj,
        register_time_start: dateString[0],
        register_time_end: dateString[1],
      },
    });
  };

  // 选择导入时间
  public selectCreatedDate = dateString => {
    this.setState({
      filterObj: {
        ...this.state.filterObj,
        created_at_start: dateString[0],
        created_at_end: dateString[1],
      },
    });
  };

  // 筛选重置
  public handleFilterReset = () => {
    this.setState({
      filterObj: {
        username: '', // 过滤的名称
        source_id: -1, // 过滤的客户来源
        deposit_count: -1, // 过滤的存款次数
        created_at_start: '', // 导入开始日期
        created_at_end: '', // 导入结束日期
        register_time_start: '', // 注册时间范围开始
        register_time_end: '', // 注册时间范围截至
        phone: '', // 过滤的手机号
        last_login_day: -1, // 过滤的最后登录天
        tag_id: -1, // 标签id
        user_level_list: [' '], // 过滤的用户等级
        deposit_amount: '', // 最少存款金额
        deposit_amount_upper_limit: '', // 最大存款金额
        yabo_tag: undefined, // 客户标签id
        is_daili: '',
      },
    });
  };

  // 获取客户列表
  public componentWillMount() {
    this.getCustomers();

    // 获取标签列表
    if (this.props.all.tags.tags.length === 0) {
      this.getTags();
    }
  }

  // 获取标签
  public getTags = () => {
    this.props.dispatch({
      type: 'system/tags/getTagsEpic',
      payload: {
        page_size: 1000,
      },
    });
  };

  // 下载模板
  public getTemplateDownload = () => {
    this.props.dispatch({
      type: 'customers/downloadTemplate',
      payload: {},
    });
  };

  public showModal = () => {
    this.setState({ modalVisible: true });
  };
  public showGroupCallTaskModal = () => {
    this.setState({ groupCallTaskModalVisible: true });
  };

  public importTask = () => {
    const { taskName, taskTag, fileList } = this.state;
    this.props.dispatch({
      type: 'customers/createTaskByImport',
      payload: {
        param: {
          task_name: taskName,
          tag_id: taskTag,
          file_name: fileList[0].uid,
        },
        callback: () => {
          message.info('添加任务成功');
          this.hanlderColse();
        },
      },
    });
  };

  public hanlderColse = () =>
    this.setState({
      taskName: undefined,
      taskTag: undefined,
      fileList: undefined,
      modalVisible: undefined,
      groupCallTaskModalVisible: undefined,
    });

  private taskModal = () => (
    <Modal
      visible
      title={'创建任务'}
      footer={
        this.state.fileList && this.state.fileList.length ? undefined : null
      }
      onCancel={this.hanlderColse}
      onOk={this.importTask}
      wrapClassName={'taskModal'}
    >
      <div className={'row'}>
        <span>任务名称：</span>
        <Input
          className={'input'}
          placeholder={'请输入任务名称'}
          value={this.state.taskName}
          onChange={e => this.setState({ taskName: e.target.value })}
        />
      </div>
      <div className={'row'}>
        <span>分配标签：</span>
        <Select
          className={'input'}
          placeholder={'请选择标签'}
          value={this.state.taskTag}
          onSelect={value => this.setState({ taskTag: value })}
        >
          {this.state.tags.map(item => (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          ))}
        </Select>
      </div>
      {this.state.taskName && this.state.taskTag && (
        <Upload.Dragger
          name={'file'}
          multiple={false}
          accept={
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          }
          customRequest={() => {
            return null;
          }}
          fileList={this.state.fileList}
          beforeUpload={file => {
            const formData = new FormData();
            formData.append('file', file);
            customersAPI.handleUploadCustomersExcel({
              url: `${servicPath}/products/importFile`,
              data: formData,
              success: res => {
                if (res.error_code === 6000) {
                  this.setState({
                    fileList: [
                      {
                        name: file.name,
                        status: 'done',
                        uid: res.data.fileName,
                      },
                    ],
                  });
                } else {
                  message.error(res.message);
                  this.setState({
                    fileList: undefined,
                  });
                }
              },
            });
            return true;
          }}
        >
          <p className={'ant-upload-drag-icon'}>
            <Icon type={'inbox'} />
          </p>
          <p className={'ant-upload-text'}>点击或拖拽文件到此上传</p>
        </Upload.Dragger>
      )}
    </Modal>
  );

  // 渲染按钮列表
  public renderBtnList = () => {
    const CustomersUploadComponent = this.CustomersUploadComponent;
    const CreateTaskComponent = this.CreateTaskComponent;
    const CreateGroupCallTaskComponent = this.CreateGroupCallTaskComponent;
    const { allSelectedCustomers } = this.state;

    // 按钮权限控制
    const buttonPermission = this.props.all.main.buttonPermission;
    const uploadFlag = _.includes(buttonPermission, 4);
    const addFlag = _.includes(buttonPermission, 5);
    const downFlag = _.includes(buttonPermission, 3);
    return (
      <p>
        {helpers.isJudge(downFlag)(
          <Button icon={'download'} onClick={this.getTemplateDownload}>
            下载模板
          </Button>,
          null,
        )}
        {helpers.isJudge(uploadFlag)(<CustomersUploadComponent />, null)}
        <Button
          type={'primary'}
          style={{ marginLeft: 10 }}
          icon={'plus'}
          onClick={this.showModal}
        >
          导入创建任务
        </Button>
        {helpers.isJudge(addFlag)(
          <CreateTaskComponent selectedCustomers={allSelectedCustomers} />,
          null,
        )}
        {helpers.isJudge(addFlag)(
          <CreateGroupCallTaskComponent
            selectedCustomers={allSelectedCustomers}
          />,
          null,
        )}
      </p>
    );
  };

  // 筛选模块渲染
  public renderFilter = () => {
    const dateFormat = 'YYYY/MM/DD';
    return (
      <div>
        <p>
          <span className={'notWrap'}>
            客户来源：
            <Button
              type={helpers.isJudge(this.state.filterObj.source_id === -1)(
                'primary',
                null,
              )}
              onClick={() =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    source_id: -1,
                  },
                })
              }
            >
              不限
            </Button>
            <Button
              type={helpers.isJudge(this.state.filterObj.source_id === 0)(
                'primary',
                null,
              )}
              onClick={() =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    source_id: 0,
                  },
                })
              }
            >
              系统会员
            </Button>
            <Button
              type={helpers.isJudge(this.state.filterObj.source_id === 1)(
                'primary',
                null,
              )}
              onClick={() =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    source_id: 1,
                  },
                })
              }
              className={'mr40'}
            >
              外部导入
            </Button>
          </span>
          <span className={'notWrap'}>
            选择标签：
            <Select
              value={this.state.filterObj.tag_id}
              placeholder={'不限制'}
              allowClear={false}
              onChange={value =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    tag_id: value,
                  },
                })
              }
            >
              <Option value={-1}>不限</Option>
              <Option value={0}>没有标签</Option>
              {this.state.tags.map(item => (
                <Option value={item.id}>{item.name}</Option>
              ))}
            </Select>
          </span>
          <span className={'notWrap'}>
            代理：
            <Select
              value={this.state.filterObj.is_daili}
              placeholder={'不限'}
              allowClear={false}
              onChange={value =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    is_daili: value,
                  },
                })
              }
            >
              <Option value={''}>不限</Option>
              <Option value={0}>非代理</Option>
              <Option value={1}>代理</Option>
            </Select>
          </span>
          <span className={'notWrap'}>
            存款次数：
            <Select
              value={this.state.filterObj.deposit_count}
              onChange={value =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    deposit_count: value,
                  },
                })
              }
            >
              <Option value={-1}>不限</Option>
              <Option value={0}>0次</Option>
              <Option value={1}>1次</Option>
              <Option value={2}>1次以上</Option>
            </Select>
          </span>
          <span className={'notWrap'}>
            注册时间：
            <RangePicker
              onChange={(date, dateString) =>
                this.selectRegisterDate(dateString)
              }
              value={helpers.isJudge(
                this.state.filterObj.register_time_start === '',
              )(null, [
                moment(this.state.filterObj.register_time_start, dateFormat),
                moment(this.state.filterObj.register_time_end, dateFormat),
              ])}
            />
          </span>
          <span className={'notWrap'}>
            导入日期：
            <RangePicker
              onChange={(date, dateString) =>
                this.selectCreatedDate(dateString)
              }
              value={helpers.isJudge(
                this.state.filterObj.created_at_start === '',
              )(null, [
                moment(this.state.filterObj.created_at_start, dateFormat),
                moment(this.state.filterObj.created_at_end, dateFormat),
              ])}
            />
          </span>
          <span className={'notWrap'}>
            登录时间：
            <DatePicker
              onChange={(date, dateString) =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    last_login_day_lt: dateString,
                  },
                })
              }
              className={'dateStyle'}
              style={{ width: '180px' }}
              placeholder={'请输入开始时间'}
            />
            &nbsp;至&nbsp;
            <DatePicker
              onChange={(date, dateString) =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    last_login_day: dateString,
                  },
                })
              }
              className={'dateStyle'}
              style={{ width: '180px', marginRight: '20px' }}
              placeholder={'请输入结束时间'}
            />
            {/* <Button
             type={helpers.isJudge(this.state.filterObj.last_login_day === -1)(
             'primary',
             null,
             )}
             onClick={() =>
             this.setState({
             filterObj: {
             ...this.state.filterObj,
             last_login_day: -1,
             last_login_day_lt: undefined, // Web不支持-1参数，所以这里改为了undefined
             },
             })
             }
             >
             不限
             </Button>
             <Button
             type={helpers.isJudge(this.state.filterObj.last_login_day === 3)(
             'primary',
             null,
             )}
             onClick={() =>
             this.setState({
             filterObj: {
             ...this.state.filterObj,
             last_login_day: 3,
             },
             })
             }
             >
             ≥3天
             </Button>
             <Button
             type={helpers.isJudge(this.state.filterObj.last_login_day === 7)(
             'primary',
             null,
             )}
             onClick={() =>
             this.setState({
             filterObj: {
             ...this.state.filterObj,
             last_login_day: 7,
             },
             })
             }
             >
             ≥7天
             </Button>
             <Input
             placeholder={'天数'}
             style={{ width: 60, marginRight: 10 }}
             value={
             this.state.filterObj.last_login_day === -1
             ? ''
             : this.state.filterObj.last_login_day
             }
             onChange={(e) =>
             this.setState({
             filterObj: {
             ...this.state.filterObj,
             last_login_day: _.isNaN(parseInt(e.target.value, 10))
             ? -1
             : parseInt(e.target.value, 10),
             },
             })
             }
             />
             至
             <Input
             placeholder={'天数'}
             style={{ width: 60, marginLeft: 10 }}
             value={
             this.state.filterObj.last_login_day_lt
             ? this.state.filterObj.last_login_day_lt
             : ''
             }
             onChange={(e) =>
             this.setState({
             filterObj: {
             ...this.state.filterObj,
             // Web不支持-1参数，所以这里改为了undefined
             last_login_day_lt: _.isNaN(parseInt(e.target.value, 10))
             ? undefined
             : parseInt(e.target.value, 10),
             },
             })
             }
             /> */}
          </span>
          <span className={'notWrap'}>
            用户名：
            <Input
              style={{ width: '280px' }}
              placeholder={'请输入用户名'}
              value={this.state.filterObj.username}
              onChange={e =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    username: e.target.value,
                  },
                })
              }
            />
          </span>
          <span className={'notWrap'}>
            会员来源：
            <Select
              value={this.state.filterObj.daili_type}
              allowClear
              placeholder={'不限'}
              onChange={value =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    daili_type: value,
                  },
                })
              }
            >
              {Object.keys(this.selectTypeList).map(n => (
                <Option value={n}>{this.selectTypeList[n]}</Option>
              ))}
            </Select>
          </span>
          <span className={'notWrap'}>
            存款金额：
            <Input
              placeholder={'金额'}
              style={{ width: 100, marginRight: 10 }}
              value={this.state.filterObj.deposit_amount}
              onChange={e =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    deposit_amount: _.isNaN(parseInt(e.target.value, 10))
                      ? e.target.value === ''
                        ? ''
                        : this.state.filterObj.deposit_amount
                      : e.target.value,
                  },
                })
              }
            />
            至
            <Input
              placeholder={'金额'}
              style={{ width: 100, marginLeft: 10 }}
              value={this.state.filterObj.deposit_amount_upper_limit}
              onChange={e =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    deposit_amount_upper_limit: _.isNaN(
                      parseInt(e.target.value, 10),
                    )
                      ? e.target.value === ''
                        ? ''
                        : this.state.filterObj.deposit_amount_upper_limit
                      : e.target.value,
                  },
                })
              }
            />
          </span>
          <span className={'notWrap'}>
            客户标签：
            <Select
              value={this.state.filterObj.yabo_tag}
              allowClear
              placeholder={'不限'}
              onChange={value =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    yabo_tag: value,
                  },
                })
              }
            >
              <Option value={'1'}>有标签</Option>
              <Option value={'2'}>无标签</Option>
            </Select>
          </span>
          <span className={'notWrap'}>
            手机号码：
            <Input
              style={{ width: '200px' }}
              placeholder={'请输入手机号码'}
              value={this.state.filterObj.phone}
              onChange={e => {
                if (
                  _.isNaN(parseInt(e.target.value, 10)) &&
                  e.target.value !== ''
                ) {
                  return;
                } else if (
                  parseInt(e.target.value, 10) < 0 &&
                  e.target.value !== ''
                ) {
                  return;
                }

                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    phone: e.target.value,
                  },
                });
              }}
            />
          </span>
          <span className={'notWrap'}>
            用户等级：
            <Select
              style={{ width: '280px', height: '32px' }}
              value={this.state.filterObj.user_level_list}
              mode={'multiple'}
              allowClear={false}
              maxTagCount={3}
              showArrow={false}
              maxTagPlaceholder={'...'}
              onChange={value => {
                if (value.length === 0) {
                  // 如果选择的数量为空，则自动绑定到不限
                  value = [' '];
                } else if (value.length > 0) {
                  // 如果选择了其他类型数据，则对“不限”字段进行过滤
                  value = value.filter(item => item !== ' ');
                }

                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    user_level_list: value,
                  },
                });
              }}
            >
              <Option value={' '}>不限</Option>
              <Option value={'-1'}>未注册</Option>
              <Option value={'0'}>VIP0</Option>
              <Option value={'1'}>VIP1</Option>
              <Option value={'2'}>VIP2</Option>
              <Option value={'3'}>VIP3</Option>
              <Option value={'4'}>VIP4</Option>
              <Option value={'5'}>VIP5</Option>
              <Option value={'6'}>VIP6</Option>
              <Option value={'7'}>VIP7</Option>
              <Option value={'8'}>VIP8</Option>
              <Option value={'9'}>VIP9</Option>
              <Option value={'10'}>VIP10</Option>
            </Select>
          </span>
          <span className={'notWrap'}>
            <Button type={'primary'} onClick={this.filterSelect}>
              筛选
            </Button>
            <Button
              type={'primary'}
              className={'pBtn'}
              onClick={this.handleFilterReset}
            >
              重置
            </Button>
          </span>
        </p>
      </div>
    );
  };

  // 改变页号
  public changePage = (page, size) => {
    this.setState({ page: { num: page, size } }, this.getListBeforeFilter);
  };

  // 改变每页大小
  public changePageSize = (page, size) => {
    this.setState({ page: { num: 1, size } }, this.getListBeforeFilter);
  };

  public rowKeys: any = []; // 行的key
  // 列表模块渲染
  public renderList = () => {
    const { total, list, page } = this.state;

    // 选择框
    const rowSelection = {
      onChange: selectedRowKeys => {
        this.setState({ allSelectedCustomers: [...selectedRowKeys] });
      },
    };

    return (
      <XList
        showCopyTimes={this.state.showCopyTimes}
        columns={this.listColumns}
        rowSelection={rowSelection}
        isShowNum={true}
        scroll={{ x: 1610 }}
        rowKey={'id'}
        pagination={{
          total,
          pageSize: parseInt(page.size, 10),
          showSizeChanger: true,
          showQuickJumper: true,
          onChange: this.changePage,
          onShowSizeChange: this.changePageSize,
          current: page.num,
          pageSizeOptions: ['10', '20', '50', '100', '200', '300', '500'],
        }}
        dataSource={list}
      />
    );
  };

  public render() {
    return (
      <React.Fragment>
        {this.renderBtnList()}
        {this.renderFilter()}
        {helpers.isJudge(this.state.newImportMount === 0)(
          null,
          <Alert1
            message={
              this.state.newImportMountDate +
              ' 新入库了<span>' +
              this.state.newImportMount +
              '</span>条客户名单'
            }
          />,
        )}
        {this.renderList()}
        {this.state.modalVisible && this.taskModal()}
      </React.Fragment>
    );
  }
}
