import * as React from 'react';
import { Select, Modal, Button, Input, Form } from 'antd';
import { InputPWD } from '@/components';
import { helpers } from '@/utils';
import * as _ from 'lodash';
const confirm = Modal.confirm;
const Option = Select.Option;

interface PersonalEditProps {
  editData: any;
  [random: string]: any;
}
interface PersonalEditState {
  [random: string]: any;
}
export default class PersonalEdit extends React.PureComponent<
  PersonalEditProps,
  PersonalEditState
> {
  constructor(props) {
    super(props);
    this.state = {
      id: 0, // 用户id
      name: 0, // 用户名
      email: 0, // 邮箱
      roleName: '', // 角色名
      tags: []
      // 可见标签
    };
  }

  // 获取登录用户信息
  public getLoginUserinfo = () => {
    this.props.dispatch({
      type: 'main/getLoginInfoEpic'
    });
  };

  public componentWillMount() {
    this.getLoginUserinfo();
  }

  public componentWillReceiveProps(nextProps) {
    // 绑定用户信息
    if (nextProps.state.userInfo.email !== undefined) {
      this.setState({
        email: nextProps.state.userInfo.email,
        name: nextProps.state.userInfo.name,
        id: nextProps.state.userInfo.id,
        roleName: nextProps.state.userInfo.role.role_name,
        tags: nextProps.state.userInfo.tags.map(n => n.name)
      });
    }
  }

  // 更新个人信息
  public upateCurrentUserinfo = values => {
    this.props.dispatch({
      type: 'updatePersonalInfoEpic',
      payload: {
        password: values.password,
        id: this.state.id
      }
    });

    this.props.form.setFieldsValue({
      password: ''
    });
  };

  public showConfirm(values) {
    const self = this;
    confirm({
      title: '您确定更新个人资料吗？',
      content: '',
      onOk() {
        self.upateCurrentUserinfo(values);
      },
    });
  }

  public handleUpdate = () => {
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.showConfirm(values);
    });
  };

  public render() {
    return (
      <div className={'singlePage'}>
        <p>
          用户名：
          <Input type={'text'} disabled={true} value={this.state.name} />
        </p>
        <p>
          邮箱账号：
          <Input type={'text'} disabled={true} value={this.state.email} />
        </p>
        <p>
          所在角色组：
          <Select disabled={true} placeholder={this.state.roleName} />
        </p>
        <p>
          可见标签：
          <Input type={'text'} disabled={true} value={this.state.tags} />
        </p>
        <p>
          密码：
          <br />
          <Form.Item>
            {this.props.form.getFieldDecorator('password', {
              rules: [
                {
                  required: true,
                  message: '请输入密码'
                },
                {
                  validator: helpers.antdFormValidators.password
                }
              ]
            })(<InputPWD placeholder={'填写想要修改的密码'} />)}
          </Form.Item>
        </p>
        <p>
          <Button
            onClick={() => this.props.form.setFieldsValue({ password: '' })}
          >
            取消
          </Button>
          <Button type={'primary'} onClick={this.handleUpdate}>
            更新个人资料
          </Button>
        </p>
      </div>
    );
  }
}
