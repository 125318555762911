import * as _ from 'lodash';
import { Action } from '../reducers/reducerInterface';

const initialStore = {
  agentList: [], // 坐席列表
  agentInfo: {}
};

export type AppStoreType = typeof initialStore;

export default function agentReducer(
  state: any = initialStore,
  action: Action
) {
  try {
    switch (action.type) {
      // 坐席列表
      case 'report/getAgentList': {
        const agentList = action.payload.list;
        return _.assign({}, state, { agentList });
      }
      case 'polylink/getAgentInfo': {
        const agentInfo = action.payload;
        return _.assign({}, state, { agentInfo });
      }
      case 'polylink/setExtensionState': {
        const agentInfo = _.assign({}, state.agentInfo, action.payload);
        return _.assign({}, state, { agentInfo });
      }
      default:
        return state;
    }
  } catch (e) {
    return state;
  }
}
