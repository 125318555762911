// 设置打电话开始时间
export const setCallStartTime = callStartTime => ({
  type: 'callCenter/setCallStartTime',
  payload: { callStartTime }
});

// 设置打电话结束时间
export const setCallEndTime = callEndTime => ({
  type: 'callCenter/setCallEndTime',
  payload: { callEndTime }
});
