import * as React from 'react';
import { Modal, Button, Form, Select } from 'antd';
import { helpers } from '@/utils';
import _ from 'lodash';

const Option = Select.Option;

interface CreateTaskEditProps {
  [random: string]: any;
}

interface CreateTaskEditState {
  [random: string]: any;
}

export default class CreateGroupCallTaskPage extends React.PureComponent<
  CreateTaskEditProps,
  CreateTaskEditState
> {
  constructor(props) {
    super(props);
    this.state = {
      visible: false, // 是否显示标签添加窗口
      taskList: props.all.callCenter.groupCallTaskList || [], // 外呼任务列表
      sitList: props.all.callCenter.sitList || [], // 坐席号列表
      customers: [], // 客户列表
    };
  }

  public createTaskTimes = 0; // 创建任务次数

  public componentWillReceiveProps(nextProps) {
    this.setState({
      taskList: nextProps.all.callCenter.groupCallTaskList || [],
      sitList: nextProps.all.callCenter.sitList || [],
    });

    // 如果创建成功
    if (this.createTaskTimes !== nextProps.all.customers.createTaskTimes) {
      this.createTaskTimes = nextProps.all.customers.createTaskTimes;
      // 清空输入框
      this.props.form.setFieldsValue({
        taskName: '',
        tigsId: undefined,
      });
      // 隐藏窗口
      this.setState({
        visible: false,
      });
    }
  }

  public componentWillMount() {
    // tslint:disable-next-line:no-unused-expression
    _.includes(this.props.all.main.buttonPermission, 126) &&
      this.getGroupCallTaskPageList();
  }

  // 获取呼叫中心列表
  public getGroupCallTaskPageList = () => {
    this.props.dispatch({
      type: 'callCenter/groupCallTasksEpic',
      payload: {
        page_size: 100, // 页面大小
        page: 1, // 页码
      },
    });
    this.props.dispatch({
      type: 'reports/getSitsEpic',
    });
  };
  // 获取列表数据
  public getData = () => {
    this.props.dispatch({
      type: 'callCenter/taskListEpic',
      payload: {
        page_size: 1000,
      },
    });
  };

  // 创建任务
  public handleAdd = () => {
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.dispatch({
        type: 'callCenter/addGroupCallTaskEpic',
        payload: {
          task_id: values.task_id,
          customer_ids: this.props.selectedCustomers.toString(),
          extensions: values.customer_ids.toString(),
          cb: this.getData,
        },
      });
      // 清空输入框
      this.handleCancel();
    });
  };

  // 隐藏，并清空
  public handleCancel = () => {
    this.props.form.setFieldsValue({
      task_id: '',
      customer_ids: [],
    });
    this.setState({ visible: false });
  };

  // 渲染表单块
  public renderFrom = () => {
    return (
      <Modal
        title={'添加到群呼任务'}
        visible={this.state.visible}
        onOk={this.handleAdd}
        maskClosable={false}
        onCancel={this.handleCancel}
        width={600}
        className={'modalFrom'}
      >
        <div>
          <div className={'ant-form-item-label'}>已选会员：</div>
          <div className={'ant-form-item-control-wrapper'}>
            <strong>{this.props.selectedCustomers.length} 人</strong>
          </div>
        </div>
        <Form.Item label={'任务名称：'}>
          {this.props.form.getFieldDecorator('task_id', {
            rules: [
              {
                required: true,
                message: '请选择任务名称',
              },
            ],
          })(
            <Select placeholder={'请选择任务名称'}>
              {this.state.taskList.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>,
          )}
        </Form.Item>
        <Form.Item label={'坐席号：'}>
          {this.props.form.getFieldDecorator('customer_ids', {
            rules: [
              {
                required: true,
                message: '请选择坐席号',
              },
            ],
          })(
            <Select placeholder={'请选择坐席号'} mode={'multiple'}>
              {this.state.sitList.map(item => (
                <Option key={item.id} value={item.employee_id}>
                  {item.employee_id}
                </Option>
              ))}
            </Select>,
          )}
        </Form.Item>
        <div style={{ color: '#f5222d' }}>
          <div className={'ant-form-item-label'}>温馨提示：</div>
          <div className={'ant-form-item-control-wrapper'}>
            会员已在此任务列表或者会员未绑定手机号都会显示失败
          </div>
        </div>
      </Modal>
    );
  };

  public render() {
    return (
      <React.Fragment>
        {helpers.isJudge(
          this.props.selectedCustomers.length === 0 ||
            (this.props.selectedCustomers.length !== 0 &&
              !_.includes(this.props.all.main.buttonPermission, 126)),
        )(
          null,
          <Button
            type={'primary'}
            icon={'plus'}
            className={'yellowBtn'}
            onClick={() => this.setState({ visible: true })}
            style={{ marginLeft: 10 }}
          >
            添加到群呼任务
          </Button>,
        )}
        {this.renderFrom()}
      </React.Fragment>
    );
  }
}
