import * as React from 'react';
import * as _ from 'lodash';
import { Table, Button, Input, Switch, Modal } from 'antd';
const confirm = Modal.confirm;
import { helpers } from '@/utils';
import {
  ContainerPropsInterface,
  ContainerStateInterface,
} from '@/routes/containerInterface';

import TaskAdd from './taskAdd';
import TaskEdit from './taskEdit';
import { multiPlatform } from '@/multiPlatform';
import { XList } from '@/components';

export default class Tasks extends React.PureComponent<
  ContainerPropsInterface<any>,
  ContainerStateInterface
> {
  // 表格列
  public columns: any = [
    {
      title: '群呼任务',
      align: 'center',
      dataIndex: 'name',
      width: 150,
    },
    {
      title: '呼叫模板',
      align: 'center',
      dataIndex: 'templateid',
      width: 150,
    },
    {
      title: '并发数',
      align: 'center',
      dataIndex: 'concurrent',
      width: 150,
    },
    {
      title: '失败重呼次数',
      align: 'center',
      dataIndex: 'maxretries',
      width: 150,
    },
    {
      title: '任务开启时间',
      align: 'center',
      dataIndex: 'starttime',
      width: 150,
    },
    {
      title: '总外呼数',
      align: 'center',
      dataIndex: 'count',
      width: 150,
    },
    {
      title: '成功数',
      align: 'center',
      dataIndex: 'success',
    },
    {
      title: '失败数',
      align: 'center',
      dataIndex: 'failure',
    },
    {
      title: '呼叫中数',
      align: 'center',
      dataIndex: 'calling',
    },
    {
      title: '任务状态',
      align: 'center',
      dataIndex: 'status',
      render: (text) => {
        return parseInt(text, 10) === 1 ? (
          <p style={{ color: '#218216' }}>运行</p>
        ) : (
          <p>停止</p>
        );
      },
    },
    {
      title: '启用/停用',
      align: 'center',
      width: 100,
      dataIndex: 'status',
      render: (text, record) => {
        return helpers.isJudge(
          _.includes(this.props.all.main.buttonPermission, 125),
        )(
          <p style={{ marginLeft: '10px' }}>
            <Switch
              checked={parseInt(text, 10) === 1}
              onChange={() => this.setLocked(record.id, record.status)}
            />
          </p>,
          null,
        );
      },
    },
    {
      title: '操作',
      align: 'center',
      width: 100,
      dataIndex: 'edit',
      render: (text, record) => {
        const TaskEditComponent = this.TaskEditComponent;
        return helpers.isJudge(
          _.includes(this.props.all.main.buttonPermission, 124),
        )(<TaskEditComponent data={record} />, null);
      },
    },
  ];

  // 列表源数据
  public sourceList = [];
  // 页面大小存储使用的key
  public STORE_PAGE_SIZE_KEY = 'pageSize';

  constructor(props) {
    super(props);
    this.state = {
      list: [],
      filterMode: false,
      filterText: '', // 过滤
      tempList: [], // 模板下拉
      page: {
        size: helpers.isJudge(
          localStorage.getItem(this.STORE_PAGE_SIZE_KEY) === null,
        )(40, localStorage.getItem(this.STORE_PAGE_SIZE_KEY)), // 用户列表的每页大小
        num: 1, // 用户列表的当前页数
      },
      total: 0,
    };
  }

  public TaskAddComponent = multiPlatform.taskAddMulti(TaskAdd); // 用户添加
  public TaskEditComponent = multiPlatform.taskEditMulti(TaskEdit); // 用户编辑
  // 启动/停用
  public setLocked(id, status) {
    // 将数据进行格式化，原因是Web下发的是string类型
    status = parseInt(status, 10);
    // 如果为0，则说明当前处于停止状态
    const isStop = status !== 0;
    const text = helpers.isJudge(status === 0)('启用', '禁用');
    const self = this;
    confirm({
      title: `您确定要${text}该账号吗？`,
      onOk() {
        self.props.dispatch({
          type: 'system/multipCallEnable',
          payload: {
            task_id: id,
            isStop,
          },
        });
      },
    });
  }

  /**
   * 过滤数据
   *
   * @memberof Tasks
   */
  public filterMethod = () => {
    if (this.state.filterText.trim()) {
      this.setState(
        { filterMode: true, page: { ...this.state.page, num: 1 } },
        this.setShowListData,
      );
    } else {
      this.resetFilterMethod();
    }
  };

  /**
   * 重置过滤器
   *
   * @memberof Tasks
   */
  public resetFilterMethod = () => {
    this.setState(
      {
        filterText: '',
        filterMode: false,
        page: { ...this.state.page, num: 1 },
      },
      this.setShowListData,
    );
  };

  public data: any = [];

  // 筛选
  public renderFilter = () => {
    const TaskAddComponent = this.TaskAddComponent;
    return (
      <div>
        <p>
          任务名称 ：&nbsp;&nbsp;
          <Input
            value={this.state.filterText}
            type={'text'}
            placeholder={'请输入名称'}
            onChange={(e) => this.setState({ filterText: e.target.value })}
          />
          <Button type={'primary'} onClick={this.filterMethod}>
            筛选
          </Button>
          <Button onClick={this.resetFilterMethod}>重置</Button>
          {/* 新增 */}
          {helpers.isJudge(
            _.includes(this.props.all.main.buttonPermission, 123),
          )(
            <TaskAddComponent data={this.props.all.callCenter.tempList} />,
            null,
          )}
        </p>
      </div>
    );
  };

  // 获取列表数据
  public getData = () => {
    this.props.dispatch({
      type: 'callCenter/taskListEpic',
      payload: {
        page_size: 1000,
      },
    });
  };

  // 获取模板列表
  public getTempData = () => {
    this.props.dispatch({
      type: 'callCenter/getTempEpic',
    });
  };

  public componentWillMount() {
    // 获取列表
    if (this.state.list.length === 0) {
      this.getData();
    }
    // 获取模板
    if (this.state.tempList.length === 0) {
      this.getTempData();
    }
  }

  public componentWillReceiveProps(nextProps) {
    if (
      nextProps.all.callCenter.taskList &&
      nextProps.all.callCenter.taskList.length !== 0 &&
      !_.isEqual(nextProps.all.callCenter.taskList, this.data)
    ) {
      this.data = nextProps.all.callCenter.taskList;
      this.sourceList = nextProps.all.callCenter.taskList;
      this.setShowListData();
    }
  }

  /**
   * 设置显示的列表数据
   *
   * @memberof TelDetail
   */
  public setShowListData() {
    // 取得页码与每个数量
    let { size, num } = this.state.page;
    // 对数据进行转类型，防止使用string进行处理
    size = parseInt(size, 10);
    num = parseInt(num, 10);
    // 计算范围
    const start = size * (num - 1);
    const end = start + size;
    // 设定显示的范围
    const list = this.state.filterMode
      ? this.sourceList.filter((item: any) => {
          return item.name === this.state.filterText;
        })
      : this.sourceList;
    this.setState({ list: list.slice(start, end), total: list.length });
  }

  // 改变页号
  public changePage = (page, size) => {
    this.setState({ page: { num: page, size } }, this.setShowListData);
  };

  // 改变每页大小
  public changePageSize = (page, size) => {
    localStorage.setItem(this.STORE_PAGE_SIZE_KEY, size);
    this.setState({ page: { num: 1, size } }, this.setShowListData);
  };

  public render() {
    return (
      <React.Fragment>
        {this.renderFilter()}
        <XList
          columns={this.columns}
          dataSource={this.state.list}
          rowKey={'id'}
          isShowNum={true}
          autoCalculation={true}
          pagination={{
            current: this.state.page.num,
            pageSize: this.state.page.size,
            total: this.state.total,
            showSizeChanger: true,
            showQuickJumper: true,
            onChange: this.changePage,
            onShowSizeChange: this.changePageSize,
            pageSizeOptions: ['10', '20', '50', '100', '200', '300', '500'],
          }}
        />
      </React.Fragment>
    );
  }
}
