import { combineReducers } from 'redux';
import main from '@/reducers/mainReducer';
import tags from '@/reducers/tagsReducer';
import roles from '@/reducers/rolesReducer';
import user from '@/reducers/userReducer';
import marketingAccounts from '@/reducers/marketingAccountsReducer';
import customers from '@/reducers/customersReducer';
import tasks from '@/reducers/tasksReducer';
import assignmentsDetailed from '@/reducers/assignmentsDetailedReducer';
import calls from '@/reducers/callsReducer';
import sms from '@/reducers/smsReducer';
import rolesSet from '@/reducers/rolesSetReducer';
import callCenter from '@/reducers/callCenterReducer';
import agent from '@/reducers/agentReducer';
import marketStatus from '@/reducers/marketStatusReducer';
import callNote from '@/reducers/callNoteReducer';
import externalCustomers from '@/reducers/externalCustomersReducer';
import telStatistics from '@/reducers/telReducer';

const rootReducer = combineReducers({
  main,
  tags,
  roles,
  user,
  marketingAccounts,
  customers,
  tasks,
  assignmentsDetailed,
  calls,
  sms,
  rolesSet,
  callCenter,
  agent,
  marketStatus,
  callNote,
  externalCustomers,
  telStatistics
});
export default rootReducer;
