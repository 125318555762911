import * as React from 'react';
import { Modal, Input, Form, Select, DatePicker } from 'antd';
const { Option } = Select;
import moment from 'moment';

import {
  ContainerPropsInterface,
  ContainerStateInterface,
} from '@/routes/containerInterface';

export default class TaskEdit extends React.PureComponent<
  ContainerPropsInterface<any>,
  ContainerStateInterface
> {
  constructor(props) {
    super(props);
    this.state = {
      visible: false, // 是否显示标签添加窗口
    };
    // this.props.form.setFieldsValue({
    //   taskName: '', // 任务名称
    //   taskTpl: '', // 任务模板
    //   maxCount: '', // 并发数
    //   retryCount: '', // 失败重试次数
    //   retryData: '', // 重复数据
    //   startTime: '', // 启动时间
    //   taskId: 0
    // });
  }

  // 添加
  public handleEdit = () => {
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      const {
        taskName,
        taskTpl,
        maxCount,
        retryCount,
        retryData,
        startTime,
      } = values;

      this.props.dispatch({
        type: 'callCenter/editTaskEpic',
        payload: {
          taskName,
          taskTpl,
          concurrent: maxCount,
          maxtries: retryCount,
          duplicate: retryData,
          start_time: startTime.format('YYYY-MM-DD HH:mm:ss'),
          task_id: this.state.taskId,
          cb: this.getData,
        },
      });
      // 隐藏标签添加窗口
      this.setState({
        visible: false,
      });
    });
  };

  // 获取列表数据
  public getData = () => {
    this.props.dispatch({
      type: 'callCenter/taskListEpic',
      payload: {
        page_size: 1000,
      },
    });
  };

  public componentDidMount = () => {
    const {
      name,
      templateid,
      concurrent,
      maxretries,
      duplicate,
      starttime,
      id,
    } = this.props.data;
    this.setState({
      taskName: name,
      taskTpl: templateid,
      maxCount: concurrent,
      retryCount: maxretries,
      retryData: duplicate,
      startTime: starttime,
      taskId: Number(id),
    });
  };

  // 取消隐藏标签添加窗口
  public handleCancel = () => {
    this.setState({ visible: false });
  };

  // 正整数校验
  public isPositiveNum = (rule, value, callback) => {
    const isPositive = /^\d+$/.test(value);
    if (!isPositive) {
      callback('请输入正整数');
    } else {
      callback();
    }
  };
  // 渲染表单块
  public renderFrom = () => {
    return (
      <Modal
        title={'编辑'}
        visible={this.state.visible}
        onOk={this.handleEdit}
        onCancel={this.handleCancel}
        maskClosable={false}
        width={600}
        className={'modalFrom'}
      >
        <Form.Item label={'群呼任务：'}>
          {this.props.form.getFieldDecorator('taskName', {
            initialValue: this.state.taskName,
            rules: [
              {
                required: true,
                message: '请输入群呼任务名',
              },
            ],
          })(<Input disabled={true} maxLength={32} />)}
        </Form.Item>
        <Form.Item label={'外呼模板'}>
          {this.props.form.getFieldDecorator('taskTpl', {
            initialValue: this.state.taskTpl,
            rules: [
              {
                required: true,
                message: '请选择外呼模板',
              },
              {
                validator: this.isPositiveNum,
              },
            ],
          })(
            <Select disabled={true}>
              <Option value={'1'}>模板1</Option>
              <Option value={'2'}>模板2</Option>
            </Select>,
          )}
        </Form.Item>
        <Form.Item label={'并发数：'}>
          {this.props.form.getFieldDecorator('maxCount', {
            initialValue: this.state.maxCount,
            rules: [
              {
                required: true,
                message: '',
              },
              {
                validator: this.isPositiveNum,
              },
            ],
          })(
            <Input
              type={'number'}
              placeholder={'请输入并发数'}
              maxLength={32}
            />,
          )}
        </Form.Item>
        <Form.Item label={'失败重复次数:'}>
          {this.props.form.getFieldDecorator('retryCount', {
            initialValue: this.state.retryCount,
            rules: [
              {
                required: true,
                message: '',
              },
              {
                validator: this.isPositiveNum,
              },
            ],
          })(
            <Input
              type={'number'}
              placeholder={'请输入群呼任务名称'}
              maxLength={32}
            />,
          )}
        </Form.Item>
        <Form.Item label={'重复数据：'}>
          {this.props.form.getFieldDecorator('retryData', {
            initialValue: this.state.retryData,
            rules: [
              {
                required: true,
                message: '',
              },
              {
                validator: this.isPositiveNum,
              },
            ],
          })(
            <Input
              type={'number'}
              placeholder={'请输入重复数据'}
              maxLength={32}
            />,
          )}
        </Form.Item>
        <Form.Item label={'启动时间：'}>
          {this.props.form.getFieldDecorator('startTime', {
            initialValue: moment(this.state.startTime, 'YYYY-MM-DD hh:mm:ss'),
            rules: [
              {
                required: true,
                message: '请选择启动时间',
              },
            ],
          })(
            <DatePicker
              disabled={true}
              showTime={{ format: 'HH:mm:ss' }}
              format={'YYYY-MM-DD HH:mm:ss'}
            />,
          )}
        </Form.Item>
      </Modal>
    );
  };

  public render() {
    return (
      <React.Fragment>
        <a
          href={'javascript:;'}
          style={{ cursor: 'pointer' }}
          onClick={() => this.setState({ visible: true })}
        >
          编辑
        </a>
        {this.renderFrom()}
      </React.Fragment>
    );
  }
}
