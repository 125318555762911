import { request, servicPath } from '../utils';
import {
  createFormDataPostRequest,
  createFormDataPutRequest,
  createGetRequestByQuery,
  createDelRequestByQuery
} from './requestTransfer';

/**
 * 获取角色列表
 */
export function handleGetRoles(params) {
  return request(
    createGetRequestByQuery(`${servicPath}/products/roles`, params)
  );
}

/**
 * 添加角色
 */
export function handleAddRoles(params) {
  return request(
    createFormDataPostRequest(`${servicPath}/products/roles`, params)
  );
}

/**
 * 删除角色
 */
export function handleDeleteRoles(params) {
  return request(
    createDelRequestByQuery(`${servicPath}/products/roles/${params.id}`)
  );
}

/**
 * 更新角色
 */
export function handleUpdateRoles(params) {
  return request(
    createFormDataPutRequest(
      `${servicPath}/products/roles/${params.id}`,
      params
    )
  );
}
