// 通过状态码获取状态名
export function getSateName(extensionState, agentSate) {
  switch (Number(extensionState)) {
    case -1: {
      return '不存在';
    }
    case 0: {
      if (agentSate === -1) {
        return '示闲';
      } else {
        return '示忙';
      }
    }
    case 1: {
      return '通话中';
    }
    case 2: {
      return '忙';
    }
    case 4: {
      return '未注册';
    }
    case 8: {
      return '振铃中';
    }
    case 16: {
      return '保持中';
    }
    case 99: {
      return '通话中';
    }
    default:
      return '未定义状态';
  }
}
