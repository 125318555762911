import { Observable, pipe, of } from 'rxjs';

import { map, mergeMap } from 'rxjs/operators';

import { Epic, ofType } from 'redux-observable';

import { history } from '../router';
import { helpers, ls } from '../utils';
import { userAPI } from '../services';

import _ from 'lodash';

interface Payload {
  [random: string]: any;
}
// dispatch传进来的action类型
interface PayloadIn {
  type: string;
  payload: {
    [random: string]: any;
  };
}

// epic传到reducer里的action类型
interface PayloadOut {
  type: string;
  payload: {
    [random: string]: any;
  };
}

// 登录
export const login: Epic<PayloadIn, PayloadOut> = (action$, store) => {
  return action$.pipe(
    ofType('entry/login'),
    mergeMap((action) => {
      return userAPI.handleLogin(action.payload).pipe(
        map((res) => {
          if (res !== null) {
            if (action.payload.rememberPassword) {
              localStorage.setItem('email', action.payload.email);
              localStorage.setItem('password', action.payload.password);
            } else {
              localStorage.setItem('email', '');
              localStorage.setItem('password', '');
            }
            localStorage.setItem('login_phone_line', action.payload.phone_line);
            sessionStorage.setItem('phone_line', action.payload.phone_line);
            ls.set('t', res.data.token);
            ls.set('mt', res.data.mixcall_ws_token); // 保存米糠云用的token
            history.push('/main');
          }
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};
