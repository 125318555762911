import { Observable, pipe, of } from 'rxjs';

import { map, mergeMap } from 'rxjs/operators';
import { Epic, ofType } from 'redux-observable';
import { helpers } from '@/utils';
import { reportServiceAPI } from '@/services';
import { message } from 'antd';
import _ from 'lodash';

interface Payload {
  [random: string]: any;
}
// dispatch传进来的action类型
interface PayloadIn {
  type: string;
  payload: {
    [random: string]: any;
  };
}

// epic传到reducer里的action类型
interface PayloadOut {
  type: string;
  payload: {
    [random: string]: any;
  };
}

// 获取坐席
export const getAgent: Epic<PayloadIn, PayloadOut> = (action$, store) => {
  return action$.pipe(
    ofType('records/getAgentListEpic'),
    mergeMap(action => {
      return reportServiceAPI.handleAgentList().pipe(
        map(res => {
          // 如果存在回调，则调用回调
          if (
            _.isObject(action.payload) &&
            _.isFunction(action.payload.callback)
          ) {
            action.payload.callback();
          }
          if (res !== null) {
            return helpers.createAction<Payload>('report/getAgentList', {
              list: res.data,
            });
          }
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};

// 监听
export const agentSpy: Epic<PayloadIn, PayloadOut> = (action$, store) => {
  return action$.pipe(
    ofType('records/agentSpyEpic'),
    mergeMap(action => {
      return reportServiceAPI.handleAgentSpy(action.payload).pipe(
        map(res => {
          if (res !== null) {
            // console.log(res);

            // return helpers.createAction<Payload>('report/getAgentList', {
            //   list: res.data,
            // });
          }
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};
