import * as _ from 'lodash';
import { history } from '../router';
import { Action } from '../reducers/reducerInterface';
import { ls } from '../utils';

const initialStore = {
  tags: [], // 标签信息
  tagsUserList: [] // 标签用户列表
};

export type AppStoreType = typeof initialStore;

export default function tagsReducer(state: any = initialStore, action: Action) {
  try {
    switch (action.type) {
      case 'system/tags/getTags': {
        // 获取标签列表
        const tags = action.payload;
        return _.assign({}, state, { tags });
      }
      case 'system/tags/getTagsSingle': {
        // 获取标签详情
        const tag = { id: action.payload.id, users: action.payload.users };
        const tagsUserList = [...state.tagsUserList, tag];
        return _.assign({}, state, { tagsUserList });
      }
      case 'system/tags/addTags': {
        const tag = { ...action.payload, userCount: 0 };
        const tags = [...state.tags, tag];
        return _.assign({}, state, { tags });
      }
      case 'system/tags/deleteTags': {
        const tags = state.tags.filter(obj => obj.id !== action.payload);
        return _.assign({}, state, { tags });
      }
      case 'system/tags/updateTags': {
        let tags = state.tags.filter(obj => obj.id !== action.payload.id);
        tags = [
          { ...action.payload, users_count: action.payload.users.length },
          ...tags
        ].sort((a, b) => a.id - b.id);

        const tag = { id: action.payload.id, users: action.payload.users };
        const tagsUserList = [
          ...state.tagsUserList.filter(n => n.id !== tag.id),
          tag
        ];
        return _.assign({}, state, { tags, tagsUserList });
      }
      default:
        return state;
    }
  } catch (e) {
    return state;
  }
}
