import * as React from 'react';

import { Observable, of } from 'rxjs';

import { ajax } from 'rxjs/ajax';

import { map, catchError } from 'rxjs/operators';

import * as helpers from '@/utils/helpers';

import { history } from '@/router';

import { message, Modal } from 'antd';

import { Events } from '@/utils/eventProxy';

import _ from 'lodash';

function processResponse(res) {
  Events.trigger('toggleLoading', false);
  return res.response;
}

/**
 * @param  {object} options
 * @returns Observable
 */
export default function request(
  options = { method: 'GET', credentials: 'include' } as any
): Observable<any> {
  if (!options.url) {
    alert('请求接口地址不能为空');
  }
  Events.trigger('toggleLoading', true);
  return ajax({
    ...options,
    withCredentials: true,
    crossDomain: true
  }).pipe(
    map(processResponse),
    catchError(err => {
      Events.trigger('toggleLoading', false);
      let errorMessage = '接口报错';
      if (!_.isEmpty(_.get(err.response, 'message'))) {
        errorMessage = err.response.message;
      }
      message.error(errorMessage);
      if (err.status === 401) {
        history.push('/login');
      }
      return of(null);
    })
  );
}
