import { request, servicPath } from '../utils';
import {
  createFormDataPostRequest,
  createGetRequestByQuery,
  createGetRequestByQueryForDownload,
  createFormDataPutRequest,
  createXHRPost,
} from './requestTransfer';

/**
 * 获取客户列表
 */
export function handleGetCustomers(params) {
  return request(
    createGetRequestByQuery(`${servicPath}/products/customers`, params),
  );
}

/**
 * 获取客户详细
 */
export function handleGetCustomersDetails(params) {
  return request(
    createGetRequestByQuery(`${servicPath}/products/customers/${params.id}`),
  );
}

/**
 * 上传客户excel
 */
export function handleUploadCustomersExcel(params) {
  return createXHRPost(params);
}

/**
 * 添加excel中的客户
 */
export function handleAddCustomers(params) {
  return request(
    createFormDataPostRequest(
      `${servicPath}/products/customers/import`,
      params,
    ),
  );
}

/**
 * 修改的客户
 */
export function handleUpdateCustomers(params) {
  return request(
    createFormDataPutRequest(
      `${servicPath}/products/customers/${params.id}`,
      params,
    ),
  );
}

/**
 * 下载模板
 */
export function handleGetTemplateDownload() {
  return request(
    createGetRequestByQueryForDownload(
      `${servicPath}/products/templateDownload`,
    ),
  );
}

/**
 * 下载错误记录
 */
export function handleDownloadFailedRecords(params) {
  return request(
    createGetRequestByQueryForDownload(
      `${servicPath}/products/downloadFailedRecords/${params.id}`,
    ),
  );
}

/**
 * 导入直接创建任务
 */
export function autoCreateTaskByImport(params) {
  return request(
    createFormDataPostRequest(`${servicPath}/products/importAndCreate`, params),
  );
}
