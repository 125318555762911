import * as _ from 'lodash';
import { Action } from '../reducers/reducerInterface';
import moment from 'moment';

const initialStore = {
  customers: [], // 客户列表
  tipsTimes: 0, // 提示次数
  duplicatedCount: 0, // 重复组数
  lastImportMount: 0, // 最后导入数量
  newImportMount: 0, // 最新导入数量
  newImportMountDate: '', // 最新导入数据
  customersDetails: {}, // 客户详情
  createTaskTimes: 0 // 创建任务次数用户反馈提交成功
};

export type AppStoreType = typeof initialStore;

export default function customersReducer(
  state: any = initialStore,
  action: Action
) {
  try {
    switch (action.type) {
      case 'customers/getCustomers': {
        const customers = action.payload.list;
        const lastImportMount = action.payload.lastImportMount;
        return _.assign({}, state, { customers, lastImportMount });
      }
      case 'customers/showTips': {
        return _.assign({}, state, {
          tipsTimes: state.tipsTimes + 1,
          duplicatedCount: action.payload.duplicatedCount,
          newImportMount: action.payload.newImportMount,
          newImportMountDate: moment().format('YYYY/MM/DD')
        });
      }
      case 'customers/getCustomersDetails': {
        const customersDetails = action.payload;
        return _.assign({}, state, { customersDetails });
      }
      case 'customers/createTaskSuccess': {
        return _.assign({}, state, {
          createTaskTimes: state.createTaskTimes + 1
        });
      }
      default:
        return state;
    }
  } catch (e) {
    return state;
  }
}
