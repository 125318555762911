import React from 'react';
import { connect } from 'react-redux';

export default parentPage => {
  return connect(state => ({
    // 此处传入该页面所需的state，也可以重写
    state: state.callCenter,
    all: state,
  }))(
    class Enchance extends parentPage {
      public constructor(props) {
        super(props);
        /*
         state也可重写
         */
        this.state = {
          ...this.state,
        };
      }
      public render() {
        return <React.Fragment>{super.render()}</React.Fragment>;
      }
    },
  );
};
