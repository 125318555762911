import * as React from 'react';
import * as _ from 'lodash';
import moment from 'moment';
import {
  Table,
  Button,
  Input,
  AutoComplete,
  Select,
  DatePicker,
  Pagination,
  message,
  Modal,
} from 'antd';
const confirm = Modal.confirm;
const { RangePicker } = DatePicker;
const Option = Select.Option;
import { helpers } from '@/utils';
import { SendSms } from '@/routes/index';
import {
  ContainerPropsInterface,
  ContainerStateInterface,
} from '@/routes/containerInterface';
import { multiPlatform } from '@/multiPlatform';
export default class SMS extends React.PureComponent<
  ContainerPropsInterface<any>,
  ContainerStateInterface
> {
  // 表格列
  public columns: any = [
    {
      align: 'center',
      title: '序号',
      width: 100,
      dataIndex: 'id',
      render: (text, record, index) => (
        <span>
          {index +
            1 +
            this.state.page.num * this.state.page.size -
            this.state.page.size}
        </span>
      ),
    },
    {
      title: '发信人',
      dataIndex: 'user',
      width: 200,
      render: text => <span>{_.get(text, 'name')}</span>,
    },
    {
      title: '发信时间',
      dataIndex: 'send_time',
      width: 200,
      render: text =>
        helpers.isJudge(text.slice(0, 10) === '0000-00-00')(
          null,
          <p className={'time'}>
            {text.slice(0, 10)}
            <br />
            {text.slice(10)}
          </p>,
        ),
    },
    {
      title: '回执时间',
      dataIndex: 'receipt_time',
      width: 200,
      render: text =>
        helpers.isJudge(text.slice(0, 10) === '0000-00-00')(
          null,
          <p className={'time'}>
            {text.slice(0, 10)}
            <br />
            {text.slice(10)}
          </p>,
        ),
    },
    {
      title: '收信人',
      dataIndex: 'customer_name',
      width: 200,
    },
    {
      title: '当前标签',
      dataIndex: 'tag_id',
      width: 200,
      render: text => (
        <span>
          {this.state.tags.filter(n => n.id === text).map(item => item.name)}
        </span>
      ),
    },
    {
      title: '发信状态',
      dataIndex: 'status',
      width: 200,
      render: status => (
        <p>
          {helpers.isJudge(status === 0)(
            <span>处理中</span>,
            helpers.isJudge(status === 1)(<span>成功</span>, <span>失败</span>),
          )}
        </p>
      ),
    },
    {
      title: '操作',
      dataIndex: 'action',
      width: 150,
      render: (text, record) =>
        helpers.isJudge(
          _.includes(this.props.all.main.buttonPermission, 27) &&
            record.status !== 1,
        )(
          <a
            href={'javascript:;'}
            onClick={() =>
              this.setState({
                showTimes: this.state.showTimes + 1,
                customer_id: record.customer_id,
                record_id: record.id,
                content: record.content,
              })
            }
          >
            重新发送
          </a>,
          null,
        ),
    },
  ];

  constructor(props) {
    super(props);
    this.state = {
      list: [], // 短信列表
      total: 0, // 全部短信的数量
      tags: [], // 标签列表
      userList: [], // 用户的列表
      sendSmsUserList: [], // 短信人的列表
      showTimes: 0, // 显示发短信窗口次数
      page: {
        size: 10, // 用户列表的每页大小
        num: 1, // 用户列表的当前页数
      },
      // 过滤对象组
      filterObj: {
        ...this.filterObj,
      },
      selectedRowKeys: [], // 选中的id数组
    };
  }

  // 用于分页时可以使用上次的筛选操作
  public filterObj: any = {
    status: undefined, // 接通状态
    user_name: '', // 短信人姓名
    customer_name: '', // 收信人名称
    tag_id: undefined, // 标签id
    third_party_id: undefined,
    send_time_start: '', // 创建开始日期
    send_time_end: '', // 创建结束日期
  };

  // 发送邮件组件
  public SendSmsComponent = multiPlatform.sendSmsMulti(SendSms);

  // 用户列表
  public userList: any = [];

  public data: any = []; // 标签列表

  public autoUpdateTimes = 0; // 自动更新次数

  public componentWillMount() {
    this.autoUpdateTimes = this.props.state.autoUpdateTimes; // 初始化自动更新次数

    // 获取标签列表
    if (this.state.tags.length === 0) {
      this.getTags();
    }

    // 获取短信列表
    this.getSMS();

    // 获取或绑定用户列表
    if (this.props.all.user.userList.length === 0) {
      this.getUserList();
    }
  }

  // 获取标签
  public getTags = () => {
    this.props.dispatch({
      type: 'system/tags/getTagsEpic',
      payload: {
        page_size: 1000,
      },
    });
  };

  // 获取发信流水
  public getSMS = () => {
    const obj = Object.assign({}, this.state.filterObj);

    this.props.dispatch({
      type: 'reports/getSMSEpic',
      payload: {
        ...obj,
        page: this.state.page.num,
        page_size: this.state.page.size,
      },
    });
  };

  // 过滤查询
  public filterSelect = () => {
    this.filterObj = this.state.filterObj; // 保存被筛选的条件

    this.setState(
      { page: { num: 1, size: this.state.page.size } },
      this.getSMS,
    );
  };

  // 通过之前的筛选条件获取列表
  public getListBeforeFilter = () => {
    localStorage.setItem('smallPageSize', this.state.page.size);

    this.props.dispatch({
      type: 'reports/getSMSEpic',
      payload: {
        ...this.filterObj,
        page: this.state.page.num,
        page_size: this.state.page.size,
      },
    });
  };

  // 获取用户列表
  public getUserList = () => {
    this.props.dispatch({
      type: 'system/getUserListEpic',
      payload: {
        page_size: 1000,
      },
    });
  };

  // 筛选重置
  public handleFilterReset = () => {
    this.setState({
      sendSmsUserList: this.userList.map(n => n.name), // 短信人列表
      filterObj: {
        status: undefined, // 接通状态
        user_name: '', // 发信息人姓名
        customer_name: '', // 收信人名称
        tag_id: undefined, // 标签id
        send_time_start: '', // 创建开始日期
        send_time_end: '', // 创建结束日期
      },
    });
  };

  // 选择发信日期
  public selectSendSmsDate = dateString => {
    this.setState({
      filterObj: {
        ...this.state.filterObj,
        send_time_start: dateString[0],
        send_time_end: dateString[1],
      },
    });
  };

  public componentWillReceiveProps(nextProps) {
    // 标签列表绑定
    if (nextProps.all.tags.tags !== undefined) {
      if (
        nextProps.all.tags.tags.length !== 0 &&
        !_.isEqual(nextProps.all.tags.tags, this.state.tags)
      ) {
        this.setState({ tags: nextProps.all.tags.tags });
      }
    }

    // 绑定短信列表
    if (!_.isEqual(nextProps.state.sms, this.data)) {
      this.data = nextProps.state.sms;
      this.setState({
        list: nextProps.state.sms,
      });
    }

    if (this.state.total !== nextProps.state.total) {
      this.setState({
        total: nextProps.state.total,
      });
    }

    // 绑定用户列表
    if (
      nextProps.all.user.userList.length !== 0 &&
      this.userList.length === 0
    ) {
      this.userList = nextProps.all.user.userList;
      const nameList = nextProps.all.user.userList.map(n => n.name);
      this.setState({
        sendSmsUserList: nameList,
      });
    }

    // 如果自动更新次数增加则更新列表
    if (this.autoUpdateTimes !== nextProps.state.autoUpdateTimes) {
      this.autoUpdateTimes = nextProps.state.autoUpdateTimes;
      this.getListBeforeFilter();
    }
  }

  // 选择短信人输入筛选
  public handleSendSmsUserSearch = value => {
    this.setState({
      sendSmsUserList: helpers.isJudge(!value)(
        [],
        this.userList
          .filter(item => item.name.indexOf(value) !== -1)
          .map(n => n.name),
      ),
      filterObj: {
        ...this.state.filterObj,
        user_name: value,
      },
    });
  };

  // 筛选标签列表
  public handleFilter(filterText) {
    if (this.data.length !== 0) {
      this.setState({
        filterText,
        tags: this.data.filter(obj => obj.name.indexOf(filterText) !== -1),
      });
    }
  }

  // 批量发短信
  public handleBatchSendSms = () => {
    if (this.state.selectedRowKeys.length === 0) {
      message.warning('请选中需要重新发送的信息');
      return;
    }

    const self = this;
    confirm({
      title: '您确定要批量发送所选信息吗？',
      onOk() {
        self.props.dispatch({
          type: 'reports/batchSendSmsEpic',
          payload: {
            records_id: self.state.selectedRowKeys.toString(),
          },
        });

        self.setState({
          selectedRowKeys: [],
        });
      },
    });
  };

  // 列表模块渲染
  public renderList = () => {
    // 选择框
    const rowSelection = {
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRowKeys });
      },
      getCheckboxProps: record => ({
        // 与需求确认，当状态为成功时，多选框禁用
        disabled: record.customer === null || record.status === 1, // Column configuration not to be checked
        name: record.id,
      }),
    };

    return (
      <React.Fragment>
        <Table
          columns={this.columns}
          dataSource={this.state.list}
          pagination={false}
          rowKey={'id'}
          scroll={{
            x: 1150,
            y: 500,
          }}
          expandedRowRender={record => <p>{_.get(record, 'content')}</p>}
          rowSelection={rowSelection}
        />
        <Pagination
          pageSize={parseInt(this.state.page.size, 10)}
          showQuickJumper
          showSizeChanger
          onShowSizeChange={(page, size) => {
            this.setState({ page: { num: 1, size } }, this.getListBeforeFilter);
          }}
          total={this.state.total}
          onChange={(page, size) => {
            this.setState(
              { page: { num: page, size } },
              this.getListBeforeFilter,
            );
          }}
          current={this.state.page.num}
          style={helpers.isJudge(this.state.total === 0)(
            {
              opacity: 0,
            },
            null,
          )}
        />
        {helpers.isJudge(this.state.total !== 0)(
          <div className={'paginationNumLeft'} style={{ bottom: '50px' }}>
            {helpers.isJudge(
              _.includes(this.props.all.main.buttonPermission, 27),
            )(
              <Button
                className={'pagination-green-btn'}
                onClick={this.handleBatchSendSms}
              >
                批量重新发送
              </Button>,
              null,
            )}
            <span>
              共有 {this.state.total} 条数据{' '}
              {Math.ceil(this.state.total / this.state.page.size)} 页
            </span>
          </div>,
          null,
        )}
      </React.Fragment>
    );
  };

  // 筛选模块渲染
  public renderFilter = () => {
    const dateFormat = 'YYYY/MM/DD';
    return (
      <div>
        <p>
          <span className={'notWrap2'}>
            发信人：
            <AutoComplete
              dataSource={this.state.sendSmsUserList}
              onSearch={this.handleSendSmsUserSearch}
              placeholder={'请输入发信人'}
              value={this.state.filterObj.user_name}
              onSelect={value =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    user_name: value,
                  },
                })
              }
            />
          </span>
          <span className={'notWrap'}>
            收信人：
            <Input
              value={this.state.filterObj.customer_name}
              type={'text'}
              placeholder={'请输入收信人名称'}
              onChange={e =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    customer_name: e.target.value,
                  },
                })
              }
            />
          </span>
          <span className={'notWrap'} style={{ marginBottom: 20 }}>
            当前标签：
            <Select
              allowClear
              placeholder={'不限'}
              onChange={value =>
                this.setState({
                  filterObj: { ...this.state.filterObj, tag_id: value },
                })
              }
              value={this.state.filterObj.tag_id}
            >
              {this.state.tags.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </span>
          <span className={'notWrap'} style={{ marginBottom: 20 }}>
            发信状态：
            <Select
              allowClear
              placeholder={'不限'}
              onChange={value =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    status: value,
                  },
                })
              }
              value={this.state.filterObj.status}
            >
              <Option key={'1'} value={1}>
                {'成功'}
              </Option>
              <Option key={'2'} value={2}>
                {'失败'}
              </Option>
            </Select>
          </span>
          <span className={'notWrap'}>
            发信时间：
            <RangePicker
              onChange={(date, dateString) =>
                this.selectSendSmsDate(dateString)
              }
              value={helpers.isJudge(
                this.state.filterObj.send_time_start === '',
              )(null, [
                moment(this.state.filterObj.send_time_start, dateFormat),
                moment(this.state.filterObj.send_time_end, dateFormat),
              ])}
            />
            <Button type={'primary'} onClick={this.filterSelect}>
              筛选
            </Button>
            <Button onClick={this.handleFilterReset}>重置</Button>
          </span>
        </p>
      </div>
    );
  };

  public render() {
    const SendSmsComponent = this.SendSmsComponent;

    return (
      <React.Fragment>
        {this.renderFilter()}
        {this.renderList()}
        <SendSmsComponent
          customer_id={this.state.customer_id}
          record_id={this.state.record_id}
          showTimes={this.state.showTimes}
          content={this.state.content}
          type={2}
        />
      </React.Fragment>
    );
  }
}
