import * as React from 'react';
import * as _ from 'lodash';
import { helpers } from '@/utils';
import { Modal, Input, Form, DatePicker, Select } from 'antd';
const Option = Select.Option;
const { TextArea } = Input;

interface TextProps {
  editData: any;
  [random: string]: any;
}
interface TextState {
  [random: string]: any;
}

export default class EditRemarkEdit extends React.PureComponent<
  TextProps,
  TextState
> {
  constructor(props) {
    super(props);
    this.state = {
      visible: false, // 是否显示编辑窗口
      marketStatus: [] // 行销状态列表
    };
  }

  // 传进来需要修改的原始数据
  public editData: any = {};

  // 显示窗口次数
  public showEditTimes = 0;

  // 下次回访时间
  public nextDate;

  // 修改
  public handleUpdate = () => {
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }

      this.props.dispatch({
        type: 'reports/updateCallNoteEpic',
        payload: {
          call_note_id: this.editData.id,
          note: values.note,
          next_date: this.nextDate,
          market_status_id: values.market_status_id
        }
      });

      // 隐藏编辑窗口
      this.setState({ visible: false });
    });
  };

  public componentWillReceiveProps(nextProps) {
    // 绑定要编辑的标签信息
    if (this.showEditTimes !== nextProps.showEditTimes) {
      this.showEditTimes = nextProps.showEditTimes;

      this.editData = nextProps.editData; // 保存要编辑的原始信息

      this.nextDate = helpers.isJudge(
        nextProps.editData.next_date === '' ||
          nextProps.editData.next_date === '0000-00-00'
      )(undefined, nextProps.editData.next_date);

      this.setState({
        visible: true
      }); // 显示编辑窗口，绑定是否为系统默认标签

      this.props.form.setFieldsValue({
        note: nextProps.editData.note, // 内容
        market_status_id: nextProps.editData.market_status_id.toString() // 行销状态
      });
    }

    // 绑定营销状态
    if (
      nextProps.all.marketStatus.marketStatus.length !== 0 &&
      this.state.marketStatus.length === 0
    ) {
      this.setState({ marketStatus: nextProps.all.marketStatus.marketStatus });
    }
  }

  // 选择日期
  public selectDate = dateString => {
    this.nextDate = dateString;
  };

  public render() {
    return (
      <React.Fragment>
        <Modal
          title={'编辑'}
          visible={this.state.visible}
          onOk={this.handleUpdate}
          onCancel={() => this.setState({ visible: false })}
          width={600}
          className={'modalFrom'}
        >
          <Form.Item
            label={'回访备注内容：'}
            style={helpers.isJudge(this.state.isSystem)(
              { display: 'none' },
              {}
            )}
          >
            {this.props.form.getFieldDecorator('note', {
              rules: [
                {
                  required: true,
                  message: '请输入回访备注内容'
                }
              ]
            })(<TextArea rows={4} placeholder={'请输入回访备注内容'} />)}
          </Form.Item>
          <div>
            <div className={'ant-form-item-label'}>下次跟进时间：</div>
            <div style={{ paddingTop: 5 }}>
              <DatePicker
                onChange={(date, dateString) => this.selectDate(dateString)}
              />
            </div>
          </div>
          <Form.Item label={'行销结果：'}>
            {this.props.form.getFieldDecorator('market_status_id', {
              rules: [
                {
                  required: true,
                  message: '请输选择下次跟进时间'
                }
              ]
            })(
              <Select placeholder={'请选择'}>
                {this.state.marketStatus.map(item => {
                  return <Option key={item.id}>{item.name}</Option>;
                })}
              </Select>
            )}
          </Form.Item>
        </Modal>
      </React.Fragment>
    );
  }
}
