import * as _ from 'lodash';
import { Action } from '../reducers/reducerInterface';

const initialStore = {
  sms: [], // 短信列表
  total: 0, // 短信记录总数
  autoUpdateTimes: 0 // 更新次数
};

export type AppStoreType = typeof initialStore;

export default function smsReducer(state: any = initialStore, action: Action) {
  try {
    switch (action.type) {
      case 'reports/getSMS': {
        const sms = action.payload.list;
        const total = action.payload.total;
        return _.assign({}, state, { sms, total });
      }
      case 'reports/sms/autoUpdateList': {
        return _.assign({}, state, {
          autoUpdateTimes: state.autoUpdateTimes + 1
        });
      }
      default:
        return state;
    }
  } catch (e) {
    return state;
  }
}
