/**
 * 根据传入的数组出标题
 */
import * as React from 'react';
import titleStyle from './title.scss';
import { history } from '@/router';
import { helpers } from '@/utils';

interface ComponentProps {
  [random: string]: any;
}

interface ComponentState {
  [random: string]: any;
}

export default class TitleBlock extends React.PureComponent<
  ComponentProps,
  ComponentState
> {
  public pathname = location.pathname; // 当前路径

  constructor(props) {
    super(props);
    this.state = {
      title1: '', // 父标题
      title2: '', // 子标题
      title3: '' // 三级标题
    };
  }

  public componentWillMount() {
    this.setTitle();
  }

  public menuLevel = 0; // 菜单等级

  // 设置标题
  public setTitle = () => {
    let title1 = '';
    let title2 = '';
    let title3 = '';

    for (const item of this.props.menuList) {
      if (location.pathname.indexOf(item.url) === 0) {
        title1 = item.title;
        for (const n of item.child) {
          if (location.pathname.indexOf(n.url) > 0) {
            title2 = n.title;
            if (
              n.child !== undefined &&
              (location.pathname.split('/').length === 6 ||
                location.pathname.split('/').length === 5)
            ) {
              // 保存菜单等级
              this.menuLevel = location.pathname.split('/').length;

              for (const n2 of n.child) {
                title3 = n2.title;
              }
            }
          }
        }
      }
    }

    this.setState({ title1, title2, title3 });
  };

  public componentWillReceiveProps(nextProps) {
    // 路径变化时设置标题
    if (nextProps.pathname !== this.pathname) {
      this.pathname = nextProps.pathname;
      this.setTitle();
    }
  }

  public render() {
    return (
      <React.Fragment>
        {helpers.isJudge(this.state.title1 === '')(
          null,
          <div className={titleStyle.title}>
            <h3>{this.state.title2}</h3>
            <p>
              <span>{this.state.title1}</span> <span>/</span>{' '}
              {helpers.isJudge(this.state.title3 === '')(
                this.state.title2,
                <React.Fragment>
                  {' '}
                  <span
                    onClick={() => {
                      history.push(
                        location.pathname.slice(
                          0,
                          helpers.isJudge(this.menuLevel === 5)(
                            location.pathname.lastIndexOf('/'),
                            location.pathname
                              .slice(0, location.pathname.lastIndexOf('/'))
                              .lastIndexOf('/')
                          )
                        )
                      );
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {this.state.title2}
                  </span>{' '}
                  <span>/</span> {this.state.title3}
                </React.Fragment>
              )}
            </p>
          </div>
        )}
      </React.Fragment>
    );
  }
}
