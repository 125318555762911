// 改变分机坐席状态
export const changeExtensionState = extensionState => ({
  type: 'polylink/setExtensionState',
  payload: { extensionState: parseInt(extensionState, 10) }
});

// 改变坐席状态
export const changeAgentState = agentState => ({
  type: 'polylink/setExtensionState',
  payload: { agentState: parseInt(agentState, 10) }
});
