import * as React from 'react';
import * as _ from 'lodash';
import moment from 'moment';
import {
  Table,
  Button,
  Input,
  AutoComplete,
  Select,
  DatePicker,
  Pagination,
} from 'antd';
const { RangePicker } = DatePicker;
const Option = Select.Option;
import { helpers } from '@/utils';
import {
  ContainerPropsInterface,
  ContainerStateInterface,
} from '@/routes/containerInterface';
import { XList } from '@/components';

export default class Calls extends React.PureComponent<
  ContainerPropsInterface<any>,
  ContainerStateInterface
> {
  //  通话类型
  public callsType = ['呼出', '三方通话', '通话转接'];

  // 表格列
  public columns: any = [
    {
      title: '通话类型',
      dataIndex: 'call_type',
      align: 'center',
      width: 100,
    },
    {
      title: '通话时长',
      dataIndex: 'duration',
      align: 'center',
      width: 120,
    },
    {
      title: '呼叫时间',
      dataIndex: 'call_start_time',
      align: 'center',
      width: 120,
      render: text =>
        helpers.isJudge(text.slice(0, 10) === '0000-00-00')(
          null,
          <time>
            {text.slice(0, 10)}
            <br />
            {text.slice(10)}
          </time>,
        ),
    },
    {
      title: '主叫人',
      dataIndex: 'user',
      align: 'center',
      width: 120,
      render: text => <span>{_.get(text, 'name')}</span>,
    },
    {
      title: '被叫人',
      dataIndex: 'customer_name',
      align: 'center',
      width: 150,
    },
    {
      title: '任务名称',
      dataIndex: 'task_name',
      align: 'center',
      width: 250,
    },
    {
      title: '标签',
      dataIndex: 'tag_id',
      align: 'center',
      width: 150,
      render: text => (
        <span>
          {helpers.isJudge(text === 29)(
            '存提受阻',
            this.state.tags.filter(n => n.id === text).map(item => item.name),
          )}
        </span>
      ),
    },
    {
      title: '转接人',
      dataIndex: 'transferred_name',
      align: 'center',
      width: 200,
    },
    {
      title: '接通状态',
      dataIndex: 'connection_status',
      align: 'center',
      width: 150,
      render: connection_status => (
        <p>
          {helpers.isJudge(connection_status === 1)(
            <span>已接</span>,
            <span style={{ color: '#ff0000' }}>未接</span>,
          )}
        </p>
      ),
    },
  ];

  // 页面大小存储使用的key
  public STORE_PAGE_SIZE_KEY = 'smallPageSize';

  constructor(props) {
    super(props);
    this.state = {
      list: [], // 呼叫列表
      total: 0, // 全部呼叫的数量
      tags: [], // 标签列表
      userList: [], // 用户的列表
      callNameList: [], // 呼叫人的列表
      transferredNameList: [], // 呼叫人的列表
      page: {
        size: helpers.isJudge(
          localStorage.getItem(this.STORE_PAGE_SIZE_KEY) === null,
        )(10, localStorage.getItem(this.STORE_PAGE_SIZE_KEY)), // 用户列表的每页大小
        num: 1, // 用户列表的当前页数
      },
      // 过滤对象组
      filterObj: {
        ...this.filterObj,
      },
    };
  }

  // 用于分页时可以使用上次的筛选操作
  public filterObj: any = {
    call_type: '', // 呼叫类型
    connection_status: undefined, // 接通状态
    user_id: '', // 呼叫人姓名
    customer_name: '', // 被叫人名称
    tag_id: undefined, // 标签id
    transferred_name: '', // 转接人名
    call_start_time_start: '', // 创建开始日期
    call_start_time_end: '', // 创建结束日期
  };

  // 用户列表
  public userList: any = [];

  public data: any = []; // 标签列表

  public componentWillMount() {
    // 获取标签列表
    if (this.state.tags.length === 0) {
      this.getTags();
    }

    // 获取通话列表
    this.getCalls();

    // 获取或绑定用户列表
    if (this.props.all.user.userList.length === 0) {
      this.getUserList();
    }
  }

  // 获取标签
  public getTags = () => {
    this.props.dispatch({
      type: 'system/tags/getTagsEpic',
      payload: {
        page_size: 1000,
      },
    });
  };

  // 获取通话流水
  public getCalls = () => {
    this.props.dispatch({
      type: 'reports/getCallsEpic',
      payload: {
        ...this.state.filterObj,
        page: this.state.page.num,
        page_size: this.state.page.size,
      },
    });
  };

  // 过滤查询
  public filterSelect = () => {
    this.filterObj = this.state.filterObj; // 保存被筛选的条件

    this.setState(
      { page: { num: 1, size: this.state.page.size } },
      this.getCalls,
    );
  };

  // 通过之前的筛选条件获取列表
  public getListBeforeFilter = () => {
    localStorage.setItem(this.STORE_PAGE_SIZE_KEY, this.state.page.size);

    this.props.dispatch({
      type: 'reports/getCallsEpic',
      payload: {
        ...this.filterObj,
        page: this.state.page.num,
        page_size: this.state.page.size,
      },
    });
  };

  // 获取用户列表
  public getUserList = () => {
    this.props.dispatch({
      type: 'system/getUserListEpic',
      payload: {
        page_size: 1000,
      },
    });
  };

  // 筛选重置
  public handleFilterReset = () => {
    this.setState({
      transferredNameList: this.userList.map(n => n.name), // 转接人列表
      callNameList: this.userList.map(n => n.name), // 呼叫人列表
      filterObj: {
        call_type: '', // 呼叫类型
        connection_status: undefined, // 接通状态
        user_name: '', // 呼叫人姓名
        customer_name: '', // 被叫人名称
        tag_id: undefined, // 标签id
        transferred_name: '', // 转接人名
        call_start_time_start: '', // 呼叫开始日期
        call_start_time_end: '', // 呼叫结束日期
        task_name: '', // 任务名
      },
    });
  };

  // 选择通话日期
  public selectCreateDate = dateString => {
    this.setState({
      filterObj: {
        ...this.state.filterObj,
        call_start_time_start: dateString[0],
        call_start_time_end: dateString[1],
      },
    });
  };

  public componentWillReceiveProps(nextProps) {
    // 标签列表绑定
    if (nextProps.all.tags.tags !== undefined) {
      if (
        nextProps.all.tags.tags.length !== 0 &&
        !_.isEqual(nextProps.all.tags.tags, this.state.tags)
      ) {
        this.setState({ tags: nextProps.all.tags.tags });
      }
    }

    // 绑定通话列表
    if (!_.isEqual(nextProps.state.calls, this.data)) {
      this.data = nextProps.state.calls;
      this.setState({
        list: nextProps.state.calls,
      });
    }

    if (this.state.total !== nextProps.state.total) {
      this.setState({
        total: nextProps.state.total,
      });
    }

    // 绑定用户列表
    if (
      nextProps.all.user.userList.length !== 0 &&
      this.userList.length === 0
    ) {
      this.userList = nextProps.all.user.userList;
      const nameList = nextProps.all.user.userList.map(n => n.name);
      this.setState({
        callNameList: nameList,
        transferredNameList: nameList,
      });
    }
  }

  // 选择呼叫人输入筛选
  public handleCallNameSearch = value => {
    this.setState({
      callNameList: helpers.isJudge(!value)(
        [],
        this.userList
          .filter(item => item.name.indexOf(value) !== -1)
          .map(n => n.name),
      ),
      filterObj: {
        ...this.state.filterObj,
        user_name: value,
      },
    });
  };

  // 选择转接人输入筛选
  public handleTransferredNameSearch = value => {
    this.setState({
      transferredNameList: helpers.isJudge(!value)(
        [],
        this.userList
          .filter(item => item.name.indexOf(value) !== -1)
          .map(n => n.name),
      ),
      filterObj: {
        ...this.state.filterObj,
        transferred_name: value,
      },
    });
  };

  // 筛选标签列表
  public handleFilter(filterText) {
    if (this.data.length !== 0) {
      this.setState({
        filterText,
        tags: this.data.filter(obj => obj.name.indexOf(filterText) !== -1),
      });
    }
  }

  // 改变页号
  public changePage = (page, size) => {
    this.setState({ page: { num: page, size } }, this.getListBeforeFilter);
  };

  // 改变每页大小
  public changePageSize = (page, size) => {
    this.setState({ page: { num: 1, size } }, this.getListBeforeFilter);
  };

  // 列表模块渲染
  public renderList = () => {
    return (
      <React.Fragment>
        <XList
          columns={this.columns}
          dataSource={this.state.list}
          isShowNum={true}
          autoCalculation={true}
          pagination={{
            current: this.state.page.num,
            pageSize: this.state.page.size,
            total: this.state.total,
            showSizeChanger: true,
            showQuickJumper: true,
            onChange: this.changePage,
            onShowSizeChange: this.changePageSize,
            pageSizeOptions: ['10', '20', '30', '40'],
          }}
        />
      </React.Fragment>
    );
  };

  // 筛选模块渲染
  public renderFilter = () => {
    const dateFormat = 'YYYY/MM/DD';
    return (
      <div>
        <p>
          <span className={'notWrap'} style={{ paddingRight: 40 }}>
            通话类型：
            <Button
              type={helpers.isJudge(this.state.filterObj.call_type === '')(
                'primary',
                null,
              )}
              onClick={() =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    call_type: '',
                  },
                })
              }
            >
              不限
            </Button>
            {this.callsType.map(item => (
              <Button
                type={helpers.isJudge(this.state.filterObj.call_type === item)(
                  'primary',
                  null,
                )}
                onClick={() =>
                  this.setState({
                    filterObj: {
                      ...this.state.filterObj,
                      call_type: item,
                    },
                  })
                }
              >
                {item}
              </Button>
            ))}
          </span>
          <span className={'notWrap'}>
            接通状态：
            <Select
              allowClear
              placeholder={'不限'}
              onChange={value =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    connection_status: value,
                  },
                })
              }
              value={this.state.filterObj.connection_status}
            >
              <Option key={'1'} value={1}>
                {'已接'}
              </Option>
              <Option key={'0'} value={0}>
                {'未接'}
              </Option>
            </Select>
          </span>
          <span className={'notWrap2'}>
            主叫人：
            <AutoComplete
              dataSource={this.state.callNameList}
              onSearch={this.handleCallNameSearch}
              placeholder={'请输入主叫人'}
              value={this.state.filterObj.user_name}
              onSelect={value =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    user_name: value,
                  },
                })
              }
            />
          </span>
          <span className={'notWrap2'}>
            转接听人：
            <AutoComplete
              dataSource={this.state.transferredNameList}
              onSearch={this.handleTransferredNameSearch}
              placeholder={'请输入转接听人'}
              value={this.state.filterObj.transferred_name}
              onSelect={value =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    transferred_name: value,
                  },
                })
              }
            />
          </span>
          <span className={'notWrap'}>
            呼叫时间：
            <RangePicker
              onChange={(date, dateString) => this.selectCreateDate(dateString)}
              value={helpers.isJudge(
                this.state.filterObj.call_start_time_start === '',
              )(null, [
                moment(this.state.filterObj.call_start_time_start, dateFormat),
                moment(this.state.filterObj.call_start_time_end, dateFormat),
              ])}
            />
          </span>
          <span className={'notWrap'}>
            被叫人：
            <Input
              value={this.state.filterObj.customer_name}
              type={'text'}
              placeholder={'请输入被叫人名称'}
              onChange={e =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    customer_name: e.target.value,
                  },
                })
              }
            />
          </span>
          <span className={'notWrap'}>
            任务名称：
            <Input
              value={this.state.filterObj.task_name}
              type={'text'}
              placeholder={'请输入任务名称'}
              onChange={e =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    task_name: e.target.value,
                  },
                })
              }
            />
          </span>
          <span className={'notWrap'}>
            当前标签：
            <Select
              allowClear
              placeholder={'不限'}
              onChange={value =>
                this.setState({
                  filterObj: { ...this.state.filterObj, tag_id: value },
                })
              }
              value={this.state.filterObj.tag_id}
            >
              {this.state.tags.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
            <Button type={'primary'} onClick={this.filterSelect}>
              筛选
            </Button>
            <Button onClick={this.handleFilterReset}>重置</Button>
          </span>
        </p>
      </div>
    );
  };

  public render() {
    return (
      <React.Fragment>
        {this.renderFilter()}
        {this.renderList()}
      </React.Fragment>
    );
  }
}
