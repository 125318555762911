import * as React from 'react';
import * as _ from 'lodash';
import { Table, Button, Input, Divider, Modal } from 'antd';
const confirm = Modal.confirm;
import { helpers } from '@/utils';
import {
  ContainerPropsInterface,
  ContainerStateInterface
} from '@/routes/containerInterface';

import TagsAdd from './tagsAddPage';
import TagsEdit from './tagsEditPage';
import { multiPlatform } from '@/multiPlatform';
export default class Tags extends React.PureComponent<
  ContainerPropsInterface<any>,
  ContainerStateInterface
> {
  // 表格列
  public columns: any = [
    {
      align: 'center',
      title: '序号',
      width: 100,
      dataIndex: 'id',
      render: (text, record, index) => <span>{index + 1}</span>
    },
    {
      title: '标签组',
      dataIndex: 'name'
    },
    {
      title: '标签说明',
      dataIndex: 'intro',
      width: 200,
      render: text => (
        <div style={{ width: 160, whiteSpace: 'normal' }}>{text}</div>
      )
    },
    {
      title: '标签负责人',
      dataIndex: 'users_count'
    },
    {
      title: '操作',
      dataIndex: 'action',
      width: 150,
      render: (text, record) => (
        <span>
          {helpers.isJudge(this.state.editFlag)(
            <a
              href={'javascript:;'}
              onClick={() =>
                this.openEditWindow({
                  ...record
                })
              }
            >
              编辑
            </a>,
            null
          )}
          {helpers.isJudge(record.is_system === 1)(
            null,
            <Divider type={'vertical'} />
          )}
          {helpers.isJudge(record.is_system === 1)(
            null,
            helpers.isJudge(this.state.delFlag)(
              <a
                href={'javascript:;'}
                onClick={() => this.confirmDelete(record.id)}
              >
                删除
              </a>,
              null
            )
          )}
        </span>
      )
    }
  ];

  constructor(props) {
    super(props);
    this.state = {
      list: [], // 标签列表
      editData: {}, // 编辑的标签信息
      filterText: '', // 过滤标签名
      editTimes: 0, // 编辑次数
      editFlag: false,
      delFlag: false,
      addFlag: false
    };
  }

  public TagsAddComponent = multiPlatform.tagsAddMulti(TagsAdd); // 用户添加
  public TagsEditComponent = multiPlatform.tagsEditMulti(TagsEdit); // 用户编辑

  public data: any = []; // 标签列表

  public componentWillMount() {
    // 获取标签列表
    if (this.state.list.length === 0) {
      this.getTags();
    }
  }

  // 打开编辑窗口
  public openEditWindow(item: any) {
    this.setState({ editData: item, editTimes: this.state.editTimes + 1 });
  }

  // 获取标签
  public getTags = () => {
    this.props.dispatch({
      type: 'system/tags/getTagsEpic',
      payload: {
        page_size: 1000
      }
    });
  };

  public componentWillReceiveProps(nextProps) {
    const buttonPermission = nextProps.all.main.buttonPermission;
    this.setState({
      editFlag: _.includes(buttonPermission, 51),
      delFlag: _.includes(buttonPermission, 52),
      addFlag: _.includes(buttonPermission, 50)
    });

    // 绑定标签列表
    if (
      nextProps.state.tags.length !== 0 &&
      !_.isEqual(nextProps.state.tags, this.data)
    ) {
      this.data = nextProps.state.tags;
      this.setState({ list: nextProps.state.tags });
    }
  }

  // 删除标签
  public confirmDelete(deleteId: number) {
    const self = this;
    confirm({
      title: '您确定要删除该标签吗？',
      onOk() {
        self.props.dispatch({
          type: 'system/tags/deleteTagsEpic',
          payload: {
            id: deleteId
          }
        });
      },
    });
  }

  // 筛选标签列表
  public handleFilter(filterText) {
    if (this.data.length !== 0) {
      this.setState({
        filterText,
        list: this.data.filter(obj => obj.name.indexOf(filterText) !== -1)
      });
    }
  }

  // 筛选模块渲染
  public renderFilter = () => {
    const TagsAddComponent = this.TagsAddComponent;

    return (
      <div>
        <p>
          标签名称：
          <Input
            value={this.state.filterText}
            type={'text'}
            placeholder={'请输入名称'}
            onChange={e => this.setState({ filterText: e.target.value })}
          />
          <Button
            type={'primary'}
            onClick={() => this.handleFilter(this.state.filterText)}
          >
            筛选
          </Button>
          <Button onClick={() => this.setState({ filterText: '' })}>
            重置
          </Button>
          {helpers.isJudge(this.state.addFlag)(<TagsAddComponent />, null)}
        </p>
      </div>
    );
  };

  public render() {
    const TagsEditComponent = this.TagsEditComponent;

    return (
      <React.Fragment>
        {this.renderFilter()}
        <Table
          columns={this.columns}
          dataSource={this.state.list}
          rowKey={'id'}
          pagination={helpers.isJudge(this.state.list.length === 0)(
            false,
            true
          )}
        />
        <TagsEditComponent
          editData={this.state.editData}
          tagsUserList={[]}
          editTimes={this.state.editTimes}
        />
      </React.Fragment>
    );
  }
}
