import { Observable, pipe, of, from } from 'rxjs';

import { map, mergeMap, concatMap } from 'rxjs/operators';
import { Epic, ofType } from 'redux-observable';
import { helpers } from '@/utils';
import { rolesSetAPI } from '@/services';
import { message } from 'antd';
import { history } from '@/router';

interface Payload {
  [random: string]: any;
}
// dispatch传进来的action类型
interface PayloadIn {
  type: string;
  payload: {
    [random: string]: any;
  };
}

// epic传到reducer里的action类型
interface PayloadOut {
  type: string;
  payload: {
    [random: string]: any;
  };
}

// 获取用户角色菜单信息
export const getRolesInfo: Epic<PayloadIn, PayloadOut> = (action$, store) => {
  return action$.pipe(
    ofType('products/roles'),
    mergeMap(action => {
      return rolesSetAPI.handleGetRolesSetMenu(action.payload).pipe(
        map(res => {
          if (res !== null) {
            return helpers.createAction<Payload>(
              'products/rolesReducer',
              res.data
            );
          }
          return helpers.createAction<typeof action.payload>('no');
        })
      );
    })
  );
};

// 获取用户所有角色菜单信息
export const getRolesAllMenuInfo: Epic<PayloadIn, PayloadOut> = (
  action$,
  store
) => {
  return action$.pipe(
    ofType('systems/permissions'),
    mergeMap(action => {
      return rolesSetAPI.handleGetRolesAllMenu(action.payload).pipe(
        map(res => {
          if (res !== null) {
            return helpers.createAction<Payload>(
              'systems/permissionsReducer',
              res.data
            );
          }
          return helpers.createAction<typeof action.payload>('no');
        })
      );
    })
  );
};

// 更新角色权限菜单
export const updateRolesSet: Epic<PayloadIn, PayloadOut> = (action$, store) => {
  return action$.pipe(
    ofType('systems/rolepermission'),
    mergeMap(action => {
      return rolesSetAPI.handleupdateRolesSet(action.payload).pipe(
        map(res => {
          if (res !== null) {
            message.success('配置成功.');
            // location.href = '/main/system/roles';
            // history.location.pathname = '/main/system/roles';
            return helpers.createAction<Payload>(
              'systems/rolepermissionSucesss',
              res.payload
            );
          }
          return helpers.createAction<typeof action.payload>('no');
        })
      );
    }),
    pipe(
      ofType('systems/rolepermissionSucesss'),
      concatMap((action: any) => {
        return from([
          {
            type: 'systems/rolepermissionReducer',
            payload: { ...action.payload }
          },
          {
            type: 'main/getLoginInfoEpic',
            payload: {}
          }
        ]);
      })
    )
  );
};
