import * as _ from 'lodash';
import { history } from '../router';
import { Action } from '../reducers/reducerInterface';
import { ls } from '../utils';

const initialStore = {
    rolesSetMeau: [],  // 当前角色数组
    updateSetMeau: {}, // 更新的对象
    allMeauList: [],  // 所有的数据
    showTimes: 0 // 显示次数
};

export type AppStoreType = typeof initialStore;

export default function rolesSetPageReducer(
    state: any = initialStore,
    action: Action
) {
    try {
        switch (action.type) {
            // 获取菜单信息
            case 'products/rolesReducer': {
                const rolesSetMeau = action.payload;
                return _.assign({}, state, { rolesSetMeau, showTimes: state.showTimes + 1 });
            }
            // 获取所有菜单信息
            case 'systems/permissionsReducer': {
                const allMeauList = action.payload;
                return _.assign({}, state, { allMeauList });
            }
            // 更新菜单信息
            case 'systems/rolepermissionReducer': {
                const updateSetMeau = action.payload;
                return _.assign({}, state, { updateSetMeau });
            }
            default:
                return state;
        }
    } catch (e) {
        return state;
    }
}
