import * as React from 'react';
import { Modal, Button, message, Upload, Icon } from 'antd';
import { customersAPI } from '@/services';
import * as _ from 'lodash';
import { servicPath } from '@/utils';
interface CustomersUploadProps {
  [random: string]: any;
}
interface CustomersUploadState {
  [random: string]: any;
}

export default class CustomersUpload extends React.PureComponent<
  CustomersUploadProps,
  CustomersUploadState
> {
  constructor(props) {
    super(props);
    this.state = {
      visible: false, // 是否显示合并重复窗口
      list: [], // 去重的列表
      duplicatedCount: 0, // 合并组数
      filePath: '' // 回传值
    };
  }

  // 最新导入数量
  public newImportNum = 0;

  // 添加用户
  public addCustomers = () => {
    this.props.dispatch({
      type: 'customers/addCustomersEpic',
      payload: {
        file_path: this.state.filePath,
        duplicatedCount: this.state.duplicatedCount
      }
    });
  };

  // 确认导入数据
  public handleEnter = () => {
    this.setState({ visible: false }, this.addCustomers);
  };

  public renderUpload = () => {
    const self = this;
    const props: any = {
      customRequest: () => {
        return null;
      },
      // 上传代码
      beforeUpload: file => {
        if (
          file.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ) {
          const formData = new FormData();
          formData.append('file', file);
          customersAPI.handleUploadCustomersExcel({
            url: `${servicPath}/products/customers/import`,
            data: formData,
            success: res => {
              if (res.error_code === 6000) {
                message.success('上传成功，正在处理中');
              } else {
                message.error(res.message);
              }

              // if (res.data.user_list.length === 0) {
              //   self.setState(
              //     {
              //       filePath: res.data.file_path,
              //       duplicatedCount: 0
              //     },
              //     self.addCustomers
              //   );
              // } else {
              //   // 处理后要的列表
              //   const list: any = [];
              //   // 手动设置list为去重数据
              //   const phoneArray = res.data.user_list
              //     .map(n => n.phone)
              //     .filter((item, index, arr) => arr.indexOf(item) === index);
              //   for (let i = 0; i < phoneArray.length; i++) {
              //     for (let j = 0; j < res.data.user_list.length; j++) {
              //       if (phoneArray[i] === res.data.user_list[j].phone) {
              //         list.push(res.data.user_list[j]);
              //         break;
              //       }
              //     }
              //   }
              //   // 变成树形数据
              //   for (let i = 0; i < list.length; i++) {
              //     list[i].child = [];
              //     // 号码相同的
              //     list[i].child = res.data.user_list.filter(
              //       n => n.phone === list[i].phone
              //     );
              //   }
              //   self.setState({
              //     visible: true,
              //     list,
              //     duplicatedCount: res.data.duplicated_count,
              //     filePath: res.data.file_path
              //   });
              // }
            }
          });
          //
        } else {
          message.error('只能上传execl文件');
        }
        return;
      },
      showUploadList: false
    };

    return (
      <Upload {...props}>
        <Button className={'pBtn2'}>
          <Icon type={'upload'} /> 上传名单
        </Button>
      </Upload>
    );
  };

  // 渲染表单块
  public renderFrom = () => {
    return (
      <Modal
        title={'合并重复名单'}
        visible={this.state.visible}
        onOk={this.handleEnter}
        maskClosable={false}
        onCancel={() => {
          this.setState({ visible: false });
        }}
        width={600}
        className={'modalList'}
        okText={'立即合并'}
      >
        <div className={'top'}>
          <span>序号</span>
          <span>姓名/用户名</span>
          <span>手机号码</span>
          <span>客户来源</span>
        </div>
        <div>
          {this.state.list.map((item, index) => (
            <div>
              <div>{index + 1}</div>
              {item.child.map(n => {
                return (
                  <p>
                    <span />
                    <span>{n.username}</span>
                    <span>{n.phone}</span>
                    <span>{n.source_id}</span>
                  </p>
                );
              })}
            </div>
          ))}
        </div>
      </Modal>
    );
  };

  public render() {
    return (
      <React.Fragment>
        {this.renderUpload()}
        {this.renderFrom()}
      </React.Fragment>
    );
  }
}
