import * as _ from 'lodash';
import { history } from '../router';
import { Action } from '../reducers/reducerInterface';

const initialStore = {
  history,
  userInfo: {}, // 用户信息
  isShowLoading: false, // 是否显示全局的loading图
  buttonPermission: []
};

export type AppStoreType = typeof initialStore;

export default function mainReducer(state: any = initialStore, action: Action) {
  try {
    switch (action.type) {
      // 显示Loading图
      case '@@ty/loadingShow': {
        return Object.assign({}, state, {
          isShowLoading: true
        });
      }

      // 隐藏Loading图
      case '@@ty/loadingHide': {
        return Object.assign({}, state, {
          isShowLoading: false
        });
      }

      // 设置个人信息
      case 'main/getLoginInfo': {
        const userInfo = action.payload;
        // 按钮权限参考数据处理
        const buttonPermission: any = userInfo.role.permission_ids;
        return _.assign({}, state, { userInfo, buttonPermission });
      }

      case 'main/logout': {
        return Object.assign({}, state, {
          userInfo: {},
          buttonPermission: []
        });
      }

      default:
        return state;
    }
  } catch (e) {
    return state;
  }
}
