import { loginMulti } from './login/loginMulti'; // 登录
import { mainMulti } from './main/mainMulti'; // 主页
import { agentBtnMulti } from './main/agentBtnMulti'; // 坐席按钮
import { personalEditMulti } from './personal/edit/personalEditMulti'; // 个人信息编辑

import { userMulti } from './system/user/userMulti'; // 用户列表
import { userAddMulti } from './system/user/userAddMulti'; // 用户添加
import { userEditMulti } from './system/user/userEditMulti'; // 用户编辑

import { tagsMulti } from './system/tags/tagsMulti'; // 标签列表
import { tManagerMulti } from './callCenter/taskManage/taskMulti'; // 任务管理
import { taskAddMulti } from './callCenter/taskManage/taskAddMulti'; // 新增任务
import { taskEditMulti } from './callCenter/taskManage/taskEditMulti'; // 编辑任务
import { telStatMulti } from './report/telStatistics/telStatisticsMulti'; // 话务统计

import { perRerMulti } from './report/performReference/performReferenceMulti'; // 绩效统计
import { perStatMulti } from './report/performStatistics/performStatisticsMulti'; // 绩效参考
import { tagsAddMulti } from './system/tags/tagsAddMulti'; // 标签添加
import { tagsEditMulti } from './system/tags/tagsEditMulti'; // 标签修改

import { rolesMulti } from './system/roles/rolesMulti'; // 角色列表
import { rolesAddMulti } from './system/roles/rolesAddMulti'; // 角色添加
import { rolesEditMulti } from './system/roles/rolesEditMulti'; // 角色修改
import { rolesSetMulti } from './system/roles/rolesSetMulti'; // 角色设置

import { marketingAccountsMulti } from './system/marketingAccounts/marketingAccountsMulti'; // 营销账户列表
import { marketingAccountsAddMulti } from './system/marketingAccounts/marketingAccountsAddMulti'; // 营销账户添加
import { marketingAccountsEditMulti } from './system/marketingAccounts/marketingAccountsEditMulti'; // 营销账户修改

import { customersMulti } from './customers/customersMulti'; // 客户列表
import { assignmentsMulti } from './customers/assignmentsMulti'; // 客户列表-分配
import { createTaskMulti } from './customers/createTaskMulti'; // 客户列表-创建任务
import { customersUploadMulti } from './customers/customersUploadMulti'; // 客户列表-上传
import { customersTasksMulti } from './customers/tasksMulti'; // 客户任务列表
import { tasksDetailedMulti } from './customers/tasksDetailedMulti'; // 客户任务详情
import { externalCustomersMulti } from './customers/externalCustomersMulti'; // 外部客户列表
import { externalCustomersDetailedMulti } from './customers/externalCustomersDetailedMulti'; // 外部客户详情
import { assignmentsDetailedMulti } from './customers/assignmentsDetailedMulti'; // 分配详情

import { tasksMulti } from './callCenter/tasks/tasksMulti'; // 分配了的任务列表
import { callMulti } from './callCenter/call/callMulti'; // 打电话页
import { remarkListMulti } from './callCenter/call/remarkListMulti'; // 打电话页-回访备注列表页
import { editRemarkMulti } from './callCenter/call/editRemarkMulti'; // 打电话页-回访备注列表页-修改回访备注组件
import { postRemarkMulti } from './callCenter/call/postRemarkMulti'; // 打电话页-添加回访备注页
import { sendSmsMulti } from './callCenter/sendSms/sendSmsMulti'; // 发短信页

import { reportCallsMulti } from './report/calls/callsMulti'; // 电话流水
import { reportSmsMulti } from './report/sms/smsMulti'; // 短信流水
import { reportTelDetail } from './report/telStatistics/telDetailMulti'; // 话务详情
import AgentMulti from './report/agent/agentMulti'; // 话务详情
import membersWithoutDepositMulti from './callCenter/tasks/membersWithoutDepositMulti'; // 已注册未存款
import externalCustomersCallMulti from './callCenter/tasks/externalCustomersMulti'; // 外部名单
import groupCallTaskMulti from './callCenter/groupCallTask/groupCallTaskMulti'; // 外部名单
import groupCallTaskDetailMulti from './callCenter/groupCallTask/groupCallTaskDetailMulti'; // 外部名单

export const multiPlatform = {
  loginMulti,
  mainMulti,
  agentBtnMulti,
  personalEditMulti,
  userMulti,
  userAddMulti,
  userEditMulti,
  tagsMulti,
  tagsAddMulti,
  tagsEditMulti,
  rolesMulti,
  rolesAddMulti,
  rolesEditMulti,
  rolesSetMulti,
  marketingAccountsMulti,
  marketingAccountsAddMulti,
  marketingAccountsEditMulti,
  customersMulti,
  assignmentsMulti,
  customersTasksMulti,
  assignmentsDetailedMulti,
  tasksDetailedMulti,
  externalCustomersMulti,
  externalCustomersDetailedMulti,
  tasksMulti,
  callMulti,
  remarkListMulti,
  editRemarkMulti,
  postRemarkMulti,
  sendSmsMulti,
  createTaskMulti,
  customersUploadMulti,
  reportCallsMulti,
  reportSmsMulti,
  tManagerMulti,
  taskAddMulti,
  taskEditMulti,
  telStatMulti,
  perRerMulti,
  perStatMulti,
  reportTelDetail,
  AgentMulti,
  membersWithoutDepositMulti,
  externalCustomersCallMulti,
  groupCallTaskMulti,
  groupCallTaskDetailMulti,
};
