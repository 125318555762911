import { Observable, pipe, of } from 'rxjs';
import _ from 'lodash';

import { map, mergeMap } from 'rxjs/operators';
import { Epic, ofType } from 'redux-observable';
import { helpers } from '@/utils';
import { telAPI } from '@/services';
import { message } from 'antd';

interface Payload {
  [random: string]: any;
}
// dispatch传进来的action类型
interface PayloadIn {
  type: string;
  payload: {
    [random: string]: any;
  };
}

// epic传到reducer里的action类型
interface PayloadOut {
  type: string;
  payload: {
    [random: string]: any;
  };
}

// 获取话务统计坐席号下拉数据
export const getSits: Epic<PayloadIn, PayloadOut> = (action$, store) => {
  return action$.pipe(
    ofType('reports/getSitsEpic'),
    mergeMap(action => {
      return telAPI.handleGetSits().pipe(
        map(res => {
          if (res !== null) {
            const { data } = res;
            return helpers.createAction<Payload>('reports/getSitList', {
              sitList: data,
            });
          }
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};

// 获取话务统计列表
export const getTelList: Epic<PayloadIn, PayloadOut> = (action$, store) => {
  return action$.pipe(
    ofType('reports/getTelEpic'),
    mergeMap(action => {
      return telAPI.handleGetTelList(action.payload).pipe(
        map(res => {
          if (res !== null) {
            const { data } = res.data;
            return helpers.createAction<Payload>('reports/getTelList', {
              telList: data,
              telListTotal: res.data.total,
            });
          }
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};

// 获取话务详情列表
export const getTelDetailList: Epic<PayloadIn, PayloadOut> = (
  action$,
  store,
) => {
  return action$.pipe(
    ofType('reports/getTelDetailEpic'),
    mergeMap(action => {
      return telAPI.handleGetTelDetailList(action.payload).pipe(
        map(res => {
          if (res !== null && _.isObject(res)) {
            const { data, total } = res.data;
            return helpers.createAction<Payload>('reports/getTelDetailList', {
              telDetailList: data,
              telDetailListTotal: total,
            });
          }
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};
