import { Observable, pipe, of } from 'rxjs';

import { map, mergeMap } from 'rxjs/operators';
import { Epic, ofType } from 'redux-observable';
import { helpers } from '@/utils';
import { marketStatusAPI } from '@/services';
import { message } from 'antd';

interface Payload {
  [random: string]: any;
}
// dispatch传进来的action类型
interface PayloadIn {
  type: string;
  payload: {
    [random: string]: any;
  };
}

// epic传到reducer里的action类型
interface PayloadOut {
  type: string;
  payload: {
    [random: string]: any;
  };
}

// 获取行销状态
export const getMarketStatus: Epic<PayloadIn, PayloadOut> = (
  action$,
  store
) => {
  return action$.pipe(
    ofType('reports/getMarketStatusEpic'),
    mergeMap(action => {
      return marketStatusAPI.handleGetMarketStatus().pipe(
        map(res => {
          if (res !== null) {
            return helpers.createAction<Payload>(
              'reports/getMarketStatus',
              res.data
            );
          }
          return helpers.createAction<typeof action.payload>('no');
        })
      );
    })
  );
};
