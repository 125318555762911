/**
 * 带眼睛控制的pwd input
 */
import * as React from 'react';

import { Input } from 'antd';

import inputPDStyle from './input.scss';

interface ComponentProps {
  [random: string]: any;
}

interface ComponentState {
  [random: string]: any;
}

export default class InputPWD extends React.PureComponent<
  ComponentProps,
  ComponentState
> {
  constructor(props) {
    super(props);
    this.state = {
      pwdVisible: false
    };
  }

  public render() {
    return (
      <React.Fragment>
        <div style={{ ...this.props.style, position: 'relative' }}>
          <Input
            size={'large'}
            type={this.state.pwdVisible ? 'text' : 'password'}
            {...this.props}
          />
          <i
            onClick={() =>
              this.setState({ pwdVisible: !this.state.pwdVisible })
            }
            className={
              this.state.pwdVisible
                ? inputPDStyle.icon_pwd_visible +
                  ' ' +
                  inputPDStyle.password_icon
                : inputPDStyle.icon_pwd_hidden +
                  ' ' +
                  inputPDStyle.password_icon
            }
          />
        </div>
      </React.Fragment>
    );
  }
}
