// 通过状态码获取状态名
export function getSateName(extensionState) {
  switch (extensionState) {
    case -2: {
      return '状态未知';
    }
    case -1: {
      return '离线';
    }
    case 0: {
      return '空闲';
    }
    case 1: {
      return '振铃';
    }
    case 2: {
      return '通话';
    }
    case 3: {
      return '保持';
    }
    case 4: {
      return '后处理';
    }
    case 5: {
      return '连接中';
    }
    case 6: {
      return '忙碌';
    }
    case 7: {
      return '离开';
    }
    case 8: {
      return '预览外呼中';
    }
    case 9: {
      return '监控';
    }
    case 10: {
      return '呼出中';
    }
    case 11: {
      return '电话静音中';
    }
    case 999: {
      return '恢复原状态';
    }
    default:
      return '未定义状态';
  }
}

// 通过retCode获取错误说明
export function getRetCodeName(retCode) {
  switch (retCode) {
    case -6: {
      return '请不要重复签入';
    }
    case -7: {
      return '工号与登陆的socket号不相符';
    }
    case -8: {
      return 'agentPwd字段为空';
    }
    case -9: {
      return 'bindExten字段不为yes或no';
    }
    case -10: {
      return 'bindExten字段为yes但agentExten字段为空';
    }
    case -11: {
      return '分机状态错误,请检查该分机是否注册在线';
    }
    case -12: {
      return '分机已被其他座席绑定';
    }
    case -13: {
      return '登陆初始座席状态为空';
    }
    case -14: {
      return '登陆初始座席状态不合法';
    }
    case -15: {
      return '目标座席状态为空';
    }
    case -16: {
      return '目标座席状态不合法';
    }
    case -17: {
      return '不能手动切换至目标座席状态';
    }
    case -18: {
      return 'customerNum字段为空';
    }
    case -19: {
      return 'customerDisplayNum字段为空';
    }
    case -20: {
      return '查找座席角色失败';
    }
    case -21: {
      return '权限检查失败,请确认您是否有该权限';
    }
    case -22: {
      return 'customerDisplayNum字段不合法';
    }
    case -23: {
      return '没有找到座席，可能没有签入';
    }
    case -24: {
      return '座席状态错误';
    }
    case -25: {
      return '座席没有绑定分机';
    }
    case -26: {
      return '绑定的分机状态错误';
    }
    case -27: {
      return '目标座席状态错误';
    }
    case -28: {
      return '主叫和被叫相同';
    }
    case -29: {
      return '未找到主叫座席，可能没有签入';
    }
    case -30: {
      return '未找到被叫座席，可能没有签入';
    }
    case -31: {
      return '主叫座席状态错误';
    }
    case -32: {
      return '被叫座席状态错误';
    }
    case -33: {
      return '主叫座席未绑定分机';
    }
    case -34: {
      return '被叫座席未绑定分机';
    }
    case -35: {
      return '主叫座席绑定的分机状态错误';
    }
    case -36: {
      return '被叫座席绑定的分机状态错误';
    }
    case -37: {
      return 'language字段不是zh或en';
    }
    case -38: {
      return '查找座席所在座席组失败';
    }
    case -39: {
      return 'IVR号码为空';
    }
    case -40: {
      return 'IVR号码非法';
    }
    case -41: {
      return '队列号码为空';
    }
    case -42: {
      return '队列号码非法';
    }
    case -43: {
      return '后台查询数据库失败';
    }
    case -44: {
      return '座席组号码为空';
    }
    case -45: {
      return '座席组号码列表字段为空';
    }
    case -46: {
      return '座席组列表为空';
    }
    case -47: {
      return '队列管理模块指针为空';
    }
    case -48: {
      return '查找座席所属队列列表失败';
    }
    case -49: {
      return '未找到目标座席，可能没有签入';
    }
    case -50: {
      return '目标座席座席状态错误';
    }
    case -51: {
      return '该座席没有注册';
    }
    case -52: {
      return '该座席还未启用';
    }
    case -53: {
      return '登陆密码错误';
    }
    case -54: {
      return '使座席下线失败';
    }
    case -55: {
      return '初始化座席相关参数失败';
    }
    case -56: {
      return '锁分机失败';
    }
    case -57: {
      return '手动呼出失败';
    }
    case -58: {
      return '改变座席状态失败';
    }
    case -59: {
      return '电话管理模块操作失败';
    }
    case -60: {
      return '坐席组号码不存在';
    }
    case -61: {
      return '坐席组号码不存在';
    }
    case -62: {
      return '获得自定义网址失败';
    }
    case -63: {
      return 'IVR Profile号码为空';
    }
    case -64: {
      return 'IVR Profile号码非法';
    }
    case -65: {
      return '是否是外线号码标识为空';
    }
    case -66: {
      return '忙转的外线号码为空';
    }
    case -67: {
      return '对方坐席状态无法被强制改变';
    }
    case -68: {
      return '此坐席不属于任何坐席组';
    }
    case -69: {
      return '已申请的数量已达到允许的最大阀值，请稍后再试';
    }
    case -70: {
      return '您已经申请过，请不要重复申请';
    }
    case -71: {
      return '此坐席不属于任何坐席组';
    }
    case -72: {
      return '未找到您所在的坐席组';
    }
    case -73: {
      return '申请者的工号为空';
    }
    case -74: {
      return '此审批已经被处理';
    }
    case -75: {
      return '此审批已经被处理';
    }
    case -76: {
      return '此申请的处理状态不正确';
    }
    case -77: {
      return '坐席组名称不能为空';
    }
    case -78: {
      return '坐席组离开阀值无效';
    }
    case -79: {
      return '设置坐席组离开阀值失败';
    }
    case -80: {
      return '此申请或审批超时,操作无效';
    }
    case -81: {
      return '坐席电话uud为空';
    }
    case -82: {
      return '客户电话uuid为空';
    }
    case -83: {
      return 'web服务调用失败';
    }
    case -84: {
      return '旧密码错误';
    }
    case -1000: {
      return '客户端接收信息线程异常退出';
    }
    case -1001: {
      return '与网络断开连接';
    }
    case -1002: {
      return '网络异常错误';
    }
    case -2000: {
      return '系统异常错误';
    }
    case -2001: {
      return '系统异常错误';
    }
    default:
      return '未定义错误';
  }
}
