import * as React from 'react';
import { Modal, Tree } from 'antd';
import { connect } from 'react-redux';
// import * as _ from 'lodash';
import lodash from 'lodash';
import deepdash from 'deepdash';
const _ = deepdash(lodash);
import rolesSetStyle from './rolesSet/roleSet.scss';
interface RolesSetProps {
  [random: string]: any;
}
interface RolesSetState {
  [random: string]: any;
}
const { TreeNode } = Tree;

export default class RolesSet extends React.PureComponent<
  RolesSetProps,
  RolesSetState
  > {
  constructor(props) {
    super(props);
    this.state = {
      expandedKeys: [],
      autoExpandParent: true,
      checkedKeys: [],
      selectedKeys: [],
      visible: false,
      confirmLoading: false,
      id: ''
    };
  }

  public showTimes = 0; // 显示次数

  // 确定事件事件
  public handleOk = () => {
    const self = this;
    self.setState({
      visible: false
    });
    self.props.dispatch({
      type: 'systems/rolepermission',
      payload: {
        rolesId: self.state.id,
        permissions_id:
          self.state.checkedKeys.length === 0
            ? ''
            : self.state.checkedKeys.join(',')
      }
    });
  };
  // 取消事件
  public handleCancel = () => {
    this.setState({
      visible: false
    });
  };
  // 重置显示次数
  public componentDidMount() {
    this.showTimes = this.props.all.rolesSet.showTimes;
  }

  public componentWillMount() {
    // 清除选中
    this.setState({
      checkedKeys: []
    });
  }
  public componentWillReceiveProps(nextProps) {
    // 绑定角色列表
    if (this.showTimes !== nextProps.all.rolesSet.showTimes) {
      this.showTimes = nextProps.all.rolesSet.showTimes;
      this.setState({
        visible: true,
        id: nextProps.all.rolesSet.rolesSetMeau.id,
        checkedKeys: nextProps.all.rolesSet.rolesSetMeau.permissions
      });
    }
  }

  public onExpand = expandedKeys => {
    this.setState({
      expandedKeys,
      autoExpandParent: false
    });
  };

  // 点击多选框
  public onCheck = (checkedKeys, e) => {
    const { allMeauList } = this.props.all.rolesSet;
    const treeDate = allMeauList; // 整棵树的信息
    const currentNodeId = e.node.props.eventKey; // 当前节点的id
    let newCheckedKeys = checkedKeys.checked;
    if (e.checked) {
      /* 选中状态 */
      // 把所有父节点都选中
      const parentsIds = this.getParentIds(treeDate, currentNodeId); // 所有父节点的id
      newCheckedKeys = _.uniq(_.concat(newCheckedKeys, parentsIds));
      // 子节点要选中
      const subIds = this.getSubIds(treeDate, currentNodeId); // 子节点的id(只是子节点这一层，非孙节点)
      newCheckedKeys = _.uniq(_.concat(newCheckedKeys, subIds));
    } else {
      /* 非选中状态 */
      // 去掉所有子孙节点
      const childrenIds = this.getChildrenIds(treeDate, currentNodeId); // 所有子孙节点的id
      newCheckedKeys = _.difference(newCheckedKeys, childrenIds);
      newCheckedKeys = _.difference(newCheckedKeys, [currentNodeId]);
    }
    this.setState({ checkedKeys: newCheckedKeys }); // checkedKeys是当前节点的ID，
  };

  public onSelect = (selectedKeys, info) => {
    this.setState({ selectedKeys });
  };
  // 根据id找出下一层级节点的id值
  public getSubIds = (arr, id) => {
    let subIds;
    JSON.parse(JSON.stringify(arr), (k, v) => {
      if (v.id && v.id.toString() === id) {
        subIds = _.map(v.children, 'id');
      }
      return v;
    });
    return subIds;
  };
  // 根据id找出父节点的id值
  public getParentIds = (treeData, id) => {
    let ids = []; // 存放找到的父节点id值
    _.eachDeep(
      treeData,
      (value, key, path, depth, parent, parentKey, parentPath) => {
        // 找到指定的节点
        // debugger;
        if (key === 'id' && value.toString() === id) {
          // 根据父路径parentPath来提取出父节点的id值
          ids = _.map(_.dropRight(parentPath.split('.')), (item, index) =>
            _.get(
              treeData,
              `${_.split(parentPath, '.', index + 1).join('.')}.id`
            )
          );
        }
      }
    );
    return ids;
  };
  // 根据id找出下一层级，下下层级等所有子孙节点的id
  public getChildrenIds = (arr, id) => {
    const childrenIds = [] as any;
    JSON.parse(JSON.stringify(arr), (k, v) => {
      if (v.id && v.id.toString() === id && !_.isEmpty(v.children)) {
        // 找到目标节点，对该节点的子孙节点进行遍历，提取id
        JSON.parse(JSON.stringify(v.children), (_k, _v) => {
          if (_k === 'id') {
            // 将找到的id给存起来
            childrenIds.push(_v.toString());
            return _v;
          }
        });
      }
      return v;
    });
    return childrenIds;
  };

  // 树节点循环展示
  public renderTreeNodes = data =>
    data.map(item => {
      item.title = item.name;
      item.key = item.id;
      if (item.children && item.children.length > 0) {
        return (
          <TreeNode title={item.title} key={item.key} dataRef={item}>
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode {...item} />;
    });

  // 渲染
  public render() {
    const { visible, confirmLoading } = this.state;
    const { allMeauList } = this.props.all.rolesSet;
    return (
      <div>
        <Modal
          title={'权限配置'}
          visible={visible}
          onOk={this.handleOk}
          confirmLoading={confirmLoading}
          onCancel={this.handleCancel}
          maskClosable={false}
          width={500}
        >
          <div className={rolesSetStyle.roleSet}>
            <ul className={rolesSetStyle.roleSetTitle}>
              <li>页面权限</li>
              <li>操作权限</li>
            </ul>
            <div className={rolesSetStyle.roleSetTree}>
              <Tree
                checkable
                defaultExpandAll={true}
                checkStrictly={true}
                onCheck={this.onCheck}
                checkedKeys={this.state.checkedKeys}
              >
                {this.renderTreeNodes(allMeauList)}
              </Tree>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
