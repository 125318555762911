import * as _ from 'lodash';
import { Action } from '../reducers/reducerInterface';

const initialStore = {
  tasks: [], // 任务列表
  total: 0, // 任务总数
  updateTimes: 0, // 更新次数
  tasksDetailed: {
    showTimes: 0, // 详情框显示次数
    total: 0, // 人数的总数
    handled: [], // 处理的数组
    notHandled: 0 // 未处理的数量
  }
};

export type AppStoreType = typeof initialStore;

export default function tasksReducer(
  state: any = initialStore,
  action: Action
) {
  try {
    switch (action.type) {
      case 'customers/getTasks': {
        const tasks = action.payload.list;
        const total = action.payload.total;
        return _.assign({}, state, { tasks, total });
      }
      case 'customers/updateTasks': {
        return _.assign({}, state, { updateTimes: state.updateTimes + 1 });
      }
      case 'customers/getTasksDetails': {
        const tasksDetailed = {
          total: action.payload.total,
          handled: action.payload.handled,
          notHandled: action.payload.notHandled,
          showTimes: state.tasksDetailed.showTimes + 1
        };
        return _.assign({}, state, { tasksDetailed });
      }
      default:
        return state;
    }
  } catch (e) {
    return state;
  }
}
