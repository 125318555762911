import * as React from 'react';
import * as _ from 'lodash';
import moment from 'moment';
import { Table, Button, Input, Switch, Modal, Select, DatePicker } from 'antd';
const confirm = Modal.confirm;
import { helpers } from '@/utils';
import {
  ContainerPropsInterface,
  ContainerStateInterface,
} from '@/routes/containerInterface';
import { history } from '@/router';
import { XList } from '@/components';

const { RangePicker } = DatePicker;
const Option = Select.Option;

export default class TelStatistics extends React.PureComponent<
    ContainerPropsInterface<any>,
    ContainerStateInterface
> {
  // 表格列
  public columns: any = [
    /* {
       title: '坐席号',
       width: 80,
       align: 'center',
       render: (text, record, index) => {
         const phoneLine = this.props.all.main.userInfo.phone_line;
         const account =
           record[phoneLine === 2 ? 'mixcall_account' : 'polylink_account'];
         return helpers.isJudge(
           _.includes(this.props.all.main.buttonPermission, 120),
         )(
           <a
             href={'javascript:;'}
             onClick={() => {
               this.handleDetail(record);
             }}
           >
             {(!!account.length && account[0].employee_id) || ''}
           </a>,
           (!!account.length && account[0].employee_id) || '',
         );
       },
     },*/
    {
      title: '所属角色',
      dataIndex: 'role_id',
      align: 'center',
      width: 80,
      render: text => {
        return <span>{this.state.roleNames[text]}</span>
      },
    },
    {
      title: '主叫人',
      dataIndex: 'name',
      align: 'center',
      width: 80,
    },
    {
      title: '呼叫量',
      dataIndex: 'calls',
      align: 'center',
      width: 80,
    },
    {
      title: '接通量',
      dataIndex: 'calls_connected',
      align: 'center',
      width: 80,
    },
    {
      title: '接通百分比',
      dataIndex: 'connected_rate',
      align: 'center',
      width: 80,
    },
    {
      title: '有效通话量',
      dataIndex: 'calls_effective',
      align: 'center',
      width: 80,
    },
    {
      title: '有效通话比',
      dataIndex: 'effective_rate',
      align: 'center',
      width: 80,
    },
    {
      title: '未接通量',
      dataIndex: 'unconnected',
      align: 'center',
      width: 80,
    },
    {
      title: '未接通百分比',
      dataIndex: 'unconnected_rate',
      align: 'center',
      width: 80,
    },
    {
      title: '累计通话时长',
      width: 80,
      align: 'center',
      dataIndex: 'seconds',
      render: text => {
        return <span>{text || 0}</span>;
      },
    },
  ];

  // 计算百分比
  public calcPercent = (value, total) => {
    let percent: any = 0;
    if (total !== 0) {
      percent = Math.round((value / total) * 100);
    }
    return percent;
  };

  // 页面大小存储使用的key
  public STORE_PAGE_SIZE_KEY = 'smallPageSize';

  constructor(props) {
    super(props);
    this.state = {
      list: [], // 列表
      tags: [], // 当前标签
      sits: [], // 坐席号
      roles: [], // 角色列表
      roleNames: {}, // 角色对应的name
      page: {
        size: helpers.isJudge(
            localStorage.getItem(this.STORE_PAGE_SIZE_KEY) === null,
        )(40, localStorage.getItem(this.STORE_PAGE_SIZE_KEY)), // 用户列表的每页大小
        num: 1, // 用户列表的当前页数
      },
      total: 0,
      filterObj: this.filterObj, // 筛选条件
    };
  }

  public handleDetail = record => {
    const params = {
      ...record,
      connect_time_start: this.state.filterObj.connect_time_start,
      connect_time_end: this.state.filterObj.connect_time_end,
      current_label: this.state.filterObj.current_label,
    };
    history.push(
        history.location.pathname + '/' + record.mixcall_account[0].id,
        params,
    );
  };

  public data: any = [];
  public filterObj: any = {
    site_num: '', // 坐席号
    user_name: '', // 用户名
    // current_label: -1, // 当前标签
    connect_time_start: '', // 通话开始时间
    connect_time_end: '', // 通话结束时间
    // connect_count: '0', // 接通量
    // connect_time: '0', // 接通秒数
    rolesId: '' // 所属角色
  };

  // 重置
  public handleReset = () => {
    this.setState(
        {
          filterObj: {
            site_num: '', // 坐席号
            user_name: '', // 用户名
            // current_label: -1, // 当前标签
            connect_time_start: this.getStartTime(), // 通话开始时间
            connect_time_end: this.getEndTime(), // 通话结束时间
            rolesId: ''
            // connect_count: '0', // 接通量
            // connect_time: '0', // 接通秒数
          },
        },
        this.handleSearch,
    );
  };
  // 筛选
  public handleFilter() {
    if (this.data.length !== 0) {
      this.setState({});
    }
  }
  // 选择通话日期
  public selectCreateDate = dateString => {
    this.setState({
      filterObj: {
        ...this.state.filterObj,
        connect_time_start: dateString[0],
        connect_time_end: dateString[1],
      },
    });
  };
  public defaultQuickDateRanges = [
    {
      text: '今天',
      subtractStart: 1,
      subtractEnd: 0,
    },
    {
      text: '昨天',
      subtractStart: 2,
      subtractEnd: 1,
    },
    {
      text: '近7天',
      subtractStart: 7,
      subtractEnd: 0,
    },
    {
      text: '近30天',
      subtractStart: 30,
      subtractEnd: 0,
    },
    {
      text: '近180天',
      subtractStart: 180,
      subtractEnd: 0,
    },
    {
      text: '近360天',
      subtractStart: 360,
      subtractEnd: 0,
    },
  ]

  public datePickerRanges = (
      format = 'YYYY-MM-DD'
  ) => {
    return this.defaultQuickDateRanges.reduce((result, prev) => {
      result[prev.text] = this.initStartToEndDate(prev.subtractStart, prev.subtractEnd, format)
      return result
    }, {})
  }

  public initStartToEndDate = (
      subtractStart = 6,
      subtractEnd = 0,
      format = 'YYYY-MM-DD',
  ) => [
    moment(moment().subtract(subtractStart, 'day').startOf('day').format(format)),
    moment(moment().subtract(subtractEnd, 'day').endOf('day').format(format)),
  ]

  public getRoles = () => {
    this.props.dispatch({
      type: 'system/roles/getRolesEpic',
      payload: {
        page_size: 1000,
      },
    });
  };
  // 筛选模块渲染
  public renderFilter = () => {
    const dateFormat = 'YYYY/MM/DD';
    return (
        <div>
          <p>
          <span className={'notWrap'}>
            坐席号 :&nbsp;&nbsp;
            <Input
                style={{ width: '145px' }}
                placeholder={'请输入坐席号'}
                value={this.state.filterObj.site_num}
                onChange={e => {
                  this.setState({
                    filterObj: {
                      ...this.state.filterObj,
                      site_num: e.target.value,
                    },
                  });
                }}
            />
          </span>
            <span className={'notWrap'}>
            所属角色：
            <Select
                allowClear
                placeholder={'所属角色'}
                value={this.state.filterObj.rolesId}
                onChange={value =>
                    this.setState({
                      filterObj: { ...this.state.filterObj, rolesId: value },
                    })
                }
            >
              {this.state.roles.map(item => (
                  <Option key={item.id} value={item.id}>
                    {item.role_name}
                  </Option>
              ))}
            </Select>
          </span>
            <span className={'notWrap'}>
            主叫人 :&nbsp;&nbsp;
              <Input
                  value={this.state.filterObj.user_name}
                  type={'text'}
                  placeholder={'请输入名称'}
                  onChange={e =>
                      this.setState({
                        filterObj: {
                          ...this.state.filterObj,
                          user_name: e.target.value,
                        },
                      })
                  }
              />
          </span>
            {/*<span className={'notWrap'}>
            当前标签 :&nbsp;&nbsp;
            <Select
              allowClear={true}
              placeholder={'请选择'}
              onChange={value =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    current_label: value,
                  },
                })
              }
            >
              {this.state.tags.map(item => {
                return <Option key={item.id}>{item.name}</Option>;
              })}
            </Select>
          </span>*/}
            <span className={'notWrap'}>
            通话时间 :&nbsp;&nbsp;
              <RangePicker
                  ranges={this.datePickerRanges()}
                  value={helpers.isJudge(
                      this.state.filterObj.connect_time_start === '',
                  )(null, [
                    moment(
                        this.state.filterObj.connect_time_start,
                        this.dateFormat,
                    ),
                    moment(this.state.filterObj.connect_time_end, this.dateFormat),
                  ])}
                  onChange={(date, dateString) => this.selectCreateDate(dateString)}
              />
          </span>
            {/*<span className={'notWrap'}>
            接通量 :&nbsp;&nbsp;
            <Select
              allowClear={false}
              value={this.state.filterObj.connect_count}
              onChange={value =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    connect_count: value,
                  },
                })
              }
            >
              <Option key={'0'}>{'不限'}</Option>
              <Option key={'1'}>{'< 30次'}</Option>
              <Option key={'2'}>&ge;{'30次'}</Option>
            </Select>
          </span>
          <span className={'notWrap'}>
            接通秒数 :&nbsp;&nbsp;
            <Select
              allowClear={false}
              value={this.state.filterObj.connect_time}
              onChange={value =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    connect_time: value,
                  },
                })
              }
            >
              <Option key={'0'}>{'不限'}</Option>
              <Option key={'1'}>{'<7500'}</Option>
              <Option key={'2'}>&ge;{'7500'}</Option>
            </Select>
          </span>*/}
            <span className={'notWrap'}>
            <Button type={'primary'} onClick={this.handleSearch}>
              筛选
            </Button>
            <Button onClick={this.handleReset}>重置</Button>
          </span>
          </p>
        </div>
    );
  };

  public dateFormat = 'YYYY-MM-DD';

  // 获取默认时间
  public getDefaultTime = () => {
    this.setState({
      filterObj: {
        ...this.state.filterObj,
        connect_time_start: this.getStartTime(),
        connect_time_end: this.getEndTime(),
      },
    });
  };

  // 获取开始时间
  public getStartTime = () => {
    return moment()
        .subtract(30, 'days')
        .format('YYYY-MM-DD');
  };

  // 获取结束时间
  public getEndTime = () => {
    return moment().format('YYYY-MM-DD');
  };

  // 获取数据
  public getData = () => {
    this.props.dispatch({
      type: 'reports/getTelEpic',
      payload: {
        page: this.state.page.num,
        page_size: this.state.page.size,
      },
    });
  };

  // 筛选
  public handleSearch = () => {
    const {
      site_num,
      user_name,
      // current_label,
      connect_time_start,
      connect_time_end,
      // connect_count,
      // connect_time,
      rolesId
    } = this.state.filterObj;
    // 请求参数
    let payload: any = {
      page: this.state.page.num,
      page_size: this.state.page.size,
      // total_time_count: connect_time,
      username: user_name,
      // connected_count: connect_count,
      // tag_id: current_label,
      // phone_line 1 为 polylink 2 为米糠云
      [this.props.all.main.userInfo.phone_line === 1
          ? 'polylink_seat_number'
          : 'mixcall_seat_number']: site_num,
      role_id: rolesId,
    };
    // 如果存在联系时间，则将两个参数加入进请求值
    if (connect_time_start && connect_time_end) {
      payload = {
        ...payload,
        record_date_start: connect_time_start,
        record_date_end: connect_time_end,
      };
    }
    // 发起请求
    this.props.dispatch({
      type: 'reports/getTelEpic',
      payload,
    });
    localStorage.setItem(this.STORE_PAGE_SIZE_KEY, this.state.page.size);
  };

  public componentWillMount() {
    // 获取列表
    if (this.state.list.length === 0) {
      this.getData();
    }
    // 获取标签
    if (this.state.tags.length === 0) {
      this.getTags();
    }
    // 获取角色列表
    if (this.props.all.roles.roles.length === 0) {
      this.getRoles();
    }
    // 获取坐席号
    // if (this.state.sits.length === 0) {
    //   this.getSits();
    // }
    this.getDefaultTime();
  }

  // 获取标签下拉数据
  public getTags = () => {
    this.props.dispatch({
      type: 'system/tags/getTagsEpic',
    });
  };

  // 获取坐席号下拉数据
  public getSits = () => {
    this.props.dispatch({
      type: 'reports/getSitsEpic',
    });
  };

  public componentWillReceiveProps(nextProps) {
    const { telStatistics, tags, main } = nextProps.state;
    // 绑定列表
    if (!_.isEqual(telStatistics.telList, this.data)) {
      this.data = telStatistics.telList;
      this.setState({
        list: telStatistics.telList,
        total: telStatistics.telListTotal,
      });
    }

    // 角色列表绑定
    if (nextProps.all.roles.roles !== undefined) {
      if (
          nextProps.all.roles.roles.length !== 0 &&
          !_.isEqual(nextProps.all.roles.roles, this.state.roles)
      ) {
        const obj = {}
        nextProps.all.roles.roles.map(item => {
          obj[item.id] = item.role_name
        })
        this.setState({ roles: nextProps.all.roles.roles, roleNames: obj});
      }
    }

    // 绑定标签
    if (tags.tags.length !== 0 && !this.state.tags.length) {
      this.data = tags.tags;
      this.setState({ tags: tags.tags });
    }

    // 绑定坐席号
    if (main.userInfo.phone_line) {
      if (telStatistics.sitList.length !== 0 && !this.state.sits.length) {
        this.data = telStatistics.sitList;
        this.setState({ sits: telStatistics.sitList });
      }
    }
  }

  // 改变页号
  public changePage = (page, size) => {
    this.setState({ page: { num: page, size } }, this.handleSearch);
  };

  // 改变每页大小
  public changePageSize = (page, size) => {
    this.setState({ page: { num: 1, size } }, this.handleSearch);
  };

  public render() {
    return (
        <React.Fragment>
          {this.renderFilter()}
          <XList
              columns={this.columns}
              dataSource={this.state.list}
              autoCalculation={true}
              pagination={{
                current: this.state.page.num,
                pageSize: this.state.page.size,
                total: this.state.total,
                showSizeChanger: true,
                showQuickJumper: true,
                onChange: this.changePage,
                onShowSizeChange: this.changePageSize,
                pageSizeOptions: ['10', '20', '30', '40'],
              }}
          />
        </React.Fragment>
    );
  }
}
