import React, { createRef } from 'react';
import { List, WindowScroller } from 'react-virtualized';
import 'react-virtualized/styles.css';
import * as _ from 'lodash';
import listStyle from './list.scss';
import { Pagination, Checkbox } from 'antd';

interface Props {
  columns: Array<any>; // 列
  dataSource: Array<any>; // 数据
  isShowNum?: boolean; // 序号
  multiRowId?: string; // 第二行字段
  pagination: {
    current: number;
    pageSize: number;
    total: number;
    pageSizeOptions?: string[];
    hideOnSinglePage?: boolean;
    showSizeChanger?: boolean;
    showQuickJumper?: boolean;
    autoCalculation?: boolean;
    onChange?: (page: number, pageSize?: number) => void;
    onShowSizeChange?: (current: number, size: number) => void;
  }; // 分页
  [random: string]: any;
}

interface State {
  [random: string]: any;
}

export default class extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      scrollLeftPosition: 0, // 滚动条的左定位
      headerTop: false, // 头部置顶
      fixedLeft: 0, // 左边定位
      listWidth: '100%', // 列表宽度
      selectedRowKeys: [], // 选中的id数组
      showCopyWindow: false, // 显示复制窗口
      showScroll: false, // 显示浮动滚动条
    };
  }

  // 序号宽度,为0则不显示序号
  public numWidth =
    this.props.isShowNum === undefined || !this.props.isShowNum ? 0 : 70;

  // 选中的item
  public selectedRowItem: any = [];

  // 默认复制次数
  public showCopyTimes = 0;

  // 每页的选中
  public selectedRowKeysPage = {};

  // 默认rowKey为id
  public rowKey = 'id';

  // resize 事件
  public resize = new Event('resize');

  // list的ref
  private listRef = createRef<HTMLDivElement>();

  // 脚步滚动的ref
  private scrollLeftRef = createRef<HTMLDivElement>();

  // 距离顶部的top
  private top = 0;

  // 滚动的显示高度
  private scrollShowHeight = 0;

  // 行高
  private rowHeight = 40;

  public componentDidMount() {
    // 节点初始化
    const listNode: any = this.listRef.current;
    const scrollLeftNode: any = this.scrollLeftRef.current;

    // 监听y滚动
    window.addEventListener('scroll', this.scrollToY);

    // 监听滚动条
    listNode.addEventListener('scroll', () =>
      this.scrollToX(listNode.scrollLeft),
    );

    scrollLeftNode.addEventListener('scroll', () =>
      this.scrollToX(scrollLeftNode.scrollLeft),
    );

    // 变化时重新定位
    window.addEventListener('resize', this.setPosition);

    // 设置行高
    this.rowHeight = this.props.multiRowId
      ? this.rowHeight * 2
      : this.rowHeight;

    // 懒得用计算器算总宽度
    if (this.props.autoCalculation) {
      let num = 0;
      for (const item of this.props.columns) {
        num += item.width;
      }
    }
  }

  // 设置定位
  public setPosition = () => {
    // 节点初始化
    const listNode: any = this.listRef.current;

    if (listNode == null) {
      return;
    }

    // 设置距离顶部的top
    this.top = listNode.offsetTop - 60;

    // 设置滚动的显示高度
    this.scrollShowHeight =
      listNode.clientHeight + this.top - window.innerHeight + 17;

    // 左定位
    if (listNode.offsetLeft !== this.state.fixedLeft) {
      this.setState({
        fixedLeft: listNode.offsetLeft,
      });
    }

    // 宽度
    if (listNode.clientWidth !== this.state.listWidth) {
      this.setState({
        listWidth: listNode.clientWidth,
      });
    }

    // 设置显示滚动
    this.handleScrollShow();
  };

  // 设置显示滚动
  public handleScrollShow = () => {
    // 是否显示浮动滚动
    if (this.scrollShowHeight < window.scrollY && this.state.showScroll) {
      this.setState({ showScroll: false });
    }
    if (
      this.scrollShowHeight > window.innerHeight &&
      this.scrollShowHeight >= window.scrollY &&
      !this.state.showScroll
    ) {
      this.setState({ showScroll: true });
    }
  };

  // x滚动
  public scrollToX = left => {
    // 节点初始化
    const listNode: any = this.listRef.current;
    const scrollLeftNode: any = this.scrollLeftRef.current;
    scrollLeftNode.scrollLeft = left;
    listNode.scrollLeft = left;
    this.setState({ scrollLeftPosition: left });
  };

  // y滚动
  public scrollToY = () => {
    // head置顶
    if (!this.state.headerTop && this.top <= window.scrollY) {
      this.setState({ headerTop: true });
    }

    // header取消置顶
    if (
      (this.state.headerTop && this.top > window.scrollY) ||
      window.scrollY === 0
    ) {
      this.setState({ headerTop: false });
    }

    // 设置显示滚动
    this.handleScrollShow();
  };

  public componentWillUnMount() {
    // 节点初始化
    const listNode: any = this.listRef.current;
    const scrollLeftNode: any = this.scrollLeftRef.current;

    // 移除滚动监听
    window.removeEventListener('scroll', this.scrollToY);
    listNode.removeEventListener('scroll', this.scrollToX(listNode.scrollLeft));
    scrollLeftNode.removeEventListener(
      'scroll',
      this.scrollToX(scrollLeftNode.scrollLeft),
    );

    // 移除变化监听
    window.removeEventListener('resize', this.setPosition);
  }

  public componentWillReceiveProps(nextProps) {
    // 载加载完数据后，发动resize事件，让react-virtualized进行正确的计算
    setTimeout(() => {
      window.dispatchEvent(this.resize);
    }, 500);

    // 数据初始化
    let { dataSource } = nextProps;
    const { pagination } = nextProps;
    dataSource = dataSource === undefined ? [] : dataSource;

    // rowkey设置
    this.rowKey =
      this.props.rowKey === undefined ? this.rowKey : this.props.rowKey;

    // 同步出最新的页面keys
    this.selectedRowKeysPage[
      `page${pagination.current}`
    ] = this.state.selectedRowKeys.filter(
      item => dataSource.map(n => n[this.rowKey]).indexOf(item) > -1,
    );

    // 定位设置
    this.setPosition();
  }

  // 返回全部选中的key
  public returnBackSelect = () => {
    if (this.props.rowSelection.onChange !== undefined) {
      this.props.rowSelection.onChange(this.state.selectedRowKeys);
    }
  };

  // 点击header的checkbox
  public handleHeaderCheckbox = e => {
    // 数据初始化
    let { dataSource } = this.props;
    const { pagination, rowSelection } = this.props;
    dataSource = dataSource === undefined ? [] : dataSource;

    if (e.target.checked) {
      let data = dataSource;

      // 筛选掉不可选
      if (_.get(rowSelection, 'getCheckboxProps')) {
        data = data.filter(
          n => !_.get(rowSelection.getCheckboxProps(n), 'disabled'),
        );
      }

      // 页面的key
      const selectedRowKeysPage = data.map(n => n[this.rowKey]);

      // 按页保存key
      this.selectedRowKeysPage[
        `page${pagination.current}`
      ] = selectedRowKeysPage;

      // 全部的key
      const selectedRowKeys = this.state.selectedRowKeys.concat(
        selectedRowKeysPage.filter(
          n => !(this.state.selectedRowKeys.indexOf(n) > -1),
        ),
      );

      // 保存全部的key
      this.setState(
        {
          selectedRowKeys,
        },
        () => {
          this.returnBackSelect();
        },
      );
    } else {
      // 页面的key
      const selectedRowKeysPage = dataSource.map(n => n[this.rowKey]);

      // 按页清除key
      this.selectedRowKeysPage[`page${pagination.current}`] = [];

      // 清除全部key中的页面key
      this.setState(
        {
          selectedRowKeys: this.state.selectedRowKeys.filter(
            n => selectedRowKeysPage.indexOf(n) === -1,
          ),
        },
        () => {
          this.returnBackSelect();
        },
      );
    }
  };

  // 点击行的checkbox
  public handleRowCheckbox = (e, item) => {
    // 数据初始化
    const { pagination } = this.props;

    if (e.target.checked) {
      // 按页保存key
      if (this.selectedRowKeysPage[`page${pagination.current}`] === undefined) {
        this.selectedRowKeysPage[`page${pagination.current}`] = [
          item[this.rowKey],
        ];
      } else {
        this.selectedRowKeysPage[`page${pagination.current}`].push(
          item[this.rowKey],
        );
      }

      // 全部的key
      this.state.selectedRowKeys.push(item[this.rowKey]);
      this.setState(
        {
          selectedRowKeys: this.state.selectedRowKeys,
        },
        () => {
          this.returnBackSelect();
        },
      );
    } else {
      // 按页保存key
      this.selectedRowKeysPage[
        `page${pagination.current}`
      ] = this.selectedRowKeysPage[`page${pagination.current}`].filter(
        n => n !== item[this.rowKey],
      );

      // 全部的key
      this.setState(
        {
          selectedRowKeys: this.state.selectedRowKeys.filter(
            n => n !== item[this.rowKey],
          ),
        },
        () => {
          this.returnBackSelect();
        },
      );
    }
  };

  // 渲染header
  public renderHeader = () => {
    // 数据初始化
    let { dataSource } = this.props;
    const { pagination } = this.props;
    const { rowSelection, columns, scroll } = this.props;
    dataSource = dataSource === undefined ? [] : dataSource;
    // 宽
    let x = scroll === undefined ? '100%' : scroll.x + this.numWidth;
    x = this.state.listWidth > x ? this.state.listWidth : x;

    // 筛选掉不可选
    let data = dataSource;
    if (_.get(rowSelection, 'getCheckboxProps')) {
      data = data.filter(
        n => !_.get(rowSelection.getCheckboxProps(n), 'disabled'),
      );
    }

    return (
      <div
        className={listStyle.HeaderTop}
        style={{ width: this.state.listWidth }}
      >
        <div
          className={
            scroll === undefined
              ? `${listStyle.listHeader} ${listStyle.headFlex}`
              : `${listStyle.listHeader}`
          }
          style={{
            width: data === undefined || data.length === 0 ? '100%' : x,
            marginLeft: this.state.headerTop
              ? this.state.scrollLeftPosition * -1
              : 0,
          }}
        >
          {rowSelection === undefined ? null : (
            <div className={listStyle.Checkbox}>
              <Checkbox
                onChange={this.handleHeaderCheckbox}
                indeterminate={
                  this.selectedRowKeysPage[`page${pagination.current}`] ===
                  undefined
                    ? false
                    : this.selectedRowKeysPage[`page${pagination.current}`]
                        .length !== 0 &&
                      this.selectedRowKeysPage[`page${pagination.current}`]
                        .length !== (data === undefined ? 0 : data.length)
                }
                checked={
                  this.selectedRowKeysPage[`page${pagination.current}`] ===
                  undefined
                    ? false
                    : this.selectedRowKeysPage[`page${pagination.current}`]
                        .length !== 0 &&
                      this.selectedRowKeysPage[`page${pagination.current}`]
                        .length === (data === undefined ? 0 : data.length)
                }
              />
            </div>
          )}
          {this.numWidth === 0 ? null : (
            <div style={{ width: this.numWidth }}>序号</div>
          )}
          {columns.map(item => this.handleData(item, item.title))}
        </div>
      </div>
    );
  };

  // 数据处理
  public handleData(item: any, text, record?, render?, index?) {
    // 数据初始化
    let { dataSource } = this.props;
    dataSource = dataSource === undefined ? [] : dataSource;

    const styleObj: any = {};
    let children = text;
    if (render) {
      children = render(text, record, index);
    }

    if (item.align) {
      styleObj.textAlign = item.align;
    }

    if (item.width) {
      styleObj.width = item.width;
    }

    if (item.widthAuto) {
      styleObj.flex = 'auto';
    }

    if (dataSource.length === 0) {
      styleObj.flex = 1;
    }

    if (item.minWidth) {
      styleObj.minWidth = item.minWidth;
    }

    return Object.keys(styleObj).length === 0 ? (
      <div>{children}</div>
    ) : (
      <div style={styleObj}>{children}</div>
    );
  }

  /**
   * 通过Key来获取指定数据，支持以.来区分多层结构
   *
   * @param {*} data
   * @param {string} keyString
   * @returns {*}
   */
  public getDataByKeySupportDot(data: any, keyString: string): any {
    // 异常数据，直接返回空
    if (!(_.isObject(data) && _.isString(keyString))) {
      return undefined;
    }
    // 将数据通过.分割为多数组
    const keyArr = keyString.split('.');
    let index = 0;
    let result = data;
    while (index < keyArr.length) {
      // 如果即将进行匹配的数据非Object类型
      // 说明key指向数据不存在，则不再进行匹配
      if (!_.isObject(result)) {
        return undefined;
      }
      // 循环获取数据，直至获取到最终指向的
      result = result[keyArr[index]];
      index++;
    }
    return result;
  }

  // 渲染行
  public renderListRow = ({ index, key, style }) => {
    // 数据初始化
    let { dataSource } = this.props;
    const {
      rowSelection,
      columns,
      scroll,
      pagination,
      multiRowId,
    } = this.props;
    dataSource = dataSource === undefined ? [] : dataSource;
    let classNameCombination = listStyle.listRow;

    // 判断样式
    if (this.state.selectedRowKeys.includes(dataSource[index][this.rowKey])) {
      classNameCombination += ' ' + listStyle.active;
    }
    if (scroll === undefined) {
      classNameCombination += ' ' + listStyle.flex;
    }

    // 判断是否不可选
    let disabled = false;
    if (_.get(rowSelection, 'getCheckboxProps')) {
      if (
        _.get(rowSelection.getCheckboxProps(dataSource[index]), 'disabled') ===
        true
      ) {
        disabled = true;
      }
    }

    return (
      <div key={key} style={style} className={classNameCombination}>
        <div>
          {rowSelection === undefined ? null : (
            <div className={listStyle.Checkbox}>
              {disabled ? (
                <Checkbox
                  onChange={e => {
                    this.handleRowCheckbox(e, dataSource[index]);
                  }}
                  checked={this.state.selectedRowKeys.includes(
                    dataSource[index][this.rowKey],
                  )}
                  disabled
                />
              ) : (
                <Checkbox
                  onChange={e => {
                    this.handleRowCheckbox(e, dataSource[index]);
                  }}
                  checked={this.state.selectedRowKeys.includes(
                    dataSource[index][this.rowKey],
                  )}
                />
              )}
            </div>
          )}
          {this.numWidth === 0 ? null : (
            <div style={{ width: this.numWidth, textAlign: 'center' }}>
              {index + 1 + (pagination.current - 1) * pagination.pageSize}
            </div>
          )}
          {columns.map(item =>
            this.handleData(
              item,
              this.getDataByKeySupportDot(dataSource[index], item.dataIndex),
              dataSource[index],
              item.render,
              index,
            ),
          )}
        </div>
        {multiRowId ? (
          <div className={listStyle.multiRow}>
            {_.get(dataSource[index], multiRowId)}
          </div>
        ) : null}
      </div>
    );
  };

  // 列表
  public renderList = () => {
    // 数据初始化
    let { dataSource } = this.props;
    const { scroll } = this.props;
    let x =
      scroll === undefined ? this.state.listWidth : scroll.x + this.numWidth;
    x = this.state.listWidth > x ? this.state.listWidth : x;

    dataSource = dataSource === undefined ? [] : dataSource;

    return this.props.dataSource === undefined ||
      this.props.dataSource.length === 0 ? (
      <div className={listStyle.empty}>暂无数据</div>
    ) : (
      <WindowScroller>
        {({ height, isScrolling, onChildScroll, scrollTop }) => (
          <List
            autoHeight
            height={height}
            isScrolling={isScrolling}
            onScroll={onChildScroll}
            rowCount={dataSource.length}
            rowHeight={this.rowHeight}
            rowRenderer={params => this.renderListRow(params)}
            scrollTop={scrollTop}
            width={x}
          />
        )}
      </WindowScroller>
    );
  };

  // 横拉条
  public renderScrollLeft = () => {
    // 数据初始化
    const { scroll } = this.props;
    const x = scroll === undefined ? '100%' : scroll.x + this.numWidth;

    return (
      <div
        className={listStyle.scrollLeft}
        style={
          this.state.showScroll
            ? { width: this.state.listWidth }
            : { width: this.state.listWidth, display: 'none' }
        }
        ref={this.scrollLeftRef}
      >
        <div style={{ width: x }} />
      </div>
    );
  };

  // 分页
  public renderPagination = () => {
    // 数据初始化
    let { dataSource } = this.props;
    dataSource = dataSource === undefined ? [] : dataSource;

    return dataSource.length === 0 ? null : (
      <div className={listStyle.pagination}>
        <div>
          共<span> {this.props.pagination.total} </span>
          条记录 第 {this.props.pagination.current} /{' '}
          {Math.ceil(
            this.props.pagination.total / this.props.pagination.pageSize,
          )}{' '}
          页
        </div>
        <Pagination {...this.props.pagination} />
      </div>
    );
  };

  public render() {
    return (
      <React.Fragment>
        <div
          className={
            this.state.headerTop
              ? listStyle.list + ' ' + listStyle.listHeaderTop
              : listStyle.list
          }
          ref={this.listRef}
        >
          {this.renderHeader()}
          {this.renderScrollLeft()}
          {this.renderList()}
        </div>
        {this.renderPagination()}
      </React.Fragment>
    );
  }
}
