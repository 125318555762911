import * as React from 'react';
import { helpers } from '@/utils';
import { XList } from '@/components';
import {
  ContainerPropsInterface,
  ContainerStateInterface,
} from '@/routes/containerInterface';
import { Button } from 'antd';
import * as _ from 'lodash';

// 用户类型
export interface GroupCallTaskDetailType {
  id: string;
  taskid: string;
  userid: string;
  taskname: string;
  number: string;
  extension: string;
  actualcount: string;
  status: string;
  dst: string;
  calldate: string;
  duration: string;
  billsec: string;
  uniqueid: string;
}

// Task组件State类型
interface GroupCallTaskDetailState extends ContainerStateInterface {
  list: GroupCallTaskDetailType[];
  // 之前项目完全没有interface对数据类型进行规范
  // 先补充一个list，剩下的使用通配，之后再慢慢补充吧
  [key: string]: any;
}

export default class GroupCallTaskDetail extends React.PureComponent<
  ContainerPropsInterface<any>,
  GroupCallTaskDetailState
> {
  constructor(props) {
    super(props);
    this.state = {
      list: [], // 群呼任务列表
      total: 0, // 总条数,
      page: {
        size: helpers.isJudge(localStorage.getItem('pageSize') === null)(
          10,
          localStorage.getItem('pageSize'),
        ), // 用户列表的每页大小
        num: 1, // 用户列表的当前页数
      },
    };
  }

  public columns: any = [
    {
      title: '序号',
      align: 'center',
      width: 50,
      widthAuto: true,
      render: (text, record, index) => (
        <span>
          {index +
            1 +
            this.state.page.num * this.state.page.size -
            this.state.page.size}
        </span>
      ),
    },
    {
      title: '外呼数据ID',
      dataIndex: 'id',
      width: 150,
      align: 'center',
    },
    {
      title: '外呼任务ID',
      dataIndex: 'taskid',
      width: 150,
      align: 'center',
    },
    {
      title: '任务创建人',
      dataIndex: 'userid',
      width: 150,
      align: 'center',
    },
    {
      title: '外呼任务名称',
      dataIndex: 'taskname',
      width: 150,
      align: 'center',
    },
    {
      title: '添加的号码',
      dataIndex: 'number',
      width: 150,
      align: 'center',
    },
    {
      title: '分配到的分机',
      dataIndex: 'extension',
      width: 150,
      align: 'center',
    },
    {
      title: '实际呼叫次数',
      dataIndex: 'actualcount',
      width: 150,
      align: 'center',
    },
    {
      title: '外呼状态',
      dataIndex: 'status',
      width: 150,
      align: 'center',
      render: text => {
        switch (+text) {
          case 0:
            return <p>运行</p>;
          case 1:
            return <p>成功</p>;
          case 2:
            return <p>失败</p>;
          case 3:
            return <p>正在外呼</p>;
          case 4:
            return <p>黑名单数据；5重复数据</p>;
          case 5:
            return <p>重复数据</p>;
          default:
            break;
        }
      },
    },
    {
      title: '被叫号码',
      dataIndex: 'dst',
      width: 180,
      align: 'center',
    },
    {
      title: '呼叫日期',
      dataIndex: 'calldate',
      width: 150,
      align: 'center',
    },
    {
      title: '通话时间',
      dataIndex: 'duration',
      width: 150,
      align: 'center',
    },
    {
      title: '计费时长',
      dataIndex: 'billsec',
      width: 150,
      align: 'center',
    },
    {
      title: '唯一识别号',
      dataIndex: 'uniqueid',
      width: 150,
      align: 'center',
    },
  ];
  // 导出
  public renderExport = () => {
    return (
      <div style={{ textAlign: 'right', marginTop: '15px' }}>
        {helpers.isJudge(_.includes(this.props.all.main.buttonPermission, 129))(
          <Button icon={'download'} onClick={this.getCsvDownload}>
            导出Csv
          </Button>,
          null,
        )}
      </div>
    );
  };

  // 导出报表
  public getCsvDownload = () => {
    this.props.dispatch({
      type: 'callCenter/downloadGroupCallTaskDetail',
      payload: { task_id: this.props.match.params.id },
    });
  };

  // 获取详情列表
  public getGroupCallTaskDetail = () => {
    this.props.dispatch({
      type: 'callCenter/groupCallTaskDetailEpic',
      payload: {
        task_id: this.props.match.params.id,
        page_size: this.state.page.size, // 页面大小
        page: this.state.page.num, // 页码
      },
    });
  };

  // 重新拉取列表
  public getList = () => {
    localStorage.setItem('pageSize', this.state.page.size);
    this.props.dispatch({
      type: 'callCenter/groupCallTaskDetailEpic',
      payload: {
        task_id: this.props.match.params.id,
        page_size: this.state.page.size, // 页面大小
        page: this.state.page.num, // 页码
      },
    });
  };

  // 拉取群呼列表
  public componentWillMount() {
    this.getGroupCallTaskDetail();
  }

  public componentWillReceiveProps(nextProps, nextState) {
    this.setState({
      list: nextProps.state.groupCallTaskDetail,
      total: nextProps.state.total,
    });
  }

  // 改变页号
  public changePage = (page, size) => {
    this.setState({ page: { num: page, size } }, this.getList);
  };

  // 改变每页大小
  public changePageSize = (page, size) => {
    this.setState({ page: { num: 1, size } }, this.getList);
  };

  // 列表模块渲染
  public render() {
    const { page: pageState, total, list } = this.state;
    return (
      <React.Fragment>
        {this.renderExport()}
        <XList
          columns={this.columns}
          dataSource={list}
          scroll={{ x: 1600 }}
          rowKey={'id'}
          autoCalculation={true}
          pagination={{
            current: pageState.num,
            pageSize: pageState.size,
            total,
            showSizeChanger: true,
            showQuickJumper: true,
            onChange: this.changePage,
            onShowSizeChange: this.changePageSize,
            pageSizeOptions: ['10', '20', '30', '40'],
          }}
        />
      </React.Fragment>
    );
  }
}
