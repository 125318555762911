/**
 * 根据传入的数组排列菜单
 */
import * as React from 'react';

import { history } from '@/router';
import { Menu, Icon, Button } from 'antd';

const SubMenu = Menu.SubMenu;

import './menu.scss';

interface ComponentProps {
  [random: string]: any;
}

interface ComponentState {
  [random: string]: any;
}

export default class LeftMenu extends React.PureComponent<
  ComponentProps,
  ComponentState
> {
  // submenu keys of first level
  public rootSubmenuKeys = this.props.menuList.map((n) => n.url);
  public openKeysTmp: string[] = [];
  public openKeysFlag = false;

  constructor(props) {
    super(props);
    let menuUrl = location.pathname;
    if (menuUrl.split('/').length === 6) {
      // 处理拨号页菜单不对的问题
      menuUrl = menuUrl.slice(0, menuUrl.lastIndexOf('/'));
      menuUrl = menuUrl.slice(0, menuUrl.lastIndexOf('/'));
    } else if (menuUrl.split('/').length === 5) {
      menuUrl = menuUrl.slice(0, menuUrl.lastIndexOf('/'));
    }
    this.openKeysTmp = JSON.parse(localStorage.getItem('openKeysTmp') || '[]');
    this.state = {
      openKeys:
        localStorage.getItem('leftMenuExpand') === '0'
          ? []
          : [location.pathname.slice(0, menuUrl.lastIndexOf('/'))], // 打开的菜单
      selectedMenu: menuUrl, // 选中的菜单
      collapsed: props.collapsed,
    };
  }

  public componentWillReceiveProps(nextProps) {
    // 路径发生变化时，设置当前选中菜单
    if (!nextProps.pathname.includes(this.state.selectedMenu)) {
      localStorage.removeItem('page');
      localStorage.removeItem('pageSize');
    }
    if (nextProps.pathname !== this.state.selectedMenu) {
      let menuUrl = location.pathname;
      if (menuUrl.split('/').length === 6) {
        // 处理拨号页菜单不对的问题
        menuUrl = menuUrl.slice(0, menuUrl.lastIndexOf('/'));
        menuUrl = menuUrl.slice(0, menuUrl.lastIndexOf('/'));
      } else if (menuUrl.split('/').length === 5) {
        menuUrl = menuUrl.slice(0, menuUrl.lastIndexOf('/'));
      }
      this.setState({ selectedMenu: menuUrl });
      localStorage.setItem('selectedMenu', menuUrl);
    }
  }

  // 菜单展开
  public onOpenChange = (openKeys) => {
    const latestOpenKey = openKeys.find(
      (key) => this.state.openKeys.indexOf(key) === -1,
    );
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      if (this.state.collapsed && this.openKeysFlag) {
        this.setState({
          openKeys: this.openKeysTmp,
        });
        this.openKeysFlag = false;
      } else {
        this.setState({
          openKeys,
        });
      }
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  };

  // 菜单是否缩小切换
  public toggleCollapsed = () => {
    localStorage.setItem('leftMenuExpand', !this.state.collapsed ? '0' : '1');
    const collapsed = !this.state.collapsed;
    this.props.collapsedCB(collapsed);
    this.setState({
      collapsed,
    });
    // 收缩
    this.openKeysFlag = true;
    if (collapsed) {
      this.openKeysTmp = this.state.openKeys;
      localStorage.setItem('openKeysTmp', JSON.stringify(this.openKeysTmp));
      this.setState({ openKeys: [] });
    }
  };

  public render() {
    return (
      <Menu
        mode={'inline'}
        inlineCollapsed={this.state.collapsed}
        openKeys={this.state.openKeys}
        onOpenChange={this.onOpenChange}
        selectedKeys={[this.state.selectedMenu]}
      >
        <Menu.Item key={'/menuBtn'} className={'toggle'}>
          <Button onClick={this.toggleCollapsed}>
            <Icon type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'} />
          </Button>
        </Menu.Item>
        <Menu.Item
          key={'/main'}
          onClick={() => {
            history.push(this.props.logoUrl);
          }}
          className={this.state.collapsed ? 'logo logo2' : 'logo'}
        >
          <span>{this.props.title}</span>
        </Menu.Item>
        {this.props.menuList.map((item) => {
          return item.show ? (
            <SubMenu
              key={item.url}
              title={
                <span>
                  <Icon type={item.icon} />
                  <span>{item.title}</span>
                </span>
              }
            >
              {item.child.map((n) => {
                return n.show ? (
                  <Menu.Item
                    key={item.url + n.url}
                    onClick={() => {
                      this.openKeysTmp = [item.url];
                      localStorage.setItem(
                        'openKeysTmp',
                        JSON.stringify(this.openKeysTmp),
                      );
                      this.setState({ selectedMenu: item.url + n.url });
                      history.push(item.url + n.url);
                    }}
                  >
                    {n.title}
                  </Menu.Item>
                ) : null;
              })}
            </SubMenu>
          ) : null;
        })}
      </Menu>
    );
  }
}
