import { request, servicPath } from '../utils';
import {
  createGetRequestByQuery,
  createFormDataPostRequest,
  createFormDataPutRequest
} from './requestTransfer';

/**
 * 获取通话记录。
 */
export function handleGetCalls(params) {
  return request(
    createGetRequestByQuery(`${servicPath}/products/callrecords`, params)
  );
}

/**
 * 修改通话流水
 */
export function handleUpdateRecords(params) {
  return request(
    createFormDataPutRequest(
      `${servicPath}/products/callrecords/${params.id}`,
      params
    )
  );
}
