import * as React from 'react';
import * as _ from 'lodash';
import { helpers } from '@/utils';
import moment from 'moment';
import {
  Select,
  DatePicker,
  Table,
  Button,
  Input,
  Pagination,
  Modal,
} from 'antd';
const { RangePicker } = DatePicker;
import {
  ContainerPropsInterface,
  ContainerStateInterface,
} from '@/routes/containerInterface';
const confirm = Modal.confirm;
const Option = Select.Option;
export default class ExternalCustomersDetail extends React.PureComponent<
  ContainerPropsInterface<any>,
  ContainerStateInterface
> {
  // 名单库表格列
  public listColumns: any = [
    {
      align: 'center',
      title: '序号',
      width: 100,
      dataIndex: 'id',
      render: (text, record, index) => (
        <span>
          {index +
            1 +
            this.state.page.num * parseInt(this.state.page.size, 10) -
            parseInt(this.state.page.size, 10)}
        </span>
      ),
    },
    {
      title: '用户名',
      dataIndex: 'username',
      width: 200,
    },
    {
      title: '真实姓名',
      dataIndex: 'x_real_name',
      width: 150,
    },
    {
      title: '手机号码',
      dataIndex: 'x_phone',
      width: 150,
    },
    {
      title: 'VIP等级',
      dataIndex: 'viplevel',
      width: 150,
    },
    {
      title: '存款次数',
      dataIndex: 'deposit_count',
      width: 150,
    },
    {
      title: '存款金额',
      dataIndex: 'total_deposit_amount',
      width: 150,
    },
    {
      title: '存款时间',
      dataIndex: 'last_deposit_time',
      width: 150,
      render: text =>
        helpers.isJudge(text.slice(0, 10) === '0000-00-00')(
          null,
          <time>
            {text.slice(0, 10)}
            <br />
            {text.slice(10)}
          </time>,
        ),
    },
    {
      title: '提款金额',
      dataIndex: 'last_withdraw_amount',
      width: 150,
    },
    {
      title: '提款时间',
      dataIndex: 'last_withdraw_time',
      width: 150,
      render: text =>
        helpers.isJudge(text.slice(0, 10) === '0000-00-00')(
          null,
          <time>
            {text.slice(0, 10)}
            <br />
            {text.slice(10)}
          </time>,
        ),
    },
    {
      title: '总流水',
      dataIndex: 'total_amount',
      width: 150,
    },
    {
      title: '注册时间',
      dataIndex: 'register_time',
      width: 150,
      render: text =>
        helpers.isJudge(text.slice(0, 10) === '0000-00-00')(
          null,
          <time>
            {text.slice(0, 10)}
            <br />
            {text.slice(10)}
          </time>,
        ),
    },
    {
      title: '最后登录时间',
      dataIndex: 'last_login_time',
      width: 150,
      render: text =>
        helpers.isJudge(text.slice(0, 10) === '0000-00-00')(
          null,
          <time>
            {text.slice(0, 10)}
            <br />
            {text.slice(10)}
          </time>,
        ),
    },
  ];

  constructor(props) {
    super(props);
    this.state = {
      pageType: this.props.match.params.id, // 页面类型
      list: [], // 客户列表
      total: 0, // 总条数
      page: {
        size: helpers.isJudge(
          localStorage.getItem('customersPageSize') === null,
        )(100, localStorage.getItem('customersPageSize')), // 用户列表的每页大小
        num: 1, // 用户列表的当前页数
      },
      // 过滤对象组
      filterObj: {
        ...this.filterObj,
      },
      selectedRowKeys: [], // 选中得id列表
    };
  }

  // 用于分页时可以使用上次的筛选操作
  public filterObj: any = {
    username: '', // 过滤的名称
    deposit_count: '', // 过滤的存款次数
    created_at_start: '', // 导入开始日期
    created_at_end: '', // 导入开始日期
    register_time_start: '', // 注册时间范围开始
    register_time_end: '', // 注册时间范围截至
    phone: '', // 过滤的手机号
    last_login_day: -1, // 过滤的最后登录天
  };

  public componentWillReceiveProps(nextProps) {
    // // 绑定列表
    this.setState({
      list: nextProps.all.externalCustomers.listDetail,
      total: nextProps.all.externalCustomers.totalDetail,
    });

    // 如果自动更新次数增加则更新列表
    if (
      this.autoUpdateTimes !== nextProps.all.externalCustomers.autoUpdateTimes
    ) {
      this.autoUpdateTimes = nextProps.all.externalCustomers.autoUpdateTimes;
      this.filterSelect();
    }
  }

  // 获取客户信息
  public getCustomers = () => {
    const obj = Object.assign({}, this.state.filterObj);
    if (obj.last_login_day === -1) {
      delete obj.last_login_day;
    }
    if (obj.source_id === -1) {
      delete obj.source_id;
    }
    if (obj.deposit_count === -1) {
      delete obj.deposit_count;
    }
    this.props.dispatch({
      type: 'customers/getExternalCustomersDetailEpic',
      payload: {
        page_size: parseInt(this.state.page.size, 10),
        page: this.state.page.num,
        ...obj,
      },
    });
  };

  // 过滤查询
  public filterSelect = () => {
    this.filterObj = this.state.filterObj; // 保存被筛选的条件

    this.setState(
      { page: { num: 1, size: this.state.page.size } },
      this.getCustomers,
    );
  };

  // 通过之前的筛选条件获取任务
  public getListBeforeFilter = () => {
    localStorage.setItem('customersPageSize', this.state.page.size);
    const obj = Object.assign({}, this.filterObj);
    if (obj.last_login_day === -1) {
      delete obj.last_login_day;
    }
    if (obj.source_id === -1) {
      delete obj.source_id;
    }
    if (obj.deposit_count === -1) {
      delete obj.deposit_count;
    }
    this.props.dispatch({
      type: 'customers/getExternalCustomersDetailEpic',
      payload: {
        page_size: parseInt(this.state.page.size, 10),
        page: this.state.page.num,
        ...obj,
      },
    });
  };

  // 选择注册时间
  public selectRegisterDate = dateString => {
    this.setState({
      filterObj: {
        ...this.state.filterObj,
        register_time_start: dateString[0],
        register_time_end: dateString[1],
      },
    });
  };

  // // 选择导入时间
  public selectCreatedDate = dateString => {
    this.setState({
      filterObj: {
        ...this.state.filterObj,
      },
    });
  };

  // 筛选重置
  public handleFilterReset = () => {
    this.setState({
      filterObj: {
        username: '', // 过滤的名称
        deposit_count: '', // 过滤的存款次数
        created_at_start: '', // 导入开始日期
        created_at_end: '', // 导入开始日期
        register_time_start: '', // 注册时间范围开始
        register_time_end: '', // 注册时间范围截至
        phone: '', // 过滤的手机号
        last_login_day: -1, // 过滤的最后登录天
      },
    });
  };

  public autoUpdateTimes = 0; // 自动更新次数

  // 获取客户列表
  public componentWillMount() {
    this.autoUpdateTimes = this.props.state.autoUpdateTimes; // 初始化自动更新次数

    this.getCustomers();
    // 初始化提示
  }

  // 批量删除
  public confirmDelete() {
    const self = this;
    const id = window.location.pathname.substring(
      window.location.pathname.lastIndexOf('/') + 1,
    );
    confirm({
      title: '您确定删除选中的名单吗？',
      onOk() {
        self.props.dispatch({
          type: 'customers/removeExternalCustomersEpic',
          payload: {
            customers_id: self.state.selectedRowKeys.join(','),
            import_record_id: id,
          },
        });

        // 清空选中
        self.setState({
          selectedRowKeys: [],
        });
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  }

  // 渲染按钮列表
  public renderBtnList = () => {
    // 按钮权限控制
    const buttonPermission = this.props.all.main.buttonPermission;
    const delFlag = _.includes(buttonPermission, 106);
    return (
      <p>
        {helpers.isJudge(delFlag)(
          <Button className={'redBtn2'} onClick={() => this.confirmDelete()}>
            批量删除
          </Button>,
          null,
        )}
      </p>
    );
  };

  // 筛选模块渲染
  public renderFilter = () => {
    const dateFormat = 'YYYY/MM/DD';
    const imported_at = localStorage.getItem('imported_at') || '';
    return (
      <div>
        <p>
          <span className={'notWrap'}>
            存款次数：
            <Select
              value={this.state.filterObj.deposit_count}
              onChange={value =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    deposit_count: value,
                  },
                })
              }
            >
              <Option value={''}>不限</Option>
              <Option value={0}>0次</Option>
              <Option value={1}>1次</Option>
              <Option value={2}>1次以上</Option>
            </Select>
          </span>
          <span className={'notWrap'}>
            注册时间 :
            <RangePicker
              onChange={(date, dateString) =>
                this.selectRegisterDate(dateString)
              }
              value={helpers.isJudge(
                this.state.filterObj.register_time_start === '',
              )(null, [
                moment(this.state.filterObj.register_time_start, dateFormat),
                moment(this.state.filterObj.register_time_end, dateFormat),
              ])}
            />
          </span>

          <span className={'notWrap'}>
            未登录时长：
            <Button
              type={helpers.isJudge(this.state.filterObj.last_login_day === -1)(
                'primary',
                null,
              )}
              onClick={() =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    last_login_day: -1,
                  },
                })
              }
            >
              不限
            </Button>
            <Button
              type={helpers.isJudge(this.state.filterObj.last_login_day === 3)(
                'primary',
                null,
              )}
              onClick={() =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    last_login_day: 3,
                  },
                })
              }
            >
              ≥3天
            </Button>
            <Button
              type={helpers.isJudge(this.state.filterObj.last_login_day === 7)(
                'primary',
                null,
              )}
              onClick={() =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    last_login_day: 7,
                  },
                })
              }
            >
              ≥7天
            </Button>
            <Button
              type={helpers.isJudge(this.state.filterObj.last_login_day === 15)(
                'primary',
                null,
              )}
              onClick={() =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    last_login_day: 15,
                  },
                })
              }
            >
              ≥15天
            </Button>
            <Input
              addonBefore={'≥'}
              placeholder={'请输入天数'}
              className={'num'}
              value={helpers.isJudge(
                this.state.filterObj.last_login_day === -1,
              )('', this.state.filterObj.last_login_day)}
              onChange={e =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    last_login_day: helpers.isJudge(
                      _.isNaN(parseInt(e.target.value, 10)),
                    )(-1, parseInt(e.target.value, 10)),
                  },
                })
              }
            />
          </span>
          <span className={'notWrap'}>
            导入时间：
            <Input value={imported_at} disabled />
          </span>
          <span className={'notWrap'}>
            用户名：
            <Input
              placeholder={'请输入用户名'}
              value={this.state.filterObj.username}
              onChange={e =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    username: e.target.value,
                  },
                })
              }
            />
          </span>
          <span className={'notWrap'}>
            手机号码：
            <Input
              placeholder={'请输入手机号码'}
              value={this.state.filterObj.phone}
              onChange={e => {
                if (
                  _.isNaN(parseInt(e.target.value, 10)) &&
                  e.target.value !== ''
                ) {
                  return;
                } else if (
                  parseInt(e.target.value, 10) < 0 &&
                  e.target.value !== ''
                ) {
                  return;
                }

                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    phone: e.target.value,
                  },
                });
              }}
            />
          </span>
          <span className={'notWrap'}>
            <Button type={'primary'} onClick={this.filterSelect}>
              筛选
            </Button>
            <Button
              type={'primary'}
              className={'pBtn'}
              onClick={this.handleFilterReset}
            >
              重置
            </Button>
          </span>
        </p>
      </div>
    );
  };

  public rowKeys: any = []; // 行的key
  // 列表模块渲染
  public renderList = () => {
    // 选择框
    const rowSelection = {
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRowKeys });
      },
    };

    return (
      <React.Fragment>
        <Table
          columns={this.listColumns}
          dataSource={this.state.list}
          pagination={false}
          rowKey={'id'}
          scroll={{
            x: helpers.isJudge(this.state.list.length > 0)(2000, null),
            y: 450,
          }}
          rowSelection={rowSelection}
        />
        <Pagination
          pageSize={parseInt(this.state.page.size, 10)}
          showQuickJumper
          showSizeChanger
          onShowSizeChange={(page, size) => {
            this.setState({ page: { num: 1, size } }, this.getListBeforeFilter);
          }}
          total={this.state.total}
          onChange={(page, size) => {
            this.setState(
              { page: { num: page, size } },
              this.getListBeforeFilter,
            );
          }}
          pageSizeOptions={['10', '20', '50', '100', '200', '500']}
          current={this.state.page.num}
          hideOnSinglePage={this.state.total === 0}
        />
      </React.Fragment>
    );
  };

  public render() {
    return (
      <React.Fragment>
        {helpers.isJudge(this.state.selectedRowKeys.length > 0)(
          this.renderBtnList(),
          null,
        )}
        {this.renderFilter()}
        {this.renderList()}
      </React.Fragment>
    );
  }
}
