import * as _ from 'lodash';
import { Action } from '../reducers/reducerInterface';

const initialStore = {
  callNote: [], // 坐席列表
  autoUpdateListTimes: 0 // 自动更新列表次数
};

export type AppStoreType = typeof initialStore;

export default function callNoteReducer(
  state: any = initialStore,
  action: Action
) {
  try {
    switch (action.type) {
      case 'reports/getCallNote': {
        // 回访备注列表
        const callNote = action.payload;
        return _.assign({}, state, { callNote });
      }
      case 'reports/autoUpdateListTimes': {
        // 自动更新列表次数
        return _.assign({}, state, {
          autoUpdateListTimes: state.autoUpdateListTimes + 1
        });
      }
      default:
        return state;
    }
  } catch (e) {
    return state;
  }
}
