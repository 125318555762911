import { request, servicPath } from '../utils';
import {
  createFormDataPostRequest,
  createGetRequestByQuery,
  createGetRequestByQueryForDownload,
  createDelRequestByQuery,
  createFormDataPutRequest
} from './requestTransfer';
/**
 * 创建任务
 * @param {object} params
 */
export function handleCreateTask(params) {
  return request(
    createFormDataPostRequest(`${servicPath}/products/tasks`, params)
  );
}

/**
 * 分配任务
 * @param {object} params
 */
export function handleAssignTask(params) {
  return request(
    createFormDataPostRequest(`${servicPath}/products/tasks/assign`, params)
  );
}

/**
 * 获取任务列表
 * @param {object} params
 */
export function handleGetTask(params) {
  return request(
    createGetRequestByQuery(`${servicPath}/products/tasks`, params)
  );
}

/**
 * 删除任务
 */
export function handleDeleteTask(params) {
  return request(
    createDelRequestByQuery(`${servicPath}/products/tasks/${params.id}`)
  );
}

/**
 * 查看任务详情
 */
export function handleGetTaskDetails(params) {
  return request(
    createGetRequestByQuery(`${servicPath}/products/tasks/${params.id}`)
  );
}

/**
 * 启动任务
 */
export function handleRunTask(params) {
  return request(
    createFormDataPutRequest(
      `${servicPath}/products/tasks/runtask/${params.id}`,
      params
    )
  );
}

/**
 * 暂停任务
 */
export function handlePauseTask(params) {
  return request(
    createFormDataPutRequest(
      `${servicPath}/products/tasks/pausetask/${params.id}`,
      params
    )
  );
}

/**
 * 下载
 */
export function handleGetTaskDownloadUrl(params) {
  return request(
    createGetRequestByQueryForDownload(
      `${servicPath}/products/tasks/export/${params.id}`
    )
  );
}

/**
 * 查看任务分配详情
 */
export function handleGetTaskAssignmentsDetails(params) {
  return request(
    createGetRequestByQuery(
      `${servicPath}/products/tasks/details/${params.id}`,
      params
    )
  );
}
