import { request, servicPath } from '../utils';
import {
  createGetRequestByQuery,
  createFormDataPostRequest
} from './requestTransfer';

/**
 * 获取短信记录
 */
export function handleGetSMS(params) {
  return request(
    createGetRequestByQuery(`${servicPath}/products/smsrecords`, params)
  );
}

/**
 * 批量发送短信
 */
export function handleBatchSendSms(params) {
  return request(
    createFormDataPostRequest(
      `${servicPath}/products/systems/batchresend`,
      params
    )
  );
}
