export const menuData = [
  {
    title: '呼叫中心',
    icon: 'phone',
    url: '/main/callCenter',
    show: false,
    switchShow: true,
    child: [
      {
        title: '群呼任务',
        url: '/groupCallTask',
        show: false,
        child: [
          {
            title: '外呼任务详情',
            url: '/*',
          },
        ],
      },
      {
        title: '新注册未存款',
        url: '/newRegistration',
        show: false,
        child: [
          {
            title: '拨号',
            url: '/*',
          },
        ],
      },
      {
        title: '已注册未存款',
        url: '/membersWithoutDeposit',
        show: false,
        child: [
          {
            title: '拨号',
            url: '/*',
          },
        ],
      },
      {
        title: '沉睡',
        url: '/sleeping',
        show: false,
        child: [
          {
            title: '拨号',
            url: '/*',
          },
        ],
      },
      {
        title: '存提受阻',
        url: '/blocked',
        show: false,
        child: [
          {
            title: '拨号',
            url: '/*',
          },
        ],
      },
      {
        title: '群维护',
        url: '/groupMaintenance',
        show: false,
        child: [
          {
            title: '拨号',
            url: '/*',
          },
        ],
      },
      {
        title: '外部名单',
        url: '/externalCustomers',
        show: false,
        child: [
          {
            title: '拨号',
            url: '/*',
          },
        ],
      },
    ],
  },
  {
    title: '客户管理',
    icon: 'team',
    url: '/main/customers',
    show: false,
    switchShow: false,
    child: [
      {
        title: '名单库',
        url: '/customers',
        show: false,
      },
      {
        title: '外部导入列表',
        url: '/externalCustomers',
        show: false,
        child: [
          {
            title: '外部导入详情',
            url: '/*',
          },
        ],
      },
      {
        title: '任务列表',
        url: '/tasks',
        show: false,
        child: [
          {
            title: '分配详情',
            url: '/*',
          },
        ],
      },
    ],
  },
  {
    title: '报表监测',
    icon: 'project',
    url: '/main/reports',
    show: false,
    switchShow: false,
    child: [
      {
        title: '通话流水',
        url: '/calls',
        show: false,
      },
      {
        title: '短信流水',
        url: '/sms',
        show: false,
      },
      {
        title: '坐席监控',
        url: '/agent',
        show: false,
      },
      {
        title: '绩效统计1',
        url: '/performance',
        show: false,
      },
      {
        title: '话务统计',
        url: '/telStatistics',
        show: false,
        child: [
          {
            title: '详情',
            url: '/*',
          },
        ],
      },
      {
        title: '绩效统计',
        url: '/performStatistics',
        show: false,
      },
      {
        title: '绩效参考',
        url: '/performReference',
        show: false,
      },
    ],
  },
  {
    title: '系统管理',
    icon: 'setting',
    url: '/main/system',
    show: false,
    switchShow: false,
    child: [
      {
        title: '系统用户管理',
        url: '/user',
        show: false,
      },
      {
        title: '权限配置',
        url: '/roles',
        show: false,
      },
      // {
      //   title: '标签列表',
      //   url: '/tags',
      //   show: false,
      // },
      {
        title: '营销账号列表',
        url: '/marketingAccounts',
        show: false,
      },
    ],
  },
  {
    title: '个人资料',
    icon: 'setting',
    url: '/main/personal',
    show: false,
    switchShow: false,
    child: [
      {
        title: '个人资料编辑',
        url: '/edit',
        show: false,
      },
    ],
  },
];
