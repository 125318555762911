import { request, servicPath } from '../utils';
import {
  createFormDataPostRequest,
  createFormDataPutRequest,
  createGetRequestByQuery,
} from './requestTransfer';

/**
 * 获取所有坐席
 */
export function handleAgentList() {
  return request(createGetRequestByQuery(`${servicPath}/mixcall/monitoring`));
}

/**
 * 监听
 */
export function handleAgentSpy(params) {
  return request(createGetRequestByQuery(`${servicPath}/mixcall/spy`, params));
}
