import * as _ from 'lodash';
import { Action } from './reducerInterface';

const initialStore = {
  list: [], // 列表
  detail: {},
  total: 0, // 任务总数
  listDetail: [], // 列表
  totalDetail: 0, // 任务总数
  autoUpdateTimes: 0 // 更新次数
};

export type AppStoreType = typeof initialStore;

export default function tasksReducer(
  state: any = initialStore,
  action: Action
) {
  try {
    switch (action.type) {
      case 'customers/externalCustomers': {
        const list = action.payload.list;
        const total = action.payload.total;
        return _.assign({}, state, { list, total });
      }
      case 'customers/getExternalCustomersDetail': {
        const listDetail = action.payload.list;
        const totalDetail = action.payload.total;
        return _.assign({}, state, { listDetail, totalDetail });
      }
      case 'customers/getExternalCustomersDetailReducer': {
        const detail = action.payload;
        return _.assign({}, state, { detail });
      }
      case 'customers/updateExternalCustomers': {
        return _.assign({}, state, {
          autoUpdateTimes: state.autoUpdateTimes + 1
        });
      }
      default:
        return state;
    }
  } catch (e) {
    return state;
  }
}
