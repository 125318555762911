import * as _ from 'lodash';
import { history } from '../router';
import { Action } from '../reducers/reducerInterface';
import { ls } from '../utils';

const initialStore = {
  marketingAccounts: [] // 营销账号列表
};

export type AppStoreType = typeof initialStore;

export default function marketingAccountReducer(
  state: any = initialStore,
  action: Action
) {
  try {
    switch (action.type) {
      case 'system/marketingAccounts/getMarketingAccounts': {
        const marketingAccounts = action.payload;
        return _.assign({}, state, { marketingAccounts });
      }
      case 'system/marketingAccounts/addMarketingAccounts': {
        const marketingAccounts = [...state.marketingAccounts, action.payload];
        return _.assign({}, state, { marketingAccounts });
      }
      case 'system/marketingAccounts/deleteMarketingAccounts': {
        const marketingAccounts = state.marketingAccounts.filter(
          obj => obj.id !== action.payload
        );
        return _.assign({}, state, { marketingAccounts });
      }
      case 'system/marketingAccounts/updateMarketingAccounts': {
        let marketingAccounts = state.marketingAccounts.filter(
          obj => obj.id !== action.payload.id
        );

        marketingAccounts = [action.payload, ...marketingAccounts].sort(
          (a, b) => a.id - b.id
        );
        return _.assign({}, state, { marketingAccounts });
      }
      default:
        return state;
    }
  } catch (e) {
    return state;
  }
}
