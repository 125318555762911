import { Observable, pipe, of, from } from 'rxjs';

import { map, mergeMap, concatMap } from 'rxjs/operators';
import { Epic, ofType } from 'redux-observable';
import { helpers } from '@/utils';
import { callNoteAPI, callCenterAPI } from '@/services';
import { message, Modal } from 'antd';
import { history } from '@/router';

interface Payload {
  [random: string]: any;
}
// dispatch传进来的action类型
interface PayloadIn {
  type: string;
  payload: {
    [random: string]: any;
  };
}

// epic传到reducer里的action类型
interface PayloadOut {
  type: string;
  payload: {
    [random: string]: any;
  };
}

// 获取回访备注
export const getCallNote: Epic<PayloadIn, PayloadOut> = (action$, store) => {
  return action$.pipe(
    ofType('reports/getCallNoteEpic'),
    mergeMap((action) => {
      return callNoteAPI.handleGetCallNote(action.payload).pipe(
        map((res) => {
          if (res !== null) {
            return helpers.createAction<Payload>('reports/getCallNote', res);
          }
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};

// 添加回访备注
export const addCallNote: Epic<PayloadIn, PayloadOut> = (action$, store) => {
  return action$.pipe(
    ofType('reports/addCallNoteEpic'),
    mergeMap((action) => {
      return callNoteAPI.handleAddCallNote(action.payload).pipe(
        map((res) => {
          if (res !== null) {
            console.log(res);
            if (action.payload.status === 1) {
              const obj = getListObj();
              // 获取呼叫中心列表
              return helpers.createAction<Payload>('CALLCENTER/TASKSEPIC', {
                ...obj,
                customerId: action.payload.customer_id,
              });
            } else {
              Modal.warning({
                title: '提示',
                content: '回访备注提交成功',
              });
            }
          }
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};

// 获取某条信息
export const getCallOne: Epic<PayloadIn, PayloadOut> = (action$, store) => {
  return action$.pipe(
    ofType('CALLCENTER/TASKSEPIC'),
    mergeMap((action) => {
      return callCenterAPI.handleGetCallCenterList(action.payload).pipe(
        map((res) => {
          if (res !== null) {
            if (res.data) {
              return helpers.createAction<Payload>(
                'CUSTOMERS/AFTERGETCALLONE',
                res,
              );
            } else {
              message.success(
                '来自产品经理的提示：别点了！最后1个名单已被你消灭！辛苦了，去喝点水，休息一下吧~',
                3,
              );
            }
          }
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
    pipe(
      ofType('CUSTOMERS/AFTERGETCALLONE'),
      concatMap((action: any) => {
        const res = action.payload.data;
        const tagId = getListObj().tag_id || 0;
        const requestArr = [
          helpers.createAction<Payload>('callCenter/callEpic', {
            id: res.customer.id,
            tag_id: tagId, // 标签id
            relationshipId: res.id, // 用户客户关系id
            task_id: res.task_id, // 任务id
          }),
          helpers.createAction<Payload>('customers/getCustomersDetailsEpic', {
            id: res.customer.id,
          }),
        ];
        return from(requestArr);
        // 呼叫;
        // this.props.dispatch({
        //   type: 'callCenter/callEpic',
        //   payload: {
        //     id,
        //     tag_id: this.tagsId[this.typeName], // 标签id
        //     relationshipId, // 用户客户关系id
        //     task_id, // 任务id
        //   },
        // });
        // 用户信息
      }),
    ),
  );
};

// export const afterGetCallOne: Epic<PayloadIn, PayloadOut> = (
//   action$,
//   store,
// ) => {
//   return action$.pipe(
//     ofType('CUSTOMERS/AFTERGETCALLONE'),
//     concatMap((action: any) => {
//       const res = action.payload.data;
//       console.log(action);
//       console.log(res);
//       const requestArr = [
//         helpers.createAction<Payload>('callCenter/callEpic', {
//           id: res.data.customer.id,
//           tag_id: res.data.customer.tag_id, // 标签id
//           relationshipId: res.data.customer.id, // 用户客户关系id
//           task_id: res.data.customer.task_id, // 任务id
//         }),
//         helpers.createAction<Payload>('customers/getCustomersDetailsEpic', {
//           id: res.data.customer.id,
//         }),
//       ];
//       return from(requestArr);
//       // 呼叫;
//       // this.props.dispatch({
//       //   type: 'callCenter/callEpic',
//       //   payload: {
//       //     id,
//       //     tag_id: this.tagsId[this.typeName], // 标签id
//       //     relationshipId, // 用户客户关系id
//       //     task_id, // 任务id
//       //   },
//       // });
//       // 用户信息
//     }),
//   );
// };

// 修改回访备注
export const updateCallNote: Epic<PayloadIn, PayloadOut> = (action$, store) => {
  return action$.pipe(
    ofType('reports/updateCallNoteEpic'),
    mergeMap((action) => {
      return callNoteAPI.handleUpdateCallNote(action.payload).pipe(
        map((res) => {
          if (res !== null) {
            return helpers.createAction<Payload>(
              'reports/autoUpdateListTimes',
              {},
            );
          }
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};

// 删除回访备注
export const deleteCallNote: Epic<PayloadIn, PayloadOut> = (action$, store) => {
  return action$.pipe(
    ofType('reports/deleteCallNoteEpic'),
    mergeMap((action) => {
      return callNoteAPI.handleDeleteCallNote(action.payload).pipe(
        map((res) => {
          if (res !== null) {
            return helpers.createAction<Payload>(
              'reports/autoUpdateListTimes',
              {},
            );
          }
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};

const getListObj = () => {
  try {
    return JSON.parse(sessionStorage.getListObj);
  } catch (error) {
    return {};
  }
};
