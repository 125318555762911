import * as React from 'react';
import { Icon, Modal } from 'antd';
const confirm = Modal.confirm;
import { helpers } from '@/utils';
import callStyle from './callStyle.scss';
import EditRemarkPage from './editRemarkPage';
import { multiPlatform } from '@/multiPlatform';
interface TextProps {
  [random: string]: any;
}
interface TextState {
  [random: string]: any;
}
import * as _ from 'lodash';
export default class RemarkList extends React.PureComponent<
  TextProps,
  TextState
> {
  constructor(props) {
    super(props);
    this.state = {
      list: [], // 回访备注列表
      marketStatus: [], // 行销状态列表
      userId: -1, // 用户id
      editData: {}, // 编辑的信息
      showEditTimes: 0 // 显示编辑次数
    };
  }

  // 编辑备注反馈页面组件
  public EditRemarkPageComponent = multiPlatform.editRemarkMulti(
    EditRemarkPage
  );

  public autoUpdateListTimes = 0; // 自动更新列表次数

  // 打开编辑窗口
  public openEditWindow = (item: any) => {
    this.setState({
      editData: item,
      showEditTimes: this.state.showEditTimes + 1
    });
  };

  public componentWillMount() {
    this.getCallNote();

    // 赋值初始化
    this.autoUpdateListTimes = this.props.state.autoUpdateListTimes;
  }

  // 获取回访备注
  public getCallNote = () => {
    // 是否为受阻参数
    let is_obstructed = 0;
    if (location.pathname.indexOf('blocked') !== -1) {
      is_obstructed = 1;
    }

    this.props.dispatch({
      type: 'reports/getCallNoteEpic',
      payload: {
        id: this.props.id,
        is_obstructed
      }
    });
  };

  public componentWillReceiveProps(nextProps) {
    // 绑定用户id
    if (
      nextProps.all.main.userInfo.id !== undefined &&
      nextProps.all.main.userInfo.id !== this.state.id
    ) {
      this.setState({
        userId: nextProps.all.main.userInfo.id
      });
    }

    // 绑定回访备注列表
    this.setState({ list: nextProps.state.callNote });

    // 绑定营销状态
    if (
      nextProps.all.marketStatus.marketStatus.length !== 0 &&
      this.state.marketStatus.length === 0
    ) {
      this.setState({ marketStatus: nextProps.all.marketStatus.marketStatus });
    }

    // 自动更新列表
    if (this.autoUpdateListTimes !== nextProps.state.autoUpdateListTimes) {
      this.autoUpdateListTimes = nextProps.state.autoUpdateListTimes;
      this.getCallNote();
    }
  }

  // 删除备注
  public confirmDelete(deleteId: number) {
    const self = this;
    confirm({
      title: '您确定要删除该备注吗？',
      onOk() {
        self.props.dispatch({
          type: 'reports/deleteCallNoteEpic',
          payload: {
            id: deleteId
          }
        });
      },
    });
  }

  public render() {
    const EditRemarkPageComponent = this.EditRemarkPageComponent;

    return (
      <div className={callStyle.block3}>
        <div>
          {this.state.list.map(item => {
            return (
              <div>
                <p>
                  <span className={callStyle.title}>
                    {item.user_name} / {item.employee_id}
                  </span>
                  <span className={callStyle.time}>{item.updated_at}</span>
                </p>
                <p style={{ wordBreak: 'break-all' }}>
                  {helpers.isJudge(item.next_date === '0000-00-00')(
                    '',
                    `下次回访：${item.next_date}，`
                  )}
                  {item.note}
                </p>
                <p>
                  <span className={callStyle.type}>
                    {helpers.isJudge(this.state.marketStatus.length === 0)(
                      null,
                      this.state.marketStatus.filter(
                        n => n.id === item.market_status_id
                      )[0].name
                    )}
                  </span>
                  <span className={callStyle.operating}>
                    {helpers.isJudge(this.state.userId === item.user_id)(
                      <React.Fragment>
                        <Icon
                          type={'edit'}
                          theme={'filled'}
                          onClick={() =>
                            this.openEditWindow({
                              id: item.id,
                              next_date: item.next_date,
                              note: item.note,
                              market_status_id: item.market_status_id
                            })
                          }
                        />
                        <Icon
                          type={'delete'}
                          theme={'filled'}
                          onClick={() => this.confirmDelete(item.id)}
                        />
                      </React.Fragment>,
                      null
                    )}
                  </span>
                </p>
              </div>
            );
          })}
        </div>
        <EditRemarkPageComponent
          editData={this.state.editData}
          showEditTimes={this.state.showEditTimes}
        />
      </div>
    );
  }
}
