import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { history } from '@/router';
import { helpers } from '@/utils';
import * as _ from 'lodash';
import { multiPlatform } from '@/multiPlatform';
import {
  ContainerPropsInterface,
  ContainerStateInterface,
} from '@/routes/containerInterface';

import { LeftMenu, TitleBlock, SwitchMenu } from '@/components/index';
import {
  User,
  Customers,
  CustomersTasks,
  Tasks,
  PersonalEdit,
  Tags,
  Roles,
  MarketingAccounts,
  BlockedTasks,
  Call,
  Calls,
  SMS,
  Performance,
  externalCustomers,
  externalCustomersDetail,
  AssignmentsDetailed,
  TaskPage,
  GroupCallTask,
  GroupCallTaskDetail,
  TelStatistics,
  PerformReference,
  PerformStatistics,
  TelDetail,
} from '@/routes/index';

import mainStyle from './main.scss';
import { Menu, Icon, Button, Dropdown, Modal } from 'antd';
import AgentBtn from './agentBtnPage';
import { menuData } from './data';

export default class AppPage extends React.PureComponent<
  ContainerPropsInterface<any>,
  ContainerStateInterface
> {
  // 左菜单数据列表
  public menuList = menuData;

  constructor(props) {
    super(props);
    const tmp = localStorage.getItem('leftMenuExpand');
    this.state = {
      menuList: this.menuList, // 菜单列表
      pathname: location.pathname, // 当前路径
      currentUrl: '',
      userName: '', // 用户名
      agentName: undefined, // 坐席名
      showLeftMenu: false, // 显示左菜单
      collapsed: tmp === null ? false : tmp === '0', // 是否为缩小菜单
    };
  }

  // 组件列表
  public componentList: any = {
    user: multiPlatform.userMulti(User),
    tags: multiPlatform.tagsMulti(Tags),
    roles: multiPlatform.rolesMulti(Roles),
    marketingAccounts: multiPlatform.marketingAccountsMulti(MarketingAccounts),
    customers: multiPlatform.customersMulti(Customers),
    tasks: multiPlatform.tasksMulti(Tasks),
    blockedTasks: multiPlatform.tasksMulti(BlockedTasks),
    call: multiPlatform.callMulti(Call),
    customersTasks: multiPlatform.customersTasksMulti(CustomersTasks),
    groupCallTask: multiPlatform.groupCallTaskMulti(GroupCallTask),
    groupCallTaskDetail: multiPlatform.groupCallTaskDetailMulti(
      GroupCallTaskDetail,
    ),
    agentBtn: multiPlatform.agentBtnMulti(AgentBtn),
    personalEdit: multiPlatform.personalEditMulti(PersonalEdit),
    reportCallsMulti: multiPlatform.reportCallsMulti(Calls),
    reportSmsMulti: multiPlatform.reportSmsMulti(SMS),
    reportTelDetail: multiPlatform.reportTelDetail(TelDetail),
    externalCustomers: multiPlatform.externalCustomersMulti(externalCustomers),
    externalCustomersDetail: multiPlatform.externalCustomersDetailedMulti(
      externalCustomersDetail,
    ),
    assignD: multiPlatform.assignmentsDetailedMulti(AssignmentsDetailed),
    task: multiPlatform.tManagerMulti(TaskPage),
    telStats: multiPlatform.telStatMulti(TelStatistics),
    perStats: multiPlatform.perStatMulti(PerformStatistics),
    perRefer: multiPlatform.perRerMulti(PerformReference),
  };

  public componentWillMount() {
    this.getLoginUserinfo(); // 获取登录用户信息
  }

  // 获取登录用户信息
  public getLoginUserinfo = () => {
    this.props.dispatch({
      type: 'main/getLoginInfoEpic',
    });
  };

  // 菜单权限数据处理
  public formatData(data) {
    const arr: any = [];
    let currentNum = 0; // 控制获取当前第一菜单路由
    if (data.length === 0) {
      // 处理render渲染为空的情况
      return;
    }
    // 清空数据
    // const showMenu = ['群呼任务'];
    this.state.menuList.forEach(menu => {
      menu.show = false;
      if (menu.child.length > 0) {
        menu.child.forEach(menuChildren => {
          menuChildren.show = false;
          // if (showMenu.indexOf(menuChildren.title) > -1) {
          //   menuChildren.show = true;
          // }
        });
      }
    });
    this.state.menuList.forEach(menu => {
      data.forEach(item => {
        // 判断对比一级菜单是否有权限
        if (item.name.toString() === menu.title.toString()) {
          menu.show = true;
          if (item.children && item.children.length > 0) {
            item.children.forEach(itemChildren => {
              menu.child.forEach(menuChildren => {
                // 判断对比二级级菜单是否有权限
                if (
                  itemChildren.name.toString() === menuChildren.title.toString()
                ) {
                  currentNum = currentNum + 1;
                  if (currentNum === 1) {
                    // 判断第一次获取二级菜单记录url
                    if (location.pathname === '/main') {
                      history.push(menu.url + menuChildren.url);
                    }
                  }
                  menuChildren.show = true;
                }

                if (
                  (menuChildren.title === '群呼任务' ||
                    menuChildren.title === '坐席监控') &&
                  menuChildren.show
                ) {
                  // 群呼任务和坐席监控只有在米糠云登录下，才会显示，否则隐藏
                  // 使用title来判断具体指定模块，其实并不合理，应该为每个类目标识一个ID，通过ID来判断
                  // 因为title的变更性强，并不适合作为标识来判断，但因为这属于旧开发者遗留问题，不了解重构的影响，
                  // 所以先这样吧
                  menuChildren.show =
                    this.props.all.main.userInfo &&
                    this.props.all.main.userInfo.phone_line === 2;
                }
              });
            });
          }
          arr.push(menu);
        }
      });
    });
    this.setState({ menuList: arr, showLeftMenu: true });
  }

  public componentWillReceiveProps(nextProps) {
    // 绑定当前路径
    if (nextProps.location.pathname !== this.state.pathname) {
      this.setState({ pathname: nextProps.location.pathname });
    }
    // 绑定当前用户信息
    if (
      nextProps.state.userInfo.name !== undefined &&
      nextProps.state.userInfo.name !== this.state.userName
    ) {
      this.setState({
        userName: nextProps.state.userInfo.name,
        agentName: _.get(
          nextProps.state.userInfo.polylink_account,
          'employee_id',
        ),
      });
    }
    if (nextProps.state.userInfo.role) {
      this.formatData(nextProps.state.userInfo.role.permissions_tree);
    }
  }

  // 退出登录
  public logout = () => {
    this.props.dispatch({
      type: 'main/logoutEpic',
    });
  };

  // 退出确认
  public confirm() {
    const self = this;
    Modal.confirm({
      title: '您确定要退出吗？',
      onOk() {
        self.logout();
      },
    });
  }

  // 个人菜单
  public menu = (
    <Menu
      onClick={e => {
        if (e.key === '1') {
          history.push('/main/personal/edit');
        } else {
          this.confirm();
        }
      }}
    >
      <Menu.Item key={'1'}>
        <Icon type={'user'} />
        个人资料
      </Menu.Item>
      <Menu.Item key={'2'}>
        <Icon type={'logout'} />
        退出系统
      </Menu.Item>
    </Menu>
  );

  // 顶部菜单模块
  public renderTopNav = () => {
    const AgentBtnComponent = this.componentList.agentBtn;

    return (
      <div className={mainStyle.topNav}>
        <div>
          <AgentBtnComponent />
          {helpers.isJudge(this.state.userName === '')(
            null,
            <Dropdown overlay={this.menu}>
              <Button>
                {this.state.userName} <Icon type={'down'} />
              </Button>
            </Dropdown>,
          )}
        </div>
      </div>
    );
  };

  public render() {
    const { num } = this.props.all.callCenter;

    const badgeamount = [
      0,
      num.newRegistration,
      num.membersWithoutDeposit,
      num.sleeping,
      num.blocked,
      num.groupMaintenance,
      num.externalCustomers,
    ];
    return (
      <div className={mainStyle.main + ' main'}>
        {helpers.isJudge(this.state.showLeftMenu)(
          <LeftMenu
            menuList={this.state.menuList}
            title={'电维CRM系统'}
            logoUrl={'/main'}
            pathname={this.state.pathname}
            collapsed={this.state.collapsed}
            collapsedCB={v => {
              this.setState({ collapsed: v });
            }}
          />,
          null,
        )}
        <div
          className={
            this.state.collapsed === true ? 'contentMain0' : 'contentMain1'
          }
        >
          {this.renderTopNav()}
          <TitleBlock menuList={this.menuList} pathname={this.state.pathname} />
          <SwitchMenu
            menuList={this.state.menuList}
            pathname={this.state.pathname}
            num={badgeamount}
          />
          <div
            className={helpers.isJudge(
              location.pathname.split('/').length === 6,
            )(null, mainStyle.content)}
          >
            <Switch>
              <Route
                path={'/main/system/user'}
                component={this.componentList.user}
              />
              <Route
                path={'/main/system/tags'}
                component={this.componentList.tags}
              />
              <Route
                path={'/main/system/roles'}
                component={this.componentList.roles}
              />
              <Route
                path={'/main/system/marketingAccounts'}
                component={this.componentList.marketingAccounts}
              />
              <Route
                path={'/main/customers/customers'}
                component={this.componentList.customers}
              />
              <Route
                path={'/main/customers/tasks/:id'}
                component={this.componentList.assignD}
              />
              <Route
                path={'/main/customers/tasks'}
                component={this.componentList.customersTasks}
              />
              <Route
                path={'/main/customers/externalCustomers/:id'}
                component={this.componentList.externalCustomersDetail}
              />
              <Route
                path={'/main/customers/externalCustomers'}
                component={this.componentList.externalCustomers}
              />
              <Route
                exact
                path={'/main/callCenter/groupCallTask'}
                component={this.componentList.groupCallTask}
              />
              <Route
                path={'/main/callCenter/groupCallTask/:id'}
                component={this.componentList.groupCallTaskDetail}
              />
              <Route
                path={'/main/callCenter/:type/:id/:relationshipId'}
                component={this.componentList.call}
              />
              <Route
                path={'/main/callCenter/newRegistration'}
                component={this.componentList.tasks}
              />
              <Route
                path={'/main/callCenter/sleeping'}
                component={this.componentList.tasks}
              />
              <Route
                path={'/main/callCenter/groupMaintenance'}
                component={this.componentList.tasks}
              />
              <Route
                path={'/main/callCenter/membersWithoutDeposit'}
                component={multiPlatform.membersWithoutDepositMulti}
              />
              <Route
                path={'/main/callCenter/externalCustomers'}
                component={multiPlatform.externalCustomersCallMulti}
              />
              <Route
                path={'/main/callCenter/blocked'}
                component={this.componentList.blockedTasks}
              />
              <Route
                exact
                path={'/main/reports/telStatistics'}
                component={this.componentList.telStats}
              />
              <Route
                exact
                path={'/main/reports/telStatistics/:id'}
                component={this.componentList.reportTelDetail}
              />
              <Route
                path={'/main/reports/performStatistics'}
                component={this.componentList.perStats}
              />
              <Route
                path={'/main/reports/performReference'}
                component={this.componentList.perRefer}
              />
              <Route
                path={'/main/reports/calls'}
                component={this.componentList.reportCallsMulti}
              />
              <Route
                path={'/main/reports/sms'}
                component={this.componentList.reportSmsMulti}
              />
              <Route
                path={'/main/reports/agent'}
                component={multiPlatform.AgentMulti}
              />
              <Route
                path={'/main/reports/performance'}
                component={Performance}
              />
              <Route
                path={'/main/personal/edit'}
                component={this.componentList.personalEdit}
              />
              {/* <Route path={'/main'} component={mainCompent} /> */}
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}
