import * as React from 'react';
import { Modal, Button, Input, Form, AutoComplete, message } from 'antd';
import * as _ from 'lodash';
import { helpers } from '@/utils';
import {
  ContainerPropsInterface,
  ContainerStateInterface
} from '@/routes/containerInterface';

class MarketingAccountsAdd extends React.PureComponent<
  ContainerPropsInterface<any>,
  ContainerStateInterface
> {
  // 用户列表
  public userList: any = [];

  // 营销账户类型列表
  public accountType: any = [];

  // 营销账户列表
  public marketingAccounts: any = [];

  constructor(props) {
    super(props);
    this.state = {
      visible: false, // 是否显示营销账户添加窗口
      userList: [], // 用列表
      accountType: [] // 营销账户类型列表
    };
  }

  public handleAdd = () => {
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }

      if (!this.userList.map(item => item.name).includes(values.belong_user)) {
        message.error('账号所属人不存在');
        return;
      }

      this.props.form.setFieldsValue({
        account_type: '',
        account_no: '',
        belong_user: ''
      });
      this.setState({
        visible: false,
        intro: ''
      });

      const belong_user = this.userList.filter(
        item => item.name === values.belong_user
      )[0].id;

      // 添加营销账户
      this.props.dispatch({
        type: 'system/marketingAccounts/addMarketingAccountsEpic',
        payload: {
          account_type: helpers.filter.trimStr(values.account_type),
          account_no: helpers.filter.trimStr(values.account_no),
          belong_user,
          belong_user_name: values.belong_user
        }
      });
    });
  };

  public componentWillReceiveProps(nextProps) {
    // 绑定营销账户列表
    if (
      nextProps.state.marketingAccounts.length !== 0 &&
      !_.isEqual(nextProps.state.marketingAccounts, this.marketingAccounts)
    ) {
      this.marketingAccounts = nextProps.state.marketingAccounts;
      // 过滤出营销账户类型列表
      this.accountType = nextProps.state.marketingAccounts
        .map(item => item.account_type)
        .filter((el, i, arr) => {
          return arr.indexOf(el) === i;
        });
      this.setState({
        accountType: this.accountType
      });
    }

    // 绑定用户列表
    if (
      nextProps.all.user.userList.length !== 0 &&
      this.userList.length === 0
    ) {
      this.userList = nextProps.all.user.userList;
      this.setState({ userList: nextProps.all.user.userList.map(n => n.name) });
    }
  }

  // 选择成员输入筛选
  public handleSearch = value => {
    this.setState({
      userList: helpers.isJudge(!value)(
        [],
        this.userList
          .filter(item => item.name.indexOf(value) !== -1)
          .map(n => n.name)
      )
    });
  };

  // 应用类型补全提示
  public handleSearchType = value => {
    this.setState({
      accountType: !value
        ? []
        : this.accountType.filter(item => item.indexOf(value) !== -1)
    });
  };

  // 取消隐藏营销账号添加窗口，并清空输入框
  public handleCancel = () => {
    this.props.form.setFieldsValue({
      account_type: '',
      account_no: '',
      belong_user: ''
    });

    this.setState({ visible: false });
  };

  public renderFrom = () => {
    return (
      <Modal
        title={'新增营销账号'}
        visible={this.state.visible}
        onOk={this.handleAdd}
        onCancel={this.handleCancel}
        maskClosable={false}
        width={600}
        className={'modalFrom'}
      >
        <Form.Item label={'应用类型：'}>
          {this.props.form.getFieldDecorator('account_type', {
            rules: [
              {
                required: true,
                message: '请输入应用类型名称'
              },
              {
                validator: helpers.antdFormValidators.lengthLessThan32
              }
            ]
          })(
            <AutoComplete
              dataSource={this.state.accountType}
              onSearch={this.handleSearchType}
              placeholder={'请输入应用类型名称'}
            />
          )}
        </Form.Item>
        <Form.Item label={'账号名称：'}>
          {this.props.form.getFieldDecorator('account_no', {
            rules: [
              {
                required: true,
                message: '请输入账号名称'
              }
            ]
          })(<Input placeholder={'请输入账号名称'} maxLength={32} />)}
        </Form.Item>
        <Form.Item label={'账号所属人：'}>
          {this.props.form.getFieldDecorator('belong_user', {
            rules: [
              {
                required: true,
                message: '请输入选择账号所属人'
              }
            ]
          })(
            <AutoComplete
              dataSource={this.state.userList}
              onSearch={this.handleSearch}
              placeholder={'请选择账号所属人'}
            />
          )}
        </Form.Item>
      </Modal>
    );
  };

  public render() {
    return (
      <React.Fragment>
        <Button
          type={'primary'}
          icon={'plus'}
          className={'pBtn fRight'}
          onClick={() => this.setState({ visible: true })}
        >
          新增营销账号
        </Button>
        {this.renderFrom()}
      </React.Fragment>
    );
  }
}
const WrappedMarketingAccountsAdd = Form.create()(MarketingAccountsAdd as any);
export default WrappedMarketingAccountsAdd;
