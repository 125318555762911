import * as React from 'react';
import { Select, DatePicker, Button, Input, Modal, message } from 'antd';
const { TextArea } = Input;
import { helpers } from '@/utils';
const Option = Select.Option;
import moment from 'moment';
import callStyle from './callStyle.scss';
import { InputTime } from '@/components/index';
import { TAG_ID } from '@/routes/callCenter/tasks/tasksPage';
interface TextProps {
  [random: string]: any;
}
interface TextState {
  [random: string]: any;
}
import * as _ from 'lodash';
export default class PostRemark extends React.PureComponent<
  TextProps,
  TextState
> {
  constructor(props) {
    super(props);
    this.state = {
      userName: '', // 用户名
      marketStatus: [], // 行销状态列表
      marketStatusId: undefined, // 行销状态id
      extensionState: '-1', // 坐席状态
      note: '', // 记录
      nextDate: '', // 下次日期
      agentName: undefined, // 坐席名
    };
  }

  public callRecordsId = undefined; // 通话记录id
  public userId; // 用户id

  // 时间interval
  public timeInterval;

  public componentWillMount() {
    if (this.props.all.marketStatus.marketStatus.length === 0) {
      this.getMarketStatu();
    }
  }

  // 获取营销状态
  public getMarketStatu = () => {
    this.props.dispatch({
      type: 'reports/getMarketStatusEpic',
      payload: {},
    });
  };

  // 添加通讯备注
  public addCallNote = status => {
    // 是否为受阻参数
    let is_obstructed = 0;
    if (location.pathname.indexOf('blocked') !== -1) {
      is_obstructed = 1;
    }

    this.props.dispatch({
      type: 'reports/addCallNoteEpic',
      payload: {
        call_records_id: this.state.allRecordsId,
        users_customers_id: this.props.usersCustomersId,
        customer_id: this.props.customerId,
        user_id: this.userId,
        note: this.state.note,
        next_date: this.state.nextDate,
        market_status_id: this.state.marketStatusId,
        tag_id: TAG_ID[this.props.tagName],
        is_obstructed,
        status,
      },
    });
  };

  public componentWillReceiveProps(nextProps) {
    if (
      nextProps.all.main.userInfo.name !== undefined &&
      nextProps.all.main.userInfo.name !== this.state.userName
    ) {
      this.setState({
        userName: nextProps.all.main.userInfo.name,
        agentName: _.get(
          nextProps.all.main.userInfo.mixcall_account ||
            nextProps.all.main.userInfo.polylink_account,
          'employee_id',
        ),
      });

      this.userId = nextProps.all.main.userInfo.id;
    }

    // 绑定坐席状态
    if (_.get(nextProps.all.agent.agentInfo, 'extensionState') != null) {
      this.setState({
        extensionState: nextProps.all.agent.agentInfo.extensionState,
      });
    }

    // 绑定营销状态
    if (
      nextProps.all.marketStatus.marketStatus.length !== 0 &&
      this.state.marketStatus.length === 0
    ) {
      this.setState({ marketStatus: nextProps.all.marketStatus.marketStatus });
    }

    if (nextProps.all.calls.newCall.id !== undefined) {
      this.callRecordsId = nextProps.all.calls.newCall.id;
    }
  }

  // 选择日期
  public selectDate = (date, dateString) => {
    this.setState({ nextDate: dateString });
  };

  // 重置
  public handleFilterReset = () => {
    this.setState({ marketStatusId: undefined, note: '', nextDate: '' });
  };

  // 提交
  public handleSummit = () => {
    // 如果没有坐席号，不能提交
    if (this.state.agentName === undefined) {
      Modal.warning({
        title: '提示',
        content: '请先绑定坐席号',
      });
      return;
    }

    if (this.state.note === '') {
      Modal.warning({
        title: '提示',
        content: '内容不能为空',
      });
      return;
    }

    if (this.state.marketStatusId === undefined) {
      Modal.warning({
        title: '提示',
        content: '请选择行销结果',
      });
      return;
    }

    this.addCallNote(0);

    this.setState({ note: '', marketStatusId: undefined });
  };
  public timer: any;
  public countdown = () => {
    let time = 5;
    this.setState({
      haveClick: true,
    });
    this.timer = setInterval(() => {
      time--;
      if (time === 0) {
        clearInterval(this.timer);
        this.setState({
          haveClick: false,
        });
        window.clearInterval(this.timer);
        this.timer = null;
      }
    }, 1000);
  };
  // 提交下一步
  public handleNewSummit = () => {
    // 如果没有坐席号，不能提交
    if (this.state.agentName === undefined) {
      Modal.warning({
        title: '提示',
        content: '请先绑定坐席号',
      });
      return;
    }

    if (this.state.note === '') {
      Modal.warning({
        title: '提示',
        content: '内容不能为空',
      });
      return;
    }

    if (this.state.marketStatusId === undefined) {
      Modal.warning({
        title: '提示',
        content: '请选择行销结果',
      });
      return;
    }
    if (this.state.haveClick) {
      this.countdown();
    }
    this.addCallNote(1);

    this.setState({ note: '', marketStatusId: undefined });
  };
  public getCustomersDetails = () => {
    this.props.dispatch({
      type: 'customers/getCustomersDetailsEpic',
      payload: {
        id: this.props.match.params.id,
      },
    });
  };
  // 呼叫
  // public call = (id, relationshipId, task_id) => {
  //   this.props.dispatch({
  //     type: 'callCenter/callEpic',
  //     payload: {
  //       id,
  //       tag_id: this.tagsId[this.typeName], // 标签id
  //       relationshipId, // 用户客户关系id
  //       task_id, // 任务id
  //     },
  //   });

  //   // 已处理
  //   // this.props.dispatch({
  //   //   type: 'callCenter/dealWithEpic',
  //   //   payload: {
  //   //     relationshipId, // 用户客户关系id
  //   //     task_id,
  //   //   },
  //   // });
  // };
  public render() {
    const dateFormat = 'YYYY/MM/DD';
    return (
      <div className={callStyle.block2}>
        <div>
          <p>
            当前时间：
            <InputTime />
            <span>
              处理人：<span>{this.state.userName}</span>
            </span>
          </p>
          <TextArea
            placeholder={'请将跟进到的客户信息都填写到这里......'}
            rows={12}
            onChange={e => {
              this.setState({ note: e.target.value });
            }}
            value={this.state.note}
          />
          <span>
            行销结果：
            <Select
              placeholder={'请选择'}
              onChange={value =>
                this.setState({
                  marketStatusId: value,
                })
              }
              value={this.state.marketStatusId}
            >
              {this.state.marketStatus.map(item => {
                return <Option key={item.id}>{item.name}</Option>;
              })}
            </Select>
          </span>
          <span style={{ float: 'right' }}>
            下次跟进时间：
            <DatePicker
              onChange={this.selectDate}
              value={helpers.isJudge(this.state.nextDate === '')(
                null,
                moment(this.state.nextDate, dateFormat),
              )}
            />
          </span>
          <div>
            <Button onClick={this.handleFilterReset}>重置</Button>
            <Button
              type={helpers.isJudge(this.state.agentName === undefined)(
                'dashed',
                'primary',
              )}
              onClick={this.handleSummit}
            >
              提交备注
            </Button>
            {helpers.isJudge(this.state.extensionState === 0)(
              <Button
                type={helpers.isJudge(this.state.agentName === undefined)(
                  'dashed',
                  'primary',
                )}
                style={helpers.isJudge(this.state.timeStatus)(
                  {
                    background: '#666',
                    color: '#fff',
                    border: '1px solid #666',
                  },
                  { background: '' },
                )}
                onClick={this.handleNewSummit}
                // onClick={() => this.call(record.customer_id, record.id, record.task_id)}
              >
                呼叫下一个
              </Button>,
              <Button
                type={'primary'}
                style={{
                  background: '#666',
                  color: '#fff',
                  border: '1px solid #666',
                }}
                onClick={() => message.warning('当前状态，无法呼叫')}
              >
                呼叫下一个
              </Button>,
            )}
          </div>
        </div>
      </div>
    );
  }
}
