import * as React from 'react';
import * as _ from 'lodash';
import { Modal, Input, Table, message } from 'antd';
import { helpers } from '@/utils';

interface AssignmentsEditProps {
  [random: string]: any;
}
interface AssignmentsEditState {
  [random: string]: any;
}
export default class AssignmentsEdit extends React.PureComponent<
  AssignmentsEditProps,
  AssignmentsEditState
> {
  // 表格列
  public columns: any = [
    {
      title: '坐席号',
      dataIndex: 'polylink_account',
      width: 250,
      render: (text, record) => (
        <span>{_.get(record.polylink_account, 'employee_id')}</span>
      )
    },
    {
      title: '用户名',
      dataIndex: 'name',
      width: 250
    }
  ];

  constructor(props) {
    super(props);
    this.state = {
      visible: false, // 是否显示窗口
      userList: [], // 用列表
      selectedUser: [], // 选中的用户
      amount: undefined // 分配的数据
    };
  }

  public selectedTasks: any = []; // 选中的任务
  public showTimes = 0; // 显示的次数

  // 提交
  public handleSubmit = () => {
    if (this.state.selectedUser.length === 0) {
      message.error('请选择用户');
      return;
    }

    if (this.state.amount === undefined) {
      message.error('请填写要分配的数量');
      return;
    }
    if (this.state.amount <= 0) {
      message.warning('请输入大于0的分配数量！');
      return;
    }
    if (this.state.amount > this.props.unprocessedAmount) {
      message.warning('分配数量大于未处理数量,请重新分配数量！');
      return;
    }
    this.assignTasks();
    this.setState({ visible: false, selectedUser: [], amount: undefined });
  };

  public componentWillMount() {
    // 获取用户列表
    if (this.props.all.user.userList.length === 0) {
      this.getUserList();
    }
  }

  // 获取用户列表
  public getUserList = () => {
    this.props.dispatch({
      type: 'system/getUserListEpic',
      payload: {
        page_size: 1000
      }
    });
  };

  // 分配任务
  public assignTasks = () => {
    this.props.dispatch({
      type: 'customers/assignTasksEpic',
      payload: {
        users_id: this.state.selectedUser.toString(),
        amount: this.state.amount,
        tasks_id: this.selectedTasks.toString()
      }
    });
  };

  public componentWillReceiveProps(nextProps) {
    // 绑定用户列表
    if (
      nextProps.all.user.userList.length !== 0 &&
      this.state.userList.length === 0
    ) {
      this.setState({ userList: nextProps.all.user.userList });
    }

    // 绑定选中的客户
    if (this.showTimes !== nextProps.showTimes) {
      this.showTimes = nextProps.showTimes;
      this.selectedTasks = nextProps.selectedTasks;
      this.setState({ visible: true });
    }
  }

  public randerTable = () => {
    // 选择框
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({ selectedUser: selectedRowKeys });
      },
      selectedRowKeys: this.state.selectedUser
    };

    return (
      <div className={'fromTable'}>
        <Table
          columns={this.columns}
          dataSource={this.state.userList}
          rowKey={'id'}
          pagination={false}
          scroll={{ y: 350 }}
          rowSelection={rowSelection}
        />
      </div>
    );
  };

  public render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    };
    return (
      <React.Fragment>
        <Modal
          title={'分配任务'}
          visible={this.state.visible}
          onOk={this.handleSubmit}
          maskClosable={false}
          onCancel={() =>
            this.setState({
              visible: false,
              selectedUser: [],
              amount: undefined
            })
          }
          width={600}
          className={'modalFrom modalFrom2'}
          okText={'立即分配'}
        >
          <div className={'inputHead'}>
            <div>
              未分配名单数：<span>{this.props.unprocessedAmount}</span>
            </div>
            <div style={{ textAlign: 'right' }}>
              分配数量：
              <Input
                placeholder={'请输入要分配的数量'}
                value={this.state.amount}
                onChange={e =>
                  this.setState({
                    amount: helpers.filter.number(e.target.value)
                  })
                }
              />
            </div>
          </div>
          {this.randerTable()}
        </Modal>
      </React.Fragment>
    );
  }
}
