import * as React from 'react';
import io from 'socket.io-client';
import { helpers, ls, polylink, mixphone } from '@/utils';
import { history } from '@/router';
import * as _ from 'lodash';
interface TextProps {
  [random: string]: any;
}
interface TextState {
  [random: string]: any;
}
import { changeExtensionState, changeAgentState } from '@/actions/agentAction';
import { setCallStartTime } from '@/actions/callAction';
import mainStyle from './main.scss';
import { Menu, Icon, Button, Dropdown, Modal, message } from 'antd';
let mix_extens = undefined; // 订阅分机状态
let mix_popscreen = undefined; // 订阅弹屏
let mix_mixdnd = undefined; // 订阅示忙示闲事件
export default class AgentBtn extends React.PureComponent<
  TextProps,
  TextState
> {
  constructor(props) {
    super(props);
    this.state = {
      userName: '', // 用户名
      extensionState: -1, // 坐席码
      extensionStateTitle: '离线', // 坐席按钮名
      agentName: '未绑定', // 坐席名
      showAgentBtn: false, // 是否显示坐席按钮
    };
  }

  // polyLink心跳，获取登录信息
  public polyLinkCallServer = () => {
    this.props.dispatch({
      type: 'polylink/callServerEpic',
      payload: {},
    });
  };

  // 心跳
  public heartbeat;

  public phone_line = parseInt(sessionStorage.getItem('phone_line') + '', 10); // 电话类型
  // 订阅分机状态
  public start_extensionstatus = (exten) => {
    const extensionstatus_params = {
      extension: exten, // 指定分机号，如果是all则为订阅全部分机。必填
      callback: (data) => {
        console.log(data, '111');
        this.props.dispatch(changeExtensionState(data.Status));

        // 结束通话后
        if (
          data.Status === '0' &&
          localStorage.getItem('callingState') === '99'
        ) {
          const startTime = localStorage.getItem('callStartTime') + '';
          const endTime = new Date().getTime();
          const duration = endTime - parseInt(startTime, 10);
          // 结束时间戳保存到本地
          localStorage.setItem('callEndTime', endTime + '');
          // 通话记录，记录通话时间
          this.props.dispatch({
            type: 'reports/updateCallRecordsEpic',
            payload: {
              id: parseInt(localStorage.getItem('callrecordsId') + '', 10),
              duration: helpers.secondToTime(Math.floor(duration / 1000)),
            },
          });
          // 通话状态记录本地
          localStorage.setItem('callingState', '');
        }
      },
    };
    // @ts-ignore
    mix_extens = mixextensionstatus(extensionstatus_params);
  };
  // 订阅弹屏
  public start_popscreen(exten) {
    const popscreen_params = {
      extension: exten, // 要订阅哪个分机的弹屏。必填
      popinout: 'all', // 弹屏类型，呼入(dialin)、呼出(dialout)或全部(all)弹屏。必填
      poptime: 'LINK', // 弹屏时机，振铃(RING)或接通(LINK)。必填
      poptype: '2', // 弹屏方式，1为弹出新窗口，2为指定js回调。必填
      popurl: '', // 弹屏地址，只有当poptype为1时才有效
      cover_win: true, // 是否覆盖弹屏窗口，true为覆盖，false为不覆盖。只有当poptype为1时才有效
      callback: (data) => {
        console.log(data, '333333');
        // 用户接通通话中
        // 回调函数
        const startTime = new Date().getTime();
        // 通话开始记录时间戳
        localStorage.setItem('callStartTime', startTime.toString());

        // 通话状态记录本地
        localStorage.setItem('callingState', '99');

        this.props.dispatch(setCallStartTime(startTime));
        this.props.dispatch(changeExtensionState(99));

        // 通话记录，记录通话时间
        this.props.dispatch({
          type: 'reports/updateCallRecordsEpic',
          payload: {
            id: parseInt(localStorage.getItem('callrecordsId') + '', 10),
            duration: '00:00:01',
          },
        });
      }, // 回调方法名，只有当poptype为2时才有效。选填
      trim_prefix: '', // 号码前缀。选填
      phone_number_length: '', // 限制对方电话号码的长度。选填
    };
    // @ts-ignore
    mix_popscreen = mixpopscreen(popscreen_params);
  }
  // 订阅示忙示闲事件
  public start_mixdnd(exten) {
    const mixdnd_params = {
      extension: exten, // 指定分机号，如果是all则为订阅全部分机
      callback: (data) => {
        // 改变坐席状态
        if (exten === data.Exten) {
          this.props.dispatch(changeAgentState(data.DND));
        }
      },
    };
    // @ts-ignore
    mix_mixdnd = mixdndstatus(mixdnd_params);
  }
  public componentWillReceiveProps(nextProps) {
    // 绑定当前用户信息
    if (
      nextProps.state.userInfo.name !== undefined &&
      nextProps.state.userInfo.name !== this.state.userName
    ) {
      let agentName = _.get(
        nextProps.state.userInfo.thirdPartyAccount,
        'employee_id',
      );

      if (agentName === '' || agentName === undefined) {
        agentName = '未绑定';
      }

      this.setState({
        userName: nextProps.state.userInfo.name,
        agentName,
      });

      // 如果账户未关联第三方，提示，并不进行第三方接口调用
      if (
        _.get(nextProps.state.userInfo.thirdPartyAccount, 'error_message') ===
          '' &&
        _.get(nextProps.state.userInfo.thirdPartyAccount, 'error_message') !==
          undefined &&
        agentName !== '未绑定'
      ) {
        this.setState({ showAgentBtn: true });
        // ploylink
        if (this.phone_line === 1) {
          // 监听坐席事件
          const socket = io.connect(
            nextProps.state.userInfo.thirdPartyAccount.websocket_host +
              '/?token=' +
              ls.get('polylinkToken'),
          );

          socket.on('CTIEVENT', (data) => {
            if (data.cmdType === 'agent_status_change') {
              // 通话开始记录时间戳
              if (data.status_after === '2' && data.status_before === '10') {
                const startTime = new Date().getTime().toString();
                localStorage.setItem('callStartTime', startTime);
                this.props.dispatch(setCallStartTime(startTime));

                // 通话记录，记录通话时间
                this.props.dispatch({
                  type: 'reports/updateCallRecordsEpic',
                  payload: {
                    id: parseInt(
                      localStorage.getItem('callrecordsId') + '',
                      10,
                    ),
                    duration: '00:00:01',
                  },
                });
              }

              // 通话结束 更新通话时间
              if (data.status_before === '2' && data.status_after === '4') {
                const startTime = localStorage.getItem('callStartTime') + '';
                const endTime = new Date().getTime();
                const duration = endTime - parseInt(startTime, 10);

                // 结束时间戳保存到本地
                localStorage.setItem('callEndTime', endTime + '');

                // 通话记录，记录通话时间
                this.props.dispatch({
                  type: 'reports/updateCallRecordsEpic',
                  payload: {
                    id: parseInt(
                      localStorage.getItem('callrecordsId') + '',
                      10,
                    ),
                    duration: helpers.secondToTime(Math.floor(duration / 1000)),
                  },
                });
              }

              this.props.dispatch(changeExtensionState(data.status_after));
            } else if (data.cmdType === 'hangup') {
              message.warning('已挂机');
            } else if (data.cmdType === 'manual_callout_result') {
              Modal.error({
                title: '失败',
                content: polylink.getRetCodeName(data.retCode),
              });
            }

            if (data.relationUuid !== undefined) {
              localStorage.setItem('relationUuid', data.relationUuid);
            }

            if (data.agentUuid !== undefined) {
              localStorage.setItem('agentUuid', data.agentUuid);
            }
          });

          setTimeout(() => {
            // 获取坐席信息
            this.props.dispatch({
              type: 'polylink/getAgentInfoEpic',
              payload: {},
            });
          }, 500);

          // 保持心跳，获取登录信息
          clearInterval(this.heartbeat);
          this.heartbeat = setInterval(() => {
            this.polyLinkCallServer();
          }, 15000);
        } else {
          // exten:分机号
          const exten = agentName;
          // 获取坐席信息
          this.props.dispatch({
            type: 'mixcall/getStatusEpic',
            payload: { exten },
          });
          // 初始化米糠云对象
          // @ts-ignore
          connect_websocket({
            websocket_host: 'wss://edm02.abc7ga7k.com', // websocket连接地址
            websocket_port: '1238', // websocket连接端口
            token: ls.get('mt'), // websocket连接token
          });
          this.start_popscreen(exten);
          this.start_extensionstatus(exten);
          this.start_mixdnd(exten);
        }
      }
    }
    // 绑定坐席状态
    if (_.get(nextProps.all.agent.agentInfo, 'extensionState') != null) {
      let extensionStateTitle = '离线';
      if (this.phone_line === 1) {
        extensionStateTitle = polylink.getSateName(
          nextProps.all.agent.agentInfo.extensionState,
        );

        this.setState({
          extensionState: nextProps.all.agent.agentInfo.extensionState,
          extensionStateTitle,
        });
      } else {
        extensionStateTitle = '示闲';
        extensionStateTitle = mixphone.getSateName(
          nextProps.all.agent.agentInfo.extensionState,
          nextProps.all.agent.agentInfo.agentState,
        );

        // 判断是否为电话中
        if (
          localStorage.getItem('callingState') === '99' &&
          (nextProps.all.agent.agentInfo.extensionState === 1 ||
            nextProps.all.agent.agentInfo.extensionState === 99)
        ) {
          if (nextProps.all.agent.agentInfo.extensionState === 1) {
            this.props.dispatch(changeExtensionState(99));
          }

          this.setState({
            extensionState: 99,
            extensionStateTitle: '电话中',
          });
        } else {
          localStorage.setItem('callingState', ''); // 清空电话中信息
          this.setState({
            extensionState: nextProps.all.agent.agentInfo.extensionState,
            extensionStateTitle,
          });
        }
      }
    }
  }

  public componentWillUnmount() {
    // 清除心跳
    clearInterval(this.heartbeat);
  }

  // 改变坐席状态
  public changeAgentState = (id) => {
    if (this.phone_line === 1) {
      if (this.state.extensionState === -1) {
        // 保存坐席信息
        this.agentSaveUserInfo(true);

        // 登录cti
        this.loginCti(id);
      } else if (id === '-1') {
        // 保存坐席信息
        this.agentSaveUserInfo(false);

        // 登出cti
        this.loginOutCti();
      } else {
        // 更改坐席状态
        this.agentChangeStatus(id);
      }
    } else {
      // 更改坐席状态
      this.agentChangeStatus(id);
    }
  };

  // 保存坐席信息
  public agentSaveUserInfo = (softPhone) => {
    this.props.dispatch({
      type: 'polylink/saveUserInfoEpic',
      payload: {
        name: this.state.agentName,
        nickname: this.state.agentName,
        softPhone,
      },
    });
  };

  // 更改坐席状态
  public agentChangeStatus = (toStatus) => {
    this.props.dispatch({
      type: 'polylink/changeStatusEpic',
      payload: {
        toStatus,
      },
    });
  };

  // 语音坐席上线
  public loginCti = (initStatus) => {
    this.props.dispatch({
      type: 'polylink/loginCtiEpic',
      payload: {
        agentNum: this.state.agentName,
        exten: this.state.agentName,
        initStatus,
      },
    });
  };

  // 语音坐席下线
  public loginOutCti = () => {
    this.props.dispatch({
      type: 'polylink/signOutCtiEpic',
      payload: {
        agent_num: this.state.agentName,
      },
    });
  };

  public callingState: any = [-1, 1, 2, 3, 5, 10, 11]; // polylink -1: 离线, 1: 振铃, 2: 通话, 3: 保持, 5: 连接中, 10: 呼出中, 11: 电话静音中

  // 坐席菜单
  public getSeatMenu = () => {
    return (
      <Menu
        onClick={(e) => {
          this.changeAgentState(e.key);
        }}
      >
        <Menu.Item key={helpers.isJudge(this.phone_line === 1)(0, -1)}>
          示闲
        </Menu.Item>
        <Menu.Item key={helpers.isJudge(this.phone_line === 1)(6, 1)}>
          示忙
        </Menu.Item>
        {helpers.isJudge(
          this.callingState.includes(_.get(this.state, 'extensionState')) ||
            this.phone_line === 2,
        )(null, <Menu.Item key={-1}>离线</Menu.Item>)}
        {helpers.isJudge(
          _.get(this.state, 'extensionState') === -1 || this.phone_line === 2,
        )(null, <Menu.Item key={7}>离开</Menu.Item>)}
      </Menu>
    );
  };

  // 顶部菜单模块
  public renderTopNav = () => {
    return;
  };

  // 去打电话页
  public handleGoCalling = () => {
    const url = localStorage.getItem('callingUrl') + '';
    history.push(url);
  };

  public render() {
    // 不同颜色的按钮
    let btnClassName = '';
    if (this.state.extensionState === 0) {
      btnClassName = mainStyle.greenBtn;
    } else if (this.state.extensionState === 6) {
      btnClassName = mainStyle.yellowBtn;
    } else if (this.state.extensionState === -1) {
      btnClassName = mainStyle.grayBtn;
    } else if (this.state.extensionState === 7) {
      btnClassName = mainStyle.redBtn;
    } else {
      btnClassName = mainStyle.greenBtn;
    }

    let showBackBtn = false; // 是否显示返回按钮
    if (this.phone_line === 1) {
      // ploylink
      if (
        this.state.extensionState === 1 ||
        this.state.extensionState === 2 ||
        this.state.extensionState === 3 ||
        this.state.extensionState === 4 ||
        this.state.extensionState === 5 ||
        this.state.extensionState === 10 ||
        this.state.extensionState === 11
      ) {
        showBackBtn = true;
      }
    } else {
      // 米糠云
      if (
        this.state.extensionState === 1 ||
        this.state.extensionState === 8 ||
        this.state.extensionState === 16 ||
        this.state.extensionState === 99
      ) {
        showBackBtn = true;
      }
    }
    return (
      <React.Fragment>
        {helpers.isJudge(showBackBtn)(
          <Button type={'primary'} onClick={this.handleGoCalling}>
            返回
            <Icon type={'phone'} style={{ fontSize: 16 }} />
          </Button>,
          null,
        )}

        {helpers.isJudge(
          this.state.showAgentBtn && this.state.agentName !== '未绑定',
        )(
          <Dropdown overlay={this.getSeatMenu}>
            <Button className={btnClassName} style={{ width: 100 }}>
              {this.state.extensionStateTitle}
            </Button>
          </Dropdown>,
          null,
        )}
        <span>
          坐席号：<span>{this.state.agentName}</span>
        </span>
      </React.Fragment>
    );
  }
}
