import { combineEpics } from 'redux-observable';

import * as entryPageEpic from './entryPageEpic';
import * as mainPageEpic from './mainPageEpic';
import * as personalPageEpic from './personalPageEpic';
import * as tagsPageEpic from './system/tagsPageEpic';
import * as rolesPageEpic from './system/rolesPageEpic';
import * as userPageEpic from './system/userPageEpic';
import * as marketingAccountsEpic from './system/marketingAccountsPageEpic';
import * as customersPageEpic from './customers/customersPageEpic';
import * as tasksPageEpic from './customers/tasksPageEpic';
import * as externalCustomersPageEpic from './customers/externalCustomersPageEpic';
import * as callsPageEpic from './records/callsPageEpic';
import * as telPageEpic from './records//telPageEpic';
import * as smsPageEpic from './records/smsPageEpic';
import * as rolesSetEpic from './system/rolesSetEpic';
import * as callCenterEpic from './callCenterEpic';
import * as callNoteEpic from './callNotePageEpic';
import * as marketStatusEpic from './marketStatusPageEpic';
import * as agentEpic from './records/agentEpic';

function distructor(obj) {
  return Object.keys(obj).map(item => {
    return obj[item];
  });
}

const rootEpic = combineEpics(
  ...distructor(entryPageEpic),
  ...distructor(mainPageEpic),
  ...distructor(personalPageEpic),
  ...distructor(tagsPageEpic),
  ...distructor(userPageEpic),
  ...distructor(rolesPageEpic),
  ...distructor(rolesSetEpic),
  ...distructor(marketingAccountsEpic),
  ...distructor(customersPageEpic),
  ...distructor(tasksPageEpic),
  ...distructor(callsPageEpic),
  ...distructor(smsPageEpic),
  ...distructor(callCenterEpic),
  ...distructor(callNoteEpic),
  ...distructor(externalCustomersPageEpic),
  ...distructor(marketStatusEpic),
  ...distructor(telPageEpic),
  ...distructor(agentEpic)
);

export default rootEpic;
