import { request, servicPath } from '../utils';
import { createGetRequestByQuery } from './requestTransfer';

/**
 * 获取行销状态
 */
export function handleGetMarketStatus() {
  return request(
    createGetRequestByQuery(`${servicPath}/products/marketstatus`)
  );
}
