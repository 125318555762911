import * as React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import * as HistoryCreator from 'history';
import { Entry, MainPage } from '@/routes/index';
import { connect } from 'react-redux';
import { Spin, message } from 'antd';
import { Events } from '@/utils';
import { multiPlatform } from '@/multiPlatform';

export const history = HistoryCreator.createBrowserHistory();
message.config({
  maxCount: 1
});
@connect(state => ({ state: state.main }))
export default class AppRouter extends React.PureComponent<
  { [random: string]: any },
  { [random: string]: any }
> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  public mainComponent = multiPlatform.mainMulti(MainPage);

  // 样式
  public style: any = {
    masker: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'fixed',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      margin: 'auto',
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      zIndex: 99
    }
  };

  public listenerTimer: any = null; // 监听的定时器ID

  // 监听loading图的显示隐藏
  public initLoadingListener = () => {
    Events.listen('toggleLoading', (value: boolean) => {
      if (value) {
        // true：显示loading
        if (this.listenerTimer) {
          return;
        }
        // 一定时间后才显示loading图
        this.listenerTimer = setTimeout(() => {
          this.props.dispatch({ type: '@@ty/loadingShow' });
        }, 500);
      } else {
        // false：隐藏loading
        if (this.listenerTimer) {
          clearTimeout(this.listenerTimer);
          this.listenerTimer = null;
        }
        this.props.dispatch({ type: '@@ty/loadingHide' });
      }
    });
  };

  public componentWillMount() {
    this.initLoadingListener(); // 全局loading图
  }

  public render() {
    return (
      <React.Fragment>
        <Router history={history}>
          <Switch>
            <Route path={'/entry/login'} component={Entry} />
            <Route path={'/main'} component={this.mainComponent} />
            <Redirect from={'/'} to={'/entry/login'} />
            <Redirect from={'/*'} to={'/entry/login'} />
          </Switch>
        </Router>
        {/* <Footer /> */}
        {/* Loading图 */}
        {this.props.state.isShowLoading && (
          <div style={this.style.masker}>
            <Spin size={'large'} />
          </div>
        )}
      </React.Fragment>
    );
  }
}
