import * as React from 'react';
import { connect } from 'react-redux';
import { helpers } from '@/utils';
import { Modal, Button, Input, Form } from 'antd';
const { TextArea } = Input;

import {
  ContainerPropsInterface,
  ContainerStateInterface
} from '@/routes/containerInterface';

class RolesAdd extends React.PureComponent<
  ContainerPropsInterface<any>,
  ContainerStateInterface
> {
  constructor(props) {
    super(props);
    this.state = {
      visible: false, // 是否显示角色添加窗口
      intro: '', // 角色说明
      name: helpers.isJudge(props.state.userInfo.name !== undefined)(
        props.state.userInfo.name,
        ''
      ) // 创建人名
    };
  }

  // 添加角色并清空输入
  public handleAdd = () => {
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }

      this.setState({
        visible: false
      });

      this.props.dispatch({
        type: 'system/roles/addRolesEpic',
        payload: {
          role_name: values.roleName,
          intro: this.state.intro
        }
      });
    });
  };

  // 显示
  public handleShow = () => {
    this.props.form.setFieldsValue({
      roleName: ''
    });

    this.setState({
      visible: true,
      intro: ''
    });
  };

  public componentWillReceiveProps(nextProps) {
    // 绑定创建人
    if (
      nextProps.state.userInfo.name !== undefined &&
      nextProps.state.userInfo.name !== this.state.name
    ) {
      this.setState({
        name: nextProps.state.userInfo.name
      });
    }
  }

  // 渲染表单块
  public renderFrom = () => {
    return (
      <Modal
        title={'新增系统角色'}
        visible={this.state.visible}
        onOk={this.handleAdd}
        maskClosable={false}
        onCancel={() => this.setState({ visible: false })}
        width={600}
        className={'modalFrom'}
      >
        <div>
          <div className={'ant-form-item-label'}>创建人：</div>
          <div className={'ant-form-item-control-wrapper'}>
            {this.state.name}
          </div>
        </div>
        <Form.Item label={'角色名称：'}>
          {this.props.form.getFieldDecorator('roleName', {
            rules: [
              {
                required: true,
                message: '请输入角色名称'
              }
            ]
          })(<Input placeholder={'请输入角色名称'} />)}
        </Form.Item>
        <div>
          <div className={'ant-form-item-label'}>描述：</div>
          <TextArea
            rows={4}
            placeholder={'请输入描述，最大可输入15个中文字符'}
            maxLength={15}
            onChange={e => {
              this.setState({ intro: e.target.value });
            }}
            value={this.state.intro}
          />
        </div>
      </Modal>
    );
  };

  public render() {
    return (
      <React.Fragment>
        <Button
          type={'primary'}
          icon={'plus'}
          className={'pBtn fRight'}
          onClick={this.handleShow}
        >
          新增系统角色
        </Button>
        {this.renderFrom()}
      </React.Fragment>
    );
  }
}
const WrappedRolesAdd = Form.create()(RolesAdd as any);
export default WrappedRolesAdd;
