import { Observable, pipe, of } from 'rxjs';

import { map, mergeMap } from 'rxjs/operators';
import { Epic, ofType } from 'redux-observable';
import { helpers } from '@/utils';
import { tasksAPI } from '@/services';
import { message } from 'antd';

interface Payload {
  [random: string]: any;
}
// dispatch传进来的action类型
interface PayloadIn {
  type: string;
  payload: {
    [random: string]: any;
  };
}

// epic传到reducer里的action类型
interface PayloadOut {
  type: string;
  payload: {
    [random: string]: any;
  };
}

// 获取任务信息
export const getTasks: Epic<PayloadIn, PayloadOut> = (action$, store) => {
  return action$.pipe(
    ofType('customers/getTasksEpic'),
    mergeMap(action => {
      return tasksAPI.handleGetTask(action.payload).pipe(
        map(res => {
          if (res !== null) {
            return helpers.createAction<Payload>('customers/getTasks', {
              list: res.data,
              total: res.total
            });
          }
          return helpers.createAction<typeof action.payload>('no');
        })
      );
    })
  );
};

// 添加任务信息
export const addTasks: Epic<PayloadIn, PayloadOut> = (action$, store) => {
  return action$.pipe(
    ofType('customers/addTasksEpic'),
    mergeMap(action => {
      return tasksAPI.handleCreateTask(action.payload).pipe(
        map(res => {
          if (res !== null) {
            message.success('创建成功');
            return helpers.createAction<Payload>(
              'customers/createTaskSuccess',
              {}
            );
          }
          return helpers.createAction<typeof action.payload>('no');
        })
      );
    })
  );
};

// 删除任务信息
export const removeTasks: Epic<PayloadIn, PayloadOut> = (action$, store) => {
  return action$.pipe(
    ofType('customers/removeTasksEpic'),
    mergeMap(action => {
      return tasksAPI.handleDeleteTask(action.payload).pipe(
        map(res => {
          if (res !== null) {
            message.success('删除成功');
            return helpers.createAction<Payload>('customers/updateTasks', {});
          }
          return helpers.createAction<typeof action.payload>('no');
        })
      );
    })
  );
};

// 获取任务详情
export const getTasksDetails: Epic<PayloadIn, PayloadOut> = (
  action$,
  store
) => {
  return action$.pipe(
    ofType('customers/getTasksDetailsEpic'),
    mergeMap(action => {
      return tasksAPI.handleGetTaskDetails(action.payload).pipe(
        map(res => {
          if (res !== null) {
            return helpers.createAction<Payload>('customers/getTasksDetails', {
              total: res.data.total,
              handled: res.data.handled,
              notHandled: res.data.not_handled
            });
          }
          return helpers.createAction<typeof action.payload>('no');
        })
      );
    })
  );
};

// 分配任务
export const assignTasks: Epic<PayloadIn, PayloadOut> = (action$, store) => {
  return action$.pipe(
    ofType('customers/assignTasksEpic'),
    mergeMap(action => {
      return tasksAPI.handleAssignTask(action.payload).pipe(
        map(res => {
          if (res !== null) {
            message.success('分配成功');
            return helpers.createAction<Payload>('customers/updateTasks', {});
          }
          return helpers.createAction<typeof action.payload>('no');
        })
      );
    })
  );
};

// 运行任务
export const runTask: Epic<PayloadIn, PayloadOut> = (action$, store) => {
  return action$.pipe(
    ofType('customers/runTaskEpic'),
    mergeMap(action => {
      return tasksAPI.handleRunTask(action.payload).pipe(
        map(res => {
          if (res !== null) {
            message.success('任务启动成功');
            return helpers.createAction<Payload>('customers/updateTasks', {});
          }
          return helpers.createAction<typeof action.payload>('no');
        })
      );
    })
  );
};

// 暂停任务
export const pauseTask: Epic<PayloadIn, PayloadOut> = (action$, store) => {
  return action$.pipe(
    ofType('customers/pauseTaskEpic'),
    mergeMap(action => {
      return tasksAPI.handlePauseTask(action.payload).pipe(
        map(res => {
          if (res !== null) {
            message.success('任务暂停成功');
            return helpers.createAction<Payload>('customers/updateTasks', {});
          }
          return helpers.createAction<typeof action.payload>('no');
        })
      );
    })
  );
};

// 下载任务excel
export const downloadTask: Epic<PayloadIn, PayloadOut> = (action$, store) => {
  return action$.pipe(
    ofType('customers/downloadTaskEpic'),
    mergeMap(action => {
      return tasksAPI.handleGetTaskDownloadUrl(action.payload).pipe(
        map(res => {
          if (res !== null) {
            const blob = new Blob([res]);
            const a = document.createElement('a');
            a.download = action.payload.name + '.xlsx';
            a.href = window.URL.createObjectURL(blob);
            // 解决火狐下载兼容问题
            document.body.appendChild(a);
            setTimeout(() => {
              document.body.removeChild(a);
            }, 1000);
            a.click();
          }
          return helpers.createAction<typeof action.payload>('no');
        })
      );
    })
  );
};

// 获取任务分配详情
export const getTasksAssignmentsDetails: Epic<PayloadIn, PayloadOut> = (
  action$,
  store
) => {
  return action$.pipe(
    ofType('customers/getTasksAssignmentsDetailsEpic'),
    mergeMap(action => {
      return tasksAPI.handleGetTaskAssignmentsDetails(action.payload).pipe(
        map(res => {
          if (res !== null) {
            return helpers.createAction<Payload>(
              'customers/assignmentsDetailed',
              {
                total: res.total,
                list: res.data
              }
            );
          }
          return helpers.createAction<typeof action.payload>('no');
        })
      );
    })
  );
};
