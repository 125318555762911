import { Observable, pipe, of } from 'rxjs';

import { map, mergeMap } from 'rxjs/operators';
import { Epic, ofType } from 'redux-observable';
import { helpers } from '@/utils';
import { customersAPI } from '@/services';
import { message } from 'antd';

interface Payload {
  [random: string]: any;
}
// dispatch传进来的action类型
interface PayloadIn {
  type: string;
  payload: {
    [random: string]: any;
  };
}

// epic传到reducer里的action类型
interface PayloadOut {
  type: string;
  payload: {
    [random: string]: any;
  };
}

// 获取客户信息
export const getCustomers: Epic<PayloadIn, PayloadOut> = (action$, store) => {
  return action$.pipe(
    ofType('customers/getCustomersEpic'),
    mergeMap(action => {
      return customersAPI.handleGetCustomers(action.payload).pipe(
        map(res => {
          if (res !== null) {
            return helpers.createAction<Payload>('customers/getCustomers', {
              list: res.data,
              lastImportAmount: res.last_import_amount,
            });
          }
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};

// 获取客户信息
export const getCustomersDetails: Epic<PayloadIn, PayloadOut> = (
  action$,
  store,
) => {
  return action$.pipe(
    ofType('customers/getCustomersDetailsEpic'),
    mergeMap(action => {
      return customersAPI.handleGetCustomersDetails(action.payload).pipe(
        map(res => {
          if (res !== null) {
            return helpers.createAction<Payload>(
              'customers/getCustomersDetails',
              res.data,
            );
          }
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};

// 添加客户信息
export const addCustomers: Epic<PayloadIn, PayloadOut> = (action$, store) => {
  return action$.pipe(
    ofType('customers/addCustomersEpic'),
    mergeMap(action => {
      return customersAPI.handleAddCustomers(action.payload).pipe(
        map(res => {
          if (res !== null) {
            return helpers.createAction<Payload>('customers/showTips', {
              duplicatedCount: action.payload.duplicatedCount,
              newImportMount: res.data.record_amount,
            });
          }
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};

// 修改客户信息
export const updateCustomers: Epic<PayloadIn, PayloadOut> = (
  action$,
  store,
) => {
  return action$.pipe(
    ofType('customers/updateCustomersEpic'),
    mergeMap(action => {
      return customersAPI.handleUpdateCustomers(action.payload).pipe(
        map(res => {
          if (res !== null) {
            message.success('编辑成功');
            return helpers.createAction<Payload>(
              'callCenter/updateCallCenterList',
              {},
            );
          }
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};

// 下载模板excel
export const downloadTemplate: Epic<PayloadIn, PayloadOut> = (
  action$,
  store,
) => {
  return action$.pipe(
    ofType('customers/downloadTemplate'),
    mergeMap(action => {
      return customersAPI.handleGetTemplateDownload().pipe(
        map(res => {
          if (res !== null) {
            const blob = new Blob([res]);
            const a = document.createElement('a');
            a.download = '客户导入模板.xlsx';
            a.href = window.URL.createObjectURL(blob);
            // 解决火狐下载兼容问题
            document.body.appendChild(a);
            setTimeout(() => {
              document.body.removeChild(a);
            }, 1000);
            a.click();
          }
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};

// 下载错误excel
export const downloadFailedRecords: Epic<PayloadIn, PayloadOut> = (
  action$,
  store,
) => {
  return action$.pipe(
    ofType('customers/downloadFailedRecordsEpic'),
    mergeMap(action => {
      return customersAPI.handleDownloadFailedRecords(action.payload).pipe(
        map(res => {
          if (res !== null) {
            const blob = new Blob([res]);
            const a = document.createElement('a');
            a.download = action.payload.name + '.xlsx';
            a.href = window.URL.createObjectURL(blob);
            // 解决火狐下载兼容问题
            document.body.appendChild(a);
            setTimeout(() => {
              document.body.removeChild(a);
            }, 1000);
            a.click();
          }
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};

// 导入直接创建任务
export const autoCreateTaskByImport: Epic<PayloadIn, PayloadOut> = (
  action$,
  store,
) => {
  return action$.pipe(
    ofType('customers/createTaskByImport'),
    mergeMap(action => {
      return customersAPI.autoCreateTaskByImport(action.payload.param).pipe(
        map(res => {
          if (res !== null) {
            if (res.error_code !== 6000) {
              message.info(res.message);
            } else {
              action.payload.callback();
            }
          }
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};
