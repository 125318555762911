import React from 'react';
import { connect } from 'react-redux';
import Tasks from '@/routes/callCenter/tasks/tasksPage';
import { Button, Icon, Input, message, Popover, Select } from 'antd';
import { history } from '@/router';
import { helpers } from '@/utils';
import * as _ from 'lodash';

const Option = Select.Option;

@connect(state => ({
  state: state.callCenter,
  all: state,
}))
export default class MembersWithoutDepositMulti extends Tasks {
  constructor(props) {
    super(props);
    this.state = {
      // state也可重写
      ...this.state,
    };
  }

  public getColumns = () => {
    return [
      {
        title: '客户编号',
        dataIndex: 'id',
        align: 'center',
        width: 100,
      },
      {
        title: '用户名',
        dataIndex: 'customer.username',
        width: 220,
        render: (text, record) => (
          <span className={'greenPointCell'}>
            {helpers.isJudge(record.is_handled === 0)(<span />, null)}
            {text}
          </span>
        ),
      },
      {
        title: '真实姓名',
        align: 'center',
        dataIndex: 'customer.x_real_name',
        width: 150,
      },
      {
        title: '行销结果',
        align: 'center',
        dataIndex: 'market_status_id',
        width: 150,
        render: text => (
          <span>{_.get(this.state.marketStatus[text], 'name')}</span>
        ),
      },
      {
        title: '手机号码',
        align: 'right',
        dataIndex: 'customer.x_phone',
        minWidth: 180,
        width: 200,
        render: (text, record) => (
          <p className={'phone'}>
            <span
              style={{
                lineHeight: '14px',
                width: 100,
                overflow: 'hidden',
                padding: 0,
              }}
            >
              {text}
            </span>{' '}
            {helpers.isJudge(this.state.messageFlag)(
              <Icon
                type={'mail'}
                theme={'filled'}
                style={{
                  fontSize: '18px',
                  marginLeft: 15,
                  cursor: 'pointer',
                }}
                onClick={() =>
                  this.setState({
                    showTimes: this.state.showTimes + 1,
                    customer_id: record.customer_id,
                    relationshipId: record.id,
                  })
                }
              />,
              null,
            )}{' '}
            {helpers.isJudge(this.state.phoneFlag)(
              helpers.isJudge(this.state.extensionState === 0)(
                <Icon
                  type={'phone'}
                  theme={'filled'}
                  style={{
                    fontSize: '18px',
                    marginLeft: 15,
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    this.call(record.customer_id, record.id, record.task_id)
                  }
                />,
                <Icon
                  type={'phone'}
                  theme={'filled'}
                  style={{
                    fontSize: '18px',
                    marginLeft: 15,
                    cursor: 'pointer',
                    color: '#6c7c8e',
                  }}
                  onClick={() => message.warning('当前状态，无法呼叫')}
                />,
              ),
              null,
            )}
          </p>
        ),
      },
      {
        title: '注册时间',
        dataIndex: 'customer.register_time',
        align: 'center',
        width: 150,
        render: text =>
          text.slice(0, 10) === '0000-00-00' ? null : (
            <time>
              {text.slice(0, 10)}
              <br />
              {text.slice(10)}
            </time>
          ),
      },
      {
        title: '呼出次数',
        dataIndex: 'callout_count',
        align: 'center',
        width: 150,
      },
      {
        title: '任务名称',
        dataIndex: '任务名称',
        align: 'center',
        width: 250,
        render: (text, record) => <span>{record.task_name}</span>,
      },
      {
        title: '导入时间',
        dataIndex: 'customer',
        align: 'center',
        width: 150,
        render: text => (
          <time>
            {text.import_date.slice(0, 10)}
            <br />
            {text.import_date.slice(10)}
          </time>
        ),
      },
      {
        title: '最后登录时间',
        dataIndex: 'customer.last_login_time',
        align: 'center',
        width: 200,
        render: (text, record) => <span>{record.customer && record.customer.last_login_time === '0000-00-00 00:00:00' ? '' : record.customer.last_login_time}</span>,
      },
      {
        title: '回访备注',
        dataIndex: '回访备注',
        align: 'center',
        width: 150,
        minWidth: 150,
        render: (text, record) => {
          // 第一条备注
          const [firstNote] = record.call_notes || [undefined];
          const placeholder = firstNote ? (
            <Popover content={<span>{firstNote.note}</span>}>
              <span
                style={{
                  maxWidth: 'calc(100% - 30px)',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  lineHeight: '1em',
                  verticalAlign: 'inherit',
                  display: 'inline-block',
                }}
              >
                {firstNote.note}
              </span>
            </Popover>
          ) : null;
          return helpers.isJudge(
            _.includes(this.props.all.main.buttonPermission, 115),
          )(
            <React.Fragment>
              {placeholder}
              <a
                href={'javascript:;'}
                onClick={() => {
                  history.push(
                    history.location.pathname +
                    '/' +
                    record.customer_id +
                    '/' +
                    record.id,
                  );
                }}
              >
                全部
              </a>
            </React.Fragment>,
            null,
          );
        },
      },
    ];
  };

  // 呼出统计的筛选列表
  public callCountList = [
    { key: -1, name: '不限' },
    { key: 0, name: '0次' },
    { key: 1, name: '1次' },
    { key: 2, name: '大于1次' },
  ];

  // 过滤渲染
  public renderFilter = () => {
    const dateFormat = 'YYYY/MM/DD';
    return (
      <div>
        <p>
          <span className={'notWrap'}>
            行销结果：
            <Select
              placeholder={'不限'}
              onChange={value =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    market_status_id: value,
                  },
                })
              }
              value={this.state.filterObj.market_status_id}
            >
              {this.state.marketStatus.map(item => {
                return <Option key={item.id}>{item.name}</Option>;
              })}
            </Select>
          </span>
          <span className={'notWrap'}>
            任务名称：
            <Input
              placeholder={'请输入任务名称'}
              value={this.state.filterObj.task_name}
              onChange={e =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    task_name: e.target.value,
                  },
                })
              }
            />
          </span>
          <span className={'notWrap'}>
            用户名：
            <Input
              placeholder={'请输入用户名'}
              value={this.state.filterObj.username}
              onChange={e =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    username: e.target.value,
                  },
                })
              }
            />
          </span>
          <span className={'notWrap'}>
            呼出次数：
            <Select
              placeholder={'请选择'}
              value={this.state.filterObj.callout_count}
              onChange={value => {
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    callout_count: value,
                  },
                });
              }}
            >
              {this.callCountList.map(item => (
                <Option key={item.key.toString()}>{item.name}</Option>
              ))}
            </Select>
            <Button type={'primary'} onClick={this.filterSelect}>
              筛选
            </Button>
            <Button
              type={'primary'}
              className={'pBtn'}
              onClick={this.handleFilterReset}
            >
              重置
            </Button>
          </span>
        </p>
      </div>
    );
  };

  public render() {
    return <React.Fragment>{super.render()}</React.Fragment>;
  }
}
