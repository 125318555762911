import * as React from 'react';
import { history } from '@/router';
import * as _ from 'lodash';
import moment from 'moment';
import { Table, Button, Input, Modal, DatePicker, Pagination } from 'antd';
const { RangePicker } = DatePicker;
const confirm = Modal.confirm;
import { helpers } from '@/utils';
import {
  ContainerPropsInterface,
  ContainerStateInterface,
} from '@/routes/containerInterface';
import { XList } from '@/components';

//  外部客户导入列表
export default class ExternalCustomers extends React.PureComponent<
  ContainerPropsInterface<any>,
  ContainerStateInterface
> {
  // 导入结果
  public importResult = [
    '处理中',
    '导入完成',
    '文件无法解析',
    '导入excel模板错误',
  ];

  // 表格列
  public columns: any = [
    {
      title: '导入时间',
      dataIndex: 'imported_at',
      align: 'center',
      width: 200,
      render: imported_at => <p className={'time'}>{imported_at}</p>,
    },
    {
      title: '操作人',
      dataIndex: 'user',
      align: 'center',
      width: 250,
      render: text => <span>{_.get(text, 'name')}</span>,
    },
    {
      title: '名单数量',
      dataIndex: 'amount',
      align: 'center',
      width: 150,
      render: (text, record) =>
        helpers.isJudge(record.status === 0)(
          <span>正在处理中</span>,
          helpers.isJudge(record.status === 1)(
            helpers.isJudge(this.state.detailFlag && record.status === 1)(
              <a
                href={'javascript:;'}
                onClick={() => this.getTasksDetails(record)}
              >
                {text}
              </a>,
              <span>{text}</span>,
            ),
            <span style={{ color: '#f5222d' }}>
              {this.importResult[record.status]}
            </span>,
          ),
        ),
    },
    {
      title: '未导入数据',
      dataIndex: '未导入数据',
      align: 'center',
      width: 150,
      render: (text, record) =>
        helpers.isJudge(record.failed_rows > 0)(
          <a
            href={'javascript:;'}
            onClick={() => this.downloadErrorExcel(record.id)}
          >
            下载查看
          </a>,
          null,
        ),
    },
    {
      title: '操作',
      dataIndex: 'action',
      align: 'center',
      width: 80,
      render: (text, record) =>
        helpers.isJudge(this.state.delFlag && record.status !== 0)(
          <a
            href={'javascript:;'}
            title={'下载'}
            onClick={() => this.confirmDelete(record.id)}
          >
            删除
          </a>,
          null,
        ),
    },
  ];
  // 页面大小存储使用的key
  public STORE_PAGE_SIZE_KEY = 'smallPageSize';

  constructor(props) {
    super(props);
    this.state = {
      list: [], // 任务列表
      total: 0, // 全部任务的数量
      page: {
        size: helpers.isJudge(
          localStorage.getItem(this.STORE_PAGE_SIZE_KEY) === null,
        )(10, localStorage.getItem(this.STORE_PAGE_SIZE_KEY)), // 用户列表的每页大小
        num: 1, // 用户列表的当前页数
      },
      // 过滤对象组
      filterObj: {
        ...this.filterObj,
      },
      detailFlag: false,
      delFlag: false,
    };
  }
  // 用于分页时可以使用上次的筛选操作
  public filterObj = {
    username: '', // 任务名称
    imported_at_start: '', // 创建开始日期
    imported_at_end: '', // 创建结束日期
  };

  public selectedTasks: any = []; // 用户提交的选中任务
  public updateTimes = 0; // 被动更新次数
  public data: any = []; // 标签列表

  public componentWillMount() {
    // 获取任务列表
    this.getTasks();
  }

  // 下载错误excel
  public downloadErrorExcel = id => {
    this.props.dispatch({
      type: 'customers/downloadFailedRecordsEpic',
      payload: {
        id,
        name: '导入错误',
      },
    });
  };

  // 获取任务
  public getTasks = () => {
    this.filterObj = this.state.filterObj; // 保存被筛选的条件
    const obj = Object.assign({}, this.state.filterObj);
    if (obj.tag_id === -1) {
      obj.tag_id = null;
    }
    this.props.dispatch({
      type: 'customers/externalCustomersEpic',
      payload: {
        ...obj,
        page: this.state.page.num,
        page_size: this.state.page.size,
      },
    });
  };

  // 过滤查询
  public filterSelect = () => {
    this.filterObj = this.state.filterObj; // 保存被筛选的条件

    this.setState(
      { page: { num: 1, size: this.state.page.size } },
      this.getTasks,
    );
  };

  // 通过之前的筛选条件获取任务
  public getTasksBeforeFilter = () => {
    const obj = Object.assign({}, this.filterObj);
    this.props.dispatch({
      type: 'customers/externalCustomersEpic',
      payload: {
        ...obj,
        page: this.state.page.num,
        page_size: this.state.page.size,
      },
    });
  };

  // 获取任务详情
  public getTasksDetails = record => {
    localStorage.setItem('imported_at', record.imported_at);
    history.push('/main/customers/externalCustomers/' + record.id);
  };

  // 筛选重置
  public handleFilterReset = () => {
    this.filterObj = {
      username: '', // 任务名称
      imported_at_start: '', // 创建开始日期
      imported_at_end: '', // 创建结束日期
    };
    this.setState({
      filterObj: this.filterObj,
    });
  };

  // 选择创建日期
  public selectCreateDate = dateString => {
    this.setState({
      filterObj: {
        ...this.state.filterObj,
        imported_at_start: dateString[0],
        imported_at_end: dateString[1],
      },
    });
  };
  public componentWillReceiveProps(nextProps) {
    // 按钮数据权限控制
    const buttonPermission = nextProps.all.main.buttonPermission;
    this.setState({
      detailFlag: _.includes(buttonPermission, 107),
      delFlag: _.includes(buttonPermission, 105),
    });

    // // 绑定列表
    this.data = nextProps.all.externalCustomers.list;
    this.setState({
      list: nextProps.all.externalCustomers.list,
      total: nextProps.all.externalCustomers.total,
    });
  }

  // 删除
  public confirmDelete(deleteId: number) {
    const self = this;
    confirm({
      title: '您确定删除该导入批次的所有名单吗？',
      onOk() {
        self.props.dispatch({
          type: 'customers/removeExternalCEpic',
          payload: {
            id: deleteId,
          },
        });
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  }

  // 筛选标签列表
  public handleFilter(filterText) {
    if (this.data.length !== 0) {
      this.setState({
        filterText,
      });
    }
  }

  // 改变页号
  public changePage = (page, size) => {
    this.setState({ page: { num: page, size } }, this.getTasksBeforeFilter);
  };

  // 改变每页大小
  public changePageSize = (page, size) => {
    localStorage.setItem(this.STORE_PAGE_SIZE_KEY, this.state.page.size);
    this.setState({ page: { num: 1, size } }, this.getTasksBeforeFilter);
  };

  // 列表模块渲染
  public renderList = () => {
    return (
      <React.Fragment>
        <XList
          columns={this.columns}
          dataSource={this.state.list}
          isShowNum={true}
          autoCalculation={true}
          pagination={{
            current: this.state.page.num,
            pageSize: this.state.page.size,
            total: this.state.total,
            showSizeChanger: true,
            showQuickJumper: true,
            onChange: this.changePage,
            onShowSizeChange: this.changePageSize,
            pageSizeOptions: ['10', '20', '30', '40'],
          }}
        />
      </React.Fragment>
    );
  };

  // 筛选模块渲染
  public renderFilter = () => {
    const dateFormat = 'YYYY/MM/DD';
    return (
      <div>
        <p>
          <span className={'notWrap'}>
            导入时间：
            <RangePicker
              onChange={(date, dateString) => this.selectCreateDate(dateString)}
              value={helpers.isJudge(
                this.state.filterObj.imported_at_start === '',
              )(null, [
                moment(this.state.filterObj.imported_at_start, dateFormat),
                moment(this.state.filterObj.imported_at_end, dateFormat),
              ])}
            />
          </span>
          <span className={'notWrap'}>
            操作人：
            <Input
              type={'text'}
              placeholder={'请输入操作人名称'}
              value={this.state.filterObj.username}
              maxLength={15}
              onChange={e =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    username: e.target.value,
                  },
                })
              }
            />
            <Button type={'primary'} onClick={this.filterSelect}>
              筛选
            </Button>
            <Button onClick={this.handleFilterReset}>重置</Button>
          </span>
        </p>
      </div>
    );
  };

  public render() {
    return (
      <React.Fragment>
        {this.renderFilter()}
        {this.renderList()}
      </React.Fragment>
    );
  }
}
