/**
 * 因为atd的alert不能重点其中的部分字所以单独写一个提示组件
 */
import * as React from 'react';

import { Icon } from 'antd';
export interface AlertProps {
  message: string;
}

export default class Alert extends React.PureComponent<AlertProps, any> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  public render() {
    return (
      <div className={'ant-alert ant-alert-success'}>
        <Icon
          type={'exclamation-circle'}
          theme={'filled'}
          className={'ant-alert-icon'}
        />
        {this.props.message.indexOf('<span>') === -1 ? (
          <span>{this.props.message}</span>
        ) : (
          <span>
            {this.props.message.slice(0, this.props.message.indexOf('<span>'))}
            <span>
              {this.props.message.slice(
                this.props.message.indexOf('<span>') + 6,
                this.props.message.indexOf('</span>')
              )}
            </span>
            {this.props.message.slice(
              this.props.message.indexOf('</span>') + 7
            )}
          </span>
        )}
      </div>
    );
  }
}
