import React from 'react';
import { connect } from 'react-redux';
import Agent from '@/routes/report/agent/agentPage';

@connect(state => ({
  userList: state.user.userList,
  agent: state.agent
}))
export default class AgentMulti extends Agent {
  public constructor(props) {
    super(props);
    this.state = {
      // state也可重写
      ...this.state
    };
  }

  public render() {
    return (
      <React.Fragment>
        {super.render()}
        {/* {this.renderAddCondition()} */}
      </React.Fragment>
    );
  }
}
