import { request, servicPath } from '../utils';
import {
  createFormDataPostRequest,
  createFormDataPutRequest,
  createGetRequestByQuery,
  createDelRequestByQuery
} from './requestTransfer';

/**
 * 获取营销账号列表
 */
export function handleGetMarketingAccounts(params) {
  return request(
    createGetRequestByQuery(`${servicPath}/products/marketingaccounts`, params)
  );
}

/**
 * 添加营销账号
 */
export function handleAddMarketingAccounts(params) {
  return request(
    createFormDataPostRequest(
      `${servicPath}/products/marketingaccounts`,
      params
    )
  );
}

/**
 * 删除营销账号
 */
export function handleDeleteMarketingAccounts(params) {
  return request(
    createDelRequestByQuery(
      `${servicPath}/products/marketingaccounts/${params.id}`
    )
  );
}

/**
 * 更新营销账号
 */
export function handleUpdateMarketingAccounts(params) {
  return request(
    createFormDataPutRequest(
      `${servicPath}/products/marketingaccounts/${params.id}`,
      params
    )
  );
}
