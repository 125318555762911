import * as _ from 'lodash';
import { Action } from './reducerInterface';

const initialStore = {
  callCenterList: [], // 呼叫中心列表
  blockedList: [], // 受阻列表
  taskList: [], // 任务管理列表
  tempList: {}, // 模板数据
  total: 0,
  updateTimes: 0, // 更新次数
  num: {
    newRegistration: 0,
    membersWithoutDeposit: 0,
    sleeping: 0,
    blocked: 0,
    groupMaintenance: 0,
    externalCustomers: 0,
  },
  callStartTime: -1, // 通话开始时间戳
  callEndTime: -1, // 通话结束时间戳
};

export type AppStoreType = typeof initialStore;

export default function callCenterReducer(
  state: any = initialStore,
  action: Action,
) {
  try {
    switch (action.type) {
      case 'callCenter/tasks': {
        const callCenterList: any = action.payload.list;
        const blockedList: any = action.payload.list;

        if ('blocked' === action.payload.type) {
          return _.assign({}, state, {
            blockedList,
            total: action.payload.total,
          });
        } else {
          return _.assign({}, state, {
            callCenterList,
            total: action.payload.total,
          });
        }
      }
      case 'callCenter/taskList': {
        return _.assign({}, state, {
          taskList: action.payload.taskList,
        });
      }
      case 'callCenter/groupCallTask': {
        return _.assign({}, state, {
          groupCallTaskList: action.payload.groupCallTaskList.list,
          total: action.payload.groupCallTaskList.total,
        });
      }
      case 'reports/getSitList': {
        return _.assign({}, state, { sitList: action.payload.sitList });
      }
      case 'callCenter/groupCallTaskDetail': {
        return _.assign({}, state, {
          groupCallTaskDetail: action.payload.groupCallTaskDetail.list,
          total: action.payload.groupCallTaskDetail.total,
        });
      }
      case 'callCenter/tempList': {
        return _.assign({}, state, {
          tempList: action.payload.tempList,
        });
      }
      case 'callCenter/updateCallCenterList': {
        return _.assign({}, state, { updateTimes: state.updateTimes + 1 });
      }
      case 'callCenter/setCallStartTime': {
        return _.assign({}, state, {
          callStartTime: action.payload.callStartTime,
        });
      }
      case 'callCenter/setCallEndTime': {
        return _.assign({}, state, {
          callEndTime: action.payload.callEndTime,
        });
      }
      case 'callCenter/getTasksBadgeamount': {
        return _.assign({}, state, {
          num: {
            newRegistration: action.payload.unHandledNewRestrictionAmount,
            membersWithoutDeposit:
              action.payload.unHandledMembersWithoutDeposit,
            sleeping: action.payload.unHandledSleepingAmount,
            blocked: action.payload.unHandledObstructedAmount,
            groupMaintenance: action.payload.unhandledGroupMaintenance,
            externalCustomers: action.payload.unHandledExternalCustomerList,
          },
        });
      }
      default:
        return state;
    }
  } catch (e) {
    return state;
  }
}
