import { Observable, pipe, of } from 'rxjs';

import { map, mergeMap } from 'rxjs/operators';
import { Epic, ofType } from 'redux-observable';
import { helpers } from '@/utils';
import { marketingAccountsAPI } from '@/services';

interface Payload {
  [random: string]: any;
}
// dispatch传进来的action类型
interface PayloadIn {
  type: string;
  payload: {
    [random: string]: any;
  };
}

// epic传到reducer里的action类型
interface PayloadOut {
  type: string;
  payload: {
    [random: string]: any;
  };
}

// 获取营销账户信息
export const getMarketingAccounts: Epic<PayloadIn, PayloadOut> = (
  action$,
  store
) => {
  return action$.pipe(
    ofType('system/marketingAccounts/getMarketingAccountsEpic'),
    mergeMap(action => {
      return marketingAccountsAPI
        .handleGetMarketingAccounts(action.payload)
        .pipe(
          map(res => {
            if (res !== null) {
              return helpers.createAction<Payload>(
                'system/marketingAccounts/getMarketingAccounts',
                res.data.data
              );
            }
            return helpers.createAction<typeof action.payload>('no');
          })
        );
    })
  );
};

// 删除营销账户信息
export const deleteMarketingAccounts: Epic<PayloadIn, PayloadOut> = (
  action$,
  store
) => {
  return action$.pipe(
    ofType('system/marketingAccounts/deleteMarketingAccountsEpic'),
    mergeMap(action => {
      return marketingAccountsAPI
        .handleDeleteMarketingAccounts(action.payload)
        .pipe(
          map(res => {
            if (res !== null) {
              return helpers.createAction<Payload>(
                'system/marketingAccounts/deleteMarketingAccounts',
                action.payload.id
              );
            }
            return helpers.createAction<typeof action.payload>('no');
          })
        );
    })
  );
};

// 更新营销账户信息
export const updateMarketingAccounts: Epic<PayloadIn, PayloadOut> = (
  action$,
  store
) => {
  return action$.pipe(
    ofType('system/marketingAccounts/updateMarketingAccountsEpic'),
    mergeMap(action => {
      return marketingAccountsAPI
        .handleUpdateMarketingAccounts(action.payload)
        .pipe(
          map(res => {
            if (res !== null) {
              return helpers.createAction<Payload>(
                'system/marketingAccounts/updateMarketingAccounts',
                { ...res.data, user: { name: action.payload.belong_user_name } }
              );
            }
            return helpers.createAction<typeof action.payload>('no');
          })
        );
    })
  );
};

// 添加营销账户信息
export const addMarketingAccounts: Epic<PayloadIn, PayloadOut> = (
  action$,
  store
) => {
  return action$.pipe(
    ofType('system/marketingAccounts/addMarketingAccountsEpic'),
    mergeMap(action => {
      return marketingAccountsAPI
        .handleAddMarketingAccounts(action.payload)
        .pipe(
          map(res => {
            if (res !== null) {
              return helpers.createAction<Payload>(
                'system/marketingAccounts/addMarketingAccounts',
                { ...res.data, user: { name: action.payload.belong_user_name } }
              );
            }
            return helpers.createAction<typeof action.payload>('no');
          })
        );
    })
  );
};
