import * as _ from 'lodash';

import { message } from 'antd';

import moment from 'moment';

export const getpattern = {
  // 验证码：数字，4到6位
  sms: /^\d{4,6}$/,
  // 英文名：字母+空格，2到15位
  name: /^[a-zA-Z ]{2,15}$/,
  // 邮箱验证
  email: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
  // 允许注册的手机号码： 中国(86-13800138000)，菲律宾(63-9454561234)
  phone: /\d{2,3}\-\d{8,11}/,
  // 密码：不能为纯数字或纯字母，8~16位
  password: /^(?!\d+$|[A-Za-z]+$)[a-zA-Z0-9]{8,16}$/
};

// antd的表单验证
export const antdFormValidators = {
  sms(rule, value, callback) {
    if (value && !new RegExp(getpattern.sms).test(value)) {
      callback('请输入正确的验证码');
    } else {
      callback();
    }
  },
  name(rule, value, callback) {
    if (value && !new RegExp(getpattern.name).test(value)) {
      callback('请输入正确的英文名');
    } else {
      callback();
    }
  },
  email(rule, value, callback) {
    if (value && !new RegExp(getpattern.email).test(value)) {
      callback('请输入正确的邮箱地址');
    } else {
      callback();
    }
  },
  phone(rule, value, callback) {
    if (value && !new RegExp(getpattern.phone).test(value)) {
      callback('请输入正确的手机号码，例：86-13010001000');
    } else {
      callback();
    }
  },
  password(rule, value, callback) {
    if (value && !new RegExp(getpattern.password).test(value)) {
      callback('请输入8至16位的非纯数字或字母的密码');
    } else {
      callback();
    }
  },
  /**
   * 比较两次输入的密码是否一致
   * @param firstPassword 第一次输入的密码
   * @param curPassword 当前输入的密码
   * @param callback 回调
   */
  compareToFirstPassword(firstPassword, curPassword, callback) {
    if (curPassword && curPassword !== firstPassword) {
      callback('两次输入的密码不一致');
    } else {
      callback();
    }
  },
  // 标签说明校验
  length2to100(rule, value, callback) {
    if (value && !(value.length >= 2 && value.length <= 100)) {
      callback('标签说明长度为2-100字符');
    } else {
      callback();
    }
  },
  // 小于32个字符
  lengthLessThan32(rule, value, callback) {
    if (value && value.length > 32) {
      callback('不能大于32个字符');
    } else {
      callback();
    }
  }
};

/**
 * 格式化时间
 * @param {number} timestamp 时间戳
 * @param {string} format 时间格式
 */
export function formatDate(timestamp, format = 'YYYY-MM-DD HH:mm:ss') {
  if (!timestamp || timestamp === '0') {
    return '';
  } else {
    return moment.unix(timestamp).format(format);
  }
}

// 字符串处理
export const filter = {
  // 只保留数字且第一位不为零
  number(value) {
    return value.replace(/[^0-9]+/g, '');
  },
  trimStr(str) {
    // 去掉前后空格
    return str.replace(/(^\s*)|(\s*$)/g, '');
  }
};

/**
 * @param  {string} format='YYYY-MM-DD'
 * 获取当前时间,默认按年月日返回
 */
export function momentGetNow(format = 'YYYY-MM-DD') {
  return moment().format(format);
}

export function createAction<T>(type: string, payload?: T) {
  return {
    type,
    payload: payload || {}
  };
}

// 替代三元表达式
export function isJudge(value) {
  return (trued, falsed) => {
    if (value) {
      return trued;
    } else {
      return falsed;
    }
  };
}

/**
 * 获取路径中的pathname
 *
 * ('q', 'hxxp://example.com/?q=abc') => 'abc'
 * @param {string} path location中的pathname
 * @param {nubmer} len 保留pathname中的个数
 * @example
 *  getPathname('/app/financial', 2) => '/app/financial'
 *  getPathname('/app/financial/withdrawal', 2) => '/app/financial'
 *  getPathname('/app/financial/withdrawal', 3) => '/app/financial/withdrawal'
 */
export function getPathname(path, len) {
  if (len === undefined) {
    return path;
  }
  return path
    .split('/')
    .splice(0, len + 1)
    .join('/');
}

/**
 * 隐藏部分姓名
 *
 * @param {string} name 姓名
 * @example
 *  '周杰' => '周*'
 *  '周杰伦' => '周*伦'
 *  '古力娜扎' => '古*扎'
 */
export function hideName(name) {
  if (name.length === 2) {
    return `${name[0]}*`;
  } else if (name.length > 2) {
    return `${name[0]}*${name.slice(-1)}`;
  } else {
    return name;
  }
}

/**
 * 隐藏手机部分号码
 *
 * @param {string} phone 手机号码
 * @example
 *  '63-9454986531' => '63-94****31'
 */
export function hidePhone(phone) {
  if (!phone) {
    return;
  }
  const [code, number] = phone.split('-');
  return `${code}-${number.slice(0, 2)}****${number.slice(-2)}`;
}

// 最小图片
export function get_smallest_img() {
  return 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
}

/**
 * 出除字符串中重复的字符
 * @param {string} str 需要去重的字符串
 * @param {string} s 目标字符
 * @return {string} 返回处理后的字符串
 *
 * @example deleteDuplicate('1.1.1.000', '.') => ('1.1000')
 */
export function deleteDuplicate(str, s) {
  return `${str.slice(0, str.indexOf(s) + 1)}${str
    .slice(str.indexOf(s) + 1)
    .split('.')
    .join('')}`;
}

// 格式化成金额的格式
export function formatToMoney(money, decimalPlaces = 2) {
  if (!money) {
    return '';
  }
  // 如果是number类型则转为string类型
  if (_.isNumber(money)) {
    money = String(money);
  }
  money = money.replace(/[^\d\.]/g, ''); // 去掉数字及小数点以外的
  money = deleteDuplicate(money, '.'); // 只保留一个小数点，去除多余的小数点
  if (money === '.') {
    return '';
  }
  money = Math.floor(money * 10 ** decimalPlaces) / 10 ** decimalPlaces; // 保留指定位数的小数
  return String(money);
}

// throttle+弹窗
export function throttleAndMsg(callback, time) {
  let wait = false;
  // tslint:disable-next-line
  return function() {
    if (!wait) {
      callback.apply(null, arguments);
      wait = true;
      setTimeout(() => {
        wait = false;
      }, time);
    } else {
      message.error('您的操作速度过快');
    }
  };
}

// 秒数转时间显示
export function secondToTime(s) {
  let t;
  if (s > -1) {
    const hour = Math.floor(s / 3600);
    const min = Math.floor(s / 60) % 60;
    const sec = s % 60;
    if (hour < 10) {
      t = '0' + hour + ':';
    } else {
      t = hour + ':';
    }

    if (min < 10) {
      t += '0';
    }
    t += min + ':';
    if (sec < 10) {
      t += '0';
    }
    t += sec.toFixed(0);
  }
  return t;
}
