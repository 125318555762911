import * as React from 'react';
import { Modal, Button, Input, Form } from 'antd';
const { TextArea } = Input;

import {
  ContainerPropsInterface,
  ContainerStateInterface
} from '@/routes/containerInterface';
import { helpers } from '@/utils';

export default class TagsAdd extends React.PureComponent<
  ContainerPropsInterface<any>,
  ContainerStateInterface
> {
  constructor(props) {
    super(props);
    this.state = {
      visible: false // 是否显示标签添加窗口
    };
  }

  // 标签添加
  public handleAdd = () => {
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }

      // 清空输入框
      this.props.form.setFieldsValue({
        tagName: '',
        intro: ''
      });

      // 隐藏标签添加窗口
      this.setState({
        visible: false
      });

      this.props.dispatch({
        type: 'system/tags/addTagsEpic',
        payload: {
          tag_name: values.tagName,
          intro: values.intro
        }
      });
    });
  };

  // 取消隐藏标签添加窗口，并清空输入框
  public handleCancel = () => {
    this.props.form.setFieldsValue({
      tagName: '',
      intro: ''
    });
    this.setState({ visible: false });
  };

  // 渲染表单块
  public renderFrom = () => {
    return (
      <Modal
        title={'新增标签'}
        visible={this.state.visible}
        onOk={this.handleAdd}
        onCancel={this.handleCancel}
        maskClosable={false}
        width={600}
        className={'modalFrom'}
      >
        <Form.Item label={'标签组名：'}>
          {this.props.form.getFieldDecorator('tagName', {
            rules: [
              {
                required: true,
                message: '请输入标签组名'
              }
            ]
          })(<Input placeholder={'请输入新增标签组的名称'} maxLength={32} />)}
        </Form.Item>
        <Form.Item label={'标签说明：'}>
          {this.props.form.getFieldDecorator('intro', {
            rules: [
              {
                required: true,
                message: '请输入标签说明'
              },
              {
                validator: helpers.antdFormValidators.length2to100
              }
            ]
          })(
            <TextArea
              rows={4}
              placeholder={'请输入新增标签说明'}
              onChange={e => {
                this.setState({ intro: e.target.value });
              }}
            />
          )}
        </Form.Item>
      </Modal>
    );
  };

  public render() {
    return (
      <React.Fragment>
        <Button
          type={'primary'}
          icon={'plus'}
          className={'pBtn fRight'}
          onClick={() => this.setState({ visible: true })}
        >
          新增标签
        </Button>
        {this.renderFrom()}
      </React.Fragment>
    );
  }
}
