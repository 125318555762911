import { map, mergeMap } from 'rxjs/operators';
import { Epic, ofType } from 'redux-observable';
import { message } from 'antd';
import { helpers } from '../../utils';
import { userAPI } from '../../services';

interface Payload {
  [random: string]: any;
}

// dispatch传进来的action类型
interface PayloadIn {
  type: string;
  payload: {
    [random: string]: any;
  };
}

// epic传到reducer里的action类型
interface PayloadOut {
  type: string;
  payload: {
    [random: string]: any;
  };
}

// 获取用户列表
export const getUserList: Epic<PayloadIn, PayloadOut> = (action$, _store) => {
  return action$.pipe(
    ofType('system/getUserListEpic'),
    mergeMap(action => {
      return userAPI.handleGetUserList(action.payload).pipe(
        map(res => {
          if (res !== null) {
            return helpers.createAction<Payload>(
              'system/getUserList',
              res.data.data,
            );
          }
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};

// 添加用户
export const addUser: Epic<PayloadIn, PayloadOut> = (action$, _store) => {
  return action$.pipe(
    ofType('system/addUserEpic'),
    mergeMap(action => {
      return userAPI.handleAddUser(action.payload).pipe(
        map(res => {
          if (res !== null) {
            message.success('新增用户成功');
            return helpers.createAction<Payload>('system/addUser', res.data);
          }
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};

// 更新用户信息
export const updateUser: Epic<PayloadIn, PayloadOut> = (action$, _store) => {
  return action$.pipe(
    ofType('system/updateUserEpic'),
    mergeMap(action => {
      return userAPI.handleUpdateUser(action.payload).pipe(
        map(res => {
          if (res !== null) {
            message.success('编辑用户成功');
            return helpers.createAction<Payload>('system/updateUser', res.data);
          }
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};

// 删除标签信息
export const deleteUser: Epic<PayloadIn, PayloadOut> = (action$, _store) => {
  return action$.pipe(
    ofType('system/deleteUserEpic'),
    mergeMap(action => {
      return userAPI.handleDeleteUser(action.payload).pipe(
        map(res => {
          if (res !== null) {
            message.success('删除用户成功');
            return helpers.createAction<Payload>(
              'system/deleteUser',
              action.payload.id,
            );
          }
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};

// 锁定解锁用户
export const setLockedUser: Epic<PayloadIn, PayloadOut> = (action$, _store) => {
  return action$.pipe(
    ofType('system/setLockedEpic'),
    mergeMap(action => {
      return userAPI.handleSetIsLocked(action.payload).pipe(
        map(res => {
          if (res !== null) {
            return helpers.createAction<Payload>('system/setLocked', res.data);
          }
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};

// 停止与开启群呼任务
export const multipCallEnable: Epic<PayloadIn, PayloadOut> = (
  action$,
  _store,
) => {
  return action$.pipe(
    ofType('system/multipCallEnable'),
    mergeMap(action => {
      return userAPI.handleMultipCall(action.payload).pipe(
        map(res => {
          if (res !== null) {
            return helpers.createAction<Payload>('callCenter/taskListEpic', {
              page_size: 1000,
            });
          }
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};
