import { Observable, pipe, of } from 'rxjs';

import { map, mergeMap } from 'rxjs/operators';
import { Epic, ofType } from 'redux-observable';
import { helpers } from '@/utils';
import { externalCustomersAPI } from '@/services';
import { message } from 'antd';

interface Payload {
  [random: string]: any;
}
// dispatch传进来的action类型
interface PayloadIn {
  type: string;
  payload: {
    [random: string]: any;
  };
}

// epic传到reducer里的action类型
interface PayloadOut {
  type: string;
  payload: {
    [random: string]: any;
  };
}

// 获取信息
export const getTasks: Epic<PayloadIn, PayloadOut> = (action$, store) => {
  return action$.pipe(
    ofType('customers/externalCustomersEpic'),
    mergeMap(action => {
      return externalCustomersAPI.handleGet(action.payload).pipe(
        map(res => {
          if (res !== null) {
            return helpers.createAction<Payload>(
              'customers/externalCustomers',
              {
                list: res.data.data,
                total: res.data.total
              }
            );
          }
          return helpers.createAction<typeof action.payload>('no');
        })
      );
    })
  );
};

// // 获取信息
// export const getDetail: Epic<PayloadIn, PayloadOut> = (action$, store) => {
//   return action$.pipe(
//     ofType('customers/getExternalDetailEpic'),
//     mergeMap(action => {
//       return externalCustomersAPI.handleGet(action.payload).pipe(
//         map(res => {
//           if (res !== null) {

//           }
//           return helpers.createAction<typeof action.payload>('no');
//         })
//       );
//     })
//   );
// };

// 批量删除信息
export const removeETasks: Epic<PayloadIn, PayloadOut> = (action$, store) => {
  return action$.pipe(
    ofType('customers/removeExternalCustomersEpic'),
    mergeMap(action => {
      return externalCustomersAPI.handleDeletes(action.payload).pipe(
        map(res => {
          if (res !== null) {
            message.success('删除成功');
            return helpers.createAction<Payload>(
              'customers/updateExternalCustomers',
              {}
            );
          }
          return helpers.createAction<typeof action.payload>('no');
        })
      );
    })
  );
};

// 删除信息
export const removeTasks: Epic<PayloadIn, PayloadOut> = (action$, store) => {
  return action$.pipe(
    ofType('customers/removeExternalCEpic'),
    mergeMap(action => {
      return externalCustomersAPI.handleDelete(action.payload).pipe(
        map(res => {
          if (res !== null) {
            message.success('删除成功');
            return helpers.createAction<Payload>(
              'customers/externalCustomersEpic',
              {}
            );
          }
          return helpers.createAction<typeof action.payload>('no');
        })
      );
    })
  );
};

// 获取详情
export const getTasksDetails: Epic<PayloadIn, PayloadOut> = (
  action$,
  store
) => {
  return action$.pipe(
    ofType('customers/getExternalCustomersDetailEpic'),
    mergeMap(action => {
      return externalCustomersAPI.handleGetDetails(action.payload).pipe(
        map(res => {
          if (res !== null) {
            return helpers.createAction<Payload>(
              'customers/getExternalCustomersDetail',
              {
                list: res.data.data,
                total: res.data.total
              }
            );
          }
          return helpers.createAction<typeof action.payload>('no');
        })
      );
    })
  );
};
