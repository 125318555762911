import * as React from 'react';
import { Modal, Button, Input, Form, Select, DatePicker } from 'antd';
const { Option } = Select;
const confirm = Modal.confirm;

import {
  ContainerPropsInterface,
  ContainerStateInterface,
} from '@/routes/containerInterface';
import { helpers } from '@/utils';

export default class TaskAdd extends React.PureComponent<
  ContainerPropsInterface<any>,
  ContainerStateInterface
> {
  constructor(props) {
    super(props);
    this.state = {
      visible: false, // 是否显示标签添加窗口
      tempSelectList: [],
    };
  }

  public componentWillReceiveProps = nextProps => {
    const keyList = Object.keys(nextProps.state.callCenter.tempList);
    const list = keyList.map(item => {
      return {
        key: item,
        value: nextProps.state.callCenter.tempList[item],
      };
    });
    if (list.length > 0) {
      this.setState({
        tempSelectList: list,
      });
    }
  };

  // 获取列表数据
  public getData = () => {
    this.props.dispatch({
      type: 'callCenter/taskListEpic',
      payload: {
        page_size: 1000,
      },
    });
  };

  // 添加
  public handleAdd = () => {
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      const {
        taskName,
        taskTpl,
        maxCount,
        retryCount,
        retryData,
        startTime,
      } = values;

      this.props.dispatch({
        type: 'callCenter/insertCallTaskEpic',
        payload: {
          start_time: startTime.format('YYYY-MM-DD HH:mm:ss'),
          name: taskName,
          template_id: Number(taskTpl),
          maxtries: Number(retryCount),
          duplicate: Number(retryData),
          concurrent: Number(maxCount),
          cb: this.getData,
        },
      });
      // 隐藏标签添加窗口
      this.setState({
        visible: false,
      });
      // 清空输入框
      this.props.form.setFieldsValue({
        taskName: '', // 任务名称
        taskTpl: '', // 任务模板
        maxCount: '', // 并发数
        retryCount: '', // 失败重试次数
        retryData: '', // 重复数据
        startTime: '', // 启动时间
      });
    });
  };

  /**
   * 关闭弹窗
   *
   * @memberof TaskAdd
   */
  public closePopWindows = () => {
    this.props.form.setFieldsValue({
      taskName: '', // 任务名称
      taskTpl: '', // 任务模板
      maxCount: '', // 并发数
      retryCount: '', // 失败重试次数
      retryData: '', // 重复数据
      startTime: '', // 启动时间
    });
    this.setState({ visible: false });
  };

  // 取消隐藏标签添加窗口，并清空输入框
  public handleCancel = () => {
    // 获取表单数据
    const values = this.props.form.getFieldsValue();

    // 如果存在数据，则弹出提示，用户是否不保存
    for (const key in values) {
      if (values.hasOwnProperty(key) && values[key]) {
        return confirm({
          title: '关闭弹窗后不保存填写内容',
          onOk: () => {
            this.closePopWindows();
          },
        });
      }
    }
    // 不存在数据，则直接关闭
    this.closePopWindows();
  };

  // 正整数校验
  public isPositiveNum = (rule, value, callback) => {
    const isPositive = /^\d+$/.test(value);
    if (!isPositive) {
      callback('请输入正整数');
    } else {
      callback();
    }
  };

  // 渲染表单块
  public renderFrom = () => {
    return (
      <Modal
        title={'新增群呼任务'}
        visible={this.state.visible}
        onOk={this.handleAdd}
        onCancel={this.handleCancel}
        maskClosable={false}
        width={600}
        className={'modalFrom'}
      >
        <Form.Item label={'群呼任务：'}>
          {this.props.form.getFieldDecorator('taskName', {
            rules: [
              {
                required: true,
                message: '请输入群呼任务名',
              },
            ],
          })(<Input placeholder={'请输入群呼任务名称'} maxLength={32} />)}
        </Form.Item>
        <Form.Item label={'外呼模板'}>
          {this.props.form.getFieldDecorator('taskTpl', {
            rules: [
              {
                required: true,
                message: '请选择外呼模板',
              },
            ],
          })(
            <Select placeholder={'请选择外呼模板'}>
              {/* <Option value={'1'}>模板1</Option>
              <Option value={'2'}>模板2</Option> */}
              {this.state.tempSelectList.map(item => {
                return <Option value={item.key}>{item.value}</Option>;
              })}
            </Select>,
          )}
        </Form.Item>
        <Form.Item label={'并发数：'}>
          {this.props.form.getFieldDecorator('maxCount', {
            rules: [
              {
                required: true,
                message: '并发数不能为空',
              },
              {
                validator: (rule, value, callback) => {
                  callback(
                    /^\d+$/.test(value) && value > 0
                      ? undefined
                      : '请输入大于0的正整数',
                  );
                },
              },
            ],
          })(
            <Input
              type={'number'}
              placeholder={'请输入并发数'}
              maxLength={32}
            />,
          )}
        </Form.Item>
        <Form.Item label={'失败重复次数:'}>
          {this.props.form.getFieldDecorator('retryCount', {
            rules: [
              {
                required: true,
                message: '失败重复次数不能为空',
              },
              {
                validator: this.isPositiveNum,
              },
            ],
          })(
            <Input
              type={'number'}
              placeholder={'请输入失败重复次数'}
              maxLength={32}
            />,
          )}
        </Form.Item>
        <Form.Item label={'重复数据：'}>
          {this.props.form.getFieldDecorator('retryData', {
            rules: [
              {
                required: true,
                message: '重复数据不能为空',
              },
              {
                validator: this.isPositiveNum,
              },
            ],
          })(
            <Input
              type={'number'}
              placeholder={'请输入重复数据'}
              maxLength={32}
            />,
          )}
        </Form.Item>
        <Form.Item label={'启动时间：'}>
          {this.props.form.getFieldDecorator('startTime', {
            rules: [
              {
                required: true,
                message: '请选择启动时间',
              },
            ],
          })(
            <DatePicker
              showTime={{ format: 'HH:mm:ss' }}
              format={'YYYY-MM-DD HH:mm:ss'}
              placeholder={'请选择启动时间'}
            />,
          )}
        </Form.Item>
      </Modal>
    );
  };

  public render() {
    return (
      <React.Fragment>
        <Button
          type={'primary'}
          icon={'plus'}
          className={'pBtn fRight'}
          onClick={() => this.setState({ visible: true })}
        >
          新增群呼任务
        </Button>
        {this.renderFrom()}
      </React.Fragment>
    );
  }
}
