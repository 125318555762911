import * as _ from 'lodash';
import { history } from '../router';
import { Action } from '../reducers/reducerInterface';

const initialStore = {
  userList: [], // 用户列表
  visibleAdd: false // 用户添加弹框
};

export type AppStoreType = typeof initialStore;

export default function userReducer(state: any = initialStore, action: Action) {
  try {
    switch (action.type) {
      case 'system/getUserList': {
        const userList = action.payload;
        return _.assign({}, state, { userList });
      }
      case 'system/addUser': {
        const userList = [...state.userList, action.payload];
        return _.assign({}, state, { userList, visibleAdd: false });
      }
      case 'system/deleteUser': {
        const userList = state.userList.filter(
          obj => obj.id !== action.payload
        );
        return _.assign({}, state, { userList });
      }
      case 'system/updateUser': {
        let userList = state.userList.filter(
          obj => obj.id !== action.payload.id
        );
        userList = [action.payload, ...userList].sort((a, b) => a.id - b.id);
        return _.assign({}, state, { userList });
      }
      case 'system/addUserSuccess': {
        return _.assign({}, state, action.payload);
      }
      case 'system/setLocked': {
        // 批量处理操作
        let userList: any = [];
        state.userList.forEach(obj => {
          action.payload.forEach(item => {
            if (item.id === obj.id) {
              obj.is_locked = item.is_locked;
            }
          });
          userList.push(obj);
        });
        userList = [
          // ...state.userList.filter(obj => obj.id !== action.payload[0].id),
          ...userList
        ].sort((a, b) => a.id - b.id);
        return _.assign({}, state, { userList });
      }
      default:
        return state;
    }
  } catch (e) {
    return state;
  }
}
