import { request, servicPath } from '../utils';
import {
  createFormDataPostRequest,
  createFormDataPutRequest,
  createGetRequestByQuery,
  createDelRequestByQuery
} from './requestTransfer';

/**
 * 获取当前用户角色菜单列表
 */
export function handleGetRolesSetMenu(params) {
  return request(
    createGetRequestByQuery(`${servicPath}/products/roles/${params.id}`, params)
  );
}

/**
 * 获取所有角色菜单列表
 */
export function handleGetRolesAllMenu(params) {
  return request(
    createGetRequestByQuery(`${servicPath}/systems/permissions`, params)
  );
}

/**
 *更新权限菜单
 */
export function handleupdateRolesSet(params) {
  return request(
    createFormDataPutRequest(
      `${servicPath}/systems/rolepermission/${params.rolesId}`,
      params
    )
  );
}
