import { Observable, pipe, of } from 'rxjs';

import { map, mergeMap } from 'rxjs/operators';
import { Epic, ofType } from 'redux-observable';
import { helpers } from '@/utils';
import { smsAPI } from '@/services';
import { message } from 'antd';

interface Payload {
  [random: string]: any;
}
// dispatch传进来的action类型
interface PayloadIn {
  type: string;
  payload: {
    [random: string]: any;
  };
}

// epic传到reducer里的action类型
interface PayloadOut {
  type: string;
  payload: {
    [random: string]: any;
  };
}

// 获取短信记录
export const getSMS: Epic<PayloadIn, PayloadOut> = (action$, store) => {
  return action$.pipe(
    ofType('reports/getSMSEpic'),
    mergeMap(action => {
      return smsAPI.handleGetSMS(action.payload).pipe(
        map(res => {
          if (res !== null) {
            return helpers.createAction<Payload>('reports/getSMS', {
              list: res.data,
              total: res.total
            });
          }
          return helpers.createAction<typeof action.payload>('no');
        })
      );
    })
  );
};

// 批量重发送短信
export const batchSendSms: Epic<PayloadIn, PayloadOut> = (action$, store) => {
  return action$.pipe(
    ofType('reports/batchSendSmsEpic'),
    mergeMap(action => {
      return smsAPI.handleBatchSendSms(action.payload).pipe(
        map(res => {
          if (res !== null) {
            message.success('短信已发送');
            return helpers.createAction<Payload>(
              'reports/sms/autoUpdateList',
              {}
            );
          }
          return helpers.createAction<typeof action.payload>('no');
        })
      );
    })
  );
};
