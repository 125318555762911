import * as React from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import { Modal, Input, Form, AutoComplete, List, Icon } from 'antd';
const { TextArea } = Input;

interface RolesEditProps {
  editData: any;
  [random: string]: any;
}
interface RolesEditState {
  [random: string]: any;
}

export default class RolesEdit extends React.PureComponent<
  RolesEditProps,
  RolesEditState
> {
  constructor(props) {
    super(props);
    this.state = {
      visible: false, // 是否显示角色编辑窗口
      intro: '' // 角色说明
    };
  }

  // 传进来需要修改的原始数据
  public editData: any = {};

  // 修改角色
  public handleUpdate = () => {
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }

      this.props.dispatch({
        type: 'system/roles/updateRolesEpic',
        payload: {
          id: this.editData.id,
          role_name: values.roleName,
          intro: this.state.intro
        }
      });

      // 隐藏角色编辑窗口
      this.setState({ visible: false });
    });
  };

  public componentWillReceiveProps(nextProps) {
    // 绑定角色信息
    if (!_.isEqual(nextProps.editData, this.editData)) {
      this.editData = nextProps.editData;
      this.setState({
        visible: true,
        name: nextProps.editData.add_by,
        intro: nextProps.editData.intro
      });
      this.props.form.setFieldsValue({
        roleName: nextProps.editData.role_name
      });
    }
  }

  public render() {
    return (
      <React.Fragment>
        <Modal
          title={'编辑'}
          visible={this.state.visible}
          onOk={this.handleUpdate}
          maskClosable={false}
          onCancel={() => this.setState({ visible: false })}
          width={600}
          className={'modalFrom'}
        >
          <div>
            <div className={'ant-form-item-label'}>创建人：</div>
            <div className={'ant-form-item-control-wrapper'}>
              {this.state.name}
            </div>
          </div>
          <Form.Item label={'角色名称：'}>
            {this.props.form.getFieldDecorator('roleName', {
              rules: [
                {
                  required: true,
                  message: '请输入角色名称'
                }
              ]
            })(
              <Input
                placeholder={'请输入角色名称'}
                onChange={e => {
                  this.setState({ roleName: e.target.value });
                }}
              />
            )}
          </Form.Item>
          <div>
            <div className={'ant-form-item-label'}>描述：</div>
            <TextArea
              rows={4}
              placeholder={'请输入描述，最大可输入15个中文字符'}
              maxLength={15}
              value={this.state.intro}
              onChange={e => {
                this.setState({ intro: e.target.value });
              }}
            />
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
