import * as React from 'react';
import { helpers, servicPath } from '@/utils';
import { Switch, Modal, Button, Upload, message, Icon } from 'antd';
import * as _ from 'lodash';
import { XList } from '@/components';
import {
  ContainerPropsInterface,
  ContainerStateInterface,
} from '@/routes/containerInterface';
import { history } from '@/router';
import { customersAPI } from '@/services';

// 用户类型
export interface GroupCallTaskType {
  concurrent: string;
  duplicate: string;
  endTime: string;
  extensions: string;
  id: string;
  maxretries: string;
  name: string;
  startTime: string;
  templateid: string;
  userid: string;
}

// Task组件State类型
interface GroupCallTaskState extends ContainerStateInterface {
  list: GroupCallTaskType[];
  // 之前项目完全没有interface对数据类型进行规范
  // 先补充一个list，剩下的使用通配，之后再慢慢补充吧
  [key: string]: any;
}

export default class GroupCallTask extends React.PureComponent<
  ContainerPropsInterface<any>,
  GroupCallTaskState
> {
  constructor(props) {
    super(props);
    this.state = {
      list: [], // 群呼任务列表
      total: 0, // 总条数,
      page: {
        size: helpers.isJudge(localStorage.getItem('pageSize') === null)(
          10,
          localStorage.getItem('pageSize'),
        ), // 用户列表的每页大小
        num: 1, // 用户列表的当前页数
      },
    };
  }

  public columns = [
    {
      title: '序号',
      align: 'center',
      width: 50,
      widthAuto: true,
      render: (text, record, index) => (
        <span>
          {index +
            1 +
            this.state.page.num * this.state.page.size -
            this.state.page.size}
        </span>
      ),
    },
    {
      title: '外呼任务ID',
      dataIndex: 'id',
      width: 150,
      align: 'center',
    },
    {
      title: '任务创建人',
      dataIndex: 'userid',
      width: 150,
      align: 'center',
    },
    {
      title: '外呼任务名称',
      dataIndex: 'name',
      width: 150,
      align: 'center',
      render: (text, record) =>
        helpers.isJudge(_.includes(this.props.all.main.buttonPermission, 128))(
          <a
            href={'javascript:;'}
            onClick={() => {
              history.push(`/main/callCenter/groupCallTask/${record.id}`);
            }}
          >
            {text}
          </a>,
          text,
        ),
    },
    {
      title: '模板ID',
      dataIndex: 'templateid',
      width: 150,
      align: 'center',
    },
    {
      title: '任务并发数',
      dataIndex: 'concurrent',
      width: 150,
      align: 'center',
    },
    {
      title: '失败重呼次数',
      dataIndex: 'maxretries',
      width: 150,
      align: 'center',
    },
    {
      title: '重复数据多少天内不呼',
      dataIndex: 'duplicate',
      width: 180,
      align: 'center',
    },
    {
      title: '外呼分配的分机',
      dataIndex: 'extensions',
      width: 150,
      align: 'center',
    },
    {
      title: '任务开始时间',
      dataIndex: 'startTime',
      width: 150,
      align: 'center',
      render: text =>
        `${text.split('|*|')[0].replace(/\|/g, '-')} ${text
          .split('|*|')[1]
          .replace(/\|/g, ':')}`,
    },
    {
      title: '任务结束时间',
      dataIndex: 'endTime',
      width: 150,
      align: 'center',
      render: text =>
        `${text.split('|*|')[0].replace(/\|/g, '-')} ${text
          .split('|*|')[1]
          .replace(/\|/g, ':')}`,
    },
    {
      title: '任务状态',
      dataIndex: 'status',
      width: 150,
      align: 'center',
      render: text => {
        return parseInt(text, 10) === 1 ? (
          <p style={{ color: '#218216' }}>运行</p>
        ) : (
          <p>停止</p>
        );
      },
    },
    {
      title: '启用/停用群呼任务',
      align: 'center',
      width: 150,
      dataIndex: 'status',
      render: (text, record) => (
        <Switch
          checked={+text === 1}
          disabled={
            !(
              _.includes(this.props.all.main.buttonPermission, 124) &&
              _.includes(this.props.all.main.buttonPermission, 125)
            )
          }
          onChange={() =>
            this.setGroupCallTaskStatus(record.id, +record.status)
          }
        />
      ),
    },
    {
      title: '操作',
      align: 'center',
      width: 150,
      render: (_text, record) =>
        helpers.isJudge(_.includes(this.props.all.main.buttonPermission, 127))(
          <a
            href={'javascript:;'}
            onClick={() => this.setState({ visible: true, record })}
          >
            新增群呼任务
          </a>,
          null,
        ),
    },
  ];

  public renderModal = record => {
    return (
      <Modal
        title={'添加到群呼任务'}
        visible={this.state.visible}
        onOk={() => this.handleAdd(record)}
        maskClosable={false}
        onCancel={this.handleCancel}
        width={600}
        className={'modalFrom'}
      >
        <Upload.Dragger
          name={'file'}
          multiple={false}
          accept={
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          }
          customRequest={() => {
            return null;
          }}
          fileList={this.state.fileList}
          beforeUpload={file => {
            const formData = new FormData();
            formData.append('file', file);
            customersAPI.handleUploadCustomersExcel({
              url: `${servicPath}/products/importFile`,
              data: formData,
              success: res => {
                if (res.error_code === 6000) {
                  this.setState({
                    fileList: [
                      {
                        name: file.name,
                        status: 'done',
                        uid: res.data.fileName,
                      },
                    ],
                  });
                } else {
                  message.error(res.message);
                  this.setState({
                    fileList: undefined,
                  });
                }
              },
            });
            return true;
          }}
        >
          <p className={'ant-upload-drag-icon'}>
            <Icon type={'inbox'} />
          </p>
          <p className={'ant-upload-text'}>点击或拖拽文件到此上传</p>
        </Upload.Dragger>
      </Modal>
    );
  };
  public handleAdd = record => {
    this.importGroupCallTask(record);
  };
  // 隐藏，并清空
  public handleCancel = () => {
    this.setState({ visible: false });
  };
  // 导出
  public renderExport = () => {
    return (
      <>
        {helpers.isJudge(_.includes(this.props.all.main.buttonPermission, 123))(
          <Button
            style={{ float: 'right' }}
            icon={'download'}
            onClick={this.getCsvDownload}
          >
            导出Csv
          </Button>,
          null,
        )}
        {helpers.isJudge(_.includes(this.props.all.main.buttonPermission, 130))(
          <Button
            style={{ float: 'right' }}
            icon={'download'}
            onClick={this.getTemplateDownload}
          >
            下载明细数据导入模板
          </Button>,
          null,
        )}
      </>
    );
  };
  public getTemplateDownload = () => {
    this.props.dispatch({
      type: 'callCenter/downloadGroupCallTaskTemplate',
    });
  };
  // 导入群呼任务
  public importGroupCallTask = record => {
    this.props.dispatch({
      type: 'callCenter/addGroupCallTaskEpic',
      payload: {
        task_id: record.id,
        file_name: this.state.fileList[0].uid,
      },
    });
  };

  // 导出报表
  public getCsvDownload = () => {
    this.props.dispatch({ type: 'callCenter/downloadGroupCallTask' });
  };

  public setGroupCallTaskStatus(id, status) {
    // 如果为0，则说明当前处于停止状态
    const isStop = status !== 0;
    const text = helpers.isJudge(status === 0)('启用', '禁用');
    const self = this;
    Modal.confirm({
      title: `您确定要${text}该群呼任务吗？`,
      onOk() {
        new Promise(resolve =>
          resolve(() =>
            self.props.dispatch({
              type: 'system/multipCallEnable',
              payload: {
                task_id: id,
                isStop,
              },
            }),
          ),
        ).then(res => {
          // @ts-ignore
          res();
          self.getGroupCallTaskPageList();
        });
      },
    });
  }

  // 获取呼叫中心列表
  public getGroupCallTaskPageList = () => {
    this.props.dispatch({
      type: 'callCenter/groupCallTasksEpic',
      payload: {
        page_size: this.state.page.size, // 页面大小
        page: this.state.page.num, // 页码
      },
    });
  };

  // 重新拉取列表
  public getList = () => {
    localStorage.setItem('pageSize', this.state.page.size);
    this.props.dispatch({
      type: 'callCenter/groupCallTasksEpic',
      payload: {
        page_size: this.state.page.size, // 页面大小
        page: this.state.page.num, // 页码
      },
    });
  };

  // 拉取群呼列表
  public componentWillMount() {
    this.getGroupCallTaskPageList();
  }

  public componentWillReceiveProps(nextProps, nextState) {
    this.setState({
      list: nextProps.state.groupCallTaskList,
      total: nextProps.state.total,
    });
  }

  // 改变页号
  public changePage = (page, size) => {
    this.setState({ page: { num: page, size } }, this.getList);
  };

  // 改变每页大小
  public changePageSize = (page, size) => {
    this.setState({ page: { num: 1, size } }, this.getList);
  };

  // 列表模块渲染
  public render() {
    const { page: pageState, total, list, record } = this.state;
    return (
      <React.Fragment>
        {this.renderExport()}
        {this.renderModal(record)}
        <XList
          columns={this.columns}
          dataSource={list}
          scroll={{ x: 1600 }}
          rowKey={'id'}
          autoCalculation={true}
          pagination={{
            current: pageState.num,
            pageSize: pageState.size,
            total,
            showSizeChanger: true,
            showQuickJumper: true,
            onChange: this.changePage,
            onShowSizeChange: this.changePageSize,
            pageSizeOptions: ['10', '20', '30', '40'],
          }}
        />
      </React.Fragment>
    );
  }
}
