import * as React from 'react';
import { Modal, Button, Input, Form, Select } from 'antd';
import * as _ from 'lodash';
import { helpers } from '@/utils';
const Option = Select.Option;

interface CreateTaskEditProps {
  [random: string]: any;
}
interface CreateTaskEditState {
  [random: string]: any;
}
export default class CreateTask extends React.PureComponent<
  CreateTaskEditProps,
  CreateTaskEditState
> {
  constructor(props) {
    super(props);
    this.state = {
      visible: false, // 是否显示标签添加窗口
      tags: [], // 标签列表
      customers: [], // 客户列表
    };
  }

  public createTaskTimes = 0; // 创建任务次数

  public componentWillReceiveProps(nextProps) {
    // 标签列表绑定
    if (nextProps.all.tags.tags !== undefined) {
      if (
        nextProps.all.tags.tags.length !== 0 &&
        !_.isEqual(nextProps.all.tags.tags, this.state.tags)
      ) {
        this.setState({ tags: nextProps.all.tags.tags });
      }
    }

    // 如果创建成功
    if (this.createTaskTimes !== nextProps.all.customers.createTaskTimes) {
      this.createTaskTimes = nextProps.all.customers.createTaskTimes;
      // 清空输入框
      this.props.form.setFieldsValue({
        taskName: '',
        tigsId: undefined,
      });
      // 隐藏窗口
      this.setState({
        visible: false,
      });
    }
  }

  public componentWillMount() {
    // 获取标签列表
    if (this.props.all.tags.tags.length === 0) {
      this.getTags();
    }

    this.createTaskTimes = this.props.all.customers.createTaskTimes; // 初始化
  }

  // 获取标签
  public getTags = () => {
    this.props.dispatch({
      type: 'system/tags/getTagsEpic',
      payload: {
        page_size: 1000,
      },
    });
  };

  // 创建任务
  public handleAdd = () => {
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }

      this.props.dispatch({
        type: 'customers/addTasksEpic',
        payload: {
          task_name: values.taskName,
          tag_id: values.tigsId,
          customer_ids: this.props.selectedCustomers.toString(),
        },
      });
    });
  };

  // 隐藏，并清空
  public handleCancel = () => {
    this.props.form.setFieldsValue({
      taskName: '',
      tigsId: undefined,
    });
    this.setState({ visible: false });
  };

  // 渲染表单块
  public renderFrom = () => {
    return (
      <Modal
        title={'创建任务'}
        visible={this.state.visible}
        onOk={this.handleAdd}
        maskClosable={false}
        onCancel={this.handleCancel}
        width={600}
        className={'modalFrom'}
      >
        <div>
          <div className={'ant-form-item-label'}>当前客户总量：</div>
          <div className={'ant-form-item-control-wrapper'}>
            <strong>{this.props.selectedCustomers.length}</strong>
          </div>
        </div>
        <Form.Item label={'任务名称：'}>
          {this.props.form.getFieldDecorator('taskName', {
            rules: [
              {
                required: true,
                message: '请输入任务名称',
              },
            ],
          })(<Input placeholder={'请输入任务名称'} />)}
        </Form.Item>
        <Form.Item label={'分配标签：'}>
          {this.props.form.getFieldDecorator('tigsId', {
            rules: [
              {
                required: true,
                message: '请选择标签',
              },
            ],
          })(
            <Select placeholder={'请选择标签'}>
              {this.state.tags.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>,
          )}
        </Form.Item>
      </Modal>
    );
  };

  // 显示次数
  public showTimes = 0;

  public render() {
    return (
      <React.Fragment>
        {helpers.isJudge(this.props.selectedCustomers.length === 0)(
          null,
          <Button
            type={'primary'}
            icon={'plus'}
            className={'yellowBtn'}
            onClick={() => this.setState({ visible: true })}
            style={{ marginLeft: 10 }}
          >
            创建任务
          </Button>,
        )}
        {this.renderFrom()}
      </React.Fragment>
    );
  }
}
