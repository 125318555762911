import { request, servicPath } from '../utils';
import {
  createFormDataPostRequest,
  createFormDataPutRequest,
  createGetRequestByQuery,
  createDelRequestByQuery
} from './requestTransfer';

/**
 * 获取标签列表
 */
export function handleGetTags(params) {
  return request(
    createGetRequestByQuery(`${servicPath}/products/tags`, params)
  );
}

/**
 * 添加标签
 */
export function handleAddTags(params) {
  return request(
    createFormDataPostRequest(`${servicPath}/products/tags`, params)
  );
}

/**
 * 删除标签
 */
export function handleDeleteTags(params) {
  return request(
    createDelRequestByQuery(`${servicPath}/products/tags/${params.id}`)
  );
}

/**
 * 更新标签
 */
export function handleUpdateTags(params) {
  return request(
    createFormDataPutRequest(`${servicPath}/products/tags/${params.id}`, params)
  );
}

/**
 * 获取标签详情
 */
export function handleGetTagsSingle(params) {
  return request(
    createGetRequestByQuery(`${servicPath}/products/tags/${params.id}`)
  );
}
