/**
 * 带眼睛控制的pwd input
 */
import * as React from 'react';
import moment from 'moment';
import { Input } from 'antd';

interface ComponentProps {
  [random: string]: any;
}

interface ComponentState {
  [random: string]: any;
}

export default class InputPWD extends React.PureComponent<
  ComponentProps,
  ComponentState
> {
  constructor(props) {
    super(props);
    this.state = {
      now: moment().format('YYYY-MM-DD HH:mm:ss') // 当前时间
    };
  }

  // 时间interval
  public timeInterval;

  public componentWillMount() {
    clearInterval(this.timeInterval);
    this.timeInterval = setInterval(() => {
      this.setState({
        now: moment().format('YYYY-MM-DD HH:mm:ss')
      });
    }, 1000);
  }

  // 清除记时
  public componentWillUnmount() {
    clearInterval(this.timeInterval);
  }

  public render() {
    return <Input disabled={true} value={this.state.now} />;
  }
}
