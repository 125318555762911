import * as _ from 'lodash';
import { Action } from '../reducers/reducerInterface';

const initialStore = {
  assignmentsDetailed: [], // 列表
  total: 0 // 总数
};

export type AppStoreType = typeof initialStore;

export default function tasksReducer(
  state: any = initialStore,
  action: Action
) {
  try {
    switch (action.type) {
      case 'customers/assignmentsDetailed': {
        const assignmentsDetailed = action.payload.list;
        const total = action.payload.total;
        return _.assign({}, state, { assignmentsDetailed, total });
      }
      default:
        return state;
    }
  } catch (e) {
    return state;
  }
}
