import { request, servicPath } from '../utils';
import {
  createFormDataPostRequest,
  createFormDataPutRequest,
  createGetRequestByQuery,
  createDelRequestByQuery,
  createFormDataPatchRequest,
} from './requestTransfer';
/**
 * 用户登录
 * @param {object} params
 */
export function handleLogin(params) {
  return request(
    createFormDataPostRequest(`${servicPath}/users/tokens`, params),
  );
}

/**
 * 用户退出登录
 */
export function handleLogout() {
  return request(createDelRequestByQuery(`${servicPath}/users/tokens`));
}

/**
 * 获取登录用户信息
 */
export function handleGetLoginInfo() {
  return request(createGetRequestByQuery(`${servicPath}/users/tokens`));
}

/**
 * 添加用户
 */
export function handleAddUser(params) {
  return request(
    createFormDataPostRequest(`${servicPath}/users/accounts/`, params),
  );
}

/**
 * 修改用户
 */
export function handleUpdateUser(params) {
  return request(
    createFormDataPutRequest(
      `${servicPath}/users/accounts/${params.id}`,
      params,
    ),
  );
}

/**
 * 获取用户列表
 */
export function handleGetUserList(params) {
  return request(
    createGetRequestByQuery(`${servicPath}/users/accounts`, params),
  );
}

/**
 * 删除用户
 */
export function handleDeleteUser(params) {
  return request(
    createDelRequestByQuery(`${servicPath}/users/accounts/${params.id}`),
  );
}

// 设置账户是否禁用
export function handleSetIsLocked(params) {
  return request(
    createFormDataPatchRequest(
      `${servicPath}/users/accounts/${params.isLock}`,
      params,
    ),
  );
}

// 设置群呼助手，启动与停止
export function handleMultipCall(params) {
  const URL = params.isStop ? '/mixcall/stopTask' : '/mixcall/startTask';
  return request(
    createFormDataPostRequest(`${servicPath}${URL}`, {
      task_id: params.task_id,
    }),
  );
}
