import * as _ from 'lodash';
import { Action } from '../reducers/reducerInterface';

const initialStore = {
  roles: [] // 标签信息
};

export type AppStoreType = typeof initialStore;

export default function rolesReducer(
  state: any = initialStore,
  action: Action
) {
  try {
    switch (action.type) {
      // 获取标签列表
      case 'system/roles/getRoles': {
        const roles = action.payload;
        return _.assign({}, state, { roles });
      }
      case 'system/roles/addRoles': {
        const roles = [...state.roles, action.payload];
        return _.assign({}, state, { roles });
      }
      case 'system/roles/deleteRoles': {
        const roles = state.roles.filter(obj => obj.id !== action.payload);
        return _.assign({}, state, { roles });
      }
      case 'system/roles/updateRoles': {
        let roles = state.roles.filter(
          obj => obj.id !== action.payload.data.id
        );

        // 设置users_count
        action.payload.data.users_count = state.roles.filter(
          obj => obj.id === action.payload.data.id
        )[0].users_count;

        roles = [action.payload.data, ...roles].sort((a, b) => a.id - b.id);

        return _.assign({}, state, { roles });
      }
      default:
        return state;
    }
  } catch (e) {
    return state;
  }
}
