import * as React from 'react';
import * as _ from 'lodash';
import { Button, Input } from 'antd';
import {
  ContainerPropsInterface,
  ContainerStateInterface,
} from '@/routes/containerInterface';
import { bindCallback } from 'rxjs';

import agentStyle from './agent.scss';

export default class Agent extends React.PureComponent<
  ContainerPropsInterface<any>,
  ContainerStateInterface
> {
  constructor(props) {
    super(props);
    this.state = {
      list: [], // 坐席列表
      filterUserName: '', // 筛选的用户名
      filterSeatNumber: '', // 筛选的坐席号
      // 过滤对象组
      filterObj: {
        enable: false, // 是否启用过滤，不控制坐席状态
        username: '', // 过滤的名称
        agent: '', // 坐席id
        status: [], // 坐席状态
      },
    };
  }

  // 坐席列表
  public agentList: any = [];

  // 用户列表
  public userList: any = [];

  // 列表
  public list: any = [];

  // 定时获取器handler
  private _timer;
  private _isUnMounted: boolean = false;

  public componentDidMount() {
    const { userList } = this.props;

    // 获取或绑定用户列表
    if (userList.length === 0) {
      this.getUserList();
    }

    // 获取坐席列表
    this.getAllAgent();
  }

  public componentWillUnmount() {
    this._isUnMounted = true;
    clearTimeout(this._timer);
  }

  public componentWillReceiveProps(nextProps) {
    const { userList, agent } = nextProps;
    const { agentList } = agent;

    // 筛选列表数据
    if (userList.length !== 0 && agentList.length !== 0) {
      this.agentList = agentList;
      this.userList = userList;

      this.list = userList.filter(n => n.mixcall_account !== null);
      this.list = this.list.map(n => {
        return {
          name: n.name,
          agent: _.get(n, 'mixcall_account.employee_id'),
          status: _.get(
            agentList.filter(item => {
              return (
                item.employee_id === _.get(n, 'mixcall_account.employee_id')
              );
            })[0],
            'status',
          ),
        };
      });

      this.setState({ list: [...this.list] });
    }
  }

  // 监听
  public agentSpy = target_seat => {
    this.props.dispatch({
      type: 'records/agentSpyEpic',
      payload: {
        target_seat,
      },
    });
  };

  // 获取用户列表
  public getUserList = () => {
    this.props.dispatch({
      type: 'system/getUserListEpic',
      payload: {
        page_size: 1000,
      },
    });
  };

  // 获取坐席列表
  public getAllAgent = () => {
    clearTimeout(this._timer);
    this.props.dispatch({
      type: 'records/getAgentListEpic',
      payload: {
        callback: () => {
          if (this._isUnMounted) {
            return;
          }
          this._timer = setTimeout(() => this.getAllAgent(), 2000);
        },
      },
    });
  };

  // 筛选重置
  public handleFilterReset = () => {
    this.setState({
      filterSeatNumber: '',
      filterUserName: '',
      filterObj: {
        status: [],
        username: '', // 过滤的名称
        agent: '', // 坐席id
        enable: false,
      },
    });
  };

  // 筛选
  public handleFilter = () => {
    const { filterObj } = this.state;

    if (this.list.length !== 0) {
      this.setState({
        filterObj: {
          ...filterObj,
          agent: this.state.filterSeatNumber,
          username: this.state.filterUserName,
          enable: true,
        },
      });
    }
  };

  // 状态转html
  public statusToHtml = status => {
    let text;
    let color;

    status = parseInt(status, 10);
    // 这里要等Web把几个返回值枚举告知再继续重构
    switch (status) {
      case 0: // 空闲
      case 2: // 忙
        text = '在线';
        color = { color: '#16a085' };
        break;

      case 1: // 通话中
      case 8: // 振铃中
      case 16: // 保持中
        text = '通话中';
        color = { color: '#e74c3c' };
        break;

      case 4: // 未注册
        text = '离线';
        color = { color: '#96a3ac' };
        break;

      case -1: // 米糠云的不存在
      case 5: // 后端的不存在
      default:
        text = '不存在';
        color = { color: '#f39c12' };
    }

    return <p style={color}>{text}</p>;
  };

  // 列表模块渲染
  public renderList = () => {
    const { list, filterObj } = this.state;
    let data = list;

    // 如果有状态筛选
    if (filterObj.status.length > 0) {
      data = list.filter(n => filterObj.status.includes(n.status));
    }

    // 如果启用了筛选功能，则对数据进行筛选
    if (filterObj.enable) {
      data = data.filter(
        obj =>
          obj.name.indexOf(filterObj.username) !== -1 &&
          obj.agent.indexOf(filterObj.agent) !== -1,
      );
    }

    // 没有数据时显示一个暂无数据
    return data.length === 0 ? (
      <div style={{ textAlign: 'center', fontSize: '12px;' }}>暂无数据</div>
    ) : (
      <div className={agentStyle.agentList}>
        {data.map(item => (
          <div>
            {this.statusToHtml(item.status)}
            <span>
              坐席号：
              <span>{item.agent}</span>
            </span>
            <span>
              用户名：
              <span>{item.name}</span>
            </span>
            <div>
              {item.status === '1' ? (
                <span onClick={() => this.agentSpy(item.agent)}>监听</span>
              ) : (
                '监听'
              )}
            </div>
          </div>
        ))}
      </div>
    );
  };

  // 筛选模块渲染
  public renderFilter = () => {
    const { filterObj } = this.state;
    const { status } = filterObj;

    return (
      <div>
        <p>
          <span className={'notWrap'}>
            用户名：
            <Input
              placeholder={'请输入用户名'}
              value={this.state.filterUserName}
              onChange={e =>
                this.setState({
                  filterUserName: e.target.value,
                })
              }
            />
          </span>
          <span className={'notWrap'}>
            坐席号：
            <Input
              placeholder={'请输入坐席号'}
              value={this.state.filterSeatNumber}
              onChange={e =>
                this.setState({
                  filterSeatNumber: e.target.value,
                })
              }
            />
            <Button type={'primary'} onClick={this.handleFilter}>
              筛选
            </Button>
            <Button onClick={this.handleFilterReset}>重置</Button>
          </span>
        </p>
        <p>
          按坐席状态筛选：
          <Button
            className={`${agentStyle.gayButton} ${
              status.includes('4') ? agentStyle.active : ''
            }`}
            onClick={() =>
              this.setState({
                filterObj: {
                  ...filterObj,
                  status: status.includes('4')
                    ? status.filter(n => n !== '4')
                    : [...status, '4'],
                },
              })
            }
          >
            离线
          </Button>
          <Button
            className={`${agentStyle.greenButton} ${
              status.includes('0') ? agentStyle.active : ''
            }`}
            onClick={() =>
              this.setState({
                filterObj: {
                  ...filterObj,
                  status:
                    status.includes('0') && status.includes('2')
                      ? status.filter(n => n !== '0' && n !== '2')
                      : [...status, '0', '2'],
                },
              })
            }
          >
            在线
          </Button>
          <Button
            className={`${agentStyle.redButton} ${
              status.includes('1') ? agentStyle.active : ''
            }`}
            onClick={() =>
              this.setState({
                filterObj: {
                  ...filterObj,
                  status: status.includes('1')
                    ? status.filter(n => n !== '1')
                    : [...status, '1'],
                },
              })
            }
          >
            通话中
          </Button>
        </p>
      </div>
    );
  };

  public render() {
    return (
      <React.Fragment>
        {this.renderFilter()}
        {this.renderList()}
      </React.Fragment>
    );
  }
}
