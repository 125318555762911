import { map, mergeMap } from 'rxjs/operators';
import { Epic, ofType } from 'redux-observable';
import { helpers } from '@/utils';
import { callCenterAPI } from '@/services';
import { message } from 'antd';
import { history } from '@/router';

interface Payload {
  [random: string]: any;
}

// dispatch传进来的action类型
interface PayloadIn {
  type: string;
  payload: {
    [random: string]: any;
  };
}

// epic传到reducer里的action类型
interface PayloadOut {
  type: string;
  payload: {
    [random: string]: any;
  };
}

// 呼叫中心 任务管理  编辑任务
export const editTask: Epic<PayloadIn, PayloadOut> = (action$, _store) => {
  return action$.pipe(
    ofType('callCenter/editTaskEpic'),
    mergeMap(action => {
      return callCenterAPI.handleEditTask(action.payload).pipe(
        map(res => {
          if (res !== null && res.error_code === 6000) {
            const { cb } = action.payload;
            // 更新任务管理列表数据
            cb();
            message.success('编辑成功');
          }
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};

// 呼叫中心 任务管理  模板下拉数据
export const getTempData: Epic<PayloadIn, PayloadOut> = (action$, _store) => {
  return action$.pipe(
    ofType('callCenter/getTempEpic'),
    mergeMap(action => {
      return callCenterAPI.handleGetTempData(action.payload).pipe(
        map(res => {
          if (res !== null) {
            return helpers.createAction<Payload>('callCenter/tempList', {
              tempList: res.data.data.msg,
            });
          }
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};

// 呼叫中心 群呼任务  列表
export const getGroupCallTask: Epic<PayloadIn, PayloadOut> = (
  action$,
  _store,
) => {
  return action$.pipe(
    ofType('callCenter/groupCallTasksEpic'),
    mergeMap(action => {
      return callCenterAPI.handleGetGroupCallTaskList(action.payload).pipe(
        map(res => {
          if (res !== null) {
            return helpers.createAction<Payload>('callCenter/groupCallTask', {
              groupCallTaskList: res.data.data,
            });
          }
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};
// 呼叫中心 群呼任务  列表
export const getGroupCallTaskDetail: Epic<PayloadIn, PayloadOut> = (
  action$,
  _store,
) => {
  return action$.pipe(
    ofType('callCenter/groupCallTaskDetailEpic'),
    mergeMap(action => {
      return callCenterAPI.handleGetGroupCallTaskDetail(action.payload).pipe(
        map(res => {
          if (res !== null) {
            return helpers.createAction<Payload>(
              'callCenter/groupCallTaskDetail',
              {
                groupCallTaskDetail: res.data.data,
              },
            );
          }
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};
// 新增任务
export const handleAddGroupCallTask: Epic<PayloadIn, PayloadOut> = (
  action$,
  _store,
) => {
  return action$.pipe(
    ofType('callCenter/addGroupCallTaskEpic'),
    mergeMap(action => {
      return callCenterAPI.handleAddGroupCallTask(action.payload).pipe(
        map(res => {
          if (res !== null && res.error_code === 6000) {
            const { cb } = action.payload;
            // 更新任务管理列表数据
            cb();
            message.success('新增成功');
          }
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};

// 新增任务
export const insertCallTask: Epic<PayloadIn, PayloadOut> = (
  action$,
  _store,
) => {
  return action$.pipe(
    ofType('callCenter/insertCallTaskEpic'),
    mergeMap(action => {
      return callCenterAPI.handleInsertCallTask(action.payload).pipe(
        map(res => {
          if (res !== null && res.error_code === 6000) {
            // return helpers.createAction<Payload>('callCenter/tasks', {
            //   list: res.data.data,
            //   total: res.data.total,
            //   type: action.payload.type
            // });
            const { cb } = action.payload;
            // 更新任务管理列表数据
            cb();
            message.success('新增成功');
          }
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};

// 导出群呼任务
export const downloadGroupCallTask: Epic<PayloadIn, PayloadOut> = (
  action$,
  _store,
) => {
  return action$.pipe(
    ofType('callCenter/downloadGroupCallTask'),
    mergeMap(action => {
      return callCenterAPI.handleGetGroupCallTaskDownload().pipe(
        map(res => {
          if (res !== null) {
            const blob = new Blob([res]);
            const a = document.createElement('a');
            a.download = '导出群呼任务.xlsx';
            a.href = window.URL.createObjectURL(blob);
            // 解决火狐下载兼容问题
            document.body.appendChild(a);
            setTimeout(() => {
              document.body.removeChild(a);
            }, 1000);
            a.click();
          }
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};
// 下载明细数据导入模板
export const downloadGroupCallTaskTemplate: Epic<PayloadIn, PayloadOut> = (
  action$,
  _store,
) => {
  return action$.pipe(
    ofType('callCenter/downloadGroupCallTaskTemplate'),
    mergeMap(action => {
      return callCenterAPI
        .handleGetGroupCallTaskTemplateDownload(action.payload)
        .pipe(
          map(res => {
            if (res !== null) {
              const blob = new Blob([res]);
              const a = document.createElement('a');
              a.download = '导出群呼任务.xlsx';
              a.href = window.URL.createObjectURL(blob);
              // 解决火狐下载兼容问题
              document.body.appendChild(a);
              setTimeout(() => {
                document.body.removeChild(a);
              }, 1000);
              a.click();
            }
            return helpers.createAction<typeof action.payload>('no');
          }),
        );
    }),
  );
};
export const downloadGroupCallTaskDetail: Epic<PayloadIn, PayloadOut> = (
  action$,
  _store,
) => {
  return action$.pipe(
    ofType('callCenter/downloadGroupCallTaskDetail'),
    mergeMap(action => {
      return callCenterAPI
        .handleGetGroupCallTaskDetailDownload(action.payload)
        .pipe(
          map(res => {
            if (res !== null) {
              const blob = new Blob([res]);
              const a = document.createElement('a');
              a.download = '导出群呼任务详情.xlsx';
              a.href = window.URL.createObjectURL(blob);
              // 解决火狐下载兼容问题
              document.body.appendChild(a);
              setTimeout(() => {
                document.body.removeChild(a);
              }, 1000);
              a.click();
            }
            return helpers.createAction<typeof action.payload>('no');
          }),
        );
    }),
  );
};
// 获取呼叫中心信息
export const getCallCenter: Epic<PayloadIn, PayloadOut> = (action$, _store) => {
  return action$.pipe(
    ofType('callCenter/tasksEpic'),
    mergeMap(action => {
      return callCenterAPI.handleGetCallCenterList(action.payload).pipe(
        map(res => {
          if (res !== null) {
            return helpers.createAction<Payload>('callCenter/tasks', {
              list: res.data.data,
              total: res.data.total,
              type: action.payload.type,
            });
          }
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};

// 退回
export const setTasksRollback: Epic<PayloadIn, PayloadOut> = (
  action$,
  _store,
) => {
  return action$.pipe(
    ofType('callCenter/rollbackTasksEpic'),
    mergeMap(action => {
      return callCenterAPI.handleTasksRollback(action.payload).pipe(
        map(res => {
          if (res !== null) {
            message.success('退回成功');
            return helpers.createAction<Payload>(
              'callCenter/updateCallCenterList',
              {},
            );
          }
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};

// 已处理
export const dealWith: Epic<PayloadIn, PayloadOut> = (action$, _store) => {
  return action$.pipe(
    ofType('callCenter/dealWithEpic'),
    mergeMap(action => {
      return callCenterAPI.handleDealWith(action.payload).pipe(
        map(res => {
          if (res !== null) {
            return helpers.createAction<Payload>(
              'callCenter/updateCallCenterList',
              {},
            );
          }
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};

// 获取受阻
export const setTasksBlocked: Epic<PayloadIn, PayloadOut> = (
  action$,
  _store,
) => {
  return action$.pipe(
    ofType('callCenter/blockedEpic'),
    mergeMap(action => {
      return callCenterAPI.handleGetBlocked(action.payload).pipe(
        map(res => {
          if (res !== null) {
            return helpers.createAction<Payload>(
              'callCenter/updateCallCenterList',
              {},
            );
          }
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};

// 更新受阻
export const updateBlocked: Epic<PayloadIn, PayloadOut> = (action$, _store) => {
  return action$.pipe(
    ofType('callCenter/updateBlockedEpic'),
    mergeMap(action => {
      return callCenterAPI.handleUpdateBlocked(action.payload).pipe(
        map(res => {
          if (res !== null) {
            message.success('操作成功');
            return helpers.createAction<Payload>(
              'callCenter/updateCallCenterList',
              {},
            );
          }
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};

// 获取当前客服未完成任务数量
export const getTasksBadgeamount: Epic<PayloadIn, PayloadOut> = (
  action$,
  _store,
) => {
  return action$.pipe(
    ofType('callCenter/getTasksBadgeamountEpic'),
    mergeMap(action => {
      return callCenterAPI.handleGetTasksBadgeamount(action.payload).pipe(
        map(res => {
          if (res !== null) {
            return helpers.createAction<Payload>(
              'callCenter/getTasksBadgeamount',
              res.data,
            );
          }
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};

// 呼叫
export const call: Epic<PayloadIn, PayloadOut> = (action$, _store) => {
  return action$.pipe(
    ofType('callCenter/callEpic'),
    mergeMap(action => {
      return callCenterAPI.handleCall(action.payload).pipe(
        map(res => {
          if (res !== null) {
            const { pathname } = history.location;
            const path = pathname
              ? pathname
                  .split('/')
                  .slice(0, 4)
                  .join('/')
              : '';
            // const path =
            //   'main/callCenter' +
            //   JSON.parse(sessionStorage.getItem('getListObj') as any).type;
            const url =
              path +
              '/' +
              action.payload.id +
              '/' +
              (action.payload.relationshipId || action.payload.obstruct_id); // 后端设计接口时，仅仅存单受阻的表单数据与其他栏目字段名不同，所以这里要做||判断
            localStorage.setItem('callingUrl', url);

            history.replace(url);
            // 初始化电话时间
            const endTime = new Date().getTime();
            localStorage.setItem('callrecordsId', res.data.call_record_id); // 保存记录id
            localStorage.setItem('callEndTime', endTime + '');
            localStorage.setItem('callStartTime', endTime + '');

            return helpers.createAction<Payload>(
              'callCenter/setCallStartTime',
              {
                callStartTime: -1,
              },
            );
          }
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};

// 挂机
export const hungUp: Epic<PayloadIn, PayloadOut> = (action$, _store) => {
  return action$.pipe(
    ofType('callCenter/hungUpEpic'),
    mergeMap(action => {
      return callCenterAPI.handleHungUp(action.payload).pipe(
        map(res => {
          if (res !== null) {
            // history.push(history.location.pathname + '/' + action.payload.id);
          }
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};

// 静音
export const muteVoice: Epic<PayloadIn, PayloadOut> = (action$, _store) => {
  return action$.pipe(
    ofType('callCenter/muteVoiceEpic'),
    mergeMap(action => {
      return callCenterAPI.handleMuteVoice(action.payload).pipe(
        map(() => {
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};

// 静音
export const unmuteVoice: Epic<PayloadIn, PayloadOut> = (action$, _store) => {
  return action$.pipe(
    ofType('callCenter/unmuteVoiceEpic'),
    mergeMap(action => {
      return callCenterAPI.handleUnmuteVoice(action.payload).pipe(
        map(() => {
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};

// 保持和取消保持
export const callHold: Epic<PayloadIn, PayloadOut> = (action$, _store) => {
  return action$.pipe(
    ofType('callCenter/hold'),
    mergeMap(action => {
      return callCenterAPI.handleCallHold().pipe(
        map(() => {
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};

// 发送短信
export const sendSms: Epic<PayloadIn, PayloadOut> = (action$, _store) => {
  return action$.pipe(
    ofType('callCenter/sendSmsEpic'),
    mergeMap(action => {
      return callCenterAPI.handleSendSms(action.payload).pipe(
        map(res => {
          if (res !== null) {
            message.success('短信已发送');

            if (action.payload.type === 2) {
              return helpers.createAction<Payload>(
                'reports/sms/autoUpdateList',
                {},
              );
            }
          }
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};

// 获取登录状态
export const getLoginState: Epic<PayloadIn, PayloadOut> = (action$, _store) => {
  return action$.pipe(
    ofType('polylink/getAgentInfoEpic'),
    mergeMap(action => {
      return callCenterAPI.handleAuthByToken().pipe(
        map(res => {
          if (res !== null) {
            return helpers.createAction<Payload>('polylink/getAgentInfo', {
              extensionState: res.data.user.extensionState,
            });
          }
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};

// 心跳
export const callServer: Epic<PayloadIn, PayloadOut> = (action$, _store) => {
  return action$.pipe(
    ofType('polylink/callServerEpic'),
    mergeMap(action => {
      return callCenterAPI.handleCallServer().pipe(
        map(() => {
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};

// 更改坐席状态
export const changeStatus: Epic<PayloadIn, PayloadOut> = (action$, _store) => {
  return action$.pipe(
    ofType('polylink/changeStatusEpic'),
    mergeMap(action => {
      return callCenterAPI.handleChangeStatus(action.payload).pipe(
        map(res => {
          if (res !== null) {
            // return helpers.createAction<Payload>('polylink/setExtensionState', {
            //   extensionState: action.payload.stateId
            // });
          }
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};

// 保存用户信息
export const saveUserInfo: Epic<PayloadIn, PayloadOut> = (action$, _store) => {
  return action$.pipe(
    ofType('polylink/saveUserInfoEpic'),
    mergeMap(action => {
      return callCenterAPI.handleSaveUserInfo(action.payload).pipe(
        map(res => {
          if (res !== null) {
            //
          }
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};

// 登录cti
export const loginCti: Epic<PayloadIn, PayloadOut> = (action$, _store) => {
  return action$.pipe(
    ofType('polylink/loginCtiEpic'),
    mergeMap(action => {
      return callCenterAPI.handleLoginCti(action.payload).pipe(
        map(res => {
          if (res !== null) {
            return helpers.createAction<Payload>('polylink/setExtensionState', {
              extensionState: parseInt(action.payload.initStatus, 10),
            });
          }
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};

// 登出cti
export const signOutCti: Epic<PayloadIn, PayloadOut> = (action$, _store) => {
  return action$.pipe(
    ofType('polylink/signOutCtiEpic'),
    mergeMap(action => {
      return callCenterAPI.handleCtiSignOut(action.payload).pipe(
        map(res => {
          if (res !== null) {
            return helpers.createAction<Payload>('polylink/setExtensionState', {
              extensionState: -1,
            });
          }
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};

/**
 * 米糠云
 */

// 获取坐席状态
export const getAgentState: Epic<PayloadIn, PayloadOut> = (action$, _store) => {
  return action$.pipe(
    ofType('mixcall/getStatusEpic'),
    mergeMap(action => {
      return callCenterAPI.handleGetAgentState().pipe(
        map(res => {
          if (res !== null) {
            const exten = action.payload.exten;
            const extensionState =
              res.data.extenstatus.data &&
              res.data.extenstatus.data.extenList[exten];
            return helpers.createAction<Payload>('polylink/getAgentInfo', {
              extensionState: Number(extensionState),
              agentState: parseInt(res.data.dndstatus.data.status, 10),
            });
          }
          return helpers.createAction<typeof action.payload>('no');
        }),
      );
    }),
  );
};
