import * as React from 'react';
import { helpers } from '@/utils';
import moment from 'moment';
import {
  Select,
  DatePicker,
  Button,
  Input,
  Icon,
  Modal,
  message,
  Popover,
} from 'antd';

const confirm = Modal.confirm;
import * as _ from 'lodash';
import { Alert1, XList } from '@/components';
import SendSms from '../sendSms/sendSmsPage';

const { RangePicker } = DatePicker;
const Option = Select.Option;
import {
  ContainerPropsInterface,
  ContainerStateInterface,
} from '@/routes/containerInterface';
import { history } from '@/router';
import { multiPlatform } from '@/multiPlatform';
import { binarySearchByKey } from '@/utils/binarySearch';
import tasksStyle from './tasksStyle.scss';

// 标签对应的ID
export const TAG_ID = {
  newRegistration: 1,
  sleeping: 2,
  blocked: 29,
  groupMaintenance: 3,
  membersWithoutDeposit: 5,
  externalCustomers: 4,
}; // tag 对象

// 用户类型
export interface CustomerType {
  app_id: number;
  call_notes: string[];
  callout_count: number;
  created_at: string;
  customer: any[];
  customer_id: number;
  id: number;
  import_record_id: number;
  is_handled: 0;
  market_status_id: number;
  operator_id: number;
  product_id: number;
  status: number;
  task_id: number;
  task_name: string;
  updated_at: string;
  user_id: number;
}

// 营销账号数据类型
export interface MarketAccountType {
  account_type: string;
  account_no: string;
  id: number;

  // 原因同TaskState
  [key: string]: any;
}

// Task组件State类型
interface TaskState extends ContainerStateInterface {
  list: CustomerType[];
  // 之前项目完全没有interface对数据类型进行规范
  // 先补充一个list，剩下的使用通配，之后再慢慢补充吧
  [key: string]: any;
}

export default class Tasks extends React.PureComponent<
  ContainerPropsInterface<any>,
  TaskState
> {
  constructor(props) {
    super(props);
    this.state = {
      list: [], // 呼叫中心列表
      marketingAccounts: [], // 营销账户列表
      marketStatus: [], // 行销状态列表
      total: 0, // 总条数,
      num: [], // 被分配数量
      extensionState: '-1', // 坐席状态
      showTimes: 0, // 显示发短信窗口次数
      customer_id: '', // 客户id
      relationshipId: '', // 关系id
      // 过滤对象组
      filterObj: {
        ...this.filterObj,
      },
      phoneFlag: false,
      messageFlag: false,
      markFlag: false,
      wechatFlag: false,
      page: {
        size: helpers.isJudge(localStorage.getItem('pageSize') === null)(
          100,
          localStorage.getItem('pageSize'),
        ), // 用户列表的每页大小
        num: helpers.isJudge(localStorage.getItem('page') === null)(
          1,
          localStorage.getItem('page'),
        ), // 用户列表的当前页数
      },
      wechatSelect: {}, // 微信的选中
    };
  }

  public phone_line = parseInt(sessionStorage.getItem('phone_line') + '', 10); // 电话类型

  // 用于分页时可以使用上次的筛选操作
  public filterObj = {
    username: '', // 过滤的名称
    source_id: -1, // 过滤的客户来源
    deposit_count: -1, // 过滤的存款次数
    import_date_start: '', // 导入开始日期
    import_date_end: '', // 导入开始日期
    register_time_start: '', // 注册时间范围开始
    register_time_end: '', // 注册时间范围截至
    not_login_after: -1, // 过滤的最后登录天
    market_status_id: undefined, // 行销状态
    task_name: '', // 任务名
  };

  // 发送邮件组件
  public SendSmsComponent = multiPlatform.sendSmsMulti(SendSms);

  public typeName = ''; // 按标签来分类
  public updateTimes = 0; // 被动更新次数

  public getColumns = (): any => {
    let data = [
      {
        title: '用户名',
        dataIndex: 'customer.username',
        width: 220,
        render: (text, record) => (
          <span className={'greenPointCell'}>
            {helpers.isJudge(record.is_handled === 0)(<span />, null)}
            {text}
          </span>
        ),
      },
      {
        title: '真实姓名',
        align: 'center',
        dataIndex: 'customer.x_real_name',
        width: 150,
      },
      {
        title: '行销结果',
        align: 'center',
        dataIndex: 'market_status_id',
        width: 150,
        render: (text) => (
          <span>{_.get(this.state.marketStatus[text], 'name')}</span>
        ),
      },
      {
        title: '手机号码',
        align: 'right',
        dataIndex: 'customer.x_phone',
        width: 200,
        minWidth: 180,
        render: (text, record) => (
          <p className={'phone'}>
            <span
              style={{
                lineHeight: '14px',
                width: 100,
                overflow: 'hidden',
                padding: 0,
              }}
            >
              {text}
            </span>{' '}
            {helpers.isJudge(this.state.messageFlag)(
              <Icon
                type={'mail'}
                theme={'filled'}
                style={{
                  fontSize: '18px',
                  marginLeft: 15,
                  cursor: 'pointer',
                }}
                onClick={() =>
                  this.setState({
                    showTimes: this.state.showTimes + 1,
                    customer_id: record.customer_id,
                    relationshipId: record.id,
                  })
                }
              />,
              null,
            )}{' '}
            {helpers.isJudge(this.state.phoneFlag)(
              helpers.isJudge(this.state.extensionState === 0)(
                <Icon
                  type={'phone'}
                  theme={'filled'}
                  style={{
                    fontSize: '18px',
                    marginLeft: 15,
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    this.call(record.customer_id, record.id, record.task_id)
                  }
                />,
                <Icon
                  type={'phone'}
                  theme={'filled'}
                  style={{
                    fontSize: '18px',
                    marginLeft: 15,
                    cursor: 'pointer',
                    color: '#6c7c8e',
                  }}
                  onClick={() => message.warning('当前状态，无法呼叫')}
                />,
              ),
              null,
            )}
          </p>
        ),
      },
      {
        title: '注册时间',
        align: 'center',
        dataIndex: 'customer.register_time',
        width: 150,
        render: (text) =>
          text.slice(0, 10) === '0000-00-00' ? null : (
            <time>
              {text.slice(0, 10)}
              <br />
              {text.slice(10)}
            </time>
          ),
      },
      {
        title: '存款次数',
        align: 'center',
        dataIndex: 'customer.deposit_count',
        width: 150,
      },
      {
        title: '最近存入金额',
        align: 'center',
        dataIndex: 'customer.last_deposit_amount',
        width: 200,
      },
      {
        title: '导入时间',
        dataIndex: 'customer',
        align: 'center',
        width: 150,
        render: (text) => (
          <time>
            {text.import_date.slice(0, 10)}
            <br />
            {text.import_date.slice(10)}
          </time>
        ),
      },
      {
        title: '回访备注',
        dataIndex: '回访备注',
        align: 'center',
        width: 150,
        minWidth: 150,
        render: (text, record) => {
          // 第一条备注
          const [firstNote] = record.call_notes || [undefined];
          const placeholder = firstNote ? (
            <Popover content={<span>{firstNote.note}</span>}>
              <span
                style={{
                  maxWidth: 'calc(100% - 30px)',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  lineHeight: '1em',
                  verticalAlign: 'inherit',
                  display: 'inline-block',
                }}
              >
                {firstNote.note}
              </span>
            </Popover>
          ) : null;
          return helpers.isJudge(this.state.markFlag)(
            <React.Fragment>
              {placeholder}
              <a
                href={'javascript:;'}
                onClick={() => {
                  history.push(
                    history.location.pathname +
                      '/' +
                      record.customer_id +
                      '/' +
                      record.id,
                  );
                }}
              >
                全部
              </a>
            </React.Fragment>,
            null,
          );
        },
      },
      {
        title: '已添加微信或QQ',
        dataIndex: 'customer',
        width: 200,
        render: (text, record) => {
          const { marketingAccounts } = this.state;
          const {
            wechat_market_account_id: wechat,
            qq_group_market_account_id: qq,
          } = text;
          // 将所有的营销账号转换为下拉选项组件列表
          const options = marketingAccounts
            .filter((item) => {
              // 后端接受任意类型数据的输入以及返回
              // 为了防止不合法数据的显示，前端需要进行过滤
              // 这里应该由后端进行后期调整
              const type = item.account_type.toLowerCase();
              return type === 'qq' || type === '微信';
            })
            .map((item) => {
              return (
                <Option key={item.id}>
                  {Tasks.formatMarketAccountDisplay(item)}
                </Option>
              );
            });
          // 设定显示的值
          // 如果用户中绑定了QQ则使用QQ信息显示
          // 若绑定了微信，则使用微信信息显示
          // 否则为空
          // 查找对应的营销账号
          const marketAccount = this.getMarketAccountFromId(wechat || qq);
          const valueData = Tasks.formatMarketAccountDisplay(marketAccount);

          // 返回选项框
          return helpers.isJudge(this.state.wechatFlag)(
            <Select
              style={{ width: '150px' }}
              allowClear
              placeholder={'请选择'}
              value={valueData}
              onChange={(value) => {
                const obj: any = {};
                // 查找对应的营销账号
                const account = this.getMarketAccountFromId(
                  parseInt(value, 10),
                );

                // Web并没有返回枚举类型来区别微信和QQ
                // 这个功能经过与Web确认，目前他们没办法修改
                // 所以先用string字段来判断类型区别
                if (account) {
                  if (account.account_type.toLowerCase() === 'qq') {
                    obj.qq_group_id = value;
                  } else if (account.account_type === '微信') {
                    obj.wechat_id = value;
                  }
                } else {
                  obj.wechat_id = '';
                  obj.qq_group_id = '';
                }
                this.updateCustomersInfo(record.customer.id, obj);
              }}
            >
              {options}
            </Select>,
            null,
          );
        },
      },
      {
        title: '任务名称',
        dataIndex: 'task_name',
        width: 250,
        align: 'center',
        render: (text, record) => <span>{record.task_name}</span>,
      },
    ];

    // 群维护模式下，增加一个操作功能
    if (this.typeName === 'groupMaintenance') {
      data = [
        ...data,
        {
          title: '操作',
          dataIndex: '操作',
          align: 'center',
          width: 100,
          render: (text, record) =>
            helpers.isJudge(
              _.includes(this.props.all.main.buttonPermission, 116),
            )(
              <span>
                <a
                  href={'javascript:;'}
                  onClick={() => this.tasksRollback(record.id)}
                >
                  退回
                </a>
              </span>,
              null,
            ),
        },
      ];
    }
    return data;
  };

  /**
   * 格式化显示营销账号的名称
   * 其他组件有些因为历史原因，并非继承于Task组件，所以这里抽出为静态方法，提供其他组件使用
   * @param acc {MarketAccountType}
   * @return {string|undefined}
   */
  public static formatMarketAccountDisplay(
    acc: MarketAccountType,
  ): string | undefined {
    return _.isObject(acc) ? acc.account_type + acc.account_no : undefined;
  }

  /**
   * 通过ID查找对应的营销账号
   * @param id {number}
   * @return {MarketAccountType}
   */
  public getMarketAccountFromId(id: number): MarketAccountType {
    // 因为营销列表属于有序数据
    // 利用有序数数组可二分的特性，加速查找效率
    return binarySearchByKey<MarketAccountType>(
      this.state.marketingAccounts,
      id,
      'id',
    );
  }

  // 获取营销状态
  public getMarketStatu = () => {
    this.props.dispatch({
      type: 'reports/getMarketStatusEpic',
      payload: {},
    });
  };

  // 修改客户相关的信息
  public updateCustomersInfo = (id, obj) => {
    this.props.dispatch({
      type: 'customers/updateCustomersEpic',
      payload: {
        id,
        qq_group_id: obj.qq_group_id, // qq群
        wechat_id: obj.wechat_id, // 绑定微信
      },
    });
  };

  // 这里属于旧代码，因为不了解是否有其他场景赋值，所以使用解构方式赋值，防止对源数据产生影响
  public tagsId = { ...TAG_ID }; // tag 对象

  public user_id; // 用户保存用户id

  // 呼叫
  public call = (id, relationshipId, task_id) => {
    this.props.dispatch({
      type: 'callCenter/callEpic',
      payload: {
        id,
        tag_id: this.tagsId[this.typeName], // 标签id
        relationshipId, // 用户客户关系id
        task_id, // 任务id
      },
    });

    // 已处理
    // this.props.dispatch({
    //   type: 'callCenter/dealWithEpic',
    //   payload: {
    //     relationshipId, // 用户客户关系id
    //     task_id,
    //   },
    // });
  };

  // 获取呼叫中心列表
  public getCallCenterList = () => {
    const getListObj = {
      type: window.location.pathname.slice(
        window.location.pathname.lastIndexOf('/') + 1,
      ), // 类型
      not_login_after: helpers.isJudge(
        this.state.filterObj.not_login_after === -1,
      )(undefined, this.state.filterObj.not_login_after), // 最后登录时间
      tag_id: this.tagsId[this.typeName], // 标签id
      user_name: this.state.filterObj.username, // 用户名
      source_id: helpers.isJudge(this.state.filterObj.source_id === -1)(
        undefined,
        this.state.filterObj.source_id,
      ), // 来源
      deposit_count: helpers.isJudge(this.state.filterObj.deposit_count === -1)(
        undefined,
        this.state.filterObj.deposit_count,
      ), // 存款次数
      register_time_start: this.state.filterObj.register_time_start, // 注册开始时间
      register_time_end: this.state.filterObj.register_time_end, // 注册结束时间
      import_date_start: this.state.filterObj.import_date_start, // 导入开始时间
      import_date_end: this.state.filterObj.import_date_end, // 导入结束时间
      page_size: this.state.page.size, // 页面大小
      page: this.state.page.num, // 页码
      market_status_id: this.state.filterObj.market_status_id, // 营销状态
      task_name: this.state.filterObj.task_name, // 任务名
      callout_count:
        this.state.filterObj.callout_count >= 0
          ? this.state.filterObj.callout_count
          : undefined, // 呼出次数，后端不支持-1搜索不限量，当为-1时，传空
    };
    this.props.dispatch({
      type: 'callCenter/tasksEpic',
      payload: {
        ...getListObj,
      },
    });
    sessionStorage.setItem('getListObj', JSON.stringify(getListObj));
  };

  // 过滤查询
  public filterSelect = () => {
    this.filterObj = this.state.filterObj; // 保存被筛选的条件

    this.setState(
      { page: { num: 1, size: this.state.page.size } },
      this.getCallCenterList,
    );
  };

  // 通过之前的筛选条件获取列表
  public getListBeforeFilter = () => {
    localStorage.setItem('page', this.state.page.num);
    localStorage.setItem('pageSize', this.state.page.size);
    this.props.dispatch({
      type: 'callCenter/tasksEpic',
      payload: {
        type: window.location.pathname.slice(
          window.location.pathname.lastIndexOf('/') + 1,
        ), // 类型
        not_login_after: helpers.isJudge(this.filterObj.not_login_after === -1)(
          undefined,
          this.filterObj.not_login_after,
        ), // 最后登录时间
        tag_id: this.tagsId[this.typeName], // 标签id
        user_name: this.filterObj.username, // 用户名
        source_id: helpers.isJudge(this.filterObj.source_id === -1)(
          undefined,
          this.filterObj.source_id,
        ), // 来源
        deposit_count: helpers.isJudge(this.filterObj.deposit_count === -1)(
          undefined,
          this.filterObj.deposit_count,
        ), // 存款次数
        register_time_start: this.filterObj.register_time_start, // 注册开始时间
        register_time_end: this.filterObj.register_time_end, // 注册结束时间
        import_date_start: this.filterObj.import_date_start, // 导入开始时间
        import_date_end: this.filterObj.import_date_end, // 导入结束时间
        page_size: this.state.page.size, // 页面大小
        page: this.state.page.num, // 页码
        market_status_id: this.filterObj.market_status_id, // 营销状态
        task_name: this.filterObj.task_name, // 任务名
      },
    });
  };

  // 获取营销账号列表
  public getMarketingAccount = () => {
    this.props.dispatch({
      type: 'system/marketingAccounts/getMarketingAccountsEpic',
      payload: {
        pageSize: 1000,
        page: 1,
      },
    });
  };

  // 回退任务
  public tasksRollback = (id) => {
    const self = this;
    confirm({
      title: '您确定要退回名单吗？',
      content: '（退回的名单可在名单库中查看，但不再展示在您的呼叫中心面板中）',
      onOk() {
        self.props.dispatch({
          type: 'callCenter/rollbackTasksEpic',
          payload: {
            id,
            _type: 'group',
          },
        });
      },
      onCancel() {
        //
      },
    });
  };

  // 选择注册时间
  public selectRegisterDate = (dateString) => {
    this.setState({
      filterObj: {
        ...this.state.filterObj,
        register_time_start: dateString[0],
        register_time_end: dateString[1],
      },
    });
  };

  // 选择导入时间
  public selectCreatedDate = (dateString) => {
    this.setState({
      filterObj: {
        ...this.state.filterObj,
        import_date_start: dateString[0],
        import_date_end: dateString[1],
      },
    });
  };

  // 获取呼叫中心四个的分配或领取数
  public getTasksBadgeamount = () => {
    this.props.dispatch({
      type: 'callCenter/getTasksBadgeamountEpic',
      payload: {},
    });
  };

  public componentWillMount() {
    this.typeName = location.pathname.slice(
      location.pathname.lastIndexOf('/') + 1,
    );

    this.getTasksBadgeamount(); // 获取呼叫中心四个的分配或领取数

    this.handleFilterResetGetList();

    // 获取营销列表
    if (this.props.all.marketingAccounts.marketingAccounts.length === 0) {
      this.getMarketingAccount();
    }

    // 营销状态
    if (this.props.all.marketStatus.marketStatus.length === 0) {
      this.getMarketStatu();
    }
  }

  // 权限绑定
  public permissionBind = (buttonPermission, typeName) => {
    // 多个类型的权限ID值
    let permission = {
      phoneFlag: 0,
      messageFlag: 0,
      markFlag: 0,
      wechatFlag: 0,
    };

    switch (typeName) {
      // 沉睡页面下的ID
      case 'sleeping':
        permission = {
          phoneFlag: 88,
          messageFlag: 89,
          markFlag: 90,
          wechatFlag: 91,
        };
        break;

      // 群组维护
      case 'groupMaintenance':
        permission = {
          phoneFlag: 100,
          messageFlag: 101,
          markFlag: 102,
          wechatFlag: 103,
        };
        break;

      // 新注册未存款
      case 'newRegistration':
        permission = {
          phoneFlag: 84,
          messageFlag: 85,
          markFlag: 86,
          wechatFlag: 87,
        };
        break;

      // 已注册未存款
      case 'membersWithoutDeposit':
        permission = {
          ...permission,
          phoneFlag: 113,
          messageFlag: 114,
          markFlag: 115,
        };
        break;

      // 外部名单
      case 'externalCustomers':
        permission = {
          ...permission,
          phoneFlag: 110,
          messageFlag: 111,
          markFlag: 112,
        };
        break;

      // 群呼任务
      case 'groupCallTask':
        permission = {
          ...permission,
          // phoneFlag: 110,
          // messageFlag: 111,
          // markFlag: 112,
        };
        break;
    }
    // 循环进行状态初始的操作
    _.forIn(permission, (id, key) => {
      // 如果id为0，则不进行操作
      if (id) {
        // setState有进行优化处理，内部会合并为一个队列
        this.setState({
          [key]: _.includes(buttonPermission, id),
        });
      }
    });
  };

  public componentWillReceiveProps(nextProps) {
    const typeName = location.pathname.slice(
      location.pathname.lastIndexOf('/') + 1,
    );

    if (typeName !== this.typeName) {
      this.typeName = typeName;
      this.handleFilterResetGetList();
    }

    // 按鈕權限判斷
    this.permissionBind(nextProps.all.main.buttonPermission, typeName);
    // 绑定信息
    this.setState({
      list: nextProps.state.callCenterList,
      total: nextProps.state.total,
      num: nextProps.state.num,
    });

    // 绑定营销列表
    if (
      nextProps.all.marketingAccounts.marketingAccounts.length !== 0 &&
      this.state.marketingAccounts.length === 0
    ) {
      this.setState({
        marketingAccounts: nextProps.all.marketingAccounts.marketingAccounts,
      });
    }

    // 通过rdux重新获取
    if (nextProps.state.updateTimes !== this.updateTimes) {
      this.updateTimes = nextProps.state.updateTimes;
      this.getListBeforeFilter();
      this.getTasksBadgeamount(); // 获取呼叫中心四个的分配或领取数
    }

    // 绑定坐席状态
    if (_.get(nextProps.all.agent.agentInfo, 'extensionState') != null) {
      this.setState({
        extensionState: nextProps.all.agent.agentInfo.extensionState,
      });
    }

    // 绑定用户id
    if (_.get(nextProps.all.main.userInfo, 'user_id') !== null) {
      this.user_id = nextProps.all.main.userInfo.user_id;
    }

    // 绑定营销状态
    if (
      nextProps.all.marketStatus.marketStatus.length !== 0 &&
      this.state.marketStatus.length === 0
    ) {
      this.setState({ marketStatus: nextProps.all.marketStatus.marketStatus });
    }
  }

  // 过滤渲染
  public renderFilter = () => {
    const dateFormat = 'YYYY/MM/DD';
    return (
      <div>
        <p>
          <span className={'notWrap'}>
            客户来源：
            <Button
              type={helpers.isJudge(this.state.filterObj.source_id === -1)(
                'primary',
                null,
              )}
              onClick={() =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    source_id: -1,
                  },
                })
              }
            >
              不限
            </Button>
            <Button
              type={helpers.isJudge(this.state.filterObj.source_id === 0)(
                'primary',
                null,
              )}
              onClick={() =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    source_id: 0,
                  },
                })
              }
            >
              系统会员
            </Button>
            <Button
              type={helpers.isJudge(this.state.filterObj.source_id === 1)(
                'primary',
                null,
              )}
              onClick={() =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    source_id: 1,
                  },
                })
              }
              className={'mr40'}
            >
              外部导入
            </Button>
          </span>
          <span className={'notWrap'}>
            行销结果：
            <Select
              placeholder={'不限'}
              onChange={(value) =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    market_status_id: value,
                  },
                })
              }
              value={this.state.filterObj.market_status_id}
            >
              {this.state.marketStatus.map((item) => {
                return <Option key={item.id}>{item.name}</Option>;
              })}
            </Select>
          </span>
          <span className={'notWrap'}>
            存款次数：
            <Select
              value={this.state.filterObj.deposit_count}
              onChange={(value) =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    deposit_count: value,
                  },
                })
              }
            >
              <Option value={-1}>不限</Option>
              <Option value={0}>0次</Option>
              <Option value={1}>1次</Option>
              <Option value={2}>1次以上</Option>
            </Select>
          </span>
          <span className={'notWrap'}>
            注册时间：
            <RangePicker
              onChange={(date, dateString) =>
                this.selectRegisterDate(dateString)
              }
              value={helpers.isJudge(
                this.state.filterObj.register_time_start === '',
              )(null, [
                moment(this.state.filterObj.register_time_start, dateFormat),
                moment(this.state.filterObj.register_time_end, dateFormat),
              ])}
            />
          </span>
          <span className={'notWrap'}>
            导入时间：
            <RangePicker
              onChange={(date, dateString) =>
                this.selectCreatedDate(dateString)
              }
              value={helpers.isJudge(
                this.state.filterObj.import_date_start === '',
              )(null, [
                moment(this.state.filterObj.import_date_start, dateFormat),
                moment(this.state.filterObj.import_date_end, dateFormat),
              ])}
            />
          </span>
          <span className={'notWrap'}>
            未登录时长：
            <Button
              type={helpers.isJudge(
                this.state.filterObj.not_login_after === -1,
              )('primary', null)}
              onClick={() =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    not_login_after: -1,
                  },
                })
              }
            >
              不限
            </Button>
            <Button
              type={helpers.isJudge(this.state.filterObj.not_login_after === 3)(
                'primary',
                null,
              )}
              onClick={() =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    not_login_after: 3,
                  },
                })
              }
            >
              ≥3天
            </Button>
            <Button
              type={helpers.isJudge(this.state.filterObj.not_login_after === 7)(
                'primary',
                null,
              )}
              onClick={() =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    not_login_after: 7,
                  },
                })
              }
            >
              ≥7天
            </Button>
            <Button
              type={helpers.isJudge(
                this.state.filterObj.not_login_after === 15,
              )('primary', null)}
              onClick={() =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    not_login_after: 15,
                  },
                })
              }
            >
              ≥15天
            </Button>
            <Input
              addonBefore={'≥'}
              placeholder={'请输入天数'}
              className={'number-input-container'}
              value={helpers.isJudge(
                this.state.filterObj.not_login_after === -1,
              )('', this.state.filterObj.not_login_after)}
              onChange={(e) =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    not_login_after: helpers.isJudge(
                      _.isNaN(parseInt(e.target.value, 10)),
                    )(-1, parseInt(e.target.value, 10)),
                  },
                })
              }
            />
          </span>
          <span className={'notWrap'}>
            任务名称：
            <Input
              placeholder={'请输入任务名称'}
              value={this.state.filterObj.task_name}
              onChange={(e) =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    task_name: e.target.value,
                  },
                })
              }
            />
          </span>
          <span className={'notWrap'}>
            用户名：
            <Input
              placeholder={'请输入用户名'}
              value={this.state.filterObj.username}
              onChange={(e) =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    username: e.target.value,
                  },
                })
              }
            />
            <Button type={'primary'} onClick={this.filterSelect}>
              筛选
            </Button>
            <Button
              type={'primary'}
              className={'pBtn'}
              onClick={this.handleFilterReset}
            >
              重置
            </Button>
          </span>
        </p>
      </div>
    );
  };

  // 筛选重置
  public handleFilterReset = () => {
    this.setState({
      filterObj: {
        username: '', // 过滤的名称
        source_id: -1, // 过滤的客户来源
        deposit_count: -1, // 过滤的存款次数
        import_date_start: '', // 导入开始日期
        import_date_end: '', // 导入开始日期
        register_time_start: '', // 注册时间范围开始
        register_time_end: '', // 注册时间范围截至
        not_login_after: -1, // 过滤的最后登录天
        callout_count: undefined,
      },
    });
    this.filterSelect();
  };

  // 重置并重新拉取列表
  public handleFilterResetGetList = () => {
    this.setState(
      {
        filterObj: {
          username: '', // 过滤的名称
          source_id: -1, // 过滤的客户来源
          deposit_count: -1, // 过滤的存款次数
          import_date_start: '', // 导入开始日期
          import_date_end: '', // 导入开始日期
          register_time_start: '', // 注册时间范围开始
          register_time_end: '', // 注册时间范围截至
          not_login_after: -1, // 过滤的最后登录天
        },
        page: {
          size: helpers.isJudge(localStorage.getItem('pageSize') === null)(
            40,
            localStorage.getItem('pageSize'),
          ), // 用户列表的每页大小
          num: helpers.isJudge(localStorage.getItem('page') === null)(
            1,
            localStorage.getItem('page'),
          ), // 用户列表的当前页数
        },
      },
      this.getCallCenterList,
    );
  };

  // 改变页号
  public changePage = (page, size) => {
    this.setState({ page: { num: page, size } }, this.getListBeforeFilter);
  };

  // 改变每页大小
  public changePageSize = (page, size) => {
    this.setState({ page: { num: 1, size } }, this.getListBeforeFilter);
  };

  // 列表模块渲染
  public renderList = () => {
    const columns: any = this.getColumns();
    // 解构数据，重复使用的数据，单独赋值为变量
    const { page: pageState, total, list } = this.state;
    return (
      <React.Fragment>
        <XList
          columns={columns}
          dataSource={list}
          scroll={{ x: 1600 }}
          rowKey={'id'}
          autoCalculation={true}
          pagination={{
            current: +pageState.num,
            pageSize: +pageState.size,
            total,
            showSizeChanger: true,
            showQuickJumper: true,
            onChange: this.changePage,
            onShowSizeChange: this.changePageSize,
            pageSizeOptions: ['10', '20', '50', '100', '200', '300', '500'],
          }}
        />
      </React.Fragment>
    );
  };

  public render() {
    const SendSmsComponent = this.SendSmsComponent;

    return (
      <div className={tasksStyle.tasksPage}>
        {this.renderFilter()}
        {helpers.isJudge(
          this.state.num[this.typeName] === 0 ||
            this.state.num[this.typeName] === undefined,
        )(
          null,
          <Alert1
            message={`您被分配但未处理<span>${
              this.state.num[this.typeName]
            }</span>条新名单`}
          />,
        )}
        {this.renderList()}
        <SendSmsComponent
          customer_id={this.state.customer_id}
          showTimes={this.state.showTimes}
          relationshipId={this.state.relationshipId}
          tag_id={this.tagsId[this.typeName]}
          type={1}
        />
      </div>
    );
  }
}
