/**
 * 二分查找索引值
 *
 * @export
 * @param {any[]} array 查找数据使用的数据源
 * @param {number} value 查找值
 * @param {string} [key] 子元素属性名
 * @returns {number}
 */
export function binarySearchIndex(
  array: any[],
  value: number,
  key?: string,
): number {
  let mid: number;
  let min = 0;
  let max = array.length - 1;

  while (min <= max) {
    mid = Math.floor((min + max) / 2);
    // 当前查找的数据，
    // 如果使用Key模式查找，则使用当前元素的子属性进行比对
    const current = key ? array[mid][key] : array[mid];
    if (current === value) {
      return mid;
    } else if (current < value) {
      min = mid + 1;
    } else {
      max = mid - 1;
    }
  }

  return -1;
}

/**
 * 使用Key值比对进行二分查找
 * Key模式只支持在数组子元素为Object类型时使用
 *
 * @export
 * @template T
 * @param {T[]} array 查找数据使用的数据源
 * @param {number} value 查找值
 * @param {string} key 子元素属性名
 * @returns {T}
 */
export function binarySearchByKey<T>(
  array: T[],
  value: number,
  key: string,
): T {
  return array[binarySearchIndex(array, value, key)];
}

/**
 * 基本二分查找，只支持数字类型数组
 *
 * @export
 * @param {number[]} array
 * @param {number} value
 * @returns {number}
 */
export function binarySearch(array: number[], value: number): number {
  return array[binarySearchIndex(array, value)];
}
