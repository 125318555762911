import { request, servicPath } from '../utils';
import {
  createGetRequestByQuery,
  createFormDataPostRequest
} from './requestTransfer';

/**
 * 获取列表
 * @param {object} params
 */
export function handleGet(params) {
  return request(
    createGetRequestByQuery(`${servicPath}/products/importrecords`, params)
  );
}

/**
 * 批量删除
 */
export function handleDeletes(params) {
  return request(
    createFormDataPostRequest(`${servicPath}/products/customers/delete?`, params)
  );
}

/**
 * 删除
 */
export function handleDelete(params) {
  return request(
    createFormDataPostRequest(`${servicPath}/products/importrecords/${params.id}`, params)
  );
}

/**
 * 查看详情
 */
export function handleGetDetails(params) {
  const id = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);
  return request(
    createGetRequestByQuery(`${servicPath}/products/importrecords/${id}`, params)
  );
}
