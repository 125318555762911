import request from '@/utils/request';

import * as helpers from '@/utils/helpers';

import * as decorators from '@/utils/decorators';

import { Events } from '@/utils/eventProxy';

import area from '@/utils/area';

import RainyDay from '@/utils/rainday';

import ls from '@/utils/secureLS';

import * as polylink from '@/utils/polylink';

import * as mixphone from '@/utils/mixphone';

const servicPath = '/api';

// if (process.env.DIY === 'server') {
//   servicPath = '/api';
// }

export {
  request,
  helpers,
  decorators,
  Events,
  servicPath,
  area,
  ls,
  RainyDay,
  polylink,
  mixphone
};
