import * as React from 'react';
import * as _ from 'lodash';
import { Table, Button, Input, Divider, Modal, Select } from 'antd';
const confirm = Modal.confirm;
const Option = Select.Option;
import {
  ContainerPropsInterface,
  ContainerStateInterface
} from '@/routes/containerInterface';

import MarketingAccountsAdd from './marketingAccountsAddPage';
import MarketingAccountsEdit from './marketingAccountsEditPage';
import { helpers } from '@/utils';
import { multiPlatform } from '@/multiPlatform';
export default class MarketingAccounts extends React.PureComponent<
  ContainerPropsInterface<any>,
  ContainerStateInterface
> {
  // 列表列
  public columns: any = [
    {
      align: 'center',
      title: '序号',
      width: 100,
      dataIndex: 'id',
      render: (text, record, index) => <span>{index + 1}</span>
    },
    {
      title: '应用类型',
      dataIndex: 'account_type'
    },
    {
      title: '账号名称',
      dataIndex: 'account_no'
    },
    {
      title: '账号所属人',
      dataIndex: '账号所属人',
      render: (text, record) => <span>{_.get(record.user, 'name')}</span>
    },
    {
      title: '录入人',
      dataIndex: 'add_by'
    },
    {
      title: '操作',
      dataIndex: 'action',
      width: 150,
      render: (text, record) => (
        <span>
          {helpers.isJudge(this.state.editFlag)(
            <a
              href={'javascript:;'}
              onClick={() =>
                this.openEditWindow({
                  ...record,
                  editTimes: this.editTimes++
                })
              }
            >
              编辑
            </a>,
            null
          )}
          <Divider type={'vertical'} />
          {helpers.isJudge(this.state.delFlag)(
            <a
              href={'javascript:;'}
              onClick={() => this.confirmDelete(record.id)}
            >
              删除
            </a>,
            null
          )}
        </span>
      )
    }
  ];

  // 修改次数
  public editTimes = 0;

  // 营销账号列表
  public data: any = [];

  constructor(props) {
    super(props);
    this.state = {
      list: [], // 营销账号列表
      editData: {}, // 编辑的营销账号信息
      userList: {}, // 用户列表
      // 过滤对象组
      filterObj: {
        account_type: undefined, // 过滤的账户类型
        account_no: '', // 过滤的账户名称
        belong_person: '' // 过滤的所属人
      },
      filterTextName: '',
      filterTextOwner: '',
      editFlag: false,
      delFlag: false,
      addFlag: false
    };
  }

  public MarketingAccountsAddComponent = multiPlatform.marketingAccountsAddMulti(
    MarketingAccountsAdd
  ); // 营销账户添加
  public MarketingAccountsEditComponent = multiPlatform.marketingAccountsEditMulti(
    MarketingAccountsEdit
  ); // 营销账户编辑

  // 获取用户列表
  public getUserList = () => {
    this.props.dispatch({
      type: 'system/getUserListEpic',
      payload: {
        page_size: 1000
      }
    });
  };

  public componentWillMount() {
    // 获取营销账号列表
    if (this.props.state.marketingAccounts.length === 0) {
      this.getMarketingAccount();
    } else {
      this.data = this.props.state.marketingAccounts;
      this.setState({ list: this.props.state.marketingAccounts });
    }
    // 获取或绑定用户列表
    if (this.props.all.user.userList.length === 0) {
      this.getUserList();
    }

    // 绑定权限
    if (this.props.all.main.buttonPermission.length !== 0) {
      const buttonPermission = this.props.all.main.buttonPermission;
      this.setState({
        editFlag: _.includes(buttonPermission, 72),
        delFlag: _.includes(buttonPermission, 73),
        addFlag: _.includes(buttonPermission, 70)
      });
    }
  }

  // 打开编辑窗口
  public openEditWindow(item) {
    this.setState({ editData: item });
  }

  // 获取营销账号列表
  public getMarketingAccount = () => {
    this.props.dispatch({
      type: 'system/marketingAccounts/getMarketingAccountsEpic',
      payload: {
        pageSize: 1000,
        page: 1
      }
    });
  };

  public componentWillReceiveProps(nextProps) {
    // 绑定营销账号列表
    if (
      !_.isEqual(nextProps.all.marketingAccounts.marketingAccounts, this.data)
    ) {
      this.data = nextProps.state.marketingAccounts;
      this.setState({ list: nextProps.state.marketingAccounts });
    }
    const buttonPermission = nextProps.all.main.buttonPermission;
    this.setState({
      editFlag: _.includes(buttonPermission, 72),
      delFlag: _.includes(buttonPermission, 73),
      addFlag: _.includes(buttonPermission, 70)
    });
  }

  // 删除营销账号
  public confirmDelete(deleteId: number) {
    const self = this;
    confirm({
      title: '您确定要删除该营销号吗？',
      onOk() {
        self.props.dispatch({
          type: 'system/marketingAccounts/deleteMarketingAccountsEpic',
          payload: {
            id: deleteId
          }
        });
      },
    });
  }

  // 筛选重置
  public handleFilterReset() {
    this.setState({
      filterObj: {
        account_type: undefined, // 过滤的账户类型
        account_no: '', // 过滤的账户名称
        belong_person: '' // 过滤的所属人
      }
    });
  }

  // 过滤筛选
  public checkFilter(item) {
    let isShow = true; // 是否显示该条信息

    if (item.account_no.indexOf(this.state.filterObj.account_no) === -1) {
      // 用户名过滤
      isShow = false;
    } else if (
      item.account_type !== this.state.filterObj.account_type &&
      this.state.filterObj.account_type !== undefined
    ) {
      // 账户类型过滤
      isShow = false;
    } else if (
      item.user.name.indexOf(this.state.filterObj.belong_person) === -1
    ) {
      // 所属人过滤
      isShow = false;
    }
    return isShow;
  }

  // 触发筛选
  public handleFilter() {
    if (this.data.length !== 0) {
      this.setState({ list: this.data.filter(item => this.checkFilter(item)) });
    }
  }

  // 过滤渲染
  public renderFilter = () => {
    const MarketingAccountsAddComponent = this.MarketingAccountsAddComponent;
    return (
      <div>
        <p>
          {helpers.isJudge(this.state.addFlag)(
            <MarketingAccountsAddComponent />,
            null
          )}
          <span className={'notWrap'}>
            应用类型：
            <Select
              allowClear
              placeholder={'请选择应用类型'}
              value={this.state.filterObj.account_type}
              onChange={value =>
                this.setState({
                  filterObj: { ...this.state.filterObj, account_type: value }
                })
              }
            >
              {this.data
                .map(item => item.account_type)
                .filter((item, index, arr) => arr.indexOf(item) === index)
                .map(n => (
                  <Option key={n} value={n}>
                    {n}
                  </Option>
                ))}
            </Select>
          </span>
          <span className={'notWrap'}>
            账号名称：
            <Input
              value={this.state.filterObj.account_no}
              type={'text'}
              placeholder={'请输入账号名称'}
              onChange={e =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    account_no: e.target.value
                  }
                })
              }
            />
          </span>
          <span className={'notWrap'}>
            账号所属人：
            <Input
              value={this.state.filterObj.belong_person}
              type={'text'}
              placeholder={'请输入账号所属人'}
              onChange={e =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    belong_person: e.target.value
                  }
                })
              }
            />
            <Button type={'primary'} onClick={() => this.handleFilter()}>
              筛选
            </Button>
            <Button onClick={() => this.handleFilterReset()}>重置</Button>
          </span>
        </p>
      </div>
    );
  };

  public render() {
    const MarketingAccountsEditComponent = this.MarketingAccountsEditComponent;
    return (
      <React.Fragment>
        {this.renderFilter()}
        <Table
          columns={this.columns}
          dataSource={this.state.list}
          rowKey={'id'}
          pagination={helpers.isJudge(this.state.list.length === 0)(
            false,
            true
          )}
        />
        <MarketingAccountsEditComponent editData={this.state.editData} />
      </React.Fragment>
    );
  }
}
