import * as React from 'react';
import * as _ from 'lodash';
import moment from 'moment';
import { Table, Button, Input, Select, DatePicker, Icon } from 'antd';
import { helpers } from '@/utils';
import {
  ContainerPropsInterface,
  ContainerStateInterface,
} from '@/routes/containerInterface';
import { history } from '@/router';
import { XList } from '@/components';
import { message } from 'antd';

const { RangePicker } = DatePicker;
const Option = Select.Option;

export default class TelDetail extends React.PureComponent<
  ContainerPropsInterface<any>,
  ContainerStateInterface
> {
  // 表格列
  public columns: any = [
    {
      title: '呼叫时间',
      dataIndex: 'call_start_time',
      width: 250,
    },
    {
      title: '主叫',
      dataIndex: 'user.name',
      width: 150,
    },
    {
      title: '被叫',
      width: 150,
      render: (text, record, index) => {
        if (record.customer) {
          return <span>{record.customer.phone}</span>;
        } else {
          return null;
        }
      },
    },
    {
      title: '通话时长',
      dataIndex: 'duration',
      width: 150,
    },
    {
      title: '呼叫结果',
      width: 150,
      render: (text, record, index) => {
        return record.connection_status === 1 ? (
          <span>已接通</span>
        ) : (
          <span>未接通</span>
        );
      },
    },
    {
      title: '操作',
      render: (text, record, index) => {
        return record.connection_status === 1 ? (
          <span style={{ fontSize: 24, color: '#294D73' }}>
            <Icon
              style={{ cursor: 'pointer' }}
              type={
                this.state.voiceState.onPlay &&
                this.state.voiceState.id === record.id
                  ? 'pause'
                  : 'caret-right'
              }
              onClick={() =>
                this.state.voiceState.onPlay
                  ? this.pauseVoiceFile()
                  : this.playVoiceFile(record.id, record.file_address)
              }
            />
            <Icon
              style={{ cursor: 'pointer' }}
              type={'download'}
              onClick={() => this.downloadVoiceFile(record.file_address)}
            />
          </span>
        ) : (
          <span>未接通</span>
        );
      },
    },
  ];
  // 页面大小存储使用的key
  public STORE_PAGE_SIZE_KEY = 'smallPageSize';
  // audio DOM 节点
  public audio: HTMLAudioElement | null = null;

  /**
   * 下载录音文件
   * @param path {string}
   */
  public downloadVoiceFile = (path: string) => {
    if (!path) {
      return this.errorMessage('下载失败，录音文件不存在');
    }
    return window.open(path);
    // this.props.dispatch({
    //   type: 'reports/downloadVoiceFile',
    //   payload: {
    //     path,
    //   },
    // });
  };

  /**
   * 播放录音文件
   * @param id {number}
   * @param path {string}
   */
  public playVoiceFile = async (id: number, path: string) => {
    if (!path) {
      return this.errorMessage('播放失败，录音文件不存在');
    }
    // 音频DOM
    let audio;
    try {
      // 当播放之前暂停的音频时，则不创建新的DOM，而直接使用之前的音频DOM进行继续播放
      if (id === this.state.voiceState.id && this.audio) {
        await this.audio.play();
        // 更新播放状态
        return this.setState({
          voiceState: {
            ...this.state.voiceState,
            onPlay: true,
          },
        });
      }
      // 先暂停播放录音文件
      await this.pauseVoiceFile();
      audio = new Audio(path);
      // 播放完成后，对状态进行更新
      audio.onEnded = () => {
        this.setState({
          ...this.state.voiceState,
          onPlay: false,
        });
      };
      // 对新录音文件进行播放
      await audio.play();
    } catch (e) {
      // 无法被播放的文件弹出异常
      return this.errorMessage('音频文件播放失败');
    }
    // 变更状态
    this.setState({
      voiceState: {
        id,
        onPlay: true,
        path,
      },
    });
    this.audio = audio;
  };

  /**
   * 暂停播放音频文件
   */
  public pauseVoiceFile = async () => {
    if (this.audio) {
      // 如果存在录音文件
      await this.audio.pause();
      // 更新状态
      this.setState({
        voiceState: {
          ...this.state.voiceState,
          onPlay: false,
        },
      });
    }
  };

  /**
   * 错误消息提示
   * @param msg {string}
   */
  public errorMessage(msg: string) {
    return message.warning(msg, 1);
  }

  constructor(props) {
    super(props);
    this.state = {
      list: [], // 列表
      filterObj: this.filterObj, // 筛选条件
      page: {
        size: helpers.isJudge(
          localStorage.getItem(this.STORE_PAGE_SIZE_KEY) === null,
        )(40, localStorage.getItem(this.STORE_PAGE_SIZE_KEY)), // 用户列表的每页大小
        num: 1, // 用户列表的当前页数
      },
      total: 0,
      // 音频文件是否在播放
      voiceState: {
        id: 0,
        path: '',
        onPlay: false,
      },
    };
  }

  public handleDetail = record => {
    history.push(history.location.pathname + '/' + record.id);
  };

  public data: any = [];
  public filterObj: any = {
    site_num: '', // 坐席号
    user_name: '', // 用户名
    current_label: '', // 当前标签
    connect_time_start: '', // 通话开始时间
    connect_time_end: '', // 通话结束时间
    connect_count: '0', // 接通量
    connect_time: '0', // 接通秒数
    tags: [], // 标签
  };

  // 筛选
  public handleFilter() {
    if (this.data.length !== 0) {
      this.setState({});
    }
  }

  // 筛选模块渲染
  public renderFilter = () => {
    const dateFormat = 'YYYY/MM/DD';
    const param = this.props.location.state;
    return (
      <div>
        <p style={{ paddingBottom: '30px' }}>
          <span
            className={'notWrap'}
            style={{ marginRight: 50, color: '#555' }}
          >
            <span style={{ fontWeight: 'bold', color: 'rgba(0,0,0,.85)' }}>
              坐席号 :
            </span>
            &nbsp;&nbsp;
            {param.mixcall_account && param.mixcall_account.length
              ? param.mixcall_account[0].employee_id
              : param.polylink_account[0].employee_id}
          </span>
          <span
            className={'notWrap'}
            style={{ marginRight: 50, color: '#555' }}
          >
            <span style={{ fontWeight: 'bold', color: 'rgba(0,0,0,.85)' }}>
              主叫人 :
            </span>
            &nbsp;&nbsp;
            {this.state.filterObj.user_name}
          </span>
          {/*<span
            className={'notWrap'}
            style={{ marginRight: 50, color: '#555' }}
          >
            <span style={{ fontWeight: 'bold', color: 'rgba(0,0,0,.85)' }}>
              当前标签 :
            </span>
            &nbsp;&nbsp;
            {this.state.filterObj.tags.map((item, index) => {
              if (index !== this.state.filterObj.tags.length - 1) {
                return <span>{item.name}, </span>;
              } else {
                return <span>{item.name}</span>;
              }
            })}
          </span>*/}
          <span className={'notWrap'} style={{ color: '#555' }}>
            <span style={{ fontWeight: 'bold', color: 'rgba(0,0,0,.85)' }}>
              通话时间 :
            </span>
            &nbsp;&nbsp;
            {this.state.filterObj.connect_time_start} ~{' '}
            {this.state.filterObj.connect_time_end}
          </span>
        </p>
        {/*<p style={{ marginTop: 20 }}>
          {helpers.isJudge(
            _.includes(this.props.all.main.buttonPermission, 121),
          )(<Button icon={'download'}>批量下载录音</Button>, null)}
        </p>*/}
      </div>
    );
  };

  // 获取话务详情数据
  public getDetailData = () => {
    this.props.dispatch({
      type: 'reports/getTelDetailEpic',
      payload: {
        // user_name: this.state.filterObj.user_name,
        user_id: this.props.match.params.id,
        call_start_time_start: this.state.filterObj.connect_time_start,
        call_start_time_end: this.state.filterObj.connect_time_end,
        page: this.state.page.num,
        page_size: this.state.page.size,
      },
    });
  };

  // 获取标签下拉数据
  public getTags = () => {
    this.props.dispatch({
      type: 'system/tags/getTagsEpic',
      payload: {
        page_size: 1000,
      },
    });
  };

  public componentWillMount() {
    const { state } = this.props.location;
    const phoneLine = this.props.all.main.userInfo.phone_line;
    const account =
      state[phoneLine === 2 ? 'mixcall_account' : 'polylink_account'];
    this.setState(
      {
        ...this.state,
        filterObj: {
          ...this.state.filterObj,
          site_num: (account && account.employee_id) || '',
          user_name: state.name,
          current_label: state.current_label,
          connect_time_start: state.connect_time_start,
          connect_time_end: state.connect_time_end,
          tags: state.tags,
        },
      },
      () => {
        // 获取详情列表
        this.getDetailData();
        this.getTags();
      },
    );
  }

  public componentWillReceiveProps(nextProps) {
    const { state } = this.props.location;
    const { telDetailList, telDetailListTotal } = nextProps.state.telStatistics;

    if (nextProps.state.tags.tags.length !== 0 && state.current_label) {
      this.setState({
        ...this.state,
        total: telDetailListTotal,
        filterObj: {
          ...this.state.filterObj,
          current_label: this.filterTag(
            nextProps.state.tags.tags,
            Number(state.current_label),
          ),
        },
      });
    }

    // 绑定列表
    if (telDetailList.length !== 0 && !_.isEqual(telDetailList, this.data)) {
      this.data = telDetailList;
      // // 模拟数据，Web没有下发有效数据，所以先Mock
      // this.data = this.data.map(item => {
      //   item.file_address =
      //     'https://ia801209.us.archive.org/26/items/Kalimba_20160930/Kalimba.mp3';
      //   return item;
      // });
      // this.setShowListData();
      this.setState({
        list: telDetailList,
      });
    }
  }

  public filterTag = (tags, id) => {
    const arr = tags.filter(item => item.id === id);
    return _.isObject(arr[0]) ? arr[0].name : '';
  };

  public rowSelection = {
    getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
  };

  /**
   * 设置显示的列表数据
   *
   * @memberof TelDetail
   */
  public setShowListData() {
    // 取得页码与每个数量
    let { size, num } = this.state.page;
    // 对数据进行转类型，防止使用string进行处理
    size = parseInt(size, 10);
    num = parseInt(num, 10);
    // 计算范围
    const start = size * (num - 1);
    const end = start + size;
    // 设定显示的范围
    this.setState({ list: this.data.slice(start, end) });
  }

  // 改变页号
  public changePage = (page, size) => {
    this.setState({ page: { num: page, size } }, this.getDetailData);
  };

  // 改变每页大小
  public changePageSize = (page, size) => {
    this.setState({ page: { num: 1, size } }, this.getDetailData);
  };

  public render() {
    return (
      <React.Fragment>
        {this.renderFilter()}
        <XList
          columns={this.columns}
          dataSource={this.state.list}
          isShowNum={true}
          autoCalculation={true}
          pagination={{
            current: this.state.page.num,
            pageSize: this.state.page.size,
            total: this.state.total,
            showSizeChanger: true,
            showQuickJumper: true,
            onChange: this.changePage,
            onShowSizeChange: this.changePageSize,
            pageSizeOptions: ['10', '20', '30', '40'],
          }}
        />
      </React.Fragment>
    );
  }
}
