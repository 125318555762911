import { request, servicPath } from '../utils';
import {
  createGetRequestByQuery,
  createFormDataPostRequest,
  createFormDataPutRequest,
  createDelRequestByQuery
} from './requestTransfer';

/**
 * 获取回访备注
 */
export function handleGetCallNote(params) {
  return request(
    createGetRequestByQuery(
      `${servicPath}/products/callnote/${params.id}`,
      params
    )
  );
}

/**
 * 添加回访备注
 */
export function handleAddCallNote(params) {
  return request(
    createFormDataPostRequest(`${servicPath}/products/callnote`, params)
  );
}

/**
 * 修改回访备注
 */
export function handleUpdateCallNote(params) {
  return request(
    createFormDataPutRequest(`${servicPath}/products/callnote`, params)
  );
}

/**
 * 删除回访备注
 */
export function handleDeleteCallNote(params) {
  return request(
    createDelRequestByQuery(`${servicPath}/products/callnote/${params.id}`)
  );
}
