/**
 * 原型所需要的标签
 */
import * as React from 'react';
import labelStyle from './label1Style.scss';

export interface LabelProps {
  color?: 'blue' | 'red' | 'green' | 'yellow';
  text: string;
}

export default class Label1 extends React.PureComponent<LabelProps, any> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  public render() {
    let style = ' ';
    if (this.props.color === 'blue') {
      style += labelStyle.blue;
    } else if (this.props.color === 'red') {
      style += labelStyle.red;
    } else if (this.props.color === 'green') {
      style += labelStyle.green;
    } else if (this.props.color === 'yellow') {
      style += labelStyle.yellow;
    }
    return (
      <span className={labelStyle.label1 + ' ' + style}>
        <span>{this.props.text}</span>
      </span>
    );
  }
}
