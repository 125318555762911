import { Observable, pipe, of } from 'rxjs';

import { map, mergeMap } from 'rxjs/operators';
import { Epic, ofType } from 'redux-observable';
import { helpers } from '@/utils';
import { callsAPI } from '@/services';
import { message } from 'antd';

interface Payload {
  [random: string]: any;
}
// dispatch传进来的action类型
interface PayloadIn {
  type: string;
  payload: {
    [random: string]: any;
  };
}

// epic传到reducer里的action类型
interface PayloadOut {
  type: string;
  payload: {
    [random: string]: any;
  };
}

// 获取通话记录
export const getCall: Epic<PayloadIn, PayloadOut> = (action$, store) => {
  return action$.pipe(
    ofType('reports/getCallsEpic'),
    mergeMap(action => {
      return callsAPI.handleGetCalls(action.payload).pipe(
        map(res => {
          if (res !== null) {
            return helpers.createAction<Payload>('reports/getCalls', {
              list: res.data.data,
              total: res.data.total
            });
          }
          return helpers.createAction<typeof action.payload>('no');
        })
      );
    })
  );
};

// 修改通话流水
export const updateCallRecords: Epic<PayloadIn, PayloadOut> = (
  action$,
  store
) => {
  return action$.pipe(
    ofType('reports/updateCallRecordsEpic'),
    mergeMap(action => {
      return callsAPI.handleUpdateRecords(action.payload).pipe(
        map(res => {
          if (res !== null) {
            // console.log(res);
          }
          return helpers.createAction<typeof action.payload>('no');
        })
      );
    })
  );
};
