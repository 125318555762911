import * as React from 'react';
import * as _ from 'lodash';
import { helpers } from '@/utils';
import { Modal, Input, Form, AutoComplete, Icon } from 'antd';
const { TextArea } = Input;

interface TagsEditProps {
  editData: any;
  [random: string]: any;
}
interface TagsEditState {
  [random: string]: any;
}

export default class TagsEdit extends React.PureComponent<
  TagsEditProps,
  TagsEditState
> {
  constructor(props) {
    super(props);
    this.state = {
      visible: false, // 是否显示编辑窗口
      userList: [], // 用户的列表
      includeUser: [], // 标签包含的用户
      isSystem: true, // 是否是系统默认标签
      selectUser: '' // 选择的用户名
    };
  }

  public editTimes = 0; // 编辑框显示次数

  // 用户列表
  public userList: any = [];

  // 原始包含的用户
  public includeUser: any = [];

  // 传进来需要修改的原始数据
  public editData: any = {};

  // 修改标签
  public handleUpdate = () => {
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }

      let remove_user_ids: any = []; // 要移除的用户数组
      let add_user_ids: any = []; // 要添加的用户数组

      if (this.state.includeUser.length === 0) {
        remove_user_ids = this.includeUser;
      } else {
        remove_user_ids = this.includeUser.filter(item =>
          this.state.includeUser.every(n => n.id !== item.id)
        );
        add_user_ids = this.state.includeUser.filter(item =>
          this.includeUser.every(n => n.id !== item.id)
        );
      }

      this.props.dispatch({
        type: 'system/tags/updateTagsEpic',
        payload: {
          id: this.editData.id,
          tag_name: values.tagName,
          intro: values.intro,
          add_user_ids: add_user_ids.map(n => n.id).toString(),
          remove_user_ids: remove_user_ids.map(n => n.id).toString()
        }
      });

      // 隐藏编辑窗口
      this.setState({ visible: false, includeUser: [] });
      this.includeUser = [];
    });
  };

  public componentWillReceiveProps(nextProps) {
    // 绑定要编辑的标签信息
    if (nextProps.editTimes !== this.editTimes) {
      this.editTimes = nextProps.editTimes; // 控制编辑框显示
      this.editData = nextProps.editData; // 保存要编辑的标签的原始信息
      this.setState({
        visible: true,
        isSystem: nextProps.editData.is_system,
        selectUser: ''
      }); // 显示编辑窗口，绑定是否为系统默认标签
      this.props.form.setFieldsValue({
        tagName: nextProps.editData.name, // 绑定标签名
        intro: nextProps.editData.intro // 绑定标签描述
      });

      // 获取或绑定标签用户信息
      if (nextProps.state.length !== 0) {
        // redux中找标签详情
        const tagUserList = nextProps.state.filter(
          n => n.id === nextProps.editData.id
        );
        if (tagUserList.length === 0) {
          this.getTagsSingle(nextProps.editData.id); // 获取标签含有用户信息的详情
        } else {
          // 绑定已有的标签用户信息
          this.includeUser = tagUserList[0].users;
          this.setState({ includeUser: tagUserList[0].users });
        }
      } else {
        this.getTagsSingle(nextProps.editData.id); // 获取标签含有用户信息的详情
      }

      // 获取或绑定用户列表
      if (nextProps.all.user.userList.length === 0) {
        this.getUserList();
      } else {
        this.userList = nextProps.all.user.userList;
      }
    }

    // 绑定包含的用户
    if (this.state.visible) {
      if (nextProps.state.length !== 0 && this.includeUser.length === 0) {
        const tagUserList = nextProps.state.filter(
          n => n.id === nextProps.editData.id
        );
        if (tagUserList.length !== 0) {
          if (!_.isEqual(tagUserList[0].users, this.state.includeUser)) {
            this.includeUser = tagUserList[0].users;
            this.setState({ includeUser: tagUserList[0].users });
          }
        }
      }

      if (
        nextProps.all.user.userList.length !== 0 &&
        this.userList.length === 0
      ) {
        this.userList = nextProps.all.user.userList;
      }
    }
  }

  // 添加成员输入筛选
  public handleSearch = value => {
    const includeUser = this.state.includeUser.map(item => item.id);
    this.setState({
      userList: helpers.isJudge(!value)(
        [],
        this.userList
          .filter(
            item =>
              item.name.indexOf(value) !== -1 && !includeUser.includes(item.id)
          )
          .map(n => n.name)
      )
    });
  };

  // 添加成员输入筛选是否清空1
  public clearInput = false;

  // 选择添加已有成员
  public onSelect = value => {
    this.setState({
      includeUser: [
        this.userList.filter(item => item.name === value)[0],
        ...this.state.includeUser
      ],
      userList: []
    });

    this.clearInput = true;
  };

  // 删除已有成员
  public removeInclude(id) {
    this.setState({
      includeUser: this.state.includeUser.filter(obj => obj.id !== id)
    });
  }

  // 获取标签详情
  public getTagsSingle = id => {
    this.props.dispatch({
      type: 'system/tags/getTagsSingleEpic',
      payload: {
        id
      }
    });
  };

  // 获取用户列表
  public getUserList = () => {
    this.props.dispatch({
      type: 'system/getUserListEpic',
      payload: {
        page_size: 1000
      }
    });
  };

  public render() {
    return (
      <React.Fragment>
        <Modal
          title={'编辑'}
          visible={this.state.visible}
          maskClosable={false}
          onOk={this.handleUpdate}
          onCancel={() => {
            this.setState({ visible: false, includeUser: [] });
            this.editData = {};
            this.includeUser = [];
          }}
          width={600}
          className={'modalFrom'}
        >
          <Form.Item
            label={'标签组名：'}
            style={helpers.isJudge(this.state.isSystem)(
              { display: 'none' },
              {}
            )}
          >
            {this.props.form.getFieldDecorator('tagName', {
              rules: [
                {
                  required: true,
                  message: '请输入标签组名'
                }
              ]
            })(<Input placeholder={'请输入新增标签组的名称'} maxLength={32} />)}
          </Form.Item>
          <Form.Item
            label={'标签说明：'}
            style={helpers.isJudge(this.state.isSystem)(
              { display: 'none' },
              {}
            )}
          >
            {this.props.form.getFieldDecorator('intro', {
              rules: [
                {
                  required: true,
                  message: '请输入标签说明'
                },
                {
                  validator: helpers.antdFormValidators.length2to100
                }
              ]
            })(<TextArea rows={4} placeholder={'请输入新增标签说明'} />)}
          </Form.Item>
          <div>
            <div className={'ant-form-item-label'}>添加成员：</div>
            <AutoComplete
              dataSource={this.state.userList}
              onSelect={this.onSelect}
              onSearch={this.handleSearch}
              placeholder={'请输入要添加的标签负责人'}
              value={this.state.selectUser}
              onChange={value => {
                if (this.clearInput) {
                  this.clearInput = false;
                  this.setState({ selectUser: '' });
                } else {
                  this.setState({ selectUser: value });
                }
              }}
            />
          </div>
          <div>
            <div className={'ant-form-item-label'}>已有成员：</div>
            <div className={'scrollBlock'}>
              <span>添加后的成员在这里删除调整</span>
              {this.state.includeUser.map(item => (
                <div onClick={() => this.removeInclude(item.id)} key={item.id}>
                  {item.name}
                  <Icon type={'close'} />
                </div>
              ))}
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
