import * as React from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import { Table, Button, Input, AutoComplete, Select, Pagination } from 'antd';
const Option = Select.Option;
import { helpers } from '@/utils';
import {
  ContainerPropsInterface,
  ContainerStateInterface
} from '@/routes/containerInterface';

@connect(state => ({ state: state.sms, all: state }))
export default class Performance extends React.PureComponent<
ContainerPropsInterface<any>,
ContainerStateInterface
> {
  // 表格列
  public columns: any = [
    {
      align: 'center',
      title: '序号',
      width: 100,
      dataIndex: 'id',
      render: (text, record, index) => (
        <span>
          {index +
            1 +
            this.state.page.num * this.state.page.size -
            this.state.page.size}
        </span>
      )
    },
    {
      title: '用户名',
      dataIndex: '用户名',
      width: 150
    },
    {
      title: '所在标签',
      dataIndex: '所在标签',
      width: 150
    },
    {
      title: '所属人',
      dataIndex: '所属人',
      width: 150
    },
    {
      title: '存款金额',
      dataIndex: '存款金额',
      width: 150
    },
    {
      title: '存入时间',
      dataIndex: '存入时间',
      width: 150
    },
    {
      title: '彩金发放金额',
      dataIndex: '彩金发放金额',
      width: 150
    },
    {
      title: '绩效已发放',
      dataIndex: '绩效已发放',
      width: 150,
      render: (text, record) => (
        <Select placeholder={'请选择'} allowClear>
          <Option key={'是'}>是</Option>
          <Option key={'否'}>否</Option>
        </Select>
      )
    },
    {
      title: '达标时间',
      dataIndex: '达标时间',
      width: 150
    }
  ];

  constructor(props) {
    super(props);
    this.state = {
      list: [], // 绩效列表
      total: 0, // 全部绩效的数量
      tags: [], // 标签列表
      userList: [], // 用户的列表
      page: {
        size: helpers.isJudge(localStorage.getItem('pageSize') === null)(
          10,
          localStorage.getItem('pageSize')
        ), // 用户列表的每页大小
        num: 1 // 用户列表的当前页数
      },
      // 过滤对象组
      filterObj: {
        status: undefined, // 接通状态
        user_id: undefined, // 短信人id
        customer_name: '', // 收信人名称
        tag_id: undefined, // 标签id
        third_party_id: undefined,
        send_time_start: '', // 创建开始日期
        send_time_end: '' // 创建结束日期
      }
    };
  }

  // 用户列表
  public userList: any = [];

  public data: any = []; // 标签列表

  public componentWillMount() {
    // 获取标签列表
    if (this.state.tags.length === 0) {
      this.getTags();
    }

    // 获取短信列表
    this.getSMS();

    // 获取或绑定用户列表
    if (this.props.all.user.userList.length === 0) {
      this.getUserList();
    }
  }

  // 获取标签
  public getTags = () => {
    this.props.dispatch({
      type: 'system/tags/getTagsEpic',
      payload: {
        page_size: 1000
      }
    });
  };

  // 获取发信流水
  public getSMS = () => {
    localStorage.setItem('pageSize', this.state.page.size);

    this.props.dispatch({
      type: 'reports/getSMSEpic',
      payload: {
        page: this.state.page.num,
        page_size: this.state.page.size
      }
    });
  };

  // 获取用户列表
  public getUserList = () => {
    this.props.dispatch({
      type: 'system/getUserListEpic',
      payload: {
        page_size: 1000
      }
    });
  };

  // 筛选重置
  public handleFilterReset = () => {
    this.setState(
      {
        filterObj: {
          status: undefined, // 接通状态
          user_id: undefined, // 发信息人id
          customer_name: '', // 收信人名称
          tag_id: undefined, // 标签id
          send_time_start: '', // 创建开始日期
          send_time_end: '' // 创建结束日期
        }
      },
      this.getSMS
    );
  };

  public componentWillReceiveProps(nextProps) {
    // 标签列表绑定
    if (nextProps.all.tags.tags !== undefined) {
      if (
        nextProps.all.tags.tags.length !== 0 &&
        !_.isEqual(nextProps.all.tags.tags, this.state.tags)
      ) {
        this.setState({ tags: nextProps.all.tags.tags });
      }
    }

    // 绑定短信列表
    if (!_.isEqual(nextProps.state.sms, this.state.list)) {
      this.data = nextProps.state.sms;
      this.setState({
        list: nextProps.state.sms,
        total: nextProps.state.total
      });
    }

    // 绑定用户列表
    if (
      nextProps.all.user.userList.length !== 0 &&
      this.userList.length === 0
    ) {
      this.userList = nextProps.all.user.userList;
      const nameList = nextProps.all.user.userList.map(n => n.name);
      this.setState({
        ownerList: nameList
      });
    }
  }

  // 选择所输入筛选
  public handleOwnerSearch = value => {
    this.setState({
      ownerList: helpers.isJudge(!value)(
        [],
        this.userList
          .filter(item => item.name.indexOf(value) !== -1)
          .map(n => n.name)
      ),
      sendSmsUser: value
    });
  };

  // 筛选标签列表
  public handleFilter(filterText) {
    if (this.data.length !== 0) {
      this.setState({
        filterText,
        tags: this.data.filter(obj => obj.name.indexOf(filterText) !== -1)
      });
    }
  }

  // 列表模块渲染
  public renderList = () => {
    // 适配宽度
    let height = 455;
    let removeWidth = 0;

    if (localStorage.getItem('leftMenuExpand') === '0') {
      removeWidth = 140;
    }

    if (document.body.clientWidth < 1320) {
      if (removeWidth === 140) {
        height = 380;
      } else {
        height = 330;
      }
    } else if (document.body.clientWidth < 1833 - removeWidth) {
      height = 403;
    }

    height += 50;

    return (
      <React.Fragment>
        <Table
          columns={this.columns}
          dataSource={this.state.list}
          pagination={false}
          rowKey={'id'}
          scroll={{
            x: 1300,
            y: height
          }}
        />
        <Pagination
          pageSize={parseInt(this.state.page.size, 10)}
          showQuickJumper
          showSizeChanger
          onShowSizeChange={(page, size) => {
            this.setState({ page: { num: 1, size } }, this.getSMS);
          }}
          total={this.state.total}
          onChange={(page, size) => {
            this.setState({ page: { num: page, size } }, this.getSMS);
          }}
          defaultCurrent={1}
          style={helpers.isJudge(this.state.total === 0)(
            {
              opacity: 0
            },
            null
          )}
        />
      </React.Fragment>
    );
  };

  // 筛选模块渲染
  public renderFilter = () => {
    const dateFormat = 'YYYY/MM/DD';
    return (
      <div>
        <p>
          <span className={'notWrap'}>
            用户名：
            <Input
              value={this.state.filterObj.customer_name}
              type={'text'}
              placeholder={'请输入用户名'}
              onChange={e =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    customer_name: e.target.value
                  }
                })
              }
            />
          </span>
          <span className={'notWrap'} style={{ marginBottom: 20 }}>
            当前标签：
            <Select
              allowClear
              placeholder={'不限'}
              onChange={value =>
                this.setState({
                  filterObj: { ...this.state.filterObj, tag_id: value }
                })
              }
              value={this.state.filterObj.tag_id}
            >
              {this.state.tags.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </span>
          <span className={'notWrap2'}>
            所属人：
            <AutoComplete
              dataSource={this.state.ownerList}
              onSearch={this.handleOwnerSearch}
              placeholder={'请输入所属人'}
              value={this.state.sendSmsUser}
              onSelect={value => this.setState({ sendSmsUser: value })}
            />
          </span>
          <span className={'notWrap'}>
            绩效是否发放：
            <Select allowClear placeholder={'不限'}>
              <Option key={'1'} value={1}>
                已发放
              </Option>
              <Option key={'2'} value={2}>
                未发放
              </Option>
            </Select>
            <Button type={'primary'} onClick={this.getSMS}>
              筛选
            </Button>
            <Button onClick={this.handleFilterReset}>重置</Button>
          </span>
        </p>
      </div>
    );
  };

  public render() {
    return (
      <React.Fragment>
        {this.renderFilter()}
        {this.renderList()}
      </React.Fragment>
    );
  }
}
