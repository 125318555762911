import * as React from 'react';
import switchMenuStyle from './switchMenuStyle.scss';
import { history } from '@/router';
import { Badge } from 'antd';

interface ComponentProps {
  [random: string]: any;
}

interface ComponentState {
  [random: string]: any;
}

export default class SwitchMenu extends React.PureComponent<
  ComponentProps,
  ComponentState
> {
  public title1 = '';
  public pathname = location.pathname;

  constructor(props) {
    super(props);
    this.state = {
      list: [],
      url: '',
      rootUrl: '',
    };
  }

  public componentWillMount() {
    this.setList();
  }

  public setList() {
    // 显示选项卡菜单，并且不为三级菜单
    for (const item of this.props.menuList) {
      if (location.pathname.indexOf(item.url) === 0) {
        if (
          item.switchShow === true &&
          location.pathname.split('/').length !== 6
        ) {
          for (const n of item.child) {
            if (location.pathname.indexOf(n.url) > 0) {
              this.setState({
                list: item.child,
                url: n.url,
                rootUrl: item.url,
              });
            }
          }
        } else {
          this.setState({ list: [], url: '', rootUrl: '' });
        }
      }
    }
  }

  public componentWillReceiveProps(nextProps) {
    if (nextProps.pathname !== this.pathname) {
      this.pathname = nextProps.pathname;
      this.setList();
    }
  }

  public render() {
    const menuUrl = location.pathname.split('/');
    let tabFlag = false;
    // 判断为客户中心模块路由显示tab
    if (
      menuUrl &&
      menuUrl[2] === 'callCenter' &&
      menuUrl[3] !== 'taskManager'
    ) {
      tabFlag = true;
    }
    return tabFlag ? (
      <React.Fragment>
        {this.state.list.length === 0 ? null : (
          <div className={switchMenuStyle.switchMenu}>
            {this.state.list.map((item, index) => {
              return item.show ? (
                <span
                  className={
                    this.state.url === item.url ? switchMenuStyle.active : null
                  }
                  onClick={() => history.push(this.state.rootUrl + item.url)}
                >
                  {item.title}
                  <Badge
                    count={this.props.num[index]}
                    style={{
                      backgroundColor:
                        this.state.url === item.url ? '#ab47bc' : '#16a085',
                    }}
                  />
                </span>
              ) : null;
            })}
          </div>
        )}
      </React.Fragment>
    ) : null;
  }
}
