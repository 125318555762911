import { map, mergeMap } from 'rxjs/operators';
import { Epic, ofType } from 'redux-observable';
import { helpers, ls } from '../utils';
import { userAPI } from '../services';
import { Modal } from 'antd';

import _ from 'lodash';

interface Payload {
  [random: string]: any;
}
// dispatch传进来的action类型
interface PayloadIn {
  type: string;
  payload: {
    [random: string]: any;
  };
}

// epic传到reducer里的action类型
interface PayloadOut {
  type: string;
  payload: {
    [random: string]: any;
  };
}

// 获取登录信息
export const getLoginInfo: Epic<PayloadIn, PayloadOut> = (action$, store) => {
  return action$.pipe(
    ofType('main/getLoginInfoEpic'),
    mergeMap(action => {
      return userAPI.handleGetLoginInfo().pipe(
        map(res => {
          if (res !== null) {
            ls.set('polylinkToken', _.get(res.data.polylink_account, 'token'));

            if (
              _.get(res.data.thirdPartyAccount, 'token') === '' &&
              _.get(res.data.thirdPartyAccount, 'error_message') !== ''
            ) {
              Modal.warning({
                title: '提示',
                content: _.get(res.data.thirdPartyAccount, 'error_message')
              });
            }
            return helpers.createAction<Payload>('main/getLoginInfo', res.data);
          }
          return helpers.createAction<typeof action.payload>('no');
        })
      );
    })
  );
};

// 获取登出
export const logout: Epic<PayloadIn, PayloadOut> = (action$, store) => {
  return action$.pipe(
    ofType('main/logoutEpic'),
    mergeMap(action => {
      return userAPI.handleLogout().pipe(
        map(res => {
          if (res !== null) {
            ls.removeAll();
            // 登陆刷新清除data.ts数据引用
            location.href = '/entry/login';
            return helpers.createAction<Payload>('main/logout', {});
          }
          return helpers.createAction<typeof action.payload>('no');
        })
      );
    })
  );
};
