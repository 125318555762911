import * as React from 'react';
import { Button, Form, Checkbox, Input, Select } from 'antd';
import { InputPWD } from '@/components';
import loginStyle from './login.scss';
import { helpers } from '@/utils';
const Option = Select.Option;

import {
  ContainerPropsInterface,
  ContainerStateInterface
} from '@/routes/containerInterface';

import _ from 'lodash';

export default class LoginPage extends React.PureComponent<
  ContainerPropsInterface<any>,
  ContainerStateInterface
> {
  constructor(props) {
    super(props);
    this.state = {
      email: helpers.isJudge(localStorage.getItem('email') === null)(
        '',
        localStorage.getItem('email')
      ), // 账户邮箱
      loading: {
        login: false // 点"登录"按钮时出否显示loading图
      },
      rememberPassword:
        localStorage.getItem('email') === null ||
        helpers.isJudge(localStorage.getItem('email') === '')(false, true)
    };
  }

  public phoneType = `${process.env.phoneType}`;

  // 电话类型id
  public phone_line: number =
    parseInt(
      helpers.isJudge(localStorage.getItem('phone_line') === '')(
        0,
        localStorage.getItem('login_phone_line')
      ),
      10
    ) || 2;

  // 提交"所填信息"到接口
  public handleLogin = () => {
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      // 清除
      this.props.dispatch({
        type: 'main/logout'
      });

      // 清除坐席状态
      this.props.dispatch({
        type: 'polylink/getAgentInfo',
        payload: {}
      });

      this.props.dispatch({
        type: 'entry/login',
        payload: {
          email: values.email,
          password: values.password,
          rememberPassword: this.state.rememberPassword,
          phone_line: this.phoneType === '' ? this.phone_line : 2
        }
      });
    });
  };

  public render() {
    return (
      <div className={loginStyle.login}>
        <h3>电维CRM系统</h3>
        <p>登录您的账号</p>
        <Form.Item>
          {this.props.form.getFieldDecorator('email', {
            initialValue: this.state.email,
            rules: [
              {
                required: true,
                message: '请输入账号'
              },
              {
                validator: helpers.antdFormValidators.email
              }
            ]
          })(
            <Input
              placeholder={'输入账号'}
              onChange={e => {
                this.setState({ email: e.target.value });
              }}
              onKeyUp={e => e.keyCode === 13 && this.handleLogin()}
            />
          )}
        </Form.Item>
        <Form.Item>
          {this.props.form.getFieldDecorator('password', {
            initialValue: helpers.isJudge(
              localStorage.getItem('password') === null
            )('', localStorage.getItem('password')),
            rules: [
              {
                required: true,
                message: '请输入密码'
              },
              {
                validator: helpers.antdFormValidators.password
              }
            ]
          })(
            <InputPWD
              placeholder={'登录密码'}
              onKeyUp={e => e.keyCode === 13 && this.handleLogin()}
            />
          )}
        </Form.Item>
        <div className={loginStyle.rememberPassword}>
          <Checkbox
            onChange={e => {
              this.setState({ rememberPassword: e.target.checked });
            }}
            checked={this.state.rememberPassword}
          >
            记住密码
          </Checkbox>
        </div>
        <Button
          onClick={this.handleLogin}
          loading={this.state.loading.login}
          type={'primary'}
          size={'large'}
          block
        >
          登录
        </Button>
        {this.phoneType === '' ? (
          <div>
            切换电话类型：
            <Select
              defaultValue={this.phone_line}
              onChange={value => {
                this.phone_line = value;
              }}
            >
              <Option key={'2'} value={2}>
                米糠云
              </Option>
              <Option key={'1'} value={1}>
                polylink
              </Option>
            </Select>
          </div>
        ) : null}
        <div>
          <span>忘记密码?请联系管理员</span>
        </div>
      </div>
    );
  }
}
