import * as React from 'react';
import entryStyle from './entry.scss';
import { multiPlatform } from '@/multiPlatform';
import { Login } from '@/routes/index';
import { RainyDay } from '@/utils/index';
import { helpers } from '@/utils';
import {
  ContainerPropsInterface,
  ContainerStateInterface
} from '@/routes/containerInterface';

export default class Entry extends React.PureComponent<
  ContainerPropsInterface<any>,
  ContainerStateInterface
> {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth, // 页面宽度
      height: window.innerHeight, // 页面高度
      pathname: location.pathname // 当前页面路径
    };
  }

  // canvas ref
  public imageCanvas: any;
  public imageCanvasRef = ref => (this.imageCanvas = ref);

  // 背景图
  public Img01 = require('./img/bg.jpg');

  public componentWillReceiveProps(nextProps) {
    // 当前路径
    if (nextProps.location.pathname !== this.state.pathname) {
      this.setState({ pathname: nextProps.location.pathname });
    }
  }

  public engine: any;

  public componentDidMount() {
    // 背景滴雨canvas
    this.engine = new RainyDay(
      this.imageCanvas,
      this.Img01,
      window.innerWidth,
      window.innerHeight
    );
    this.engine.gravity = this.engine.GRAVITY_NON_LINEAR;
    this.engine.trail = this.engine.TRAIL_DROPS;

    this.engine.rain([this.engine.preset(0, 2, 500)]);

    this.engine.rain(
      [
        this.engine.preset(3, 3, 0.88),
        this.engine.preset(5, 5, 0.9),
        this.engine.preset(6, 2, 1)
      ],
      1000
    );
  }

  public componentWillUnmount() {
    this.engine.clear();
  }

  public LoginComponent = multiPlatform.loginMulti(Login);

  public render() {
    const LoginComponent = this.LoginComponent;

    return (
      <div className={entryStyle.entry + ' entry'}>
        <p />
        <canvas
          width={this.state.width}
          height={this.state.height}
          ref={this.imageCanvasRef}
        />
        <div
          className={helpers.isJudge(this.state.pathname === '/entry/login')(
            entryStyle.active,
            null
          )}
        >
          <LoginComponent />
        </div>
      </div>
    );
  }
}
