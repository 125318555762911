import * as React from 'react';
import * as _ from 'lodash';
import { helpers } from '@/utils';
import { Table, Button, Input, Divider, Modal } from 'antd';
const confirm = Modal.confirm;
import {
  ContainerPropsInterface,
  ContainerStateInterface
} from '@/routes/containerInterface';

import RolesAdd from './rolesAddPage';
import RolesEdit from './rolesEditPage';
import RolesSet from './rolesSetPage';
import { multiPlatform } from '@/multiPlatform';
export default class Roles extends React.PureComponent<
  ContainerPropsInterface<any>,
  ContainerStateInterface
> {
  // 表格列
  public columns: any = [
    {
      align: 'center',
      title: '序号',
      width: 100,
      dataIndex: 'id',
      render: (text, record, index) => <span>{index + 1}</span>
    },
    {
      title: '角色组',
      dataIndex: 'role_name'
    },
    {
      title: '角色描述',
      dataIndex: 'intro'
    },
    {
      title: '角色组人数',
      dataIndex: 'users_count'
    },
    {
      title: '创建人',
      dataIndex: 'add_by'
    },
    {
      title: '配置权限',
      dataIndex: '配置权限',
      width: 150,
      render: (text, record) => (
        <p>
          {helpers.isJudge(record.id === 1)(
            null,
            helpers.isJudge(this.state.setFlag)(
              <Button
                onClick={() => {
                  this.setState({ currentRoles: { id: record.id } });
                  this.props.dispatch({
                    type: 'products/roles',
                    payload: {
                      id: record.id
                    }
                  });
                }}
              >
                配置
              </Button>,
              null
            )
          )}
        </p>
      )
    },
    {
      title: '操作',
      dataIndex: 'action',
      width: 150,
      render: (text, record) => (
        <p>
          {helpers.isJudge(record.id === 1)(
            null,
            <span>
              {helpers.isJudge(this.state.editFlag)(
                <a
                  href={'javascript:;'}
                  onClick={() =>
                    this.openEditWindow({
                      ...record,
                      editTimes: this.editTimes++
                    })
                  }
                >
                  编辑
                </a>,
                null
              )}
              <Divider type={'vertical'} />
              {helpers.isJudge(this.state.delFlag)(
                <a
                  href={'javascript:;'}
                  onClick={() => this.confirmDelete(record.id)}
                >
                  删除
                </a>,
                null
              )}
            </span>
          )}
        </p>
      )
    }
  ];

  // 编辑次数
  public editTimes = 0;

  constructor(props) {
    super(props);
    this.state = {
      list: [], // 角色列表
      editData: {}, // 编辑的角色信息
      filterText: '', // 筛选的权限名,
      currentRoles: {
        // 当前角色菜单信息
        id: ''
      },
      editFlag: false,
      delFlag: false,
      setFlag: false,
      addFlag: false
    };
  }

  public RolesAddComponent = multiPlatform.rolesAddMulti(RolesAdd); // 角色添加
  public RolesEditComponent = multiPlatform.rolesEditMulti(RolesEdit); // 角色修改
  public RolesSetComponent = multiPlatform.rolesSetMulti(RolesSet); // 角色设置

  public data: any = []; // 角色列表

  public componentWillMount() {
    // 获取角色列表
    if (this.state.list.length === 0) {
      this.getRoles();
    }
    this.getRolesAllMenu(); // 获取角色所有菜单
  }

  // 显示编辑窗口
  public openEditWindow(item) {
    this.setState({ editData: item });
  }

  // 获取角色
  public getRoles = () => {
    this.props.dispatch({
      type: 'system/roles/getRolesEpic',
      payload: {
        page_size: 1000
      }
    });
  };

  // 获取角色所有菜单
  public getRolesAllMenu = () => {
    this.props.dispatch({
      type: 'systems/permissions',
      payload: {}
    });
  };

  public componentWillReceiveProps(nextProps) {
    // 绑定角色列表
    if (
      nextProps.state.roles.length !== 0 &&
      !_.isEqual(nextProps.state.roles, this.data)
    ) {
      this.data = nextProps.state.roles;
      this.setState({ list: nextProps.state.roles });
    }
    const buttonPermission = nextProps.all.main.buttonPermission;
    this.setState({
      editFlag: _.includes(buttonPermission, 64),
      delFlag: _.includes(buttonPermission, 67),
      addFlag: _.includes(buttonPermission, 64),
      setFlag: _.includes(buttonPermission, 68)
    });
  }

  // 删除角色组
  public confirmDelete(deleteId: number) {
    const self = this;
    confirm({
      title: '您确定要删除该角色组吗？',
      onOk() {
        self.props.dispatch({
          type: 'system/roles/deleteRolesEpic',
          payload: {
            id: deleteId
          }
        });
      },
    });
  }

  // 筛选权限名
  public handleFilter(filterText) {
    if (this.data.length !== 0) {
      this.setState({
        filterText,
        list: this.data.filter(obj => obj.role_name.indexOf(filterText) !== -1)
      });
    }
  }
  // 筛选模块渲染
  public renderFilter = () => {
    const RolesAddComponent = this.RolesAddComponent;
    return (
      <div>
        <p>
          所在角色组：
          <Input
            value={this.state.filterText}
            type={'text'}
            placeholder={'请输入名称'}
            onChange={e => this.setState({ filterText: e.target.value })}
          />
          <Button
            type={'primary'}
            onClick={() => this.handleFilter(this.state.filterText)}
          >
            筛选
          </Button>
          <Button
            onClick={() =>
              this.setState({
                filterText: ''
              })
            }
          >
            重置
          </Button>
          {helpers.isJudge(this.state.addFlag)(<RolesAddComponent />, null)}
        </p>
      </div>
    );
  };

  public render() {
    const RolesEditComponent = this.RolesEditComponent;
    const RolesSetComponent = this.RolesSetComponent;
    return (
      <React.Fragment>
        {this.renderFilter()}
        <Table
          columns={this.columns}
          dataSource={this.state.list}
          rowKey={'id'}
          pagination={helpers.isJudge(this.state.list.length === 0)(
            false,
            true
          )}
        />
        <RolesEditComponent editData={this.state.editData} />
        <RolesSetComponent />
      </React.Fragment>
    );
  }
}
