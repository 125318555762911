import { Observable, pipe, of } from 'rxjs';

import { map, mergeMap } from 'rxjs/operators';
import { Epic, ofType } from 'redux-observable';
import { helpers } from '@/utils';
import { rolesAPI } from '@/services';

interface Payload {
  [random: string]: any;
}
// dispatch传进来的action类型
interface PayloadIn {
  type: string;
  payload: {
    [random: string]: any;
  };
}

// epic传到reducer里的action类型
interface PayloadOut {
  type: string;
  payload: {
    [random: string]: any;
  };
}

// 获取标签信息
export const getRoles: Epic<PayloadIn, PayloadOut> = (action$, store) => {
  return action$.pipe(
    ofType('system/roles/getRolesEpic'),
    mergeMap(action => {
      return rolesAPI.handleGetRoles(action.payload).pipe(
        map(res => {
          if (res !== null) {
            return helpers.createAction<Payload>(
              'system/roles/getRoles',
              res.data.data
            );
          }
          return helpers.createAction<typeof action.payload>('no');
        })
      );
    })
  );
};

// 获取标签信息
export const deleteRoles: Epic<PayloadIn, PayloadOut> = (action$, store) => {
  return action$.pipe(
    ofType('system/roles/deleteRolesEpic'),
    mergeMap(action => {
      return rolesAPI.handleDeleteRoles(action.payload).pipe(
        map(res => {
          if (res !== null) {
            return helpers.createAction<Payload>(
              'system/roles/deleteRoles',
              action.payload.id
            );
          }
          return helpers.createAction<typeof action.payload>('no');
        })
      );
    })
  );
};

// 更新标签信息
export const updateRoles: Epic<PayloadIn, PayloadOut> = (action$, store) => {
  return action$.pipe(
    ofType('system/roles/updateRolesEpic'),
    mergeMap(action => {
      return rolesAPI.handleUpdateRoles(action.payload).pipe(
        map(res => {
          if (res !== null) {
            return helpers.createAction<Payload>(
              'system/roles/updateRoles',
              res
            );
          }
          return helpers.createAction<typeof action.payload>('no');
        })
      );
    })
  );
};

// 添加标签信息
export const addTag: Epic<PayloadIn, PayloadOut> = (action$, store) => {
  return action$.pipe(
    ofType('system/roles/addRolesEpic'),
    mergeMap(action => {
      return rolesAPI.handleAddRoles(action.payload).pipe(
        map(res => {
          if (res !== null) {
            return helpers.createAction<Payload>(
              'system/roles/addRoles',
              res.data
            );
          }
          return helpers.createAction<typeof action.payload>('no');
        })
      );
    })
  );
};

// 获取标签信息
export const getRoleInfo: Epic<PayloadIn, PayloadOut> = (action$, store) => {
  return action$.pipe(
    ofType('system/roles/getRolesEpic'),
    mergeMap(action => {
      return rolesAPI.handleGetRoles(action.payload).pipe(
        map(res => {
          if (res !== null) {
            return helpers.createAction<Payload>(
              'system/roles/getRoles',
              res.data.data
            );
          }
          return helpers.createAction<typeof action.payload>('no');
        })
      );
    })
  );
};
