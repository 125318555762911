import React from 'react';
import { connect } from 'react-redux';
import Tasks from '@/routes/callCenter/tasks/tasksPage';
import { Select, Button, Input, Icon, message, Popover } from 'antd';
import { history } from '@/router';
const Option = Select.Option;
import { helpers } from '@/utils';
import * as _ from 'lodash';

@connect(state => ({
  state: state.callCenter,
  all: state,
}))
export default class MembersWithoutDepositMulti extends Tasks {
  public constructor(props) {
    super(props);
    this.state = {
      // state也可重写
      ...this.state,
    };
  }

  public getColumns = () => {
    return [
      {
        title: '客户编号',
        dataIndex: 'id',
        align: 'center',
        width: 100,
      },
      {
        title: '用户名',
        dataIndex: 'customer.username',
        width: 220,
        render: (text, record) => (
          <span className={'greenPointCell'}>
            {helpers.isJudge(record.is_handled === 0)(<span />, null)}
            {text}
          </span>
        ),
      },
      {
        title: '真实姓名',
        align: 'center',
        dataIndex: 'customer.x_real_name',
        width: 150,
      },
      {
        title: '行销结果',
        align: 'center',
        dataIndex: 'market_status_id',
        width: 150,
        render: text => (
          <span>{_.get(this.state.marketStatus[text], 'name')}</span>
        ),
      },
      {
        title: '手机号码',
        align: 'right',
        dataIndex: 'customer.x_phone',
        width: 200,
        minWidth: 180,
        render: (text, record) => (
          <p className={'phone'}>
            <span
              style={{
                lineHeight: '14px',
                width: 100,
                overflow: 'hidden',
                padding: 0,
              }}
            >
              {text}
            </span>{' '}
            {helpers.isJudge(this.state.messageFlag)(
              <Icon
                type={'mail'}
                theme={'filled'}
                style={{ fontSize: '18px', marginLeft: 15, cursor: 'pointer' }}
                onClick={() =>
                  this.setState({
                    showTimes: this.state.showTimes + 1,
                    customer_id: record.customer_id,
                    relationshipId: record.id,
                  })
                }
              />,
              null,
            )}{' '}
            {helpers.isJudge(this.state.phoneFlag)(
              helpers.isJudge(this.state.extensionState === 0)(
                <Icon
                  type={'phone'}
                  theme={'filled'}
                  style={{
                    fontSize: '18px',
                    marginLeft: 15,
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    this.call(record.customer_id, record.id, record.task_id)
                  }
                />,
                <Icon
                  type={'phone'}
                  theme={'filled'}
                  style={{
                    fontSize: '18px',
                    marginLeft: 15,
                    cursor: 'pointer',
                    color: '#6c7c8e',
                  }}
                  onClick={() => message.warning('当前状态，无法呼叫')}
                />,
              ),
              null,
            )}
          </p>
        ),
      },
      {
        title: '呼出次数',
        dataIndex: 'callout_count',
        align: 'center',
        width: 150,
      },
      {
        title: '任务名称',
        dataIndex: '任务名称',
        align: 'center',
        width: 250,
        render: (text, record) => <span>{record.task_name}</span>,
      },
      {
        title: '导入时间',
        align: 'center',
        dataIndex: 'customer',
        width: 150,
        render: text => (
          <time>
            {text.import_date.slice(0, 10)}
            <br />
            {text.import_date.slice(10)}
          </time>
        ),
      },
      {
        title: '回访备注',
        dataIndex: '回访备注',
        align: 'center',
        width: 150,
        minWidth: 150,
        render: (text, record) => {
          // 第一条备注
          const [firstNote] = record.call_notes || [undefined];
          const placeholder = firstNote ? (
            <Popover content={<span>{firstNote.note}</span>}>
              <span
                style={{
                  maxWidth: 'calc(100% - 30px)',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  lineHeight: '1em',
                  verticalAlign: 'inherit',
                  display: 'inline-block',
                }}
              >
                {firstNote.note}
              </span>
            </Popover>
          ) : null;
          return helpers.isJudge(
            _.includes(this.props.all.main.buttonPermission, 112),
          )(
            <React.Fragment>
              {placeholder}
              <a
                href={'javascript:;'}
                onClick={() => {
                  history.push(
                    history.location.pathname +
                      '/' +
                      record.customer_id +
                      '/' +
                      record.id,
                  );
                }}
              >
                全部
              </a>
            </React.Fragment>,
            null,
          );
        },
      },
      {
        title: '已添加微信或QQ',
        dataIndex: 'customer',
        width: 200,
        render: (text, record) => {
          const { marketingAccounts } = this.state;

          const {
            wechat_market_account_id: wechat,
            qq_group_market_account_id: qq,
          } = text;
          // 将所有的营销账号转换为下拉选项组件列表
          const options = marketingAccounts
            .filter(item => {
              // 后端接受任意类型数据的输入以及返回
              // 为了防止不合法数据的显示，前端需要进行过滤
              // 这里应该由后端进行后期调整
              const type = item.account_type.toLowerCase();
              return type === 'qq' || type === '微信';
            })
            .map(item => {
              return (
                <Option key={item.id}>
                  {Tasks.formatMarketAccountDisplay(item)}
                </Option>
              );
            });
          // 设定显示的值
          // 如果用户中绑定了QQ则使用QQ信息显示
          // 若绑定了微信，则使用微信信息显示
          // 否则为空
          // 查找对应的营销账号
          const marketAccount = this.getMarketAccountFromId(wechat || qq);
          const valueData = Tasks.formatMarketAccountDisplay(marketAccount);

          // 返回选项框
          return helpers.isJudge(
            _.includes(this.props.all.main.buttonPermission, 118),
          )(
            <Select
              style={{ width: '150px' }}
              allowClear
              placeholder={'请选择'}
              value={valueData}
              onChange={value => {
                const obj: any = {};
                // 查找对应的营销账号
                const account = this.getMarketAccountFromId(
                  parseInt(value, 10),
                );

                // Web并没有返回枚举类型来区别微信和QQ
                // 这个功能经过与Web确认，目前他们没办法修改
                // 所以先用string字段来判断类型区别
                if (account) {
                  if (account.account_type.toLowerCase() === 'qq') {
                    obj.qq_group_id = value;
                  } else if (account.account_type === '微信') {
                    obj.wechat_id = value;
                  }
                } else {
                  obj.wechat_id = '';
                  obj.qq_group_id = '';
                }
                this.updateCustomersInfo(record.customer.id, obj);
              }}
            >
              {options}
            </Select>,
            null,
          );
        },
      },
    ];
  };

  // 过滤渲染
  public renderFilter = () => {
    const dateFormat = 'YYYY/MM/DD';
    return (
      <div>
        <p>
          <span className={'notWrap'}>
            行销结果：
            <Select
              placeholder={'不限'}
              onChange={value =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    market_status_id: value,
                  },
                })
              }
              value={this.state.filterObj.market_status_id}
            >
              {this.state.marketStatus.map(item => {
                return <Option key={item.id}>{item.name}</Option>;
              })}
            </Select>
          </span>
          <span className={'notWrap'}>
            任务名称：
            <Input
              placeholder={'请输入任务名称'}
              value={this.state.filterObj.task_name}
              onChange={e =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    task_name: e.target.value,
                  },
                })
              }
            />
          </span>
          <span className={'notWrap'}>
            用户名：
            <Input
              placeholder={'请输入用户名'}
              value={this.state.filterObj.username}
              onChange={e =>
                this.setState({
                  filterObj: {
                    ...this.state.filterObj,
                    username: e.target.value,
                  },
                })
              }
            />
          </span>
          <span className={'notWrap'}>
            呼出次数：
            <Select
              placeholder={'不限'}
              value={this.state.filterObj.callout_count}
              onChange={value =>
                this.setState({
                  filterObj: { ...this.state.filterObj, callout_count: value },
                })
              }
            >
              <Option key={' '}>不限</Option>
              <Option key={'0'}>0次</Option>
              <Option key={'1'}>1次</Option>
              <Option key={'2'}>1次以上</Option>
            </Select>
            <Button type={'primary'} onClick={this.filterSelect}>
              筛选
            </Button>
            <Button
              type={'primary'}
              className={'pBtn'}
              onClick={this.handleFilterReset}
            >
              重置
            </Button>
          </span>
        </p>
      </div>
    );
  };

  public render() {
    return <React.Fragment>{super.render()}</React.Fragment>;
  }
}
