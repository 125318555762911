import * as _ from 'lodash';
import { Action } from '../reducers/reducerInterface';
import moment from 'moment';

const initialStore = {
  calls: [], // 通话记录列表
  newCall: {}, // 新的通话记录
  total: 0 // 通话记录总数
};

export type AppStoreType = typeof initialStore;

export default function callsPageReducer(
  state: any = initialStore,
  action: Action
) {
  try {
    switch (action.type) {
      case 'reports/getCalls': {
        const calls = action.payload.list;
        const total = action.payload.total;
        return _.assign({}, state, { calls, total });
      }
      case 'reports/setCall': {
        const newCall = action.payload;
        return _.assign({}, state, { newCall });
      }
      default:
        return state;
    }
  } catch (e) {
    return state;
  }
}
